import React from 'react';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './../reportes-jss'
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import { LanguageConsumer, LanguageCxt } from './../../../../language/LanguageContext';

import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

//Filtros
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';

//Auxiliars
import { convertToStringArray } from '../../../../utils/helpers'
import { textLabelsEs } from '../../../../utils/tableTexts';
import enLocale from 'moment/locale/en-au';
import esLocale from 'moment/locale/es';

import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
// EGS 07/05/2019 - eliminar columna de firmantes 
const keys = ['proveedor', 'tituloObra', 'pais', 'tipoContrato', 'elaboracionContrato', 'firmaContrato', 'estatus'];
const locale = {
  en: enLocale,
  es: esLocale
}

class AdvFilter extends React.Component {
  state = {
    //Notifications
    open: false,
    errorMessage: "errMsg",
    //Filters
    fechaInicio: null,
    fechaFin: null,
    proveedor: 0,
    pais: 0,
    estatus: 0,
    //Catalogues
    paises: [],
    estatuses: [],
    array: [],
    //Tables
    columns: [],
    data: [],
    //Loading
    loading: false,
    dataCsv: []
  }

  //Inputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //AHDA 19/02/2019
  handleClearFilters = () => {
    this.setState({
      fechaInicio: null,
      fechaFin: null,
      proveedor: 0,
      pais: 0,
      estatus: 0,
      loading: true
    });

    const request = {
      fechaInicio: "0",
      fechaFin: "0",
      proveedor: 0,
      pais: 0,
      estatus: 0
    }
    this.handleFetch(request);
  }

  //Notifications
  handleClose = () => {
    this.setState({ open: false });
  };

  //Dates
  handleDateChangeI = (date) => {
    this.setState({ fechaInicio: date });
  }

  handleDateChangeF = (date) => {
    this.setState({ fechaFin: date });
  }

  //Download csv
  handleDownload = (data) => {
    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'obras',
      screen: 'reporteEstatusContractual'
    }
    apiPOST('/audit/add', body);

    //AHDA 02/04/2019
    //Correccion bug, desfase de Excel
    // EGS 07/05/2019 - eliminar columna de firmantes 
    const dataCsv = [["Proveedor", "Título de la Obra", "País", "Tipo de Contrato", "Elaboración de contrato", "Firma de contrato", "Estatus"]]

    for (const x in data) {
      const object = []
      object.push(data[x]);
      dataCsv.push(object);
    }

    const download = document.createElement("a");
    document.body.appendChild(download); //AHDA 23/05/2019
    const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: 'application/vnd.ms-excel' });
    download.href = window.URL.createObjectURL(blob);
    download.download = "Estatuscontractual.csv";
    download.click();
  }

  componentDidMount() {
    this.setState({ loading: true });

    const { lang } = this.context;
    this.setState(
      {
        // EGS 07/05/2019 - eliminar columna de firmantes 
        columns: [lang.reports.provider, lang.reports.title, lang.reports.country, lang.reports.contractType,
        lang.reports.contractElaboration, lang.reports.contractSignin, lang.reports.status]
      }
    )

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 3 }, true).then(
      response => {
        if (response.status === 200) {
          this.setState({ paises: response.data })
        }
      })

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 21 }, true).then(
      response => {
        if (response.status === 200) {

          this.setState({ estatuses: response.data })
        }
      })

    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 7 }, true).then(response => {
      if (response.status === 200) {

        this.setState({ array: response.data })
      }
    })

    //Llamada carga inicial
    const request = {
      fechaInicio: "0",
      fechaFin: "0",
      proveedor: 0,
      pais: 0,
      estatus: 0
    }
    this.handleFetch(request);
  }

  //Llamada de busqueda
  searchs = () => {
    this.setState({ loading: true });
    var fi = this.state.fechaInicio === null ? 0 : new Date(this.state.fechaInicio);
    var ff = this.state.fechaFin === null ? 0 : new Date(this.state.fechaFin);

    const request = {
      fechaInicio: fi === 0 ? 0 : fi.getFullYear() + "-" + (fi.getMonth() + 1) + "-" + fi.getDate(),
      fechaFin: ff === 0 ? 0 : ff.getFullYear() + "-" + (ff.getMonth() + 1) + "-" + ff.getDate(),
      proveedor: this.state.proveedor,
      pais: this.state.pais,
      estatus: this.state.estatus
    }
    const ds = new Date(fi);
    const df = new Date(ff);

    if (ds.getTime() > df.getTime()) {
      this.setState({ open: true, loading: false, errorMessage: "dateError" });
    } else {
      this.handleFetch(request);
    }
  }

  handleFetch(request) {
    apiPOST('/reportes/estatusContractual', request).then(
      response => {
        if (response.status === 200) {

          let table = []
          for (const x in response.data) {
            let row = []

            //AHDA 18/06/2019: Validations for nulls
            const item = response.data[x];  
            row.push(item.proveedor ? item.proveedor : "-")
            row.push(item.tituloObra ? item.tituloObra : "-")
            row.push(item.pais ? item.pais : "-")
            // EGS 24/04/2019 - Evitar nulls evitando tronar el multiselect de los filtroos 
            row.push(item.tipoContrato != null? item.tipoContrato: "-")
            row.push(item.elaboracionContrato ? item.elaboracionContrato : ".")
            row.push(item.firmaContrato? item.firmaContrato : "-") 
            // EGS 07/05/2019 - eliminar columna de firmantes 
            // row.push(item.firmantes? item.firmantes : "-")
            row.push(item.estatus ? item.estatus : "-")
            table.push(row)
          }

          this.setState({ data:table, loading: false, dataCsv: table })
        } else {
          this.setState({ open: true, loading: false, errorMessage: "errMsg" });
        }
      }).catch(
        error => {
          this.setState({ open: true, loading: false, errorMessage: "errMsg" });
        });
  }

  render() {
    const { classes } = this.props;
    const { columns, data } = this.state;
    const { fechaInicio } = this.state;
    const { fechaFin } = this.state;
    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: false,
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs,
      customToolbar: () => {
        return <LanguageConsumer>
          {
            ({ lang }) =>
              <Tooltip title={lang.reports.print} placement="bottom">
                   {/* JCMY 26/03/2019 */}
              {/* Cambiaron la variable y con eso dejo de imprimir el reporte */}
                <IconButton aria-label="Imprimir" onClick={() => this.handleDownload(this.state.data)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
          }
        </LanguageConsumer>
      }
    };

    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className="marginTopLogo">
              <PapperBlock title={lang.reports.reports} desc="">
                {/* Inicio Filtros */}
                {this.state.loading ? <LoaderFull /> : ''}
                <form className={classes.container}>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                      <Grid item xs={6} sm={3} md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            keyboard={false}
                            helperText={lang.reports.startDate}
                            className={classes.formControl}
                            format="DD/MM/YYYY"
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            value={fechaInicio}
                            onChange={this.handleDateChangeI}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            disableFuture={true}
                            clearable={true}
                            cancelLabel={lang.common.cancel}
                            clearLabel={lang.common.clear}
                            placeholder="DD/MM/AAAA"
                          />
                          </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            keyboard={false}
                            helperText={lang.reports.endDate}
                            className={classes.formControl}
                            format="DD/MM/YYYY"
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            value={fechaFin}
                            onChange={this.handleDateChangeF}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            disableFuture={true}
                            minDate={fechaInicio}
                            clearable={true}
                            disabled={fechaInicio == null ? true : false}
                            cancelLabel={lang.common.cancel}
                            clearLabel={lang.common.clear}
                            placeholder="DD/MM/AAAA"
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.proveedor} onChange={this.handleChange} name="proveedor" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0} >{lang.reports.all}</MenuItem>
                          {
                            this.state.array.map((arr, idx) => (
                              <MenuItem value={arr.value} key={`arr${idx}`}>
                                {arr.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.compProv}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.pais} onChange={this.handleChange} name="pais" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}> {lang.reports.all} </MenuItem>
                          {
                            this.state.paises.map((pais, idx) => (
                              <MenuItem value={pais.value} key={`pais${idx}`}>
                                {pais.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.country}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <FormControl className={classes.formControl}>
                        <Select value={this.state.estatus} onChange={this.handleChange} name="estatus" displayEmpty className={classes.selectEmpty}>
                          <MenuItem value={0}>{lang.reports.all}</MenuItem>
                          {
                            this.state.estatuses.map((esta, idx) => (
                              <MenuItem value={esta.value} key={`esta${idx}`}>
                                {esta.data}
                              </MenuItem>))
                          }
                        </Select>
                        <FormHelperText>{lang.reports.status}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    {/* CH 05/04/2019
                    Se centran botones en versión móvil */}
                    <Grid item xs={12} sm={12} md={12} className={classes.centerOnlySmall}>
                      <Button onClick={this.searchs} variant="contained" color="primary" className={classes.button}>
                        {lang.common.search}
                      </Button>
                      <Button onClick={this.handleClearFilters} variant="contained" color="primary" className={classes.button}>
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                {/* Fin Filtros */}

                <div className={classes.contractual}>
                  <MUIDataTable
                    title={`${lang.reports.repContractualState} (${this.state.data.length} ${lang.reports.results})`}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </div>

                {/*AHDA 19/02/2019*/}
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={this.handleClose}>
                  <CustomNotification
                    onClose={this.handleClose}
                    variant="error"
                    message={lang.common[this.state.errorMessage]} />
                </Snackbar>
              </PapperBlock>
            </div>
        }
      </LanguageConsumer>

    );
  }
}

AdvFilter.contextType = LanguageCxt;

export default withStyles(styles)(AdvFilter);
