module.exports = [
  {
    id:11,
    key: 'assignments',
    name: 'Asignaciones',
    icon: 'dashboard',
    link: '/reporte'
  },
  {
    id:12,
    key: 'profile',
    name: 'Mi perfil',
    icon: 'account_circle',
    link: '/profile'
  },
  {
    //isanchez 05.05.2019 add id from db
    //geo 04/06/19 cambio de icono
    id:21,
    key: 'obras_registradas',
    name: 'Obras Registradas',
    icon: 'ballot',
    link: '/obras-registradas'
  },
  {
    id:13,
    key: 'reportes',
    name: 'Reportes',
    icon: 'insert_chart',
    child: [
      {
        key: 'reporte_areaEvaluadora',
        name: 'Área evaluadora',
        link: '/reporte/area-evaluadora'
      },
      {
        key: 'bitacora_movimientos',
        name: 'Bitacora Movimientos',
        link: '/reporte/bitacora-movimientos'
      },
      {
        key: 'bitacora_obras',
        name: 'Bitacora Obras',
        link: '/reporte/bitacora-de-obras'
      },
      {
        key: 'reporte_compraObras',
        name: 'Compra de obras',
        link: '/reporte/compra-obras'
      },
      {
        key: 'reporte_curriculumAutor',
        name: 'Curriculum autor',
        link: '/reporte/curriculum-autor'
      },
      {
        key: 'reporte_estatusContractual',
        name: 'Estatus contractual',
        link: '/reporte/estatus-contractual'
      },
      {
        key: 'reporte_estatusDocumentacion',
        name: 'Estatus Documentación',
        link: '/reporte/estatus-documentacion'
      },
      {
        key: 'fechas_flujos',
        name: 'Fechas de flujos de trabajo',
        link: '/reporte/fechas-flujos'
      },
      {
        key: 'reporte_obras',
        name: 'Obras Literarias',
        link: '/reporte/obras'
      }
    ]
  },
  {
    id:0,
    key: 'settings',
    name: 'Configuración',
    icon: 'settings',
    child: [
      {
        id:14,
        key: 'users',
        name: 'Usuarios',
        link: '/users'
      },
      {
        id:15,
        key: 'privilegios',
        name: 'Privilegios',
        link: '/perfiles-y-privilegios'
      },
      {
        id:16,
        key: 'configureNotifications',
        name: 'Configuración de notificaciones',
        link: '/configuracion-de-notificaciones'
      },
      {
        id:19,
        key: 'parametros',
        name: 'Parámetros',
        link: '/parametros'
      },
    ]
  },
  {
    id:17,
    key: 'catalogos',
    name: 'Catalogos',
    icon: 'library_books',
    child: [
      {
        key: 'ambiente',
        name: 'Ambiente',
        link: '/catalogos/ambiente/'
      },
      {
        key: 'area_evaluadora', 
        name: 'area-evaluadora', 
        link: '/catalogos/area-evaluadora'
      },
      {
        key: 'audiencia',
        name: 'Audiencia',
        link: '/catalogos/audiencia/'
      },
      {
        key: 'autores_adaptadores',
        name: 'Autores/Adaptadores',
        link: '/catalogos/autores-adaptadores/'
      },
      {
        key: 'causas_rechazo',
        name: 'Causas de rechazo',
        link: '/catalogos/causas-de-rechazo/'
      },
      {
        key: 'duracion_libretos',
        name: 'Duración de los libretos',
        link: '/catalogos/duracion-de-libretos/'
      },
      {
        key: 'empresa_proveedores',
        name: 'Empresas/Proveedores',
        link: '/catalogos/empresa-proveedores/'
      },
      {
        key: 'formato',
        name: 'Formato',
        link: '/catalogos/formato/'
      },
      {
        key: 'fuente',
        name: 'Fuente',
        link: '/catalogos/fuente/'
      },
      {
        key: 'generos',
        name: 'Géneros',
        link: '/catalogos/generos/'
      },
      {
        key: 'idioma',
        name: 'Idioma',
        link: '/catalogos/idioma/'
      },
      {
        key: 'motivacion',
        name: 'Motivación',
        link: '/catalogos/motivacion/'
      },
      {
        key: 'nivel_riesgo',
        name: 'Nivel de riesgo',
        link: '/catalogos/nivel-de-riesgo/'
      },
      {
        key: 'pais_de_origen',
        name: 'País de Origen',
        link: '/catalogos/pais-de-origen'
      },
      /*{
        key: 'productores',
        name: 'Productores',
        link: '/catalogos/productores/'
      },*/
      {
        key: 'subgenero',
        name: 'Subgénero',
        link: '/catalogos/subgenero/'
      },
      {
        key: 'tematica',
        //AHDA 02/04/2019
        //Correccion de ortografia
        name: 'Temática',
        link: '/catalogos/tematica/'
      },
      {
        key: 'temporalidad',
        name: 'Temporalidad',
        link: '/catalogos/temporalidad/'
      },
      {
        key: 'tipo_contrato',
        name: 'Tipo de contrato',
        link: '/catalogos/tipo-de-contrato/'
      },
      {
        key: 'ventana',
        name: 'Ventana',
        link: '/catalogos/ventana/'
      },
      {
        key: 'vigencia',
        name: 'Vigencia',
        link: '/catalogos/vigencia/'
      }, 
    ]
  },
  {
    id: 20, 
    key: 'notifications',
    name: 'Notificaciones',
    icon: 'notifications',
    link: '/notifications'
  }
];
