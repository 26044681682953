import {
  Dialog,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  TextField,
  withStyles,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import classNames from "classnames";
import { Save, Search } from "@material-ui/icons";

const SearchDropDownDialog = ({
  open,
  onClose,
  onSelect,
  onInputChange,
  className,
  options,
  classes,
  label,
}) => {
  const [value, setValue] = useState(null);

  const update = (options, stateAndHelpers) => {
    setValue(options.selectedItem || null);
  };

  const select = (value) => {
    if (onSelect) onSelect(value);

    setValue(null);
  };

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={onClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <Downshift
          selectedItem={value}
          onStateChange={update}
          onInputValueChange={onInputChange}
          onChange={(selectedItem) => select(selectedItem)}
        >
          {({
            getInputProps,
            getItemProps,
            isOpen,
            inputValue,
            selectedItem,
            highlightedIndex,
          }) => {
            const inputProps = getInputProps({});

            return (
              <div className={classes.formControl}>
                <FormControl fullWidth>
                  <Input
                    fullWidth
                    type="text"
                    label={label}
                    className={classNames(className)}
                    value={value}
                    InputLabelProps={{ shrink: true }}
                    {...inputProps}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="Buscar">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {getSuggestions(inputValue, options).map(
                      (suggestion, index) => (
                        <Suggestion
                          key={index}
                          {...{
                            suggestion,
                            index,
                            itemProps: getItemProps({
                              item: suggestion,
                            }),
                            highlightedIndex,
                            selectedItem,
                          }}
                        />
                      )
                    )}
                  </Paper>
                ) : null}
              </div>
            );
          }}
        </Downshift>
      </Dialog>
    </>
  );
};

SearchDropDownDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  className: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(SearchDropDownDialog);

/**
 * Component that render the suggestion item.
 */
const Suggestion = ({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) => {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={index}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
};

/**
 * Handler to filter the suggestions to show.
 */
export function getSuggestions(inputValue, array) {
  let count = 0;

  return array.filter((suggestion) => {
    //AHDA 09/05/2019
    //Validation when empty
    const sugg = suggestion.label
      ? suggestion.label.toLowerCase().indexOf(inputValue.toLowerCase())
      : "";
    const keep = (!inputValue || sugg !== -1) && count < 5;

    if (keep) {
      count += 1;
    }

    return keep;
  });
}
