import React from 'react';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';
import brand from '../../../../utils/brand';
import PapperBlock from './../../../../components/PapperBlock/PapperBlock';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styles from './parameters-jss';
import { apiPOSTNotAuth, apiPOST } from '../../../../services/service_base';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

import {
    convertFromRaw,
    EditorState,
    convertToRaw,
    Modifier
} from 'draft-js'; //AHDA 18/06/2019
import { Editor } from 'react-draft-wysiwyg';
import { stateFromHTML } from 'draft-js-import-html'; //AHDA 18/06/2019
import { getSelectedBlock } from 'draftjs-utils'; //AHDA 18/06/2019
import EditorStyle from '../../../../styles/components/TextEditor.scss';
import '../../../../styles/components/vendors/react-draft-wysiwyg/react-draft-wysiwyg.css';
import {
    FormControl
} from '@material-ui/core';
import { ButtonAction } from '../../../../atoms';
import { Add, Delete } from '@material-ui/icons';

const content = {
    blocks: [
        {
            key: '637gr',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
    ],
    entityMap: {},
};

class Parameters extends React.Component {
    constructor(props) {
        super(props);
        const contentBlock = convertFromRaw(content);
        if (contentBlock) {
            const editorState = EditorState.createWithContent(contentBlock);

            this.state = {
                editorState,
                parameters: [],
                value: 0,
                idSelected: 0,
                isLoading: true,
                openNotification: false,
                messageNotification: '',
                typeNotification: '',
                chkEditParameter: false,
                files: []
            };
        }
    }

    componentDidMount() {
        this._loadParameters();
    }

    processParametersData = (data) => {

        let response = [];
        let item = {};
        data.forEach(element => {
            if (element.name != 'Aviso de privacidad') {
                response.push(element);
            } else {
                item = element;
            }
        });

        response.push(item);

        return response;
    }

    _loadParameters = () => {
        let config = JSON.parse(
            localStorage.getItem('user_permissions'),
        ).filter((x) => x.menu_id === 19)[0];
        let chk = JSON.parse(config.grant_configuration);

        apiPOSTNotAuth('/params/get', {
            portal: 2,
        })
            .then((response) => {

                if (response.data) {

                    let data = this.processParametersData(response.data);
                    this.setState(
                        {
                            parameters: data,
                            isLoading: false,
                            chkEditParameter: chk.chkEditParameter,
                        },
                        () => {
                            this.state.parameters &&
                                this.state.parameters.forEach((item) => {
                                    if (item.param_type === 'RICHTEXT') {
                                        let parse = `${item.value}`.replaceAll(
                                            '\\t',
                                            '\t',
                                        );
                                        parse = parse.replaceAll('\t', '\\t');
                                        let a = JSON.parse(parse);
                                        if (a) {
                                            const contentBlock = convertFromRaw(
                                                a,
                                            );
                                            const editorState = EditorState.createWithContent(
                                                contentBlock,
                                            );
                                            this.setState({
                                                editorState: editorState,
                                            });
                                        }
                                    } if (item.param_type === 'ARRAY') {
                                        this.setState({
                                            files: JSON.parse(item.value)
                                        });
                                    } if (item.param_type === 'OBJECT') {
                                        this.setState({
                                            [`value_${item.id_parameter}`]: JSON.parse(item.value).total,
                                            [`size_${item.id_parameter}`]: JSON.parse(item.value).size,
                                        });

                                    } else {
                                        this.setState({
                                            [`value_${item.id_parameter}`]: item.value,
                                        });
                                    }
                                });
                        },
                    );
                } else {
                    this.setState({
                        isLoading: false,
                        messageNotification: 'Ocurrió un error inesperado',
                        openNotification: true,
                        typeNotification: 'error',
                    });
                }
            })
            .catch((err) => {
                console.log('params', err);
                this.setState({
                    isLoading: false,
                    messageNotification: 'Ocurrió un error inesperado',
                    openNotification: true,
                    typeNotification: 'error',
                });
            });
    };

    _reset = () => {
        this._loadParameters();
    };

    handleCloseNotification = () => {
        this.setState({
            openNotification: false,
        });
    };

    //AHDA 18/06/2019
    //---------------------------- Input handle
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    handleChange = (event) => {
        let idParam = event.target.name.split('_')[1];
        this.setState({
            [event.target.name]: event.target.value,
            [`idParam_${idParam}`]: idParam,
        });
    };

    //AHDA 18/06/2019: validation for negative numbers and decimals
    handleKeyDown = (event, type) => {
        if (type === 'number') {
            if (event.key == '-' || event.key == '.' || event.key == 'e') {
                event.preventDefault();
                return false;
            }
            return true;
        }
    };

    //AHDA 18/06/2019
    handlePastedText = (text, html, editorState, onChange) => {
        const selectedBlock = getSelectedBlock(editorState);
        if (selectedBlock && selectedBlock.type == 'code') {
            const contentState = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                text,
                editorState.getCurrentInlineStyle(),
            );
            onChange(
                EditorState.push(
                    editorState,
                    contentState,
                    'insert-characters',
                ),
            );
            return true;
        } else if (html) {
            const blockMap = stateFromHTML(html).blockMap;
            const newState = Modifier.replaceWithFragment(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                blockMap,
            );
            onChange(
                EditorState.push(editorState, newState, 'insert-fragment'),
            );
            return true;
        }
        return false;
    };

    handleFile = (event, key) => {
        const temp = this.state.files;
        let cont = 0;
        temp[key][event.target.name] = event.target.value;
        this.state.files.forEach(({ size }) => cont = size === "" ? cont + 0 : cont + parseInt(size))
        this.setState({ files: temp, [`size_${this.state.parameters[2].id_parameter}`]: cont });
    };

    handleAddRemove = (key) => {
        const temp = this.state.files;
        let total = this.state.files.length;
        if (key) {
            temp.splice(key, 1);
        } else {
            temp.push({ "type": "", "size": "" });
            total++;
        }
        this.setState({
            files: temp,
            [`value_${this.state.parameters[2].id_parameter}`]: temp.length
        });
    }

    _saveChanges = (e) => {
        e.preventDefault()
        let editParams = new Array();
        let convertTo = this.state.editorState.getCurrentContent();

        this.state.parameters.forEach((item) => {
            if (item.param_type === 'RICHTEXT') {
                let id = this.state.parameters.find(
                    (x) => x.param_type === 'RICHTEXT',
                );
                if (id != undefined) {
                    //AHDA 18/06/2019 - Converted function for replacing line breaks
                    const converted = convertToRaw(convertTo);

                    converted.blocks = converted.blocks.map((b) => {
                        let auxObject = Object.assign({}, b);
                        auxObject.text = auxObject.text.replace(
                            /\r\n|\r|\n/,
                            ' ',
                        );

                        return auxObject;
                    });
                    editParams.push({
                        id_parameter: id.id_parameter,
                        new_value: JSON.stringify(converted),
                    });
                }
            } if (item.param_type === 'ARRAY') {
                editParams.push({
                    id_parameter: item.id_parameter,
                    new_value: JSON.stringify(this.state.files)
                });
            }
            if (item.param_type === 'OBJECT') {
                editParams.push({
                    id_parameter: item.id_parameter,
                    new_value: `{"total":${this.state[`value_${item.id_parameter}`]},"size":${this.state[`size_${item.id_parameter}`]}}`
                });
            } else {
                if (this.state[`idParam_${item.id_parameter}`] != undefined) {
                    editParams.push({
                        id_parameter: this.state[`idParam_${item.id_parameter}`],
                        new_value: this.state[`value_${item.id_parameter}`],
                    });
                }
            }
        });
        if (editParams.length > 0) {

            console.log(JSON.stringify(editParams, null, 4));

            this.setState({ isLoading: true });
            apiPOST('/params/update', {
                params: JSON.stringify(editParams),
            }).then((response) => {
                if (response.status == 200) {
                    this.setState({
                        isLoading: false,
                        messageNotification: 'Cambios guardados correctamente',
                        openNotification: true,
                        typeNotification: 'success',
                    });
                }
            });
        }
    };

    render() {
        const title = brand.name + ' - Parámetros';
        const description = brand.desc;
        const { classes } = this.props;

        return (
            <div className="marginTopLogo">
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                </Helmet>

                {this.state.isLoading ? <LoaderFull /> : ''}

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.openNotification}
                    autoHideDuration={6000}
                    onClose={this.handleCloseNotification}
                >
                    <CustomNotification
                        onClose={this.handleCloseNotification}
                        variant={this.state.typeNotification}
                        message={this.state.messageNotification}
                    />
                </Snackbar>

                <PapperBlock title="Parámetros" desc="">
                    <form onSubmit={this._saveChanges}>
                        <div className={classes.ajusteTabla}>

                            <Table
                                className={classNames(
                                    classes.table,
                                    classes.strippedFalse,
                                )}
                            >
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell padding="dense">
                                            <div className={classes.sizeCell}>
                                                Descripción
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {/*
                                            Mantain this nested divs only for cell-width purpoise, this is
                                            due to td being unable to support min/max-width properties.
                                        */}
                                            <div className={classes.valueCell}>
                                                Valor
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className={classes.sizeCell}>
                                                Tamaño máx permitido en GB
                                            </div>
                                        </TableCell>
                                        <TableCell className={[classes.cellHeading, classes.actionCellPadding]}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.parameters.map((item, index) => {
                                        return (
                                            <>
                                                <TableRow key={index.toString()}>
                                                    <TableCell
                                                        padding="dense"
                                                        className={
                                                            classes.styleCell
                                                        }
                                                    >
                                                        {item.description}
                                                    </TableCell>
                                                    <TableCell className={classes.alignTop}>
                                                        {["NUMBER", "OBJECT"].includes(item.param_type) && <div>
                                                            <Input
                                                                id={`value_${item.id_parameter}`}
                                                                name={`value_${item.id_parameter}`}
                                                                value={this.state[`value_${item.id_parameter}`]}
                                                                className={classNames(
                                                                    classes.inputMini,
                                                                    classes.inputSelect,
                                                                )}
                                                                fullWidth
                                                                type="number"
                                                                disabled={!this.state.chkEditParameter}
                                                                onChange={(event) => this.handleChange(event)}
                                                                onKeyPress={(event) =>
                                                                    this.handleKeyDown(event, 'number')
                                                                }
                                                                required
                                                                inputProps={
                                                                    { min: 0 }
                                                                }
                                                            />
                                                        </div>}

                                                        {item.param_type === "ARRAY" &&
                                                            this.state.files.map((item, key) =>
                                                                <div key={key} className={classes.arrayRowItem}>
                                                                    <FormControl
                                                                        fullWidth
                                                                        className={classes.tableFormControl}
                                                                    >
                                                                        <Input
                                                                            id={"type_" + key}
                                                                            name="type"
                                                                            value={item.type}
                                                                            disabled={!this.state.chkEditParameter}
                                                                            onChange={(event) => this.handleFile(event, key)}
                                                                            className={classNames(
                                                                                classes.inputMini,
                                                                                classes.inputSelect,
                                                                            )}
                                                                            fullWidth
                                                                            required
                                                                        />
                                                                    </FormControl>
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            item.param_type === "RICHTEXT" &&
                                                            <Editor
                                                                editorState={this.state.editorState}
                                                                readOnly={!this.state.chkEditParameter}
                                                                editorClassName={EditorStyle.TextEditor}
                                                                toolbarClassName={EditorStyle.ToolbarEditor}
                                                                onEditorStateChange={this.onEditorStateChange}
                                                                handlePastedText={this.handlePastedText}
                                                            />
                                                        }
                                                    </TableCell>
                                                    <TableCell className={classes.alignTop}>
                                                        <div className={classes.actionCell}>
                                                            {item.param_type === "ARRAY" &&
                                                                this.state.files.map((item, key) =>
                                                                    <div key={key} className={classes.arrayRowItem}>
                                                                        <Input
                                                                            key={key}
                                                                            // id={}
                                                                            name="size"
                                                                            value={item.size}
                                                                            className={[classes.inputMini, classes.inputSelect]}
                                                                            fullWidth
                                                                            type="number"
                                                                            disabled={!this.state.chkEditParameter}
                                                                            onChange={(event) => this.handleFile(event, key)}
                                                                            required
                                                                            inputProps={
                                                                                { min: 0 }
                                                                            }
                                                                        />
                                                                    </div>)
                                                            }
                                                            {item.param_type === "OBJECT" &&
                                                                <>
                                                                    <Input
                                                                        id={`size_${item.id_parameter}`}
                                                                        name={`size_${item.id_parameter}`}
                                                                        value={this.state[`size_${item.id_parameter}`]}
                                                                        className={[classes.inputMini, classes.inputSelect]}
                                                                        fullWidth
                                                                        type="number"
                                                                        disabled={!this.state.chkEditParameter}
                                                                        onChange={(event) => this.handleChange(event)}
                                                                        required
                                                                        inputProps={
                                                                            { min: 0 }
                                                                        }
                                                                    />
                                                                </>
                                                            }
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={[classes.alignTop, classes.actionCellPadding]}>
                                                        <div className={classes.actionCell}>
                                                            {item.param_type === "ARRAY" &&
                                                                this.state.files.map((item, key) =>
                                                                    <div key={key} className={classes.arrayRowItem}>
                                                                        <div>
                                                                            {key > 0 && this.state.files.length > 1 &&
                                                                                <ButtonAction
                                                                                    rounded
                                                                                    color="default"
                                                                                    className={classNames(classes.buttonAction, classes.buttonActionDelete)}
                                                                                    onClick={() => this.handleAddRemove(key)}>
                                                                                    <Delete />
                                                                                </ButtonAction>
                                                                            }
                                                                            {key + 1 === this.state.files.length &&
                                                                                <ButtonAction
                                                                                    rounded
                                                                                    color="default"
                                                                                    className={classes.buttonAdd}
                                                                                    onClick={() => this.handleAddRemove()}>
                                                                                    <Add />
                                                                                </ButtonAction>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>

                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Grid container justify="flex-end">
                                    <Button
                                        disabled={!this.state.chkEditParameter}
                                        //onClick={() => this._saveChanges()}
                                        type="submit"
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        className={classes.button}
                                    >
                                        Guardar
                                    </Button>
                                    <Button
                                        onClick={() => this._reset()}
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        className={classes.button}
                                    >
                                        Cancelar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </PapperBlock>
            </div>
        );
    }
}

export default withStyles(styles)(Parameters);
