import React from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import styles from "./registroObra-jss";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import ReactDragList from "react-drag-list";
import CharacterPaper from "../../../components/Works/CharacterPaper";
import MediaModal from "../../../components/Works/MediaModal";

import {
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PapperBlock from "../../../components/PapperBlock/PapperBlock";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import ChipInput from "material-ui-chip-input";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";

import ListItemText from "@material-ui/core/ListItemText";

// Expandible
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Tabla Catalogo
import AddCatalogue from "../../../components/Catalogue/AddCatalogue";
import Header from "../../../components/Catalogue/Header";
import TableCatalogue from "../../../components/Catalogue/TableCatalogue";
import MaterialDropZone from "../../../components/Forms/MaterialDropZone";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

import TableDocumentacion from "./TableDocumentacion";
import Modal from "@material-ui/core/Modal";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";

import {
  apiPOST,
  returnCurrentUserId,
  fetchCall,
  apiElasticSearch,
  apiPOSTNotAuth,
} from "../../../services/service_base";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import schedule from "../../../utils/schedule";
import AcceptedMaterialTypes from "../../../utils/acceptedMaterialTypes";

import Snackbar from "@material-ui/core/Snackbar";
import CustomNotification from "../../../components/Notification/CustomNotification";

//AHDA 03/05/2019
// JCMY 09/07/2019 incidecnia 1066
// import materialTypes from '../../../utils/materialTypes';
import returnDocument from "../../../utils/documentTypes";
import { getFileTitle } from "../../../helpers/general";
import {
  saveCompleteFile,
  saveMultipleFilesAll,
  getFile,
  promiseProgress,
} from "../../../services/storage";
// JCMY 13/05/2019
import LoaderFull from "../../../components/Loader/LoaderFull";

//JCMY 04/06/2019
import SweetAlert from "sweetalert-react";
import { renderToStaticMarkup } from "react-dom/server";
// JCMY 05/06/2019
import TableRepeatedWorks from "./TableRepeatedWorks";

// EDITOR
import { Editor } from "react-draft-wysiwyg";
import {
  convertFromRaw,
  EditorState,
  convertToRaw,
  Modifier,
  ContentState,
} from "draft-js";
import EditorStyle from "../../../styles/components/TextEditor.scss";
import "../../../styles/components/vendors/react-draft-wysiwyg/react-draft-wysiwyg.css";
import { stateFromHTML } from "draft-js-import-html";
import { getSelectedBlock } from "draftjs-utils";

//Helpers
import { getSpanishMonthName } from "../../../utils/formatDateHelper";

import WorkflowSteps from "../../../utils/workflowSteps";

import StepProcesoEvaluacion from "./StepProcesoEvaluacion";

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
}

// INICIA AUTOCOMPLETE
const suggestions = [
  { label: "Afghanistan" },
  { label: "Aland Islands" },
  { label: "Albania" },
  { label: "Algeria" },
  { label: "American Samoa" },
  { label: "Andorra" },
  { label: "Angola" },
  { label: "Anguilla" },
  { label: "Antarctica" },
  { label: "Antigua and Barbuda" },
  { label: "Argentina" },
  { label: "Armenia" },
  { label: "Aruba" },
  { label: "Australia" },
  { label: "Austria" },
  { label: "Azerbaijan" },
  { label: "Bahamas" },
  { label: "Bahrain" },
  { label: "Bangladesh" },
  { label: "Barbados" },
  { label: "Belarus" },
  { label: "Belgium" },
  { label: "Belize" },
  { label: "Benin" },
  { label: "Bermuda" },
  { label: "Bhutan" },
  { label: "Bolivia, Plurinational State of" },
  { label: "Bonaire, Sint Eustatius and Saba" },
  { label: "Bosnia and Herzegovina" },
  { label: "Botswana" },
  { label: "Bouvet Island" },
  { label: "Brazil" },
  { label: "British Indian Ocean Territory" },
  { label: "Brunei Darussalam" },
];

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

// JCMY 02/07/2019 incidencia 978
// function getSteps() {
//   // JCMY 18/06/2019 Incidencia televisa AC-912
//   return ['Datos del Usario', 'Generales de la Obra', 'Sinopsis', 'Personajes', 'Evaluación', 'Material', 'Contrato'];
// }

const content = {
  blocks: [
    {
      key: "637gr",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
};

class RegistroObra extends React.Component {
  // cramirez
  onDrop = (files) => {
    const { proTypeMaterial, acceptedFiles } = this.state;

    if (
      proTypeMaterial === undefined ||
      proTypeMaterial === "" ||
      (!Array.isArray(acceptedFiles) &&
        Array.isArray(acceptedFiles) && acceptedFiles.length < 1)
    ) {
      this.setState({
        openAlert: true,
        variant: "warning",
        message: "Tipo de archivo no seleccionado",
      });
      return;
    }

    if (!files) {
      this.setState({
        openAlert: true,
        variant: "warning",
        message: "Tipo de archivo no valido",
      });
    } else {
      let newAcceptedFiles = [];
      if (
        this.state.materialTypes.findIndex((m) => m.value === proTypeMaterial) >
        -1
      ) {
        newAcceptedFiles = AcceptedMaterialTypes[proTypeMaterial]
          ? [...AcceptedMaterialTypes[proTypeMaterial]]
          : [...AcceptedMaterialTypes.default];
      }

      let oldFiles = this.state.files;

      if (files.length > 1) {
        let unsupportedFiles = [];
        for (let file of files) {
          const isFileTypeValid =
            newAcceptedFiles.length > 0
              ? newAcceptedFiles.find((x) => x === file.type)
              : acceptedFiles.find((x) => x === file.type);
          const extension = file.name.split(".").pop();

          const isExtensionValid =
            file.type === "" && extension !== "" && extension === "rar";
          if (isExtensionValid || (file.type !== "" && !!isFileTypeValid)) {
            oldFiles = oldFiles.concat(file);
            this.setState({
              selectFile: oldFiles,
              files: oldFiles,
              filesMaterial: oldFiles,
              isMaterialMultipleFiles: true,
            });
          } else {
            unsupportedFiles.push(file.name);
          }
        }

        if (unsupportedFiles.length > 0) {
          this.setState({
            openAlert: true,
            variant: "warning",
            message: "Uno o más archivos no son validos",
          });
        }
      } else if (files.length === 1) {
        const [file] = files;
        const isFileTypeValid =
          newAcceptedFiles.length > 0
            ? newAcceptedFiles.find((x) => x === file.type)
            : acceptedFiles.find((x) => x === file.type);

        const extension = file.name.split(".").pop();

        const isExtensionValid =
          file.type === "" && extension !== "" && extension === "rar";
        if (isExtensionValid || (file.type !== "" && !!isFileTypeValid)) {
          oldFiles = oldFiles.concat(file);
          this.setState({
            selectFile: file,
            files: oldFiles,
            filesMaterial: oldFiles,
            isMaterialMultipleFiles: false,
          });
        } else {
          this.setState({
            openAlert: true,
            variant: "warning",
            message: "Tipo de archivo no valido",
          });
        }
      }
    }
  };

  handleDeleteJudgement = (item, pos, listName = "") => {
    if (listName !== "") {
      this.state[listName].splice(pos, 1);
    } else {
      this.state.evaluationFile.splice(pos, 1);
    }
    apiPOSTNotAuth("/works/deletemultimedia", {
      multimedia_id: item.id_material_multimedia,
    })
      .then((response) => {
        this.setState({
          openAlert: true,
          variant: "success",
          message: "Se eliminó el archivo",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  openGoogleViewer = (ruta)=>{
    let auxUrl = encodeURIComponent(ruta);
    window.open(`/file?doc=${auxUrl}`, "_blank");
  }

  handleViewSingleMaterial = (item) => { 
    let auxItem = Object.assign({}, item);
    if (auxItem) {
      if (!auxItem.isNew) {
        let type = auxItem.ruta
          ? auxItem.ruta.split(".")[auxItem.ruta.split(".").length - 1]
          : "";
        if (!type) {
          this.setState({
            openAlert: true,
            variant: "error",
            message: "No hay vista previa disponible",
          });
          return false;
        }
        switch (type.toLowerCase()) {
          case "mp4":
          case "mov":
            getFile(auxItem.ruta, "public")
              .then((result) => {
                let material = [{ type: "video", url: result }];
                this.setState({
                  openMediaModal: true,
                  modalFiles: material,
                });
              })
              .catch((err) => {
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "No hay vista previa disponible",
                });
              });
            break;
          case "png":
          case "jpg":
          case "jpeg":
            let material = [{ type: "img", url: auxItem.ruta }];
            this.setState({ openMediaModal: true, modalFiles: material });
            break;
          case "pdf":
          case "ppt":
          case "doc":
          case "docx":
          case "pptx":
          case "xls":
          case "txt":
          case "xlsx":
          case "csv":
            this.openGoogleViewer(auxItem.ruta)
            break;
          default:
            this.setState({
              openAlert: true,
              variant: "error",
              message: "No hay vista previa disponible para este tipo de archivo",
            });
            break;
        }
      } else {
        if (auxItem.file && auxItem.file.preview) {
          window.open(auxItem.file.preview, "_blank");
        } else {
          this.setState({
            openAlert: true,
            variant: "error",
            message: "No hay vista previa disponible",
          });
        }
      }
    } else {
      this.setState({
        openAlert: true,
        variant: "error",
        message: "No hay vista previa disponible",
      });
    }
  };
  //AHDA 03/05/2019
  //Added constructor
  constructor(props) {
    super(props);

    const contentBlock = convertFromRaw(content);
    let editorState = null;
    if (contentBlock) {
      editorState = EditorState.createWithContent(contentBlock);
    }

    this.state = {
      editorState,
      // Inpunt step 0
      companyProvider: "",
      representative: "",
      address: "",
      telephone: "",
      email: "",
      companyProviderCatalog: "",
      works: [],
      workTitles: {},
      adaptationsPanel: false,

      // Catalogos
      companysProviders: [],
      countries: [],
      sources: [],
      durations: [],
      //#region [ rgba (100, 100, 100, 0.1 )]  EGs - 22/04/2019 - start
      catFormat: [],
      catPlatform: [],
      catAudience: [],
      catGender: [],
      catAuthors: [],
      catSubgender: [],
      catThematic: [],
      catMotivation: [],
      catTemp: [],
      catProducers: [],
      catEnviroment: [],
      catDuration: [],
      catLanguage: [],
      catRiskLevel: [],
      catContractType: [], //AHDA 06/05/2019
      catValidity: [], //AHDA 06/05/2019
      catDocumentList: [], //AHDA 06/05/2019
      // #endregion EGS - end
      //AHDA 03/05/2019
      activeStep: 0,
      expanded: "panel1",
      altLabel: true,
      inputValue: "",
      selectedItem: [],
      completed: {},
      files: [],
      openForm: false,
      headers: ["Id", "Nombre", "Participación", "Acción"],
      authorsData: [],
      //AHDA 05/05/2019
      //Added id header
      headersMaterial: [
        "Id",
        "Título",
        "Tipo de Material",
        "Número de Capítulo",
        "Idioma",
        "Opciones",
      ],
      // JCMY 17/04/2019
      // catalogs advance work
      evaluatingAreas: [],
      evaluationAreaByWorkflow: [],
      rejectReasons: [],
      //JCMY 30/04/2019
      usersAdministrator: [],
      usersSpecialists: [],
      usersAnalysisContent: [],
      usersAnalysisDirectors: [],

      statesWorkFlow: [],

      // Fields advance work
      stateWorkflow: 0,
      //JCMY 30/04/2019
      stateWorkflowString: "",
      notViable: 0,
      evaluator: 0,
      responsibleUser: 0,
      deadLine: null,
      comments: "",
      workflowComments: "",
      // Fields extend term
      deadLineExtendTerm: null,
      commentsExtendTerm: "",

      // Fields commentsReappraise
      commentsReappraise: "",

      // Info workflow
      statusWorkFlow: "",
      stageWorkFlow: 0,

      // Alerts
      openAlert: false,
      variant: "",
      message: "",

      // Validations
      errorcommentsExtendTerm: false,
      errordeadLineExtendTerm: false,
      errorcommentsReappraise: false,

      // JCMY 29/04/2019
      errorstateWorkflow: false,
      errornotViable: false,
      errorevaluator: false,
      errorresponsibleUser: false,
      errordeadLine: false,
      errorcomments: false,

      //AHDA 02/05/2019
      //Added Files, Material Form
      //Files
      filesDiagram: [],
      filesEvaluation: [],
      filesEvaluationProcess: [],
      filesMaterial: [],
      relationDiagramFile: undefined,
      evaluationFile: [],
      evaluationProcessFile: [],
      materials: [],
      rawMaterials: [],
      //Material Form
      openFormMaterial: false,
      acceptedFiles: ["*"],
      proNameMaterial: undefined,
      proChapterMaterial: undefined,
      proTypeMaterial: undefined,
      proLanguageMaterial: undefined,
      selectedMaterial: undefined,
      openMediaModal: false,
      modalFiles: [],
      isMaterialMultipleFiles: false,

      logline: "",
      loglineLength: 0,
      sinopsis_cortaLength: 0,
      sinopsis_largaLength: 0,
      // EGS 03\05\2019
      // Evaluation
      evId: 0,
      evComments: "",
      epComments: "",
      evRiskLevel: "",
      evRating: "",
      evPaises: [],
      evHorario: "",
      evShare: "",
      evQualification: "",
      // AHDA 06/05/2019
      // Contract
      contId: 0,
      contType: undefined,
      contValidity: undefined,
      contSignDate: undefined,
      contPaymentFrequency: undefined,
      contChapterPrice: undefined,
      contCurrency: undefined,
      contPurchasedChapters: undefined,
      contTotalAmount: undefined,
      contPaymentsNumber: undefined,
      contPaymentAmount: undefined,
      contStartDate: undefined,

      authsUsr: [],
      new_characters: [],
      documents: [],
      //EGS - 06/05/2019
      proProducida: 0,
      versionList: [],
      versions: [],
      // JCMY 13/05/2019
      loading: false,

      show: false, //JCMY 04/06/2019
      dataRepeatedWorks: [], //JCMY 05/06/2019

      // JCMY 02/07/2019
      errorproNameAutor: false,
      errorauthor_rol: false,

      materialTypes: [], //JCMY 09/07/2019 incidencia 1066
      rolId: null,

      countryEvaluations: [],
      evaluadorUserMultiple: [],
      errorresponsibleevaluadorUserMultiple: false,

      otras_versiones: "",

      //New material type option selected
      isNewMaterialTypeSelected: false,
      newMaterialTypeValue: "",

      //Edit material
      idEditedMaterial: 0,
      titleModalMaterial: "",

      //new step Proceso de Evaluación
      filesDataEvaluationProcess: [],
    };
  }

  // JCMY 02/07/2019 incidencia 978
  getSteps = () => {
    // JCMY 03/06/2019
    let steps = [
      "Datos del Usuario",
      "Generales de la Obra",
      "Sinopsis",
      "Personajes",
      "Proceso de evaluación",
      "Evaluación",
      "Material",
    ];

    if (!this.props.canEditWorkUserData) {
      steps.splice(steps.indexOf("Datos del Usuario"), 1);
    }

    if (this.state.stageWorkFlow == 3 || this.state.stageWorkFlow == 4) {
      // JCMY 18/06/2019 Incidencia televisa AC-912
      // isanchez 31.07.2019 ortografía
      steps.push("Contrato");
    }

    if (
      (this.state.rolId !== 21 && this.state.rolId !== 19) ||
      !this.props.canSeeEvaluationProcess
    ) {
      steps.splice(steps.indexOf("Proceso de evaluación"), 1);
    }

    return steps;
  };

  //Inicio Input Tags
  handleKeyDown = (event) => {
    const { inputValue, selectedItem } = this.state;
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      this.setState({
        selectedItem: selectedItem.slice(0, selectedItem.length - 1),
      });
    }
  };

  handleAddChip = (item, idState) => {
    const list = [...this.state[idState]];
    list.push(item);
    this.setState({ [idState]: list });
  };

  handleDeleteChip = (chip, index, idState) => {
    const list = [...this.state[idState]];
    list.splice(index, 1);
    this.setState({ [idState]: list });
  };

  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.setState({
        contTotalAmount:
          this.state.contPaymentsNumber * this.state.contPaymentAmount,
      });
    });
  };

  handleNewMaterialChange = (event) => {
    this.setState({ newMaterialTypeValue: event.target.value });
  };

  //AHDA 03/05/2019
  //Added change for material types
  handleMaterialChange = ({ target: { value } }) => {
    if (value !== "0") {
      let acceptedFiles = [];

      if (
        AcceptedMaterialTypes[value] &&
        Array.isArray(AcceptedMaterialTypes[value])
      ) {
        acceptedFiles = AcceptedMaterialTypes[value];
      } else {
        acceptedFiles = AcceptedMaterialTypes.default;
      }

      this.setState({
        proTypeMaterial: value,
        isNewMaterialTypeSelected: false,
        filesMaterial: [],
        files: [],
        acceptedFiles,
      });
    } else {
      this.setState({
        proTypeMaterial: value,
        filesMaterial: [],
        files: [],
        isNewMaterialTypeSelected: true,
        acceptedFiles: AcceptedMaterialTypes["default"],
      });
    }
  };

  handleDelete = (item) => () => {
    this.setState((state) => {
      const selectedItem = [...state.selectedItem];
      selectedItem.splice(selectedItem.indexOf(item), 1);
      return { selectedItem };
    });
  };

  //Fin Input Tags

  //Datepickers
  handleDateChangeI = (date) => {
    this.setState({ anio_produccion: date });
  };

  handleChangeDateSign = (date) => {
    this.setState({ contSignDate: date });
  };

  handleChangeDateStartC = (date) => {
    this.setState({ contStartDate: date });
  };

  // JCMY 29/04/2019
  handleDateChangeDl = (date) => {
    this.setState({ deadLine: date });

    const error = `errordeadLine`;
    if (!date || date === "") {
      this.setState({ [error]: true });
    } else {
      this.setState({ [error]: false });
    }
  };

  handleDateChangeIM = (date) => {
    this.setState({ fechaIngresoMaterial: date });
  };

  // JCMY 22/04/2019
  handleDateChangeDlet = (date, event) => {
    this.setState({ deadLineExtendTerm: date });
    const error = `errordeadLineExtendTerm`;
    if (!date || date === "") {
      this.setState({ [error]: true });
    } else {
      this.setState({ [error]: false });
    }
  };

  //Expandables
  handleChangeExpand = (panel) => (event, expanded) => {
    // EGs - 16/04/2019 - start
    switch (panel) {
      case "panel2":
        this.handleCatalogue("catAuthors", 18);
        break;
      case "panel3":
        this.handleCatalogue("catFormat", 9);
        this.handleCatalogue("catPlatform", 13);
        this.handleCatalogue("catAudience", 8);
        this.handleCatalogue("catGender", 4);
        this.handleCatalogue("catSubgender", 25);
        this.handleCatalogue("catThematic", 26);
        this.handleCatalogue("catMotivation", 27);
        this.handleCatalogue("catEnviroment", 29);
        this.handleCatalogue("catTemp", 28);
        break;
      case "panel4":
        this.handleCatalogue("catProducers", 31);
        this.handleCatalogue("catDuration", 23);
        break;
      case "panel5":
        break;
      case "panel41":
        //AHDA 08/05/2019
        //Chnged catalogue
        this.handleCatalogue("catContractType", 6);
        this.handleCatalogue("catValidity", 10);
        this.getDocumentList();
        break;
      case "panel42":
        this.getDocumentList();
        break;
    }
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  completedSteps() {
    return Object.keys(this.state.completed).length;
  }

  totalSteps = () => this.getSteps().length;

  isLastStep() {
    if (!this.props.canEditWorkUserData) {
      return this.state.activeStep === this.totalSteps();
    } else {
      return this.state.activeStep === this.totalSteps() - 1;
    }
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps();
  }

  handleNext = () => {
    let activeStep;

    // if (this.refs.form.walk(this.refs.form.childs)) {
    if (this.isLastStep() && !this.allStepsCompleted()) {
      // It's the last step, but not all steps have been completed,
      // find the first step that has been completed

      // JCMY 09/07/2019 incidencia 1060
      activeStep = this.state.activeStep;

      // const steps = this.getSteps();
      // activeStep = steps.findIndex((step, i) => !(i in this.state.completed));
    } else {
      activeStep = this.state.activeStep + 1;
    }

    this.setState({
      activeStep,
    });
    // }
  };

  handleBack = () => {
    let { activeStep } = this.state;

    if (!this.props.canEditWorkUserData && activeStep <= 1) {
      return;
    }

    this.setState({ activeStep: activeStep - 1 });
  };

  handleStep = (step) => () => {
    this.setState({
      activeStep: step,
    });
  };

  // EGs - 16/04/2019 - start
  getUpdateObject() {
    let requestWork = {};
    return (requestWork = {
      id_obras: this.state.id_obras,
      empresa_proveedor: this.state.companyProviderCatalog,
      titulo_original: this.state.titulo_original, //Título de la obra *
      titulo_inglés: this.state.titulo_inglés, //Título en inglés *
      titulo_otro_idioma: this.state.titulo_otro_idioma, //Título en otro idioma *
      tipo_obra: this.state.tipo_obra == "" ? 0 : this.state.tipo_obra, //Tipo de obra*
      obra_primigenia: this.state.obra_primigenia, //Título de la obra primigenia* *
      numero_capitulos:
        this.state.numero_capitulos == "" ? 0 : this.state.numero_capitulos, //Número de capítulos *
      // Precio Capitulos
      duracion_min_capitulos:
        this.state.duracion_min_capitulos == ""
          ? 0
          : this.state.duracion_min_capitulos, //Duración*
      //Duración tot
      sinopsis_corta:
        this.state.sinopsis_corta.trim() == ""
          ? ""
          : JSON.stringify(this.state.sinopsis_corta)
              .replace('"{', "{")
              .replace('}"', "}"),
      logline:
        this.state.logline.trim() == ""
          ? ""
          : JSON.stringify(this.state.logline)
              .replace('"{', "{")
              .replace('}"', "}"),
      clasificacion: this.state.clasificacion, //*Destino*
      //Raiting
      horario: this.state.horario, // Horario*
      comentarios: this.state.comments,
      // JCMY 06/05/2019
      clave: this.state.clave,
      version: this.state.proNAdaptacion, //Número de adaptación*
      //Clave
      producida: this.state.proProducida, //Producida*
      sinopsis_larga:
        this.state.sinopsis_larga.trim() == ""
          ? ""
          : JSON.stringify(this.state.sinopsis_larga)
              .replace('"{', "{")
              .replace('}"', "}"),
      anio_produccion: this.state.anio_produccion, //new Date(this.state.anio_produccion).getFullYear(), // Año de prducción
      ep_alAire: this.state.ep_alAire, //Episodis al aire
      // thumbnail
      //share
      //valid
      aired_duration: this.state.aired_duration, //Duración total
      author_register: this.state.author_register, //Registro de Autor* --------------------------------ADDED TO UPDATE SERVICE

      //Libreto ID
      id_fuente: this.state.id_fuente, //Fuente*
      id_pais: parseInt(this.state.id_pais), //País de origen*
      id_tematica: this.state.id_tematica, // Temática*
      id_formato: this.state.id_formato, //Formato*
      // Proyecto ID
      id_desarrollo: this.state.id_desarrollo, // Ambiente*
      // Vigencia ID
      // Rating ID
      // Categorias
      id_motivacion_conflicto: this.state.id_motivacion_conflicto, // Motivación*
      id_audiencia: this.state.id_audiencia, // Audiencia*
      id_genero: this.state.id_genero, // Género*
      id_subgenero:
        this.state.id_subgenero == "" ? null : this.state.id_subgenero, // Subgénero
      //Idioma ID
      id_temporalidad: this.state.id_temporalidad, // Temporalidad*
      id_ventana: parseInt(this.state.id_ventana), // Ventana*
      otras_versiones: this.state.otras_versiones,
    });
  }

  validador = () => {
    if (this.state.activeStep == 1) {
      if (
        this.state.titulo_original == "" ||
        this.state.numero_capitulos == "" ||
        this.state.duracion_min_capitulos == "" ||
        this.state.clasificacion == "" ||
        this.state.titulo_original == null ||
        this.state.numero_capitulos == null ||
        this.state.duracion_min_capitulos == null ||
        this.state.clasificacion == null
      ) {
        this.setState({
          expanded: "panel1",
        });
        return false;
      }

      if (
        this.state.id_desarrollo == "" ||
        this.state.id_temporalidad == "" ||
        this.state.id_motivacion_conflicto == "" ||
        this.state.id_tematica == "" ||
        this.state.horario == "" ||
        this.state.id_audiencia == "" ||
        this.state.id_ventana == "" ||
        this.state.id_desarrollo == null ||
        this.state.id_temporalidad == null ||
        this.state.id_motivacion_conflicto == null ||
        this.state.id_tematica == null ||
        this.state.horario == null ||
        this.state.id_audiencia == null ||
        this.state.id_ventana == null
      ) {
        this.handleCatalogue("catFormat", 9);
        this.handleCatalogue("catPlatform", 13);
        this.handleCatalogue("catAudience", 8);
        this.handleCatalogue("catGender", 4);
        this.handleCatalogue("catSubgender", 25);
        this.handleCatalogue("catThematic", 26);
        this.handleCatalogue("catMotivation", 27);
        this.handleCatalogue("catEnviroment", 29);
        this.handleCatalogue("catTemp", 28);
        this.setState({
          expanded: "panel3",
        });
        return false;
      }

      if (
        this.state.anio_produccion == "" ||
        this.state.anio_produccion == null
      ) {
        this.handleCatalogue("catProducers", 31);
        this.handleCatalogue("catDuration", 23);
        this.setState({
          expanded: "panel4",
        });
        return false;
      }

      // if((this.state.versionList.find(e=>e==="Otras")&&this.state.otras_versiones=="")){
      //   this.setState({
      //     expanded: 'panel5',
      //   });
      //   return false;
      // }
    }
  };

  updateProgress = (progress) => {
    if (!this.state.uploadProgress || progress > this.state.uploadProgress)
      this.setState({ uploadProgress: progress.toFixed(2) });
  };

  handleComplete = async () => {
    const { completed } = this.state;
    // this.setState({ loading: true })

    //AHDA 10/05/2019
    //Added audit call
    const auditBody = {
      id_usuario: returnCurrentUserId(),
      user_action: 9,
      work_id: this.props.workId,
      portal: "obras",
      screen: this.props.screen,
    };

    // JCMY 18/06/2019
    this.setState({ loading: true });
    // EGs - 16/04/2019 - start

    let step = this.state.activeStep;
    let completedStep = this.state.activeStep;

    if (this.props.canSeeEvaluationProcess && (this.state.rolId === 21 || this.state.rolId === 19)) {
      if (step === 4) {
        step = 6;
      } else if (step > 3) {
        step -= 1;
      }
    }

    completedStep = step;

    if (!this.props.canEditWorkUserData) {
      if (this.state.activeStep > 0) {
        if (this.props.canSeeEvaluationProcess) {
          completedStep = 3;
        } else {
          completedStep = step - 1;
        }
      }
    }

    switch (step) {
      case 0:
        //AHDA 10/05/2019
        //Added audit call
        if (Object.keys(completed).length <= 0) {
          //AHDA 17/05/2019: added validation for refresh
          apiPOST("/audit/add", auditBody, true)
            .then()
            .catch((err) => console.log("Error in audit: ", err));
        }

        //#region - Datos del Usuario
        if (this.state.companyProviderCatalog) {
        }
        const requestWork = this.getUpdateObject();

        apiPOST("/works/updatework", requestWork)
          .then((response) => {
            if (response.status === 200) {
              if (this.state.update === 0) {
                // Send email
                const requestGet = {
                  portal: "obras",
                  notificationId: 1,
                };
              }

              completed[completedStep] = true;
              this.setState({ completed, loading: false });
              this.handleNext();
            } else {
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error al actualizar la obra",
                loading: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              openAlert: true,
              variant: "error",
              message: "Ocurrió un error al actualizar la obra",
              loading: false,
            });
          });

        const requestWorkContact = {
          companyProvider: this.state.companyProvider,
          representative: this.state.representative,
          address: this.state.address,
          telephone: this.state.telephone,
          email: this.state.email,
          idWC: this.props.workId,
        };
        apiPOST("/works/updateworkcontact", requestWorkContact)
          .then((response) => {
            if (response.status === 200) {
            } else {
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error al actualizar la obra",
                loading: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              openAlert: true,
              variant: "error",
              message: "Ocurrió un error al actualizar la obra",
              loading: false,
            });
          });

        //#endregion
        break;
      case 1:
        //AHDA 10/05/2019
        //Added audit call
        if (Object.keys(completed).length <= 0) {
          //AHDA 17/05/2019: added validation for refresh
          apiPOST("/audit/add", auditBody, true)
            .then()
            .catch((err) => console.log("Error in audit: ", err));
        }
        //#region - Generales de la obra

        if (this.state.authsCatalog.length == 0) {
          this.setState({
            openAlert: true,
            variant: "error",
            message: "Selecciona Autores/Adaptadores del catalogo",
            loading: false,
          });
        } else if (
          this.state.versionList.length == 0 &&
          this.state.tipo_obra != "primigenia"
        ) {
          this.setState({
            openAlert: true,
            variant: "error",
            message: "Selecciona las Otras Versiones de la obra",
            loading: false,
          });
        } else {
          const updateWorkObj = this.getUpdateObject();
          apiPOST("/works/updatework", updateWorkObj)
            .then((response) => {
              if (response.status === 200) {
                if (this.state.update === 0) {
                  // Send email
                  const requestGet = {
                    portal: "obras",
                    notificationId: 1,
                  };
                }

                completed[completedStep] = true;
                this.setState({ completed, loading: false });
                this.handleNext();
              } else {
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "Ocurrió un error al actualizar la obra",
                  loading: false,
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error al actualizar la obra",
                loading: false,
              });
            });

          // Delete label
          apiPOST("/works/deletelabel", { work_id: this.state.id_obras })
            .then((response) => {
              if (response.status === 200) {
                const requestAddLabels = {
                  work_id: this.state.id_obras,
                  label_value: this.state.label_work,
                };

                apiPOST("/works/addlabel", requestAddLabels)
                  .then((response) => {
                    if (response.status !== 200) {
                      this.setState({
                        openAlert: true,
                        variant: "error",
                        message: "Ocurrió un error al actualizar etiquetas",
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    this.setState({
                      openAlert: true,
                      variant: "error",
                      message: "Ocurrió un error inesperado",
                    });
                  });
              } else {
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "Ocurrió un error al actualizar etiquetas",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error inesperado",
              });
            });

          let producers = [];
          this.state.producerList.map((p) => {
            producers.push({
              // work_id: this.state.id_obras,
              producer_id: parseInt(p),
            });
          });

          const producresReq = {
            work_id: this.state.id_obras,
            producers: producers,
          };

          apiPOST("/works/updateproducer", producresReq)
            .then((response) => {
              if (response.status !== 200) {
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "Ocurrió un error al actualizar productores",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error inesperado",
              });
            });

          if (this.state.adaptationsPanel) {
            let adaptation = [];
            let adaptationDrop = [];
            this.state.versionList.map((p) => {
              let item = this.state.works.find((fitem) => fitem.titulo == p);
              adaptation.push({
                // work_id: this.state.id_obras,
                idPrimigenia: this.state.id_obras,
                idAdaptacion: item ? item.id_obras : "-1",
              });
            });
            this.state.versionListStart.map((p) => {
              let item2 = this.state.works.find((fitem) => fitem.titulo == p);
              adaptationDrop.push({
                // work_id: this.state.id_obras,
                idPrimigenia: this.state.id_obras,
                idAdaptacion: item2 ? item2.id_obras : "-1",
              });
            });

            const adaptationReq = {
              adaptations: adaptation,
              dropadaptations: adaptationDrop,
            };

            apiPOST("/works/updateadaptation", adaptationReq)
              .then((response) => {
                if (response.status !== 200) {
                  this.setState({
                    openAlert: true,
                    variant: "error",
                    message: "Ocurrió un error al actualizar productores",
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "Ocurrió un error inesperado",
                });
              });
          }

          // JCMY 04/06/2019 obteniendo obras con el mismo nombre
          apiPOST("/works/getrepeatedworks", {
            title: this.state.titulo_original,
            id: this.state.id_obras,
          })
            .then((response) => {
              if (response.status === 200) {
                if (response.data.length > 0) {
                  this.setState({
                    dataRepeatedWorks: response.data,
                    show: true,
                  });
                }
              } else {
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "Ocurrió un error al revisar obras repetidas",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error inesperado",
              });
            });
        }
        //#endregion
        break;
      case 2:
        //AHDA 10/05/2019
        //Added audit call
        if (Object.keys(completed).length <= 0) {
          //AHDA 17/05/2019: added validation for refresh
          apiPOST("/audit/add", auditBody, true)
            .then()
            .catch((err) => console.log("Error in audit: ", err));
        }

        //#region - Sinopsis
        const updateWorkObjc2 = this.getUpdateObject();
        apiPOST("/works/updatework", updateWorkObjc2)
          .then((response) => {
            if (response.status === 200) {
              if (this.state.update === 0) {
                // Send emailu
                const requestGet = {
                  portal: "obras",
                  notificationId: 1,
                };
              }

              completed[completedStep] = true;
              this.setState({ completed, loading: false });
              this.handleNext();
            } else {
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error al actualizar la obra",
                loading: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              openAlert: true,
              variant: "error",
              message: "Ocurrió un error al actualizar la obra",
              loading: false,
            });
          });
        //#endregion
        break;
      case 3:
        //AHDA 10/05/2019
        //Added audit call
        if (Object.keys(completed).length <= 0) {
          //AHDA 17/05/2019: added validation for refresh
          apiPOST("/audit/add", auditBody, true)
            .then()
            .catch((err) => console.log("Error in audit: ", err));
        }

        //#region - Personajes
        //EGS - 09/05.2019  f
        // if (this.state.new_characters.length == 0) {
        //   this.setState({ openAlert: true, variant: "error", message: "Agrega al menos un Personaje " });
        // } else if (!this.state.filesDiagram) {
        //   this.setState({ openAlert: true, variant: "error", message: "Agrega Diagrama de relaciones" });
        // } else {
        let tempCharacters = [];
        for (let i in this.state.new_characters) {
          tempCharacters.push({
            nombre: this.state.new_characters[i].nombre,
            edad: this.state.new_characters[i].edad,
            psicologia: this.state.new_characters[i].psicologia,
          });
        }
        let charRequest = {
          work_id: this.state.id_obras,
          characters: tempCharacters,
        };

        //AHDA 05/05/2019
        //Changed validation, added finally
        let errCharacters = false,
          errFile = true;
        apiPOST("/works/updatepersonage", charRequest)
          .then((response) => {
            if (response.status === 200) {
              errCharacters = false;
              //completed[this.state.activeStep] = true;
              //this.setState({ completed });
              //this.handleNext();
            } else {
              errCharacters = true;
              //this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al actualizar la obra" });
            }
          })
          .catch((error) => {
            errCharacters = true;
            console.log(error);
            //this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al actualizar la obra" });
          })
          .finally(() => {
            if (this.state.filesDiagram.length > 0) {
              let auxFile = {
                tipo_archivo: "diagrama relaciones",
                ruta: "",
                id_obra: this.state.id_obras,
                id_idioma: 1,
              };

              let auxDiagramFile = undefined;

              saveCompleteFile(
                `obras/prospectos/${this.state.id_obras}${this.state.id_user_work}/otros`,
                this.state.filesDiagram[0],
                "public",
                "/works/addmultimedia",
                auxFile
              )
                .then((response) => {
                  errFile = false;
                  auxDiagramFile = auxFile;
                  auxDiagramFile.ruta = response.key;
                  auxDiagramFile.id_material_multimedia =
                    response.data.new_ids[0];
                })
                .catch((err) => {
                  errFile = true;
                  auxDiagramFile = undefined;
                })
                .finally(() => {
                  completed[completedStep] = true;
                  this.setState({
                    completed,
                    openAlert: errCharacters ? true : errFile ? true : false,
                    variant: "error",
                    message:
                      "Ocurrió un error al actualizar: Personajes, intente de nuevo más tarde",
                    relationDiagramFile: auxDiagramFile,
                    loading: false,
                  });
                  this.handleNext();
                });
            } else {
              completed[completedStep] = true;
              this.setState({
                completed,
                loading: false,
                openAlert: errCharacters,
                variant: "error",
                message:
                  "Ocurrió un error al actualizar: Personajes, intente de nuevo más tarde",
              });
              this.handleNext();
            }
          });
        // }
        break;
      case 4:
        // if (this.state.evaluationFile==undefined && this.state.filesEvaluation.length== 0) {
        //   this.setState({ openAlert: true, variant: "error", message: "Agrega el Dictamen de evaluación ", loading: false });
        // } else if((this.state.evaluationFile&&this.state.evaluationFile.length==0)&& this.state.filesEvaluation.length== 0){
        //   this.setState({ openAlert: true, variant: "error", message: "Agrega el Dictamen de evaluación ", loading: false });
        // }else{
        //AHDA 10/05/2019
        //Added audit call
        if (Object.keys(completed).length <= 0) {
          //AHDA 17/05/2019: added validation for refresh
          apiPOST("/audit/add", auditBody, true)
            .then()
            .catch((err) => console.log("Error in audit: ", err));
        }

        let errEvaluation = false,
          errEvaluationFile = false;
   
          let evaluationReq = {
            idEvaluacionObras: this.state.evId,
            comentarios: this.state.evComments,
            id_nivel_riesgo: this.state.evRiskLevel,
            analisis: null,
            rating: this.state.evRating,
            horario: this.state.evHorario,
            share: this.state.evShare,
            work_id: this.props.workId,
            calification: this.state.evQualification,
            countryEvaluations: this.state.countryEvaluations,
          };

          apiPOST("/works/updateevaluation", evaluationReq)
            .then((response) => {
              if (response.status === 200) {
                errEvaluation = false;
                //completed[this.state.activeStep] = true;
                //this.handleNext();
              } else {
                errEvaluation = true;
                //this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al actualizar contacto" });
              }
            })
            .catch((error) => {
              errEvaluation = true;
              console.log(error);
              //this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
            })
            .finally(() => {
              // JCMY 19/06/2019
              // if (eF == undefined) {
              let auxFile = {
                tipo_archivo: "evaluacion",
                ruta: "",
                id_obra: this.state.id_obras,
                id_idioma: 1,
              };

              let auxEvaluationFile = undefined,
                promises = [];
              if (this.state.filesEvaluation.length > 0) {
                for (var i = 0; i < this.state.filesEvaluation.length; i++) {
                  promises.push(
                    saveCompleteFile(
                      `obras/prospectos/${this.state.id_obras}${this.state.id_user_work}/evaluacion`,
                      this.state.filesEvaluation[i],
                      "public",
                      "/works/addmultimedia",
                      auxFile
                    )
                  );
                }
                Promise.all(promises)
                  .then((response) => {
                    for (var i = 0; i < response.length; i++) {
                      errEvaluationFile = false;
                      auxEvaluationFile = auxFile;
                      auxEvaluationFile.ruta = response[i].key;
                      auxEvaluationFile.id_material_multimedia =
                        response[i].data.new_ids[0];
                      if (auxEvaluationFile && this.state.evaluationFile)
                        this.state.evaluationFile.push({
                          tipo_archivo: "evaluacion",
                          ruta: response[i].key,
                          id_obra: this.state.id_obras,
                          id_idioma: 1,
                          id_material_multimedia: response[i].data.new_ids[0],
                        });
                      auxEvaluationFile = undefined;
                    }
                  })
                  .catch((err) => {
                    errEvaluationFile = true;
                    auxEvaluationFile = undefined;
                  })
                  .finally(() => {
                    completed[completedStep] = true;
                    this.setState({
                      completed,
                      openAlert: errEvaluation
                        ? true
                        : errEvaluationFile
                        ? true
                        : false,
                      variant: "error",
                      message:
                        "Ocurrió un error al actualizar: Evaluación, intente de nuevo más tarde",
                      // evaluationFile: auxEvaluationFile,
                      loading: false,
                      filesEvaluation: [],
                    });
                    this.handleNext();
                  });
                const requestGet = {
                  portal: "obras",
                  notificationId: 6,
                };
                apiPOST("/notifications/getemailanduser", requestGet)
                  .then((response) => {
                    if (response.status === 200) {
                      const users = response.data[1];
                      let userList = [];
                      for (const x in users) {
                        userList.push(users[x].id_usuario);
                      }
                      const emails = response.data[0];
                      let emailList = [];
                      for (const x in emails) {
                        emailList.push(emails[x].usuario_cognito);
                      }
                      if (emailList.length > 0) {
                        const requestEmail = {
                          emails: emailList.filter((e) => e != ""),
                          type: "evaluationAssessment",
                          work: {
                            titulo_original: this.state.titulo_original,
                          },
                          portal: "obras",
                        };
                        apiPOST("/notifications/sendemail", requestEmail)
                          .then((response) => {
                            if (response.status !== 200) {
                              this.setState({
                                openAlert: true,
                                variant: "error",
                                message:
                                  "Ocurrió un error al enviar correo electrónico",
                              });
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                            this.setState({
                              openAlert: true,
                              variant: "error",
                              message: "Ocurrió un error inesperado",
                            });
                          });
                      }
                      // Envio de notificacion
                      for (const x in userList) {
                        const requestNotification = {
                          notification_type: "evaluationAssessment",
                          id_user: userList[x],
                          id_work: this.props.workId,
                          portal: "obras",
                          specifiedProductor: 79,
                          id_notification_type: 6,
                        };
                        apiPOST("/notifications/add", requestNotification)
                          .then((response) => {
                            if (response.status !== 200) {
                              this.setState({
                                openAlert: true,
                                variant: "error",
                                message:
                                  "Ocurrió un error al enviar la notificación",
                              });
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                            this.setState({
                              openAlert: true,
                              variant: "error",
                              message: "Ocurrió un error inesperado",
                            });
                          });
                      }
                    } else {
                      this.setState({
                        openAlert: true,
                        variant: "error",
                        message:
                          "Ocurrió un error al obtener correos y usuarios a notificar",
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    this.setState({
                      openAlert: true,
                      variant: "error",
                      message: "Ocurrió un error inesperado",
                    });
                  });
              } else {
                completed[completedStep] = true;
                this.setState({
                  completed,
                  openAlert: errEvaluation
                    ? true
                    : errEvaluationFile
                    ? true
                    : false,
                  variant: "error",
                  message:
                    "Ocurrió un error al actualizar: Evaluación, intente de nuevo más tarde",
                  //evaluationFile: auxEvaluationFile,
                  loading: false,
                });
                this.handleNext();
              }
            });
        
        break;
      //AHDA 05/05/2019
      //Added case 5
      case 5:
        if (this.state.rawMaterials.length > 0) {
          //AHDA 10/05/2019
          //Added audit call
          if (Object.keys(completed).length <= 0) {
            //AHDA 17/05/2019: added validation for refresh
            apiPOST("/audit/add", auditBody, true)
              .then()
              .catch((err) => console.log("Error in audit: ", err));
          }

          let errorFiles = false;
          let auxRawMaterials = [...this.state.rawMaterials];
          let auxMaterials = [...this.state.materials];
          let auxNewMaterials = this.state.rawMaterials.filter((m) => m.isNew);
          let auxEditedMaterials = this.state.rawMaterials.filter(
            (m) => m.isEdited && !m.isNew
          );
          //let auxEditedMaterials = this.state.rawMaterials.filter(m => m.isEdited);

          //Push objects to S3
          if (auxEditedMaterials.length > 0) {
            let materialsUpdated = [...auxEditedMaterials];
            let materialsFileUpdated = [],
              materialsWithoutFile = [];

            for (let edited in materialsUpdated) {
              let folder = "";
              let auxItem = { ...materialsUpdated[edited] };

              if (auxItem.file) {
                switch (auxItem.tipo_archivo) {
                  case "libreto":
                    folder = "libretos";
                    break;
                  case "video":
                  case "capitulo1":
                  case "trailer":
                    folder = "videos";
                    break;
                  default:
                    folder = "otros";
                    break;
                }

                auxItem.path = `obras/prospectos/${this.state.id_obras}${this.state.id_user_work}/${folder}`;
                auxItem.id_idioma = Number.parseInt(
                  materialsUpdated[edited].id_idioma
                );
                auxItem.idObra = this.state.id_obras;
                auxItem.id_material = auxItem.id_material_multimedia;
                auxItem.idUser = returnCurrentUserId();

                materialsFileUpdated.push(auxItem);
              } else {
                auxItem.id_idioma = Number.parseInt(
                  materialsUpdated[edited].id_idioma
                );
                auxItem.idObra = this.state.id_obras;
                auxItem.id_material = auxItem.id_material_multimedia;
                auxItem.idUser = returnCurrentUserId();

                materialsWithoutFile.push(auxItem);
              }
            }

            if (materialsFileUpdated.length > 0) {
              const responseStorage = await saveMultipleFilesAll(
                materialsFileUpdated,
                "public",
                this.updateProgress
              );
              materialsUpdated = [
                ...materialsWithoutFile,
                ...responseStorage.keys,
              ];
            } else {
              materialsUpdated = [...materialsWithoutFile];
            }

            apiPOST("/works/updatematerial", { material: materialsUpdated })
              .then((response) => {
                if (response.status === 200) {
                  let auxUpdatedMaterials = [...materialsUpdated];
                  for (let x in auxUpdatedMaterials) {
                    const auxRawItem = auxUpdatedMaterials[x];
                    const auxRawItemIdx = auxRawMaterials.findIndex(
                      (m) =>
                        m.id_material_multimedia ==
                        auxUpdatedMaterials[x].id_material_multimedia
                    );
                    const auxItemIdx = auxMaterials.findIndex(
                      (m) =>
                        m.id_material_multimedia ==
                        auxUpdatedMaterials[x].id_material_multimedia
                    );

                    auxRawItem.isEdited = false;

                    auxRawMaterials[auxRawItemIdx] = auxRawItem;
                  }
                } else {
                  errorFiles = true;
                }
              })
              .catch((err) => {
                errorFiles = true;
              })
              .finally(() => {
                if (auxNewMaterials.length <= 0) {
                  this.setState({
                    completed,
                    loading: false,
                    openAlert: errorFiles,
                    variant: "error",
                    message:
                      "Ocurrió un error al actualizar: Material, intente de nuevo más tarde",
                    rawMaterials: auxRawMaterials,
                    materials: auxMaterials,
                  });
                  this.handleNext();
                }
              });
          }

          if (auxNewMaterials.length > 0) {
            for (let x in auxNewMaterials) {
              let folder = "";

              switch (auxNewMaterials[x]) {
                case "libreto":
                  folder = "libretos";
                  break;
                case "video":
                case "capitulo1":
                case "trailer":
                  folder = "videos";
                  break;
                default:
                  folder = "otros";
                  break;
              }

              auxNewMaterials[
                x
              ].path = `obras/prospectos/${this.state.id_obras}${this.state.id_user_work}/${folder}`;
              auxNewMaterials[x].id_idioma = Number.parseInt(
                auxNewMaterials[x].id_idioma
              );
              auxNewMaterials[x].id_obra = this.state.id_obras;
            }

            saveMultipleFilesAll(
              auxNewMaterials,
              "public",
              this.updateProgress
            ).then((responseKeys) => {
              this.setState({ uploadProgress: null });

              if (responseKeys.errors.length > 0) {
                errorFiles = true;
              }
              console.log("S3 keys:", responseKeys.keys);
              //Send to RDS
              apiPOST("/works/addmultimedia", { material: responseKeys.keys })
                .then((response) => {
                  if (response.status === 200) {
                    auxNewMaterials = responseKeys.keys;
                    for (let x in auxNewMaterials) {
                      const auxRawItem = auxNewMaterials[x];
                      const auxRawItemIdx = auxRawMaterials.findIndex(
                        (m) =>
                          m.id_material_multimedia ==
                          auxNewMaterials[x].id_material_multimedia
                      );
                      const auxItemIdx = auxMaterials.findIndex(
                        (m) =>
                          m.id_material_multimedia ==
                          auxNewMaterials[x].id_material_multimedia
                      );

                      auxRawItem.id_material_multimedia =
                        response.data.new_ids[x];
                      auxRawItem.isNew = false;

                      auxRawMaterials[auxRawItemIdx] = auxRawItem;
                      auxMaterials[auxItemIdx].id_material_multimedia =
                        auxRawItem.id_material_multimedia;
                    }
                  } else {
                    errorFiles = true;
                  }
                })
                .catch((err) => {
                  errorFiles = true;
                })
                .finally(() => {
                  completed[completedStep] = true;
                  this.setState({
                    completed,
                    openAlert: errorFiles,
                    variant: "error",
                    message:
                      "Ocurrió un error al actualizar: Material, intente de nuevo más tarde",
                    rawMaterials: auxRawMaterials,
                    materials: auxMaterials,
                    loading: false,
                  });
                  this.handleNext();
                });
            });
          } else {
            completed[completedStep] = true;
            this.setState({
              completed,
              loading: false,
            });
            this.handleNext();
          }
        } else {
          completed[completedStep] = true;
          this.setState({
            completed,
            loading: false,
          });
          this.handleNext();
        }
        break;
      case 6: //Added new step : 'Proceso de evaluación'
        //AHDA 10/05/2019
        //Added audit call
        if (
          this.state.filesEvaluationProcess.length <= 0 &&
          this.state.filesDataEvaluationProcess.length <= 0
        ) {
          this.setState({
            openAlert: true,
            variant: "warning",
            message: "Ingresa un dictamen de evaluación como mínimo",
            loading: false,
          });
          return false;
        } else {
          if (Object.keys(completed).length <= 0) {
            //AHDA 17/05/2019: added validation for refresh
            apiPOST("/audit/add", auditBody, true)
              .then()
              .catch((err) => console.log("Error in audit: ", err));
          }
          let errEvaluationProcess = false,
            errEvaluationProcessFile = false,
            tipo_archivo = "evaluacion_pro",
            material_nombre = "";

          let auxFile = {
            tipo_archivo,
            ruta: "",
            id_obra: this.state.id_obras,
            id_idioma: 1,
            material_nombre,
            id_user: returnCurrentUserId(),
          };

          let auxEvaluationProcessFile = undefined,
            promises = [];
          if (this.state.filesEvaluationProcess.length > 0) {
            for (var i = 0; i < this.state.filesEvaluationProcess.length; i++) {
              promises.push(
                saveCompleteFile(
                  `obras/prospectos/${this.state.id_obras}${this.state.id_user_work}/${tipo_archivo}`,
                  this.state.filesEvaluationProcess[i],
                  "public",
                  "/works/addmultimedia",
                  auxFile
                )
              );
            }
            Promise.all(promises)
              .then((response) => {
                for (var i = 0; i < response.length; i++) {
                  errEvaluationProcessFile = false;
                  auxEvaluationProcessFile = auxFile;
                  auxEvaluationProcessFile.ruta = response[i].key;
                  auxEvaluationProcessFile.id_material_multimedia =
                    response[i].data.new_ids[0];
                  if (
                    auxEvaluationProcessFile &&
                    this.state.evaluationProcessFile
                  )
                    this.state.evaluationProcessFile.push({
                      tipo_archivo,
                      ruta: response[i].key,
                      id_obra: this.state.id_obras,
                      id_idioma: 1,
                      material_nombre,
                      id_material_multimedia: response[i].data.new_ids[0],
                    });
                  auxEvaluationProcessFile = undefined;
                }
              })
              .catch((err) => {
                errEvaluationProcess = true;
                auxEvaluationProcessFile = undefined;
              })
              .finally(() => {
                completed[completedStep] = true;
                this.setState({
                  completed,
                  openAlert: errEvaluationProcess
                    ? true
                    : auxEvaluationProcessFile
                    ? true
                    : false,
                  variant: "error",
                  message:
                    "Ocurrió un error al actualizar: Proceso de Evaluación, intente de nuevo más tarde",
                  // evaluationFile: auxEvaluationFile,
                  loading: false,
                  filesEvaluationProcess: [],
                });
                this.handleNext();
              });
          } else {
            completed[completedStep] = true;
            this.setState({
              completed,
              openAlert: errEvaluationProcess
                ? true
                : auxEvaluationProcessFile
                ? true
                : false,
              variant: "error",
              message:
                "Ocurrió un error al actualizar: Proceso de Evaluación, intente de nuevo más tarde",
              // evaluationFile: auxEvaluationFile,
              loading: false,
              filesEvaluationProcess: [],
            });
            this.handleNext();
          }
          break;
        }
      default:
        break;
    }
    // #endregion
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: {},
    });
  };

  handleChangeSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //JCMY 30/04/2019
  handleChangeSelectWorkflow = (event) => {
    const states = this.state.statesWorkFlow;
    const obj = states.find((obj) => obj.value === event.target.value);
    const evaluationAreaByWorkflow = this.state.evaluatingAreas.filter((e) => {
      switch (obj.data) {
        case "Más material":
        case "Solicitar ajustes":
          return (
            e.data === "Jefe de Análisis de Contenido" ||
            e.data === "Empresa/Proveedor"
          );
        default:
          return e;
      }
    });

    if (
      obj.data == "Preevaluación" &&
      this.state.usersFull == null &&
      this.state.usersExtFull == null
    ) {
      apiPOST("/users/getAll", { portal: 2 }).then((response) => {
        let usersExtFull, usersFull;
        usersFull = response.data;
        usersExtFull =
          response.data && response.data.filter((e) => e.id_rol == 12);
        this.setState({ usersFull, usersExtFull });
      });
    }

    this.setState({
      [event.target.name]: event.target.value,
      stateWorkflowString: obj.data,
      evaluationAreaByWorkflow,
    });
  };

  handledOpenForm = (item) => {
    // EGs - 16/04/2019 - start
    this.setState({ autFromUsr: false });

    if (item !== undefined) {
      let isusr = false;

      for (let usrA in this.state.authsUsr) {
        if (this.state.authsUsr[usrA].authors_id == item.authors_id) {
          this.setState({
            autFromUsr: true,
            author_id: item.authors_id,
            author_nameUsr: item.name,
            author_rol: item.rol,
            openForm: true,
          });
          isusr = true;
        }
      }

      if (!isusr) {
        const authorIdx = this.state.authorsData.findIndex(
          (a) => a.authors_id === item.authors_id
        );

        this.setState({
          authorIdx: authorIdx !== -1 ? authorIdx : null,
          itsNewAuth: false,
          proNameAutor: item.authors_id,
          author_rol: item.rol,
          openForm: true,
        });
      }
    } else {
      this.setState({
        openForm: true,
        itsNewAuth: true,
        proNameAutor: "",
        author_rol: "",
      });
    }
  };

  handleSubmitAuthor = () => {
    // JCMY 02/07/2019 incidencia 1015

    if (
      !this.state.author_rol ||
      !this.state.proNameAutor ||
      this.state.proNameAutor === ""
    ) {
      this.setState({
        errorproNameAutor:
          !this.state.proNameAutor || this.state.proNameAutor === ""
            ? true
            : false,
        errorauthor_rol: this.state.participation ? false : true,
      });
    } else {
      //Validate errors
      if (!this.state.errorproNameAutor && !this.state.errorauthor_rol) {
        //EGs - 16/04/2019 - start

        if (this.state.autFromUsr) {
          const aCat = [...this.state.authsCatalog];
          const aUsr = [...this.state.authsUsr];

          for (let i in aUsr) {
            if (aUsr[i].authors_id === this.state.author_id) {
              //eliminar el autor capturado por el usuario
              aUsr.splice(i, 1);
              // Obtener la informaión del autor de catalogo
              let thename = this.state.catAuthors.find(
                (p) => parseInt(p.value) == this.state.proNameAutor
              );
              // Agregar nuevo autor de catalogo
              // JCMY 92/07/2019 insidencia 984
              aCat.push({
                authors_id: this.state.proNameAutor,
                name: thename.label,
                rol: this.state.author_rol,
                active: "active",
              });
            }
          }

          let totalAuths = [...aUsr];
          let reqAuthsCatalog = [];

          for (let x in aCat) {
            // Llenar arreglo de aauutores para el servicio de actualizar autores de catalogo
            let temp = {
              id_autor: aCat[x].authors_id,
              rol: aCat[x].rol,
            };
            reqAuthsCatalog.push(temp);
            // Crear oobjeto para actualzar información de la tabla
            totalAuths.push(aCat[x]);
          }
          // ------------------------------UPDATE Autor de Catalogo
          let autRequest = {
            work_id: this.props.workId,
            authors: reqAuthsCatalog,
          };

          apiPOST("/works/updateauthor", autRequest)
            .then((response) => {
              if (response.status === 200) {
                this.setState({ authsCatalog: aCat });
              } else {
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "Ocurrió un error al actualizar autores",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error inesperado",
              });
            });

          // ------------------------------UPDATE Autor capturado por el usuario
          apiPOST("/works/deleteauthortmp", { work_id: this.props.workId })
            .then((response) => {
              if (response.status === 200) {
                const aut = {
                  work_id: this.props.workId,
                  authors: aUsr,
                };

                apiPOST("/works/addauthortmp", aut)
                  .then((response) => {
                    if (response.status !== 200) {
                      this.setState({
                        openAlert: true,
                        variant: "error",
                        message: "Ocurrió un error al actualizar autores",
                      });
                    } else {
                      // JCMY 02/07/2019 insidencia 981
                      this.setState({
                        authorsData: totalAuths,
                        authsUsr: aUsr,
                        openForm: false,
                        proNameAutor: "",
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    this.setState({
                      openAlert: true,
                      variant: "error",
                      message: "Ocurrió un error inesperado",
                    });
                  });
              } else {
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "Ocurrió un error al actualizar autores",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error inesperado",
              });
            });
        } else {
          const aCat = [...this.state.authsCatalog];
          const aUsr = [...this.state.authsUsr];
          let totalAuths = [...aUsr];

          let reqAuthsCatalog = [];

          aCat.forEach((a) => {
            let temp = {
              id_autor: a.authors_id,
              rol:
                a.authors_id === this.state.proNameAutor
                  ? this.state.author_rol
                  : a.rol,
            };

            reqAuthsCatalog.push(temp);

            totalAuths.push({
              authors_id: a.authors_id,
              name: a.name,
              rol:
                a.authors_id === this.state.proNameAutor
                  ? this.state.author_rol
                  : a.rol,
              active: "active",
            });
          });

          const currentAuthor =
            this.state.authorIdx != null && this.state.authorIdx !== -1
              ? this.state.authorsData[this.state.authorIdx]
              : null;

          if (this.state.itsNewAuth && currentAuthor == null) {
            // JCMY 01/07/2019 incidencia 983
            let authorExist = totalAuths.find(
              (p) => parseInt(p.authors_id) == this.state.proNameAutor
            );

            if (authorExist === undefined) {
              let temp = {
                id_autor: this.state.proNameAutor,
                rol: this.state.author_rol,
              };
              reqAuthsCatalog.push(temp);

              let thename = this.state.catAuthors.find(
                (p) => parseInt(p.value) == this.state.proNameAutor
              );

              aCat.push({
                authors_id: this.state.proNameAutor,
                name: thename.label,
                rol: this.state.author_rol,
              });
              // JCMY 02/07/2019 insidencia 984
              totalAuths.push({
                authors_id: this.state.proNameAutor,
                name: thename.label,
                rol: this.state.author_rol,
                active: "active",
              });

              // ------------------------------UPDATE Autor de Catalogo
              let autRequest = {
                work_id: this.props.workId,
                authors: reqAuthsCatalog,
              };

              apiPOST("/works/updateauthor", autRequest)
                .then((response) => {
                  if (response.status === 200) {
                    this.setState({
                      openForm: false,
                      authorsData: totalAuths,
                      authsCatalog: aCat,
                    });
                  } else {
                    this.setState({
                      openAlert: true,
                      variant: "error",
                      message: "Ocurrió un error al actualizar autores",
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({
                    openAlert: true,
                    variant: "error",
                    message: "Ocurrió un error inesperado",
                  });
                });
            } else {
              this.setState({
                openAlert: true,
                variant: "warning",
                message: "El autor/adaptador ya existe en la lista",
                loading: false,
              });
            }
          }

          if (currentAuthor) {
            const shouldUpdate =
              currentAuthor.authors_id !== this.state.proNameAutor ||
              currentAuthor.rol !== this.state.author_rol;

            if (shouldUpdate) {
              let authorsRequest = reqAuthsCatalog.filter(
                (a) => a.id_autor !== currentAuthor.authors_id
              );
              let authorsData = totalAuths.filter(
                (a) => a.authors_id !== currentAuthor.authors_id
              );
              let authorsCatalog = aCat.filter(
                (a) => a.authors_id !== currentAuthor.authors_id
              );

              authorsRequest.push({
                id_autor: this.state.proNameAutor,
                rol: this.state.author_rol,
              });

              let thename = this.state.catAuthors.find(
                (p) => parseInt(p.value) == this.state.proNameAutor
              );
              authorsCatalog.push({
                authors_id: this.state.proNameAutor,
                name: thename.label,
                rol: this.state.author_rol,
              });

              authorsData.push({
                authors_id: this.state.proNameAutor,
                name: thename.label,
                rol: this.state.author_rol,
                active: "active",
              });

              let autRequest = {
                work_id: this.props.workId,
                authors: authorsRequest,
              };

              apiPOST("/works/updateauthor", autRequest)
                .then((response) => {
                  if (response.status === 200) {
                    this.setState({
                      openForm: false,
                      authorIdx: null,
                      authorsData,
                      authsCatalog: authorsCatalog,
                    });
                  } else {
                    this.setState({
                      openAlert: true,
                      variant: "error",
                      message: "Ocurrió un error al actualizar autores",
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({
                    openAlert: true,
                    variant: "error",
                    message: "Ocurrió un error inesperado",
                  });
                });
            }
          }
        }

        this.setState({ openForm: false });
      }
    }

    // #endregion
    // this.state.authorsData.push(auth)
  };

  //AHDA 03/05/2019
  //Chanaged method
  handledCloseForm = (form) => {
    this.setState({ [form]: false });
  };

  //AHDA 03/05/2019
  //Added method for clearing forms
  handleClear = (form) => {
    //TODO: add casse for other forms
    switch (form) {
      case "material":
        this.setState({
          filesMaterial: [],
          proNameMaterial: "",
          proTypeMaterial: "",
          proLanguageMaterial: "",
          proChapterMaterial: "",
          selectedMaterial: undefined,
          newMaterialTypeValue: "",
          isNewMaterialTypeSelected: false,
        });
        break;
      // JCTY 05/062019
      case "personaje":
        this.setState({
          new_characterName: "",
          new_characterAge: "",
          new_characterPsychology: "",
        });
        break;
      case "autor":
        this.setState({
          author_nameUsr: "",
          proNameAutor: "",
          author_rol: "",
        });
        break;
      default:
        break;
    }
  };

  //Characters
  handledCloseFormCharacters = () => {
    this.setState({ openForm: false });
  };

  //#region [rgba(100,100,100,0.1)] EGS - 03/05/2019

  //Characters
  handledOpenFormCharacters = (item) => {
    // this.handleClear('characters');
    //TODO, Bind when has elements
    if (item) {
      this.setState({
        openFormCharacters: true,
        selected: item,
        new_characterName: item.nombre,
        new_characterAge: item.edad,
        new_characterPsychology: item.psicologia,
      });
    } else {
      this.setState({ openFormCharacters: true });
    }
  };

  handleSubmitCharacters = () => {
    if (
      !this.state.errornew_characterName &&
      !this.state.errornew_characterPsychology
    ) {
      if (
        !this.state.new_characterName &&
        !this.state.new_characterPsychology
      ) {
        this.setState({
          errornew_characterName: true,
          errornew_characterPsychology: true,
        });
        return false;
      }

      //Changes are made locally
      let auxData = [...this.state.new_characters];
      let auxItem = {
        nombre: this.state.new_characterName,
        edad: this.state.new_characterAge,
        psicologia: this.state.new_characterPsychology,
      };

      if (this.state.selected) {
        let auxIndex = this.state.new_characters.findIndex(
          (c) => c.id === this.state.selected.id
        );
        auxItem.id = this.state.selected.id;
        auxData[auxIndex] = auxItem;
      } else {
        auxItem.id = auxData.length + 1;
        auxData.push(auxItem);
      }

      // JCMY 26/03/2019
      //Limpia datos tras agregar personaje
      this.setState({
        new_characters: auxData,
        selected: null,
        openFormCharacters: false,
        new_characterName: "",
        new_characterAge: "",
        new_characterPsychology: "",
      });
    }
  };

  //#endregion

  // EGS - 29/04/2019
  handleDeleteAuthor = (item) => {
    this.setState({ autFromUsr: false });
    for (let usrA in this.state.authsUsr) {
      if (this.state.authsUsr[usrA].authors_id == item.authors_id) {
        this.setState({ autFromUsr: true });
      }
    }
    if (!this.state.autFromUsr) {
      const auth = [...this.state.authsCatalog];
      let reqAuth = [];
      for (let x in auth) {
        if (auth[x].authors_id != item.authors_id) {
          let tempVar = {
            id_autor: parseInt(auth[x].authors_id),
            rol: auth[x].rol,
          };
          reqAuth.push(tempVar);
        } else {
          this.state.authsCatalog.splice(x, 1);
        }
      }
      let autRequest = {
        work_id: this.state.id_obras,
        authors: reqAuth,
      };

      apiPOST("/works/updateauthor", autRequest)
        .then((response) => {
          if (response.status === 200) {
            let authsTemp = [...this.state.authsUsr];
            for (let i in this.state.authsCatalog) {
              authsTemp.push(this.state.authsCatalog[i]);
            }
            this.setState({ authorsData: authsTemp });
          } else {
            this.setState({
              openAlert: true,
              variant: "error",
              message: "Ocurrió un error al actualizar autores",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            openAlert: true,
            variant: "error",
            message: "Ocurrió un error inesperado",
          });
        });
    } else {
      apiPOST("/works/deleteauthortmp", { work_id: this.state.id_obras })
        .then((response) => {
          if (response.status === 200) {
            const auth = [...this.state.authsUsr];
            const reqAuth = [];
            for (let x in auth) {
              if (auth[x].authors_id != item.authors_id) {
                reqAuth.push(auth[x]);
              } else {
                this.state.authsUsr.splice(x, 1);
              }
            }
            const aut = {
              work_id: this.state.id_obras,
              authors: reqAuth,
            };

            apiPOST("/works/addauthortmp", aut)
              .then((response) => {
                if (response.status !== 200) {
                  this.setState({
                    openAlert: true,
                    variant: "error",
                    message: "Ocurrió un error al actualizar autores",
                  });
                } else {
                  for (let i in this.state.authsCatalog) {
                    reqAuth.push(this.state.authsCatalog[i]);
                  }
                  this.setState({ authorsData: reqAuth });
                }
              })
              .catch((error) => {
                console.log(error);
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "Ocurrió un error inesperado",
                });
              });
          } else {
            this.setState({
              openAlert: true,
              variant: "error",
              message: "Ocurrió un error al actualizar autores",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            openAlert: true,
            variant: "error",
            message: "Ocurrió un error inesperado",
          });
        });
    }
  };

  handleDeleteCharacters = (item) => {
    let auxData = [...this.state.new_characters];
    let auxIndex = auxData.findIndex((c) => c.id === item.id);
    auxData.splice(auxIndex, 1);
    this.setState({ new_characters: auxData, selected: null });
  };

  handleUpdateCharacters = (evt, updated) => {
    this.setState({ new_characters: [...updated] });
  };

  //Modales
  handleOpenAvanzarObra = () => {
    // JCMY 16/04/19
    // Carga catalogos
    // Area Evaluadora
    // EGS - Carga de atalogos con metodo reutilizado
    this.handleCatalogue("evaluatingAreas", 1);
    this.handleCatalogue("rejectReasons", 36);
    this.handleCatalogue("usersSpecialists", 37);
    this.handleCatalogue("usersAdministrator", 38);
    this.handleCatalogue("usersAnalysisContent", 46);
    this.handleCatalogue("usersAnalysisDirectors", 45);

    // JCMY 17/04/2019
    let states = [];
    const state = this.state.statusWorkFlow;

    // WorkflowSteps

    switch (state) {
      case "Registrada":
        states = WorkflowSteps.registeredStep;
        break;
      case "Más material":
        states = WorkflowSteps.moreMaterialStep;
        break;
      case "Asignada":
        states = WorkflowSteps.assignedStep;
        break;
      case "Solicitar ajustes":
        states = WorkflowSteps.requestAdjustmentsStep;
        break;
      case "Preevaluación":
        states = WorkflowSteps.inEvaluationStep;
        break;
      case "Viable":
        states = WorkflowSteps.viableStep;
        break;
      case "En proceso de compra":
        states = WorkflowSteps.inProcessPurchaseStep;
        break;
      case "Comprar":
        states = WorkflowSteps.buyStep;
        break;
      case "En condiciones de negociación":
        states = WorkflowSteps.negotiationConditionsStep;
        break;
      case "Solicitud de elaboración de contrato":
        states = WorkflowSteps.contractApplicationStep;
        break;
      case "En firmas":
        states = WorkflowSteps.inSignaturesStep;
        break;
      case "En proceso de pago":
        states = WorkflowSteps.inProcessPaymentStep;
        break;
      case "No viable":
        states = WorkflowSteps.notViableStep;
        break;
      case "Con potencial":
        states = WorkflowSteps.withPotencialStep;
        break;
      case "Con potencial para otros formatos":
        states = WorkflowSteps.withPotencialOtherFormatStep;
        break;
      default:
        break;
    }

    // WorkflowSteps
    this.setState({ openAvanzarObra: true, statesWorkFlow: states });
  };

  // JCMY 24/04/2019
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  // JCMY 26/04/2019
  handleUpdatestage = (status) => {
    apiPOST("/works/updatestage", {
      idObra: this.props.workId,
      idWorkflow: 0,
      idStatus: status,
    })
      .then((response) => {
        // console.log(response)
        if (response.status !== 200) {
          this.setState({
            openAlert: true,
            variant: "error",
            message: "Ocurrió un error al actualizar obra",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          variant: "error",
          message: "Ocurrió un error inesperado",
        });
      });
  };

  handleNotifyUserProvider = (type, idNotification) => {
    const requestEmail = {
      emails: [this.state.email],
      type,
      work: {
        titulo_original: this.state.titulo_original,
      },
      portal: "obras",
    };
    apiPOST("/notifications/sendemail", requestEmail)
      .then((response) => {
        if (response.status !== 200) {
          this.setState({
            openAlert: true,
            variant: "error",
            message: "Ocurrió un error al enviar correo electrónico",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          variant: "error",
          message: "Ocurrió un error inesperado",
        });
      });
    const requestNotification = {
      notification_type: type,
      id_user: this.state.id_user_work,
      id_work: this.props.workId,
      portal: "obras",
      specifiedProductor: 79,
      id_notification_type: idNotification,
    };
    apiPOST("/notifications/add", requestNotification)
      .then((response) => {
        if (response.status !== 200) {
          this.setState({
            openAlert: true,
            variant: "error",
            message: "Ocurrió un error al enviar la notificación",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          variant: "error",
          message: "Ocurrió un error inesperado",
        });
      });
  };


  // JCMY 30/04/2019
  handleSendNotifications = (idNotification, notification, responsibleUser) => {
    const requestGet = {
      portal: "obras",
      notificationId: idNotification,
    };

    apiPOST("/notifications/getemailanduser", requestGet)
      .then((response) => {
        if (response.status === 200) {
          const users = response.data[1];

          let userList = [];
          const emails = response.data[0];

          let emailList = [];

          if (this.state.stateWorkflowString == "Preevaluación") {
            const user =
              this.state.usersFull &&
              this.state.usersFull.filter((e) =>
                this.state.evaluadorUserMultiple.includes(
                  e.id_usuario.toString()
                )
              );
            if (user) emailList.push(user.map((e) => e.usuario_cognito));
          } else if (this.state.stateWorkflowString === "Asignar evaluadores") {
            const evaluatorsList = emails.filter(user => this.state.evaluadorUserMultiple.includes(user.id_usuario.toString()));
            emailList.push(...evaluatorsList.map(user => user.usuario_cognito));
            userList.push(...evaluatorsList.map(user => user.id_usuario));
          } else {
            for (const x in users) {
              userList.push(users[x].id_usuario);
            }

            for (const x in emails) {
              emailList.push(emails[x].usuario_cognito);
            }
          }

          let authors = "";

          const dataAuthors = this.state.authorsData;
          for (const x in dataAuthors) {
            if (x == dataAuthors.length - 1) {
              authors = authors + dataAuthors[x].name;
            } else {
              authors = authors + dataAuthors[x].name + ", ";
            }
          }

          const companysProviders = this.state.companysProviders;
          const companyId = this.state.companyProviderCatalog;
          const objCompany = companysProviders.find(
            (obj) => obj.value === companyId
          );

          apiPOSTNotAuth("/works/getdataelastic", { idObra: this.props.workId })
            .then((response) => {
              if (response.status === 200) {
                let requestElastic = response.data[0][0];

                if (emailList.length > 0) {
                  const requestEmail = {
                    emails: emailList.filter((e) => e != ""),
                    type: notification,
                    work: {
                      titulo_original: requestElastic.titulo_original,
                      tipo_obra: requestElastic.tipo_obra,
                      pais: requestElastic.pais,
                      autores: authors,
                      // capitulos: this.state.numero_capitulos,
                      // duracion_cap: "ddddd",
                      formato: requestElastic.formato,
                      genero: requestElastic.genero,
                      empresa: objCompany.data,
                    },
                    portal: "obras",
                  };
                  apiPOST("/notifications/sendemail", requestEmail)
                    .then((response) => {
                      if (response.status !== 200) {
                        this.setState({
                          openAlert: true,
                          variant: "error",
                          message:
                            "Ocurrió un error al enviar correo electrónico",
                        });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      this.setState({
                        openAlert: true,
                        variant: "error",
                        message: "Ocurrió un error inesperado",
                      });
                    });
                }
              } else {
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "Ocurrió un error al obtener datos",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error inesperado",
              });
            });

          // Envio de notificacion
          for (const x in userList) {
            const requestNotification = {
              notification_type: notification,
              id_user: userList[x],
              id_work: this.props.workId,
              portal: "obras",
              specifiedProductor: responsibleUser,
              id_notification_type: idNotification,
            };

            apiPOST("/notifications/add", requestNotification)
              .then((response) => {
                if (response.status !== 200) {
                  this.setState({
                    openAlert: true,
                    variant: "error",
                    message: "Ocurrió un error al enviar la notificación",
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "Ocurrió un error inesperado",
                });
              });
          }
        } else {
          this.setState({
            openAlert: true,
            variant: "error",
            message:
              "Ocurrió un error al obtener correos y usuarios a notificar",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          openAlert: true,
          variant: "error",
          message: "Ocurrió un error inesperado",
        });
      });
  };

  // JCMY 06/06/2019
  handleValidateDataSteps = (characters) => {
    if (
      this.state.titulo_original == "" ||
      this.state.tipo_obra == "" ||
      this.state.proProducida === "" ||
      this.state.proPais == "" ||
      this.state.numero_capitulos == "" ||
      this.state.duracion_min_capitulos == null ||
      this.state.author_register == null ||
      this.state.clasificacion == null ||
      this.state.id_fuente == null ||
      this.state.id_formato == null ||
      this.state.id_ventana == null ||
      this.state.id_audiencia == null ||
      this.state.horario == null ||
      this.state.id_genero == null ||
      this.state.id_subgenero == null ||
      this.state.id_tematica == null ||
      this.state.id_motivacion_conflicto == null ||
      this.state.id_temporalidad == null ||
      this.state.id_desarrollo == null //|| (this.state.versionList.find(e=>e==="Otras")&&this.state.otras_versiones=="")
    ) {
      return false;
    }

    // if ((this.state.tipo_obra == "primigenia" && this.state.proProducida === 1) || this.state.tipo_obra == "adaptacion") {
    //   if (this.state.versionList.length == 0) {
    //     return false;
    //   }
    // }

    if (this.state.tipo_obra == "adaptacion") {
      if (
        this.state.proNAdaptacion == null ||
        this.state.obra_primigenia == ""
      ) {
        return false;
      }
    }

    if (characters == 1) {
      if (this.state.new_characters.length == 0) {
        return false;
      }
    }

    return true;
  };

  handleSubmitChangeStep = () => {
    // JCMY 29/04/2019
    let stateWorkflow = this.state.stateWorkflow;
    let validate = true;

    let val = true;
    switch (stateWorkflow) {
      case null:
        if (!this.state.errorstateWorkflow) {
          if (!this.state.stateWorkflow) {
            this.setState({ errorstateWorkflow: true });
            validate = false;
          }
          validate = false;
        }
        break;
      case "0":
        const notViable = this.state.notViable;
        const workflowComments = this.state.workflowComments;

        if (!notViable || !workflowComments) {
          if (notViable == "" && workflowComments == "") {
            this.setState({ errornotViable: true, errorcomments: true });
          } else if (notViable == "") {
            this.setState({ errornotViable: true });
          } else if (workflowComments == "") {
            this.setState({ errorcomments: true });
          }

          validate = false;
        }
        break;
      case "1":
        //  JCMY 30/04/2019
        switch (this.state.stateWorkflowString) {
          case "Asignada":
            if (!this.state.workflowComments) {
              if (!this.state.workflowComments) {
                this.setState({ errorcomments: true });
                validate = false;
              }
              validate = false;
            }
            // JCMY 06/05/2019
            val = this.handleValidateDataSteps(0);

            if (val == false) {
              this.setState({
                openAlert: true,
                variant: "warning",
                message: "Llena todos los campos requeridos en el formulario",
              });
              validate = false;
            }
            break;
          case "Preevaluación":
            if (!this.state.workflowComments) {
              if (!this.state.workflowComments) {
                this.setState({ errorcomments: true });
                validate = false;
              }
              validate = false;
            }

            val = this.handleValidateDataSteps(0);
            if (val == false) {
              this.setState({
                openAlert: true,
                variant: "warning",
                message: "Llena todos los campos requeridos en el formulario",
              });
              validate = false;
            }
            if (
              //this.state.evQualification == undefined || this.state.evRiskLevel == undefined ||
              this.state.evaluationFile.length === 0
            ) {
              this.setState({
                openAlert: true,
                variant: "warning",
                message:
                  "Agrega por lo menos un dictamen de evaluación. Verifica que hayas guardado los cambios",
              });
              validate = false;
            }
            const deadLine = this.state.deadLine;

            if (!this.state.evaluadorUserMultiple || !deadLine) {
              if (this.state.evaluadorUserMultiple == [] && deadLine == null) {
                this.setState({
                  errorevaluadorresponsibleUser: true,
                  errordeadLine: true,
                });
              } else if (this.state.evaluadorUserMultiple == []) {
                this.setState({ errorevaluadorresponsibleUser: true });
              } else if (deadLine == "") {
                this.setState({ errordeadLine: true });
              }
              validate = false;
            }

            const companyProviderCatalog = this.state.companyProviderCatalog;
            if (companyProviderCatalog == 0) {
              this.setState({
                openAlert: true,
                variant: "warning",
                message: "Selecciona una Empresa/Proveedor del catalogo",
                loading: false,
              });
              validate = false;
            }

            const authsUsr = this.state.authsCatalog;
            if (authsUsr.length == 0) {
              this.setState({
                openAlert: true,
                variant: "warning",
                message: "Selecciona Autores/Adaptadores del catalogo",
                loading: false,
              });
              validate = false;
            }

            if (!this.state.workflowComments) {
              if (!this.state.workflowComments) {
                this.setState({ errorcomments: true });
                validate = false;
              }
              validate = false;
            }

            break;
          case "Viable":
            if (!this.state.responsibleUser || !this.state.workflowComments) {
              // if (this.state.responsibleUser == "0" && this.state.workflowComments == "") {
              this.setState({
                errorresponsibleUser: true,
                errorcomments: true,
                errorevaluator: true,
                errordeadLine: true,
              });
              // } else if (this.state.responsibleUser == "") {
              //   this.setState({ errorresponsibleUser: true });
              // } else if (this.state.workflowComments == "") {
              //   this.setState({ errorcomments: true });
              // }
              this.setState({
                openAlert: true,
                variant: "warning",
                message: "Los campos son obligatorios",
              });
              validate = false;
            }
            break;
          case "En proceso de compra":
            if (!this.state.responsibleUser || !this.state.workflowComments) {
              if (
                this.state.responsibleUser == "0" &&
                this.state.workflowComments == ""
              ) {
                this.setState({
                  errorresponsibleUser: true,
                  errorcomments: true,
                });
              } else if (this.state.responsibleUser == "") {
                this.setState({ errorresponsibleUser: true });
              } else if (this.state.workflowComments == "") {
                this.setState({ errorcomments: true });
              }

              validate = false;
            }

            // JCMY 06/05/2019
            val = this.handleValidateDataSteps(0);

            if (val == false) {
              this.setState({
                openAlert: true,
                variant: "warning",
                message: "Llena todos los campos requeridos en el formulario",
              });
              validate = false;
            }

            break;
          // JCMY 08/05/2019
          case "Solicitud de elaboración de contrato":
            if (
              this.state.companyProviderCatalog == 0 ||
              this.state.contType == undefined ||
              this.state.contValidity == undefined ||
              this.state.contChapterPrice == undefined ||
              this.state.contCurrency == undefined ||
              this.state.contPurchasedChapters == undefined ||
              this.state.contTotalAmount == undefined ||
              this.state.contPaymentsNumber == undefined ||
              this.state.contPaymentAmount == undefined ||
              this.state.contStartDate == undefined ||
              this.state.contPaymentFrequency == undefined
            ) {
              this.setState({
                openAlert: true,
                variant: "warning",
                message:
                  "Llena todos los campos requeridos en la seccion de contrato",
              });
              validate = false;
            }

            break;
          case "En proceso de pago":
            if (this.state.contSignDate == undefined) {
              this.setState({
                openAlert: true,
                variant: "warning",
                message:
                  "Llena todos los campos requeridos en la seccion de contrato",
              });
              validate = false;
            }

            break;
          default:
            break;
        }

        break;
      case "2":
        switch (this.state.stateWorkflowString) {
          case "Con potencial":
            if (
              this.state.evaluationFile.length === 0
            ) {
              this.setState({
                openAlert: true,
                variant: "warning",
                message:
                  "Agrega por lo menos un dictamen de evaluación. Verifica que hayas guardado los cambios",
              });
              validate = false;
            }
            break;
          case "Comprada":
            const company = this.state.companysProviders.find(
              (cp) => cp.value === this.state.companyProviderCatalog
            );
            const person =
              company.pais_origin === 1
                ? company.tipo_persona
                : company.tipo_persona === "fisica"
                ? "foreignFisica"
                : "foreignMoral";
            const documentList = returnDocument(person);
            let requiredDocuments = "";

            const auxDocuments = [...this.state.documents];

            for (let x in documentList) {
              if (documentList[x].required === true) {
                const document = auxDocuments.find(
                  (cp) => cp.tipo_archivo === documentList[x].value
                );
                if (document == undefined) {
                  validate = false;
                  requiredDocuments =
                    requiredDocuments + (documentList[x].data + ",");
                }
              }
            }
            if (!validate) {
              this.setState({
                openAlert: true,
                variant: "warning",
                message:
                  "Los siguientes documentos son requeridos: " +
                  requiredDocuments.substring(0, requiredDocuments.length - 1),
              });
            }
            if (!this.state.workflowComments) {
              if (!this.state.workflowComments) {
                this.setState({ errorcomments: true });
                validate = false;
              }
              validate = false;
            }
            // JCMY 08/05/2019
            if (this.state.contSignDate == undefined) {
              this.setState({
                openAlert: true,
                variant: "warning",
                message:
                  "Llena todos los campos requeridos en la seccion de contrato",
              });
              validate = false;
            }
            break;
        }
        break;
      case "5":
        {
          // Compra directa
          const company = this.state.companysProviders.find(
            (cp) => cp.value === this.state.companyProviderCatalog
          );
          const person =
            company.pais_origin === 1
              ? company.tipo_persona
              : company.tipo_persona === "fisica"
              ? "foreignFisica"
              : "foreignMoral";
          const documentList = returnDocument(person);
          let requiredDocuments = "";

          const auxDocuments = [...this.state.documents];

          for (let x in documentList) {
            if (documentList[x].required === true) {
              const document = auxDocuments.find(
                (cp) => cp.tipo_archivo === documentList[x].value
              );
              if (document == undefined) {
                validate = false;
                requiredDocuments =
                  requiredDocuments + (documentList[x].data + ",");
              }
            }
          }
          if (!validate) {
            this.setState({
              openAlert: true,
              variant: "warning",
              message:
                "Los siguientes documentos son requeridos: " +
                requiredDocuments.substring(0, requiredDocuments.length - 1),
            });
          }
          if (!this.state.workflowComments) {
            if (!this.state.workflowComments) {
              this.setState({ errorcomments: true });
              validate = false;
            }
            validate = false;
          }
          // JCMY 08/05/2019
          if (this.state.contSignDate == undefined) {
            this.setState({
              openAlert: true,
              variant: "warning",
              message:
                "Llena todos los campos requeridos en la seccion de contrato",
            });
            validate = false;
          }
        }
        break;
      case "7": // Compra cancelada
        const company = this.state.companysProviders.find(
          (cp) => cp.value === this.state.companyProviderCatalog
        );
        const person =
          company.pais_origin === 1
            ? company.tipo_persona
            : company.tipo_persona === "fisica"
            ? "foreignFisica"
            : "foreignMoral";
        const documentList = returnDocument(person);
        let requiredDocuments = "";

        const auxDocuments = [...this.state.documents];

        for (let x in documentList) {
          if (documentList[x].required === true) {
            const document = auxDocuments.find(
              (cp) => cp.tipo_archivo === documentList[x].value
            );
            if (document == undefined) {
              validate = false;
              requiredDocuments =
                requiredDocuments + (documentList[x].data + ",");
            }
          }
        }
        if (!validate) {
          this.setState({
            openAlert: true,
            variant: "warning",
            message:
              "Los siguientes documentos son requeridos: " +
              requiredDocuments.substring(0, requiredDocuments.length - 1),
          });
        }
        if (!this.state.workflowComments) {
          if (!this.state.workflowComments) {
            this.setState({ errorcomments: true });
            validate = false;
          }
          validate = false;
        }
        // JCMY 08/05/2019
        if (this.state.contSignDate == undefined) {
          this.setState({
            openAlert: true,
            variant: "warning",
            message:
              "Llena todos los campos requeridos en la seccion de contrato",
          });
          validate = false;
        }
        break;
      default:
        if (this.state.stateWorkflowString === "") {
          this.setState({ errorstateWorkflow: true });
          validate = false;
          break;
        }
    }

    if (validate) {
      // JCMY 13/05/2019
      this.setState({ loading: true });
      var dl = this.state.deadLine === null ? 0 : new Date(this.state.deadLine);
      const request = {
        workId: this.props.workId,
        approved: parseInt(this.state.stateWorkflow),
        ownerId: this.state.evaluator,
        assigneeId: this.state.responsibleUser,
        dueDate:
          dl === 0
            ? "0"
            : dl.getFullYear() +
              "-" +
              (dl.getMonth() + 1 > 9 ? "" : "0") +
              (dl.getMonth() + 1) +
              "-" +
              (dl.getDate() <= 9 ? "0" : "") +
              dl.getDate(),
        description: this.state.workflowComments,
        causeNonViability: parseInt(this.state.notViable),
      };

      // LLamada a cambio de estado en workflow
      // JCMY 24/04/2019

      fetchCall("POST", "/changeStepWorkFlow", request)
        .then((response) => {
          if (response.status === 200) {
            // 26/04/2019
            switch (stateWorkflow) {
              case "1":
                switch (response.data.step) {
                  case "Asignada":
                    this.handleUpdatestage(2);
                    this.handleSendNotifications(
                      2,
                      "workAssign",
                      this.state.responsibleUser
                    );
                    break;
                  case "Preevaluación":
                    this.handleUpdatestage(2);
                    apiPOST("/works/assignworkevaluators", {
                      idwork: this.props.workId,
                      evaluators: this.state.evaluadorUserMultiple,
                    }).then((res) => {
                      this.handleSendNotifications(
                        2,
                        "workEvaluationAssign",
                        this.state.responsibleUser
                      );
                    });
                    this.handleNotifyUserProvider(
                      "preevaluation_userProvider",
                      21
                    );

                    break;
                  case "Viable":
                  case "En proceso de compra":
                  case "Comprar":
                  case "En condiciones de negociación":
                    this.handleUpdatestage(3);
                    // JCMY 30/04/2019 Envio notificacion
                    this.handleSendNotifications(
                      7,
                      "purchaseAuthorization",
                      this.state.responsibleUser
                    );
                    if (response.data.step === "Viable") {
                      this.handleNotifyUserProvider("viable_userProvider", 22);
                    }
                    break;
                  default:
                    //Solicitud de elaboración de contrato, En firmas,En proceso de pago
                    this.handleUpdatestage(4);
                }
                break;
              case "2":
                switch (response.data.step) {
                  case "Comprada":
                    this.handleUpdatestage(6);

                    // JCMY 06/05/2019
                    let key = this.getFileNumber();

                    this.setState({ clave: key });

                    const updateWorkObj = this.getUpdateObject();

                    apiPOST("/works/updatework", updateWorkObj)
                      .then((response) => {
                        if (response.status === 200) {
                          // JCMY 07/05/2019
                          const auxKeys = this.state.keys;

                          apiPOSTNotAuth("/works/clonefolder", {
                            keyList: auxKeys,
                            newKey: `obras/${key}/otros`,
                          })
                            .then((response) => {
                              console.log("Shared: ", response);
                            })
                            .catch((err) => {
                              console.log("Shared error: ", err);
                              // this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado" });
                            });
                        } else {
                          this.setState({
                            openAlert: true,
                            variant: "error",
                            message: "Ocurrió un error al guardar clave",
                          });
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        this.setState({
                          openAlert: true,
                          variant: "error",
                          message:
                            "Ocurrió un error inesperado al actualizar la obra",
                        });
                      });

                    // JCMY 08/05/2019
                    apiPOSTNotAuth("/works/getdataelastic", {
                      idObra: this.props.workId,
                    })
                      .then((response) => {
                        if (response.status === 200) {
                          let requestElastic = [];

                          const authors = this.state.authsUsr;
                          let authorString = "";

                          for (let x in authors) {
                            authorString =
                              authorString + authors[x].name + ", ";
                          }

                          const multimedia = this.state.multimedia;
                          let mult = [];

                          for (let y in multimedia) {
                            if (
                              multimedia[y].tipo_archivo == "img" ||
                              multimedia[y].tipo_archivo == "video"
                            ) {
                              const obj = [];
                              obj.type = multimedia[y].tipo_archivo;
                              obj.url = multimedia[y].ruta;
                              mult.push(obj);
                            }
                          }

                          requestElastic = response.data[0][0];

                          const producers = response.data[1];
                          let producerList = "";
                          for (let x in producers) {
                            producerList =
                              producerList + producers[x].nombre + ", ";
                          }

                          requestElastic.autores = authorString;
                          requestElastic.producida =
                            this.state.proProducida == 1 ? "SI" : "NO";

                          requestElastic.productores = producerList;

                          requestElastic.folio = this.getFileNumber();
                          requestElastic.tags = this.state.label_work;
                          requestElastic.multimedia = mult;

                          apiElasticSearch("/doc/upsert-doc", {
                            id: this.props.workId,
                            doc: requestElastic,
                          })
                            .then((response) => console.log(response))
                            .catch((err) => console.log(err));
                        } else {
                          this.setState({
                            openAlert: true,
                            variant: "error",
                            message: "Ocurrió un error al obtener datos",
                          });
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        this.setState({
                          openAlert: true,
                          variant: "error",
                          message:
                            "Ocurrió un error inesperado al actualizar los datos",
                        });
                      });
                    break;
                  case "Más material":
                    this.handleUpdatestage(1);
                    this.handleSendNotifications(
                      7,
                      "additionalMaterialRequest",
                      this.state.responsibleUser
                    );
                    this.handleNotifyUserProvider(
                      "additionalMaterial_userProvider",
                      19
                    );
                    break;
                  default:
                    //Con potencial
                    this.handleUpdatestage(3);
                }
              case "3":
                switch (response.data.step) {
                  case "Solicitar ajustes":
                    this.handleUpdatestage(1);
                    this.handleSendNotifications(1, "requestMoreAdjustment");
                    break;
                  case "Con potencial para otros formatos":
                    this.handleUpdatestage(5);
                    break;
                  default:
                  //this.handleUpdatestage(3);
                }
                break;
              case "5":
                if (response.data.step == "Compra directa") {
                  this.handleUpdatestage(6);
                }
              case "7":
                if (response.data.step == "Compra cancelada") {
                  this.handleUpdatestage(5);
                }
              default:
                // No viable
                this.handleUpdatestage(5);
                this.handleNotifyUserProvider("notViable_userProvider", 20);
            }

            this.props.getRegisteredWorks();

            this.setState({
              openAvanzarObra: false,

              stateWorkflow: 0,
              notViable: 0,
              evaluator: 0,
              responsibleUser: 0,
              responsibleevaluadorUserMultiple: [],
              deadLine: null,
              workflowComments: "",
              openAlert: true,
              variant: "success",
              message: "Obra avanzada",
              statusWorkFlow: response.data.step,
            });

            // JCMY 03/07/2019 incidencia 989
            this.props.onClose();

            // JCMY 13/05/2019
            this.setState({ loading: false });
          } else {
            this.setState({
              openAlert: true,
              variant: "error",
              message: "Ocurrió un error al cambiar de estado",
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            openAlert: true,
            variant: "error",
            message: "Ocurrió un error inesperado",
            loading: false,
          });
        });
    }
  };

  handleCloseAvanzarObra = () => {
    this.setState({ openAvanzarObra: false });
  };

  handleOpenReevaluar = () => {
    this.setState({ openReevaluar: true });
  };

  handleCloseReevaluar = () => {
    this.setState({ openReevaluar: false, commentsReappraise: "" });
  };

  // JCMY 22/04/2019
  // JCMY 25/04/2019
  handleSubmitReappraise = () => {
    if (!this.state.errorcommentsReappraise) {
      if (!this.state.commentsReappraise) {
        this.setState({ errorcommentsReappraise: true });
        return false;
      } else {
        var dl =
          this.state.deadLine === null ? 0 : new Date(this.state.deadLine);

        const request = {
          workId: this.props.workId,
          approved: 1,
          ownerId: this.state.evaluator,
          assigneeId: this.state.responsibleUser,
          dueDate:
            dl === 0
              ? "0"
              : dl.getFullYear() +
                "-" +
                (dl.getMonth() + 1 > 9 ? "" : "0") +
                (dl.getMonth() + 1) +
                "-" +
                (dl.getDate() <= 9 ? "0" : "") +
                dl.getDate(),
          description: this.state.commentsReappraise,
          causeNonViability: this.state.notViable,
        };

        // LLamada a reevaluar
        // JCMY 13/05/2019
        this.setState({ loading: true });
        fetchCall("POST", "/changeStepWorkFlow", request)
          .then((response) => {
            if (response.status === 200) {
              const requestStage = {
                idObra: this.props.workId,
                idWorkflow: 0,
                idStatus: 1,
              };

              apiPOST("/works/updatestage", requestStage)
                .then((response) => {
                  if (response.status !== 200) {
                    this.setState({
                      openAlert: true,
                      variant: "error",
                      message: "Ocurrió un error al actualizar obra",
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({
                    openAlert: true,
                    variant: "error",
                    message: "Ocurrió un error inesperado",
                  });
                });

              this.props.getRegisteredWorks();

              // JCMY 13/05/2019
              this.setState({
                openAlert: true,
                variant: "success",
                message: "Obra avanzada",
                openReevaluar: false,
                statusWorkFlow: response.data.step,
                commentsReappraise: "",
                loading: false,
              });
            } else {
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error al cambiar de estado",
                loading: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              openAlert: true,
              variant: "error",
              message: "Ocurrió un error inesperado",
              loading: false,
            });
          });
      }
    }
  };

  handleOpenAmpliar = () => {
    this.setState({ openAmpliar: true });
  };

  handleCloseExtendTerm = () => {
    this.setState({
      openAmpliar: false,
      deadLineExtendTerm: null,
      commentsExtendTerm: "",
    });
  };

  // JCMY 22/04/2019
  // JCMY 25/04/2019
  handleBlur = (event) => {
    const error = `error${event.target.name}`;
    if (!event.target.value || event.target.value === "") {
      this.setState({ [error]: true });
    } else {
      this.setState({ [error]: false });
    }
  };

  handleSubmitExtendTerm = () => {
    if (
      !this.state.errorcommentsExtendTerm &&
      !this.state.errordeadLineExtendTerm
    ) {
      if (!this.state.commentsExtendTerm && !this.state.deadLineExtendTerm) {
        this.setState({
          errorcommentsExtendTerm: true,
          errordeadLineExtendTerm: true,
        });
        return false;
      } else {
        var dlet =
          this.state.deadLineExtendTerm === null
            ? 0
            : new Date(this.state.deadLineExtendTerm);

        const request = {
          workId: this.props.workId,
          dueDate:
            dlet === 0
              ? "0"
              : dlet.getFullYear() +
                "-" +
                (dlet.getMonth() + 1 > 9 ? "" : "0") +
                (dlet.getMonth() + 1) +
                "-" +
                (dlet.getDate() <= 9 ? "0" : "") +
                dlet.getDate(),
          description: this.state.commentsExtendTerm,
        };

        // LLamada externder plazo
        // JCMY 13/05/2019
        this.setState({ loading: true });
        fetchCall("POST", "/extendTerm", request)
          .then((response) => {
            if (response.status === 200) {
              this.setState({
                openAmpliar: false,
                deadLineExtendTerm: null,
                commentsExtendTerm: "",
                openAlert: true,
                variant: "success",
                message: "Tiempo extendido",
                loading: false,
              });
            } else {
              this.setState({
                openAlert: true,
                variant: "error",
                message: "Ocurrió un error al cambiar de estado",
                loading: false,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              openAlert: true,
              variant: "error",
              message: "Ocurrió un error inesperado",
              loading: false,
            });
          });
      }
    }
  };

  //AHDA 02/05/2019
  //Added files managment functions
  // ----------------------- File Managment
  onDropFile = (files, fileArray, fileType) => {
    if (files.length <= 0) {
      let errMessage = "Tipo de archivo inválido";
      this.setState({ openAlert: true, variant: "error", message: errMessage });
      return false;
    }

    if (fileType && fileType === "PDF") {
      if (files[0].type !== "application/pdf") {
        if (files[0].type !== "") {
          let errMessage = "Tipo de archivo inválido, solo se admite PDF";
          this.setState({
            openAlert: true,
            variant: "error",
            message: errMessage,
          });
          return false;
        }
      }
    } else {
      for (var i = 0; i < files.length; i++) {
        if (
          files[i].type !== "application/pdf" &&
          files[i].type !== "application/msword" &&
          files[i].type !==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          if (files[i].type !== "") {
            let errMessage =
              "Tipos de archivo inválidos, solo se admiten PDF y Word";
            this.setState({
              openAlert: true,
              variant: "error",
              message: errMessage,
            });
            return false;
          }
        }
      }
    }

    let step = fileArray ? fileArray : "filesMaterial";

    let oldFiles = this.state[step];
    // if(fileArray=="filesEvaluation"){

    if (fileType && oldFiles.length >= 1) {
      this.setState({
        openAlert: true,
        variant: "error",
        message: "Solo se puede subir un archivo",
      });
    } else {
      if (fileType && files.length > 1) {
        files = [files[0]];
      }
      oldFiles = oldFiles.concat(files);
      this.setState({ [step]: oldFiles });
    }
  };

  handleRemove = (file, fileIndex, fileArray) => {
    let step = fileArray ? fileArray : "filesMaterial";
    const thisFiles = this.state[step];
    // This is to prevent memory leaks.
    window.URL.revokeObjectURL(file.preview);

    thisFiles.splice(fileIndex, 1);
    this.setState({ [step]: thisFiles });
  };

  handleDeleteSingleMaterial = (item) => {
    let auxItem = Object.assign({}, this.state[item]);
    if (auxItem) {
      if (auxItem) {
        apiPOSTNotAuth("/works/deletemultimedia", {
          multimedia_id: auxItem.id_material_multimedia,
        })
          .then((response) => {
            if (response.status === 200) {
              auxItem = undefined;
              this.setState({
                [item]: auxItem,
                openAlert: true,
                variant: "success",
                message: "Se eliminó el archivo",
              });
            } else {
              this.setState({
                openAlert: true,
                variant: "error",
                message: "No se pudo eliminar el archivo",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              openAlert: true,
              variant: "error",
              message: "No se pudo eliminar el archivo",
            });
          });
      }
    }
  };
  // ----------------------- End File Managment

  //AHDA 03/05/2019
  //Added Material managment functions
  // ----------------------- Material Managment
  handleOpenFormMaterial = (item) => {
    if (item !== undefined) {
      const auxRawMaterials = [...this.state.rawMaterials];
      const auxItem = auxRawMaterials.find(
        (m) => m.id_material_multimedia == item.id_material_multimedia
      );

      this.setState({
        filesMaterial: [],
        proNameMaterial: auxItem.material_nombre,
        proTypeMaterial: auxItem.tipo_archivo,
        proLanguageMaterial: auxItem.id_idioma,
        proChapterMaterial: auxItem.numero_capitulo,
        selectedMaterial: undefined,
        newMaterialTypeValue: "",
        isNewMaterialTypeSelected: false,
        openFormMaterial: true,
        idEditedMaterial: auxItem.id_material_multimedia,
        titleModalMaterial: "Actualizar Material",
      });
    } else {
      this.setState({
        openFormMaterial: true,
        typeMaterial: "",
        language: "",
        selectFile: {},
        files: [],
        titleModalMaterial: "Nuevo Material",
      });
      this.handleClear("material");
    }

    this.handleCatalogue("catLanguage", 32);
    this.handleCatalogue("materialTypes", 5);

    //this.setState({ openFormMaterial: true });
  };

  handleSubmitMaterial = async () => {
    let auxMaterial = [...this.state.materials],
      auxRawMaterial = [...this.state.rawMaterials];

    const regChapter = /^[0-9.,-\s]*$/;
    const regDouble = /[.,-]{2,}/;

    let keyTypeMaterial = "",
      nameTypeMaterial = "";

    if (
      !regChapter.test(this.state.proChapterMaterial) &&
      regDouble.test(this.state.proChapterMaterial)
    ) {
      return;
    }

    if (this.state.isNewMaterialTypeSelected) {
      if (!this.state.newMaterialTypeValue) {
        this.setState({
          openAlert: true,
          variant: "warning",
          message: "Ingrese el nombre del nuevo tipo",
        });
        return;
      } else {
        const keyNewType = this.state.newMaterialTypeValue
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        const nameNewType = this.state.newMaterialTypeValue;

        const response = await apiPOST("/works/addtypematerial", {
          name_type: nameNewType,
          key_: keyNewType,
        });

        if (response.status === 200) {
          if (response.data.new_id) {
            keyTypeMaterial = keyNewType;
            nameTypeMaterial = nameNewType;
            const responseCatalogue = await apiPOST(
              "/catalogos/getCatalogo",
              { idCatalogo: 5 },
              true
            );
            if (responseCatalogue.status === 200) {
              let valueType = "";
              const data = responseCatalogue.data.filter((type) => {
                if (valueType !== type.value) {
                  valueType = type.value;
                  return type;
                }
              });

              let find = data.findIndex((x) => x.value === "evaluacion");
              if (find !== -1) {
                data.splice(find, 1);
              }
              let findProceso = data.findIndex(
                (x) => x.value === "evaluacion_pro"
              );
              if (findProceso !== -1) {
                data.splice(findProceso, 1);
              }
              const findOtro = data.findIndex((x) => x.value === "otro");
              if (findOtro !== -1) {
                data.splice(findOtro, 1);
              }
              const findCapitulo1 = data.findIndex(
                (x) => x.value === "capitulo1"
              );
              if (findCapitulo1 !== -1) {
                data.splice(findCapitulo1, 1);
              }

              this.setState({ ["materialTypes"]: data });
            }
          }
        }
      }
    } else {
      keyTypeMaterial = this.state.proTypeMaterial;
      nameTypeMaterial = this.state.materialTypes.filter(
        (m) => m.value === this.state.proTypeMaterial
      )[0].data;
    }

    const { idEditedMaterial } = this.state;

    if (idEditedMaterial !== 0) {
      const indexMaterialEdited = auxMaterial.findIndex(
        (material) => material.id_material_multimedia === idEditedMaterial
      );
      const indexRawMaterialEdited = auxRawMaterial.findIndex(
        (material) => material.id_material_multimedia === idEditedMaterial
      );
      const materialEdited = auxRawMaterial[indexRawMaterialEdited];
      const response = this.getFilesMaterialMultimedia(
        true,
        false,
        [],
        [],
        idEditedMaterial,
        keyTypeMaterial,
        nameTypeMaterial,
        materialEdited
      );

      auxMaterial.splice(indexMaterialEdited, 1);
      auxRawMaterial.splice(indexRawMaterialEdited, 1);

      auxMaterial = [...auxMaterial, ...response.auxMaterial];
      auxRawMaterial = [...auxRawMaterial, ...response.auxRawMaterial];
    } else {
      const response = this.getFilesMaterialMultimedia(
        false,
        true,
        auxRawMaterial,
        auxMaterial,
        0,
        keyTypeMaterial,
        nameTypeMaterial,
        null
      );
      auxMaterial = [...response.auxMaterial];
      auxRawMaterial = [...response.auxRawMaterial];
    }

    this.setState({
      materials: auxMaterial,
      rawMaterials: auxRawMaterial,
      openFormMaterial: false,
      isMaterialMultipleFiles: false,
      selectFile: null,
      files: [],
      isNewMaterialTypeSelected: false,
      newMaterialTypeValue: "",
      idEditedMaterial: 0,
    });
  };

  getFilesMaterialMultimedia = (
    isEdited,
    isNew,
    auxRawMaterial,
    auxMaterial,
    idMaterialMultimedia,
    keyTypeMaterial,
    nameTypeMaterial,
    itemEdited
  ) => {
    if (
      this.state.isMaterialMultipleFiles &&
      this.state.filesMaterial.length > 1
    ) {
      this.state.filesMaterial.forEach((file, index) => {
        let idAuxMaterialMultimedia = 0,
          isNewForEdited = false;
        if (isEdited) {
          if (index > 0) {
            idAuxMaterialMultimedia = this.state.materials.length + index;
            isNewForEdited = true;
          } else {
            idAuxMaterialMultimedia = idMaterialMultimedia;
          }
        } else {
          idAuxMaterialMultimedia = auxRawMaterial.length + 1;
        }

        auxRawMaterial.push({
          ...itemEdited,
          id_material_multimedia: idAuxMaterialMultimedia,
          material_nombre: this.state.proNameMaterial,
          tipo_archivo: keyTypeMaterial,
          descripcion: this.state.catLanguage.find(
            (l) => l.value == this.state.proLanguageMaterial
          ).data,
          numero_capitulo: this.state.proChapterMaterial || "0",
          id_idioma: this.state.proLanguageMaterial,
          isNew: isNewForEdited ? isNewForEdited : isNew,
          isEdited: isEdited,
          file: file,
        });
        auxMaterial.push({
          id_material_multimedia: `${idAuxMaterialMultimedia}`,
          material_nombre: this.state.proNameMaterial,
          tipo_archivo: nameTypeMaterial,
          numero_capitulo: this.state.proChapterMaterial || "0",
          descripcion: this.state.catLanguage.find(
            (l) => l.value == this.state.proLanguageMaterial
          ).data,
        });
      });
    } else {
      const rawItem = {
        ...itemEdited,
        id_material_multimedia: idMaterialMultimedia
          ? idMaterialMultimedia
          : auxRawMaterial.length + 1,
        material_nombre: this.state.proNameMaterial,
        tipo_archivo: keyTypeMaterial,
        descripcion: this.state.catLanguage.find(
          (l) => l.value == this.state.proLanguageMaterial
        ).data,
        numero_capitulo: this.state.proChapterMaterial || "0",
        id_idioma: this.state.proLanguageMaterial,
        isNew: isNew,
        isEdited: isEdited,
        file: this.state.filesMaterial[0],
      };

      const item = {
        id_material_multimedia: `${
          idMaterialMultimedia
            ? idMaterialMultimedia
            : auxRawMaterial.length + 1
        }`,
        material_nombre: this.state.proNameMaterial,
        tipo_archivo: nameTypeMaterial,
        numero_capitulo: this.state.proChapterMaterial || "0",
        descripcion: this.state.catLanguage.find(
          (l) => l.value == this.state.proLanguageMaterial
        ).data,
      };

      auxRawMaterial.push(rawItem);
      auxMaterial.push(item);
    }

    return {
      auxRawMaterial: auxRawMaterial,
      auxMaterial: auxMaterial,
    };
  };

  handleViewItem = (item) => {
    const auxRawMaterials = [...this.state.rawMaterials];
    const auxItem = auxRawMaterials.find(
      (m) => m.id_material_multimedia == item.id_material_multimedia
    );
    if (auxItem) {
      if (!auxItem.isNew) {
        if (auxItem.file && auxItem.file.preview) {
          window.open(auxItem.file.preview, "_blank");
        } else {
          let type = auxItem.ruta
            ? auxItem.ruta.split(".")[auxItem.ruta.split(".").length - 1]
            : "";
          if (!type) {
            this.setState({
              openAlert: true,
              variant: "error",
              message: "No hay vista previa disponible",
            });
            return false;
          }
            switch (type.toLowerCase()) {
              case "mp4":
              case "mov":
                getFile(auxItem.ruta, "public")
                  .then((result) => {
                    let material = [{ type: "video", url: result }];
                    this.setState({
                      openMediaModal: true,
                      modalFiles: material,
                    });
                  })
                  .catch((err) => {
                    this.setState({
                      openAlert: true,
                      variant: "error",
                      message: "No hay vista previa disponible",
                    });
                  });
                break;
              case "png":
              case "jpg":
              case "jpeg":
                let material = [{ type: "img", url: auxItem.ruta }];
                this.setState({ openMediaModal: true, modalFiles: material });
                break;
              case "pdf":
              case "ppt":
              case "doc":
              case "docx":
              case "pptx":
              case "xls":
              case "txt":
              case "xlsx":
              case "csv":
                this.openGoogleViewer(auxItem.ruta)
                break;
              default:
                this.setState({
                  openAlert: true,
                  variant: "error",
                  message: "No hay vista previa disponible para este tipo de archivo",
                });
                break;
            }
        }
      } else {
        if (auxItem.file && auxItem.file.preview) {
          window.open(auxItem.file.preview, "_blank");
        } else {
          this.setState({
            openAlert: true,
            variant: "error",
            message: "No hay vista previa disponible",
          });
        }
      }
    } else {
      this.setState({
        openAlert: true,
        variant: "error",
        message: "No hay vista previa disponible",
      });
    }
  };

  handleDeleteMaterial = (item) => {
    const auxRawMaterials = [...this.state.rawMaterials];
    const auxMaterials = [...this.state.materials];
    const auxRawItem = auxRawMaterials.find(
      (m) => m.id_material_multimedia == item.id_material_multimedia
    );
    const auxRawIdx = auxRawMaterials.findIndex(
      (m) => m.id_material_multimedia == item.id_material_multimedia
    );
    const auxIdx = auxMaterials.findIndex(
      (m) => m.id_material_multimedia == item.id_material_multimedia
    );

    if (auxRawItem) {
      if (auxRawItem.id_material_multimedia) {
        apiPOSTNotAuth("/works/deletemultimedia", {
          multimedia_id: auxRawItem.id_material_multimedia,
        })
          .then((response) => {
            auxRawMaterials.splice(auxRawIdx, 1);
            auxMaterials.splice(auxIdx, 1);
            this.setState({
              materials: auxMaterials,
              rawMaterials: auxRawMaterials,
              openAlert: true,
              variant: "success",
              message: "Se eliminó el archivo",
            });
          })
          .catch((err) => {
            this.setState({
              openAlert: true,
              variant: "error",
              message: "No se pudo eliminar el archivo",
            });
          });
      } else {
        auxRawMaterials.splice(auxRawIdx, 1);
        auxMaterials.splice(auxIdx, 1);
        this.setState({
          materials: auxMaterials,
          rawMaterials: auxRawMaterials,
        });
      }
    } else {
      this.setState({
        openAlert: true,
        variant: "error",
        message: "No se pudo eliminar el archivo",
      });
    }
  };

  handleCloseMediaModla = () => {
    this.setState({ openMediaModal: false });
  };
  // ----------------------- End Material Managment

  //AHDA 06/05/2019
  //Added Document checklist
  // ----------------------- Document Checklist Managment
  handleViewDocument = (item) => {
    if (item.isNew) {
      if (item.file && item.file.preview) {
        window.open(item.file.preview, "_blank");
      } else {
        this.setState({
          openAlert: true,
          variant: "error",
          message: "No hay vista previa disponible",
        });
      }
    } else {
      this.openGoogleViewer(item.ruta)
    }
  };
  // ----------------------- End Document CheckList Managment

  async componentDidMount() {
    this.handleCatalogue("materialTypes", 5);

    ValidatorForm.addValidationRule("hasCountriesReachLimit", (value) => {
      return true;
    });
    ValidatorForm.addValidationRule("isChapterNumber", (value) => {
      const regChapter = /^[0-9.,-\s]*$/;
      const regDouble = /[.,-]{2,}/;
      return regChapter.test(value) && !regDouble.test(value);
    });

    const workId = this.props.workId;

    const user = JSON.parse(localStorage.getItem("current_user"));
    const rdsUser = user ? user.rdsUser : undefined;

    if (rdsUser) {
      this.setState({
        rolId: rdsUser.id_rol,
        rolName: rdsUser.asigned_profile,
      });
    }

    var response;

    apiPOST("/works/getdetailworkfull", {
      idObra: workId,
      evaluator: rdsUser.id_rol == 21 ? rdsUser.id_usuario : null,
    })
      .then((response) => {
        if (response.status === 200) {
          const [
            workData, // 000
            workLabels, // 001
            workAuthorInactive, // 002
            workMaterials, // 003
            workProducers, // 004
            workAdaptedVersions, // 005
            workCharacters, // 006
            workAuthorsActive, // 007
            workEvaluation, // 008
            workCountryEvaluations, // 008
            workRelationshipDiagramFiles, // 009
            workEvaluationFiles, // 010
            workContracts, // 011
            workDocuments, // 012
            workEvaluatorEvaluations, // 013
            workEvaluationProcessFiles, //014
          ] = response.data;

          const work = workData[0];
          const wlabel = workLabels;
          const producers = workProducers;
          let allAuths = [...workAuthorInactive];

          this.setState({
            filesDataEvaluationProcess: [...workEvaluationProcessFiles],
          });

          for (let i in workAuthorsActive) {
            allAuths.push(workAuthorsActive[i]);
          }
          //#region [ rgba(100,100,100,0.1)]  EGS - 03/05/2019
          let tempCharacters = [];
          for (let c in workCharacters) {
            tempCharacters.push({
              id: c + 1,
              id_personaje: workCharacters[c].id_personaje,
              id_obra: workCharacters[c].id_obra,
              nombre: workCharacters[c].nombre,
              edad: workCharacters[c].edad,
              psicologia: workCharacters[c].psicologia,
            });
          }
          //#endregion

          //AHDA 03/05/2019
          //Mapped material
          const relationDiagram =
            workRelationshipDiagramFiles.length > 0
              ? workRelationshipDiagramFiles[0]
              : undefined;
          let evaluationFile =
            workEvaluationFiles.length > 0 ? workEvaluationFiles : [];
          let evaluationProcessFile =
            workEvaluationProcessFiles.length > 0
              ? workEvaluationProcessFiles
              : [];
          const rawMaterial = [...workMaterials];
          let material = [];

          rawMaterial.map((m) => {
            let type = this.state.materialTypes.filter(
              (t) => t.value === m.tipo_archivo
            );

            // JCMY 08/07/2019 incidencia 1066
            if (type.length === 0) {
              type = this.state.materialTypes.filter(
                (t) => t.data === m.tipo_archivo
              );
            }

            let typeMaterial;
            if (type.length > 0) {
              typeMaterial = type[0].data;
            } else {
              typeMaterial = m.tipo_archivo;
            }

            if (m.tipo_archivo !== "evaluacion_pro") {
              const item = {
                id_material_multimedia: m.id_material_multimedia,
                material_nombre: m.material_nombre || "",
                type: typeMaterial,
                numero_capitulo: m.numero_capitulo || "0",
                language: m.descripcion,
              };

              material.push(item);
            }

            // console.log(item, type, m)
            return m;
          });

          // this.setState({
          //   //#region [ rgba(100,100,100,0.1)]  EGS - 03/05/2019
          //   //AHDA 05/05/2019
          //   //Added validations
          //   //Evaluación
          //   evId: response.data[8].length > 0 ? response.data[8][0].ev_id : 0,
          //   evComments: response.data[8].length > 0 ? response.data[8][0].ev_comments : undefined,
          //   evRating: response.data[8].length > 0 ? response.data[8][0].ev_rating : undefined,
          //   evHorario: response.data[8].length > 0 ? response.data[8][0].ev_schedule : undefined,
          //   evShare: response.data[8].length > 0 ? response.data[8][0].ev_share : undefined,
          //   evQualification: response.data[8].length > 0 ? response.data[8][0].ev_qualification : undefined,
          //   evRiskLevel: response.data[8].length > 0 ? response.data[8][0].ev_riskLevel : undefined,
          //   //#endregion
          //   //
          //   authsUsr: response.data[2],
          //   authsCatalog: response.data[7],

          //   versions: response.data[5],
          //   // Character
          //   new_characters: tempCharacters,
          // })

          //EGS 08/05/2019
          //Added contract info
          const contract =
            workContracts.length > 0 ? workContracts[0] : undefined;
          let dateContrat = new Date();
          let dateSign = new Date();

          const evaluationState = {
            /// cramirez 22/8/2019
            //Evaluación
            evId: workEvaluation.length > 0 ? workEvaluation[0].ev_id : 0,
            evComments:
              workEvaluation.length > 0
                ? workEvaluation[0].ev_comments
                : undefined,
            evRating:
              workEvaluation.length > 0
                ? workEvaluation[0].ev_rating
                : undefined,
            evHorario:
              workEvaluation.length > 0
                ? workEvaluation[0].ev_schedule
                : undefined,
            evShare:
              workEvaluation.length > 0
                ? workEvaluation[0].ev_share
                : undefined,
            evQualification:
              workEvaluation.length > 0
                ? workEvaluation[0].ev_qualification
                : undefined,
            evRiskLevel:
              workEvaluation.length > 0
                ? workEvaluation[0].ev_riskLevel
                : undefined,
            // countryEvaluations: response.data[9],
            countryEvaluations: workCountryEvaluations,
            evPaises: workCountryEvaluations.map((c) => c.countryId),
            epComments:
              workEvaluation.length > 0
                ? workEvaluation[0].ep_comments
                : undefined,
            //#endregion
          };

          if (contract) {
            let dateContrat = new Date(contract.fechaInicioContrato);
            let dateSign = new Date(contract.firmaContrato);

            dateContrat.setMinutes(
              dateContrat.getMinutes() + dateContrat.getTimezoneOffset()
            );
            dateSign.setMinutes(
              dateSign.getMinutes() + dateSign.getTimezoneOffset()
            );
            this.setState({
              contSignDate: contract
                ? dateSign.toISOString().split("T")[0]
                : undefined,
              contStartDate: contract
                ? dateContrat.toISOString().split("T")[0]
                : undefined,
              // Evaluación
              ...evaluationState,
              //
              authsUsr: workAuthorInactive,
              authsCatalog: workAuthorsActive,

              versions: workAdaptedVersions,
              // Character
              new_characters: tempCharacters,
            });
            // console.log("Dates: ",dateSign.toISOString().split('T')[0],dateContrat.toISOString().split('T')[0])
          } else {
            //cramirez 22/08/2019
            this.setState({
              //#region [ rgba(100,100,100,0.1)]  EGS - 03/05/2019
              //AHDA 05/05/2019
              //Added validations
              // Evaluación
              ...evaluationState,
              //
              authsUsr: workAuthorInactive,
              authsCatalog: workAuthorsActive,

              versions: workAdaptedVersions,
              // Character
              new_characters: tempCharacters,
            });
          }

          //EGS 10/05/2019
          let documents = workDocuments;

          let wt = new Array();
          apiPOST("/works/getregisteredworks", {})
            .then((rWorks) => {
              if (rWorks.status === 200) {
                const result = [];
                for (const x in rWorks.data) {
                  var obj = {
                    id_obras: rWorks.data[x].id_obras,
                    empresa: rWorks.data[x].empresa,
                    titulo: rWorks.data[x].titulo,
                    autores: rWorks.data[x].autores,
                    pais: rWorks.data[x].pais,
                    fechaRegistro: rWorks.data[x].fechaRegistro,
                    usuarioRegistro: rWorks.data[x].usuarioRegistro,
                    genero: rWorks.data[x].genero,
                    fuente: rWorks.data[x].fuente,
                    estatus: rWorks.data[x].estatus,
                    usuarioAsignado: rWorks.data[x].usuarioAsignado,
                    status: rWorks.data[x].statusId,
                  };

                  result.push(obj);
                  // wt[response.data[x].id_obras + ''] = response.data[x].titulo
                  // this.state.workTitles[response.data[x].id_obras + ''] = response.data[x].titulo

                  wt.push({ idTitle: obj.id_obras, title: obj.titulo });
                }

                this.setState(
                  {
                    works: result,
                    workTitles: wt,
                    versions: workAdaptedVersions,
                  },
                  () => {
                    this.state.versions.map((version, idx) => {
                      if (version == -1)
                        return this.state.versionList.push("Otras");
                      // console.log(version , wt.find(w => w.idTitle === parseInt(version) ))
                      return wt.find((w) => w.idTitle === parseInt(version)) &&
                        parseInt(version) != this.props.workId
                        ? this.state.versionList.push(
                            wt.find((w) => w.idTitle === parseInt(version))
                              .title
                          )
                        : null;
                    });
                    this.setState({
                      versionListStart: this.state.versionList,
                      adaptationsPanel: true,
                    });
                  }
                );
              } else {
                console.log("Error al cargar las obras");
              }
            })
            .catch((err) => {
              console.log(err);
            });

          const [loglineLength, loglineEditorState] = this.convertContent(
            "logLine",
            work.logline
          );
          const [
            sinopsis_cortaLength,
            sinopsis_cortaEditorState,
          ] = this.convertContent("sinopsis_corta", work.sinopsis_corta);
          const [
            sinopsis_largaLength,
            sinopsis_largaEditorState,
          ] = this.convertContent("sinopsis_larga", work.sinopsis_larga);

          this.setState({
            // Daatos Generales
            activeStep: this.props.canEditWorkUserData ? 0 : 1,
            id_obras: workId, //Id de la Obra
            id_usuario: returnCurrentUserId(), // Id del usuario
            id_user_work: work.id_usuario, //Id del usuario que la registró AHDA 06/05/2019
            // ----------------------------- PASO 1 --------------------------
            // Paso 1 - Datos Generales
            companyProvider: work.companyProvider, //Empresa/Proveedor (Capturado por el usuario)*
            companyProviderCatalog:
              work.companyProviderCatalog === 0
                ? null
                : work.companyProviderCatalog, //Empresa/Proveedor*
            representative: work.representative, //Representante
            address: work.address, //Dirección
            telephone: work.telephone, //Teléfono*
            email: work.email, //Correo Electrónico
            // ----------------------------- PASO 2 --------------------------
            //Paso 2 - Datos Generales
            titulo_original: work.titulo_original, //Título de la obra *
            tipo_obra: work.tipo_obra == "" ? 0 : work.tipo_obra, //Tipo de obra*
            proNAdaptacion: work.version, //Número de adaptación*
            proProducida: work.producida, //Producida*
            titulo_inglés: work.titulo_inglés, //Título en inglés *
            titulo_otro_idioma: work.titulo_otro_idioma, //Título en otro idioma *
            obra_primigenia: work.obra_primigenia, //Título de la obra primigenia* *
            id_pais: work.id_pais, //País de origen*
            numero_capitulos:
              work.numero_capitulos == "" ? 0 : work.numero_capitulos, //Número de capítulos *
            duracion_min_capitulos:
              work.duracion_min_capitulos == ""
                ? 0
                : work.duracion_min_capitulos, //Duración*
            author_register: work.author_register, //Registro de Autor*
            clasificacion: work.clasificacion, //*Destino*
            id_fuente: work.id_fuente, //Fuente*
            label_work: [], //Etiquetas
            // Paso 2 - Autor(es)/Adaptador(es)
            authorsData: allAuths,
            // Paso 2 - Infromación Literaria
            id_formato: work.id_formato, //Formato*
            id_ventana: work.id_ventana, // Ventana*
            id_audiencia: work.id_audiencia, // Audiencia*
            horario: work.horario, // Horario*
            id_genero: work.id_genero, // Género*
            id_subgenero: work.id_subgenero === "" ? null : work.id_subgenero, // Subgénero
            id_tematica: work.id_tematica, // Temática*
            id_motivacion_conflicto: work.id_motivacion_conflicto, // Motivación*
            id_temporalidad: work.id_temporalidad, // Temporalidad*
            id_desarrollo: work.id_desarrollo, // Ambiente*
            // Paso 2 - Produción
            producerList: [],
            anio_produccion:
              work.anio_produccion == null || work.anio_produccion === ""
                ? ""
                : work.anio_produccion,
            ep_alAire: work.ep_alAire,
            aired_duration: work.aired_duration,
            // Paso 2 Commentarios
            comments: work.comentarios ? work.comentarios : ``,
            // Paso 2 - Otras Versiones
            otras_versiones: work.otras_versiones,
            // versions: response.data[5],
            // ----------------------------- PASO 3 --------------------------
            // Paso 3 - Logline
            logline: work.logline,
            loglineLength,
            loglineEditorState,
            // Paso 3 - Sinopsis corta
            sinopsis_corta: work.sinopsis_corta,
            sinopsis_cortaLength,
            sinopsis_cortaEditorState,

            // Paso 3 - Sinopsis larga
            sinopsis_larga: work.sinopsis_larga,
            sinopsis_largaLength,
            sinopsis_largaEditorState,

            //AHDA 05/05/2019
            //Added Paso 4, 5, 6, 7
            // Paso 4 - Personajes
            relationDiagramFile: relationDiagram,
            // Paso 5 - Evaluación
            evaluationFile: evaluationFile,
            // Paso 6 - Materiales
            materials: material,
            rawMaterials: rawMaterial,
            // Paso 7 - Información Contractual
            contId: contract ? contract.idContratoObra : 0,
            contType: contract ? contract.idTipoContrato : undefined,
            contValidity: contract ? contract.id_vigencia : undefined,
            // contSignDate: contract ? dateSign : undefined,
            contPaymentFrequency: contract
              ? contract.payment_frequency
              : undefined,
            contChapterPrice: contract ? contract.chapter_price : undefined,
            contCurrency: contract ? contract.currency : undefined,
            contPurchasedChapters: contract
              ? contract.purchased_chapters
              : undefined,
            contTotalAmount: contract
              ? contract.payments_number * contract.payment_amount
              : 0,
            contPaymentsNumber: contract ? contract.payments_number : 0,
            contPaymentAmount: contract ? contract.payment_amount : 0,
            // contStartDate: contract ? dateContrat : undefined,
            documents: documents,

            comentarios: work.comentarios,
            typeProyect: work.typeProyect,

            // JCMY 17/02/2019
            statusWorkFlow: work.statusWorkFlow,
            // JCMY 17/02/2019
            stageWorkFlow: work.etapa,
            //new step
            evaluationProcessFile: evaluationProcessFile,
          });
          wlabel.map((label, idx) => this.state.label_work.push(label.value));
          producers.map((prod, idx) =>
            this.state.producerList.push(prod.producer_id)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.handleCatalogue("companysProviders", 7);
    this.handleCatalogue("countries", 3);
    this.handleCatalogue("sources", 11);
    this.handleCatalogue("durations", 23);
    this.handleCatalogue("catRiskLevel", 39);
  }

  handleCatalogue = (catalogue, idCatalogue) => {
    if (this.state[catalogue].length <= 0) {
      apiPOST("/catalogos/getCatalogo", { idCatalogo: idCatalogue }).then(
        (response) => {
          if (
            catalogue === "evaluatingAreas " ||
            catalogue === "usersSpecialists" ||
            catalogue === "usersAdministrator"
          ) {
            console.log(response.data);
          }

          if (catalogue === "materialTypes") {
            let valueType = "";
            const data = response.data.filter((type) => {
              if (valueType !== type.value) {
                valueType = type.value;
                return type;
              }
            });
            let find = data.findIndex((x) => x.value === "evaluacion");
            if (find !== -1) {
              data.splice(find, 1);
            }
            let findProceso = data.findIndex(
              (x) => x.value === "evaluacion_pro"
            );
            if (findProceso !== -1) {
              data.splice(findProceso, 1);
            }
            const findOtro = data.findIndex((x) => x.value === "otro");
            if (findOtro !== -1) {
              data.splice(findOtro, 1);
            }
            const findCapitulo1 = data.findIndex(
              (x) => x.value === "capitulo1"
            );
            if (findCapitulo1 !== -1) {
              data.splice(findCapitulo1, 1);
            }

            if (response.status === 200) {
              this.setState({ [catalogue]: data });
            }
          } else {
            if (response.status === 200) {
              this.setState({ [catalogue]: response.data });
            }
          }
        }
      );
    }
  };

  //AHDA 06/05/2019
  //Added method for getting documentation list
  getDocumentList = () => {
    let auxDocumentList = [];

    if (this.state.companysProviders.length > 0) {
      let company = this.state.companysProviders.find(
        (cp) => cp.value === this.state.companyProviderCatalog
      );
      if (company) {
        // JCMY 19/06/2019 Incidencia AC-781
        let person =
          company.pais_origin === 1
            ? company.tipo_persona
            : company.tipo_persona === "fisica"
            ? "foreignFisica"
            : "foreignMoral";
        auxDocumentList = returnDocument(person);
      } else {
        auxDocumentList = returnDocument("none");
      }
    } else {
      auxDocumentList = returnDocument("none");
    }

    this.setState({ catDocumentList: auxDocumentList });
  };

  handlePastedText = (text, html, editorState, onChange, name) => {
    const currentContent = editorState.getCurrentContent();
    const converted = convertToRaw(currentContent);
    let length = this.getEditorContentLength(converted);
    if (length + text.length > (name == "logline" ? 1000 : 15000)) {
      this.setState({
        openAlert: true,
        variant: "warning",
        message: `El límite de caracteres es de ${
          name == "logline" ? "1,000" : "15,000"
        }`,
      });
      return false;
    }
    // const selectedBlock = getSelectedBlock(editorState);
    // if (selectedBlock && selectedBlock.type == "code") {
    //   const contentState = Modifier.replaceText(
    //     editorState.getCurrentContent(),
    //     editorState.getSelection(),
    //     text,
    //     editorState.getCurrentInlineStyle(),
    //   );
    //   onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    //   return true;
    // }
    // else if (html) {
    //   const blockMap = stateFromHTML(html).blockMap;
    //   const newState = Modifier.replaceWithFragment(
    //     editorState.getCurrentContent(),
    //     editorState.getSelection(),
    //     blockMap,
    //   );
    //   onChange(EditorState.push(editorState, newState, 'insert-fragment'));
    //   return true;
    // }
    // return false;
  };

  convertContent = (name, data) => {
    let a = "";
    if (!data) {
      return [0, null];
    }
    try {
      a = JSON.parse(`${data}`);
    } catch (error) {
      console.log(`${name} Editor, is not JSON, will convert`);
    }
    if (a && typeof a === "object") {
      const contentBlock = convertFromRaw(a);
      const editorState = EditorState.createWithContent(contentBlock);
      const editorLength = this.getEditorContentLength(a);
      return [editorLength, editorState];
    } else if (data.length > 0) {
      if (data.includes('{"blocks"'))
        data = data
          .split(",")[1]
          .split(":")[1]
          .replace(/"/g, "");
      const rawContent = {
        blocks: [
          {
            key: "4tt3f",
            text: data,
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      };
      const contentBlock = convertFromRaw(rawContent);
      const editorState = EditorState.createWithContent(contentBlock);
      const editorLength = this.getEditorContentLength(a);

      return [editorLength, editorState];
    }

    return [0, null];
  };

  onEditorStateChange = (name, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const converted = convertToRaw(currentContent);
    const editorString = JSON.stringify(converted).replace(/'/g, "");
    let length = this.getEditorContentLength(converted);

    if (name == "logline" && length > 1000) {
      return false;
    } else if (name == "sinopsis_corta" && length > 15000) {
      return false;
    }

    this.setState({
      [`${name}EditorState`]: editorState,
      [name]: editorString,
      [`${name}Length`]: length,
    });
  };

  handleBeforeInput = (name) => {
    if (
      this.getEditorContentLength(
        convertToRaw(this.state[`${name}EditorState`].getCurrentContent())
      ) >= (name == "logline" ? 1000 : 15000)
    ) {
      this.setState({
        openAlert: true,
        variant: "warning",
        message: `El límite de caracteres es de ${
          name == "logline" ? "1,000" : "15,000"
        }`,
      });
      return "handled";
    }
  };

  getEditorContentLength = (data) => {
    let length = 0;
    if (Array.isArray(data.blocks)) {
      data.blocks.forEach((b) => (b.text ? (length += b.text.length) : null));
    }

    return length;
  };

  handleMultipleEvaluadorChange = ({ target: { name, value } }) => {
    if (value.length > 5) {
      this.setState({
        openAlert: true,
        variant: "warning",
        message: "El límite de especialistas a asignar es de 5",
      });
      return;
    }
    this.setState({ evaluadorUserMultiple: value });
  };

  handleEvCountryChange = ({ target: { name, value } }) => {
    let { countryEvaluations, countryEvExpanded, countries } = this.state;

    if (value.length > 10) {
      this.setState({
        openAlert: true,
        variant: "warning",
        message: "Solo se permiten 10 evaluaciones de país",
      });
      return;
    }

    value.forEach((countryId) => {
      const currentCountry = countries.find((c) => c.value === countryId);

      if (!currentCountry) {
        return;
      }

      const currentEvCountry = countryEvaluations.find(
        (c) => c.countryId === countryId
      );

      if (!currentEvCountry) {
        countryEvExpanded = countryId;
        countryEvaluations.push({
          countryId,
          countryName: currentCountry.data,
          transmissionPerformance: "",
          networkPerformance: "",
          schedule: "",
          share: "",
        });
      }
    });

    countryEvaluations.forEach((ev, index) => {
      if (!value.includes(ev.countryId)) {
        countryEvaluations.splice(index, 1);
      }
    });

    this.setState({ evPaises: value, countryEvExpanded, countryEvaluations });
  };

  handleCountryEvaluationChange = (evCountryIndex) => ({
    target: { name, value },
  }) => {
    const { countryEvaluations } = this.state;

    if (!!countryEvaluations[evCountryIndex]) {
      countryEvaluations[evCountryIndex][name] = value;

      this.setState({ countryEvaluations });
    }
  };

  handleEvCountryChangeExpand = (countryId) => {
    this.setState({
      countryEvExpanded:
        countryId === this.state.countryEvExpanded ? null : countryId,
    });
  };

  render() {
    // console.log('registroobra')
    //isanchez 06.05.2019 add props for permissions
    const {
      classes,
      canAddWork,
      canEditWork,
      canUpgradeWork,
      canExtendTermWork,
      canReevaluateWork,
      onClose,
      canDeleteEvalFilesWorkReg,
    } = this.props;

    const steps = this.getSteps();
    //AHDA 02/04/2019
    //Added filesDiagram, filesEvaluation, filesMaterial
    const {
      activeStep,
      expanded,
      altLabel,
      inputValue,
      selectedItem,
      files,
      filesDiagram,
      filesEvaluation,
      filesEvaluationProcess,
      filesMaterial,
      countryEvaluations,
    } = this.state;
    const getDocumentContent = (document) => {
      let auxFiles = [];

      if (this.state.documents.length > 0) {
        auxFiles = this.state.documents.filter(
          (d) => d.tipo_archivo === document
        );
        return auxFiles;
      } else return [];
    };
    // console.log(this.state.companyProviderCatalog)
    // GEO. 26/03/2019 Cambios en tipos de inputs y acomodo
    const getStepContent = (step) => {
      if (this.props.canSeeEvaluationProcess && (this.state.rolId === 21 || this.state.rolId === 19)) {
        if (step === 4) {
          step = 7;
        } else if (step > 3) {
          step -= 1;
        }
      }

      switch (step) {
        case 0:
          return (
            <div>
              <section className={classes.bodyForm}>
                <Grid container spacing={16}>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      label="Empresa/Proveedor (Capturado por el usuario)*"
                      placeholder="Ej. Televisa"
                      id="companyProvider"
                      value={this.state.companyProvider}
                      className={classNames(classes.field, classes.w100)}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <FormControl
                      className={classNames(classes.formControl, classes.w100)}
                    >
                      {/* EGS - 09/05/2019 */}
                      <SelectValidator
                        label="Empresa/Proveedor*"
                        placeholder="Ej. Televisa"
                        id="companyProviderCatalog"
                        name="companyProviderCatalog"
                        value={this.state.companyProviderCatalog}
                        className={classNames(classes.field, classes.w100)}
                        //className={classes.selectEmpty}
                        // helperText={(valid) => console.log(valid)}
                        onChange={this.handleChange}
                        validators={["required"]}
                        errorMessages={["(Campo requerido)"]}
                        InputLabelProps={{ shrink: true }}
                        disabled={
                          this.state.rolName !== "Jefe de Análisis de Contenido"
                        }
                      >
                        {this.state.companysProviders.map(
                          (companyProvider, idx) => (
                            <MenuItem
                              value={companyProvider.value}
                              key={`companyProvider${idx}`}
                            >
                              {companyProvider.data}
                            </MenuItem>
                          )
                        )}
                      </SelectValidator>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField
                      label="Representante"
                      placeholder="Ej. Ricardo Torres"
                      id="representative"
                      name="representative"
                      value={this.state.representative}
                      onChange={this.handleChange}
                      className={classNames(classes.field, classes.w100)}
                      InputLabelProps={{ shrink: true }}
                      disabled={
                        this.state.rolName !== "Jefe de Análisis de Contenido"
                      }
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField
                      label="Dirección"
                      placeholder="Ej. Paseo del Moral 1565"
                      id="address"
                      value={this.state.address}
                      className={classNames(classes.field, classes.w100)}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField
                      label="Teléfono*"
                      placeholder="Ej. 47712545655"
                      id="telephone"
                      value={this.state.telephone}
                      className={classNames(classes.field, classes.w100)}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <TextField
                      label="Correo Electrónico"
                      placeholder="Ej. ejemplo@ejemplo.com"
                      id="email"
                      name="email"
                      value={this.state.email}
                      className={classNames(classes.field, classes.w100)}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                </Grid>
              </section>
            </div>
          );
        // GEO. 26/03/2019 Cambios en tipos de inputs y acomodo
        case 1:
          return (
            <div>
              <section className={classes.bodyForm}>
                <div>
                  <ExpansionPanel
                    defaultExpanded
                    expanded={expanded === "panel1"}
                    onChange={this.handleChangeExpand("panel1")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>
                        Datos Generales
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        <Grid item md={9} sm={8} xs={12}>
                          {/* EGS - 09/06/2019 */}
                          <TextValidator
                            id="proTitulo"
                            label="Título de la obra"
                            placeholder="Ej. Amor de Madre"
                            name="titulo_original"
                            onChange={this.handleChange}
                            value={this.state.titulo_original}
                            className={classNames(
                              classes.textField,
                              classes.w100
                            )}
                            InputLabelProps={{ shrink: true }}
                            validators={["required", "maxStringLength:100"]}
                            errorMessages={[
                              "(Campo requerido)",
                              "(Has sobrepasado el número de caracteres permitidos)",
                            ]}
                            disabled={!this.props.canEditWorkGeneralData}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proTipo">
                              Tipo de obra*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.tipo_obra}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="tipo_obra"
                              id="proTipo"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              required
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              <MenuItem value={"adaptacion"}>
                                Adaptación
                              </MenuItem>
                              <MenuItem value={"primigenia"}>
                                Primigenia
                              </MenuItem>
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        {this.state.tipo_obra === "adaptacion" ? (
                          <Grid item md={3} sm={6} xs={12}>
                            <FormControl
                              className={classNames(
                                classes.formControl,
                                classes.w100
                              )}
                            >
                              <InputLabel shrink htmlFor="proNAdaptacion">
                                Número de adaptación*
                              </InputLabel>
                              <SelectValidator
                                value={this.state.proNAdaptacion}
                                onChange={this.handleChangeSelect}
                                displayEmpty
                                name="proNAdaptacion"
                                id="proNAdaptacion"
                                className={classes.selectEmpty}
                                InputLabelProps={{ shrink: true }}
                                validators={["required"]}
                                errorMessages={["(Campo requerido)"]}
                                disabled={!this.props.canEditWorkGeneralData}
                              >
                                <MenuItem value={11}>11</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                                <MenuItem value={13}>13</MenuItem>
                                <MenuItem value={14}>14</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={16}>16</MenuItem>
                                <MenuItem value={17}>17</MenuItem>
                                <MenuItem value={18}>18</MenuItem>
                                <MenuItem value={19}>19</MenuItem>
                              </SelectValidator>
                            </FormControl>
                          </Grid>
                        ) : null}
                        <Grid item md={3} sm={6} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proProducida">
                              Producida*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.proProducida}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="proProducida"
                              id="proProducida"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              <MenuItem value={1}>SI</MenuItem>
                              <MenuItem value={0}>NO</MenuItem>
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextValidator
                            id="proTituloEn"
                            label="Título en inglés"
                            placeholder="Ej. Mother's Love"
                            name="titulo_inglés"
                            value={this.state.titulo_inglés}
                            onChange={this.handleChange}
                            className={classNames(
                              classes.textField,
                              classes.w100
                            )}
                            InputLabelProps={{ shrink: true }}
                            disabled={!this.props.canEditWorkGeneralData}
                            // JCMY 01/07/2019 incidencia 987
                            // validators={['maxStringLength:100']}
                            // errorMessages={['(Has sobrepasado el número de caracteres permitidos)']}
                          />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <TextValidator
                            id="proTituloOther"
                            label="Título en otro idioma"
                            placeholder="Ej. L'amore della madre"
                            name="titulo_otro_idioma"
                            value={this.state.titulo_otro_idioma}
                            onChange={this.handleChange}
                            className={classNames(
                              classes.textField,
                              classes.w100
                            )}
                            InputLabelProps={{ shrink: true }}
                            disabled={!this.props.canEditWorkGeneralData}
                            // JCMY 01/07/2019 incidencia 987
                            // validators={['maxStringLength:100']}
                            // errorMessages={['(Has sobrepasado el número de caracteres permitidos)']}
                          />
                        </Grid>
                        {this.state.tipo_obra === "adaptacion" ? (
                          <Grid item md={6} sm={6} xs={12}>
                            <TextValidator
                              // id="proTituloPrimi"
                              id="obra_primigenia"
                              label="Título de la obra primigenia*"
                              placeholder="Ej. Amor de Madre"
                              name="obra_primigenia"
                              value={this.state.obra_primigenia}
                              onChange={this.handleChange}
                              className={classNames(
                                classes.textField,
                                classes.w100
                              )}
                              InputLabelProps={{ shrink: true }}
                              validators={["required", "maxStringLength:100"]}
                              disabled={!this.props.canEditWorkGeneralData}
                              errorMessages={[
                                "(Campo requerido)",
                                "(Has sobrepasado el número de caracteres permitidos)",
                              ]}
                            />
                          </Grid>
                        ) : null}
                        <Grid item md={4} sm={6} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proPais">
                              País de origen*
                            </InputLabel>
                            <SelectValidator
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="id_pais"
                              value={this.state.id_pais}
                              id="proPais"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.countries.map((country, idx) => (
                                <MenuItem
                                  value={country.value}
                                  key={`country${idx}`}
                                >
                                  {country.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <TextValidator
                            id="proNCapitulos"
                            label="Número de capítulos*"
                            placeholder="Ej. 250"
                            // JCMY 12/07/2019
                            name="numero_capitulos"
                            value={this.state.numero_capitulos}
                            onChange={this.handleChange}
                            // JCMY 05/06/2019
                            className={classNames(
                              classes.textField,
                              classes.w100
                            )}
                            InputLabelProps={{ shrink: true }}
                            validators={["required", "isNumber", "isPositive"]}
                            errorMessages={[
                              "(Campo requerido)",
                              "(Solo se permiten números)",
                              "(Valor no permitido)",
                            ]}
                            disabled={!this.props.canEditWorkGeneralData}
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proDuracion">
                              Duración*
                            </InputLabel>
                            <SelectValidator
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="duracion_min_capitulos"
                              value={this.state.duracion_min_capitulos}
                              id="proDuracion"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.durations.map((duration, idx) => (
                                <MenuItem
                                  value={duration.value}
                                  key={`duration${idx}`}
                                >
                                  {duration.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proRegistroAutor">
                              Registro de Autor*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.author_register}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="author_register"
                              id="proRegistroAutor"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              <MenuItem value={"registrada"}>
                                Registrada
                              </MenuItem>
                              <MenuItem value={"noregistrada"}>
                                No registrada
                              </MenuItem>
                              <MenuItem value={"proceso"}>
                                En proceso de registro
                              </MenuItem>
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proDestino">
                              Destino*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.clasificacion}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="clasificacion"
                              id="proDestino"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              <MenuItem value={"produccion"}>
                                Producción
                              </MenuItem>
                              <MenuItem value={"ventas"}>Ventas</MenuItem>
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proFuente">
                              Fuente*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.id_fuente}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="id_fuente"
                              id="proFuente"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.sources.map((source, idx) => (
                                <MenuItem
                                  value={source.value}
                                  key={`source${idx}`}
                                >
                                  {source.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          {/*AHDA 31/05/2019: changed newChipcodes*/}
                          <ChipInput
                            label="Etiquetas"
                            placeholder="Ej. amor, drama, cdmx"
                            value={this.state.label_work}
                            name="new_tags"
                            onChange={this.handleChange}
                            onAdd={(chip) =>
                              this.handleAddChip(chip, "label_work")
                            }
                            onDelete={(chip, index) =>
                              this.handleDeleteChip(chip, index, "label_work")
                            }
                            newChipKeyCodes={[13, 9]}
                            className={classNames(classes.field, classes.w100)}
                            InputLabelProps={{ shrink: true }}
                            disabled={!this.props.canEditWorkGeneralData}
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel
                    expanded={expanded === "panel2"}
                    onChange={this.handleChangeExpand("panel2")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>
                        Autor(es) / Adaptador(es)
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className={classes.root}>
                        <Paper className={classes.rootTable}>
                          {/*AHDA 24/05/2019: Added showSearch*/}
                          <Header
                            title="Autor(es) / Adaptador(es)"
                            tooltip="Agregar Autor/Adaptador"
                            textBtn="Nuevo"
                            openForm={() => this.handledOpenForm(undefined)}
                            workId={0}
                            canAdd={
                              canAddWork && this.props.canEditWorkGeneralData
                            }
                            showSearch={false}
                          />

                          <TableCatalogue
                            headers={this.state.headers}
                            data={this.state.authorsData}
                            openForm={this.handledOpenForm}
                            deleteItem={this.handleDeleteAuthor}
                            workId={0}
                            canDelete={
                              canEditWork && this.props.canEditWorkGeneralData
                            }
                            canEdit={
                              canEditWork && this.props.canEditWorkGeneralData
                            }
                            showActive={true}
                          />
                        </Paper>

                        {/* GEO. 26/03/2019 Cambios en tipos de inputs y acomodo */}
                        {/*AHDA 06/05/2019: changed closeForm*/}
                        <AddCatalogue
                          validate={1}
                          openForm={this.state.openForm}
                          closeForm={() => this.handledCloseForm("openForm")}
                          classes={classes}
                          submit={this.handleSubmitAuthor}
                          // JCMY 05/06/2019</div>
                          clearForm={() => this.handleClear("autor")}
                          title="Nuevo Autor / Adaptador"
                          withForm={false}
                        >
                          <Grid container spacing={16}>
                            {this.state.autFromUsr ? (
                              <Grid item md={12} sm={12} xs={12}>
                                <TextField
                                  id="proNCapitulos"
                                  label="Nombre (Capturado por el usuario)"
                                  placeholder="Ej. María Fuentes"
                                  className={classNames(
                                    classes.textField,
                                    classes.w100
                                  )}
                                  required
                                  InputLabelProps={{ shrink: true }}
                                  value={this.state.author_nameUsr}
                                  name="author_nameUsr"
                                  onChange={this.handleChange}
                                  disabled
                                />
                              </Grid>
                            ) : (
                              ""
                            )}
                            <Grid item md={12} sm={12} xs={12}>
                              {/* JCMY 02/07/2019 */}

                              <FormControl
                                className={classNames(
                                  classes.formControl,
                                  classes.w100
                                )}
                                error={this.state.errorproNameAutor}
                              >
                                <InputLabel shrink htmlFor="proNameAutor">
                                  Nombre*
                                </InputLabel>
                                <Select
                                  value={this.state.proNameAutor}
                                  onChange={this.handleChangeSelect}
                                  displayEmpty
                                  name="proNameAutor"
                                  id="proNameAutor"
                                  required
                                  disabled={
                                    !this.state.autFromUsr &&
                                    !this.state.itsNewAuth &&
                                    this.state.rolId !== 20
                                  }
                                  className={classes.selectEmpty}
                                  InputLabelProps={{ shrink: true }}
                                  onBlur={this.handleBlur}
                                >
                                  {this.state.catAuthors.map((autor, idx) => (
                                    // JCMY 01/07/2019
                                    // this.state.itsNewAuth ?
                                    //   this.state.authsCatalog.find(p => p.authors_id == autor.value) ? null : <MenuItem value={autor.value} key={`autor${idx}`}>{autor.label}</MenuItem>
                                    //   :
                                    <MenuItem
                                      value={autor.value}
                                      key={`autor${idx}`}
                                    >
                                      {autor.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {this.state.errorproNameAutor ? (
                                  <FormHelperText id="weight-helper-text">
                                    (Campo requerido)
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                              {/* JCMY 02/07/2019 */}
                              <FormControl
                                className={classNames(
                                  classes.formControl,
                                  classes.w100
                                )}
                                error={this.state.errorauthor_rol}
                              >
                                <InputLabel shrink htmlFor="proParticipacion">
                                  Participación*
                                </InputLabel>
                                <Select
                                  value={this.state.author_rol}
                                  onChange={this.handleChangeSelect}
                                  displayEmpty
                                  name="author_rol"
                                  id="author_rol"
                                  required
                                  className={classes.selectEmpty}
                                  InputLabelProps={{ shrink: true }}
                                  onBlur={this.handleBlur}
                                >
                                  <MenuItem value={"Autor"}>Autor</MenuItem>
                                  <MenuItem value={"Adaptador"}>
                                    Adaptador
                                  </MenuItem>
                                </Select>
                                {this.state.errorauthor_rol ? (
                                  <FormHelperText id="weight-helper-text">
                                    (Campo requerido)
                                  </FormHelperText>
                                ) : null}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </AddCatalogue>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  {/* GEO. 26/03/2019 Cambios en tipos de inputs y acomodo */}
                  <ExpansionPanel
                    expanded={expanded === "panel3"}
                    onChange={this.handleChangeExpand("panel3")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>
                        Información Literaria
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proFormato">
                              Formato*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.id_formato}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="id_formato"
                              id="proFormato"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.catFormat.map((format, idx) => (
                                <MenuItem
                                  value={format.value}
                                  key={`format${idx}`}
                                >
                                  {format.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proVentana">
                              Ventana*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.id_ventana}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="id_ventana"
                              id="proVentana"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.catPlatform.map((sale, idx) => (
                                <MenuItem value={sale.value} key={`sale${idx}`}>
                                  {sale.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proAudiencia">
                              Audiencia*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.id_audiencia}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="id_audiencia"
                              id="proAudiencia"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.catAudience.map((audi, idx) => (
                                <MenuItem value={audi.value} key={`audi${idx}`}>
                                  {audi.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proHorario">
                              Horario*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.horario}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="horario"
                              id="proHorario"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {schedule.map((sch, idx) => (
                                <MenuItem value={sch} key={`schedule_${idx}`}>
                                  {sch}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proGenero">
                              Género*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.id_genero}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="id_genero"
                              id="proGenero"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.catGender.map((gen, idx) => (
                                <MenuItem value={gen.value} key={`gen${idx}`}>
                                  {gen.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proSubgenero">
                              Subgénero*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.id_subgenero}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="id_subgenero"
                              id="proSubgenero"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.catSubgender.map((subg, idx) => (
                                <MenuItem value={subg.value} key={`subg${idx}`}>
                                  {subg.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proTematica">
                              Temática*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.id_tematica}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="id_tematica"
                              id="proTematica"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.catThematic.map((tema, idx) => (
                                <MenuItem value={tema.value} key={`tema${idx}`}>
                                  {tema.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proMotivacion">
                              Motivación*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.id_motivacion_conflicto}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="id_motivacion_conflicto"
                              id="proMotivacion"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.catMotivation.map((moti, idx) => (
                                <MenuItem value={moti.value} key={`moti${idx}`}>
                                  {moti.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proTemporalidad">
                              Temporalidad*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.id_temporalidad}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="id_temporalidad"
                              id="proTemporalidad"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.catTemp.map((tempo, idx) => (
                                <MenuItem
                                  value={tempo.value}
                                  key={`tempo${idx}`}
                                >
                                  {tempo.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="proAmbiente">
                              Ambiente*
                            </InputLabel>
                            <SelectValidator
                              value={this.state.id_desarrollo}
                              onChange={this.handleChangeSelect}
                              displayEmpty
                              name="id_desarrollo"
                              id="proAmbiente"
                              className={classes.selectEmpty}
                              InputLabelProps={{ shrink: true }}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                              disabled={!this.props.canEditWorkGeneralData}
                            >
                              {this.state.catEnviroment.map((amb, idx) => (
                                <MenuItem value={amb.value} key={`amb${idx}`}>
                                  {amb.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    expanded={expanded === "PanelComentarios"}
                    onChange={this.handleChangeExpand("PanelComentarios")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>
                        Comentarios
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        <Grid item md={12} sm={12} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                            error={this.state.errorcomments}
                          >
                            <TextField
                              label="Comentarios:"
                              id="workComments"
                              name="comments"
                              multiline
                              rows="7"
                              placeholder="Escribe aquí"
                              className={classNames(
                                classes.textField,
                                classes.w100
                              )}
                              onChange={this.handleChangeSelect}
                              value={this.state.comments}
                              InputLabelProps={{ shrink: true }}
                              disabled={!this.props.canEditWorkGeneralData}
                              onBlur={this.handleBlur}
                            />
                            {
                              //this.state.errorcomments ? <FormHelperText id="weight-helper-text">(Campo requerido)</FormHelperText> : null
                            }
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  {/* GEO. 26/03/2019 Cambios en tipos de inputs y acomodo */}
                  {/* JCMY 02/07/2019 incidencia 986*/}
                  {this.state.proProducida === 1 ? (
                    <ExpansionPanel
                      expanded={expanded === "panel4"}
                      onChange={this.handleChangeExpand("panel4")}
                    >
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.headingExpand}>
                          Producción
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid container spacing={16}>
                          <Grid item md={12} sm={12} xs={12}>
                            {/* <ChipInput
                            label="Productor(es)"
                            placeholder="Ej. María Cano, Javier Cruz"
                            name="producerList"
                            value={this.state.producerList}
                            onAdd={(chip) => this.handleAddChip(chip, 'producerList')}
                            onDelete={(chip, index) => this.handleDeleteChip(chip, index, 'producerList')}
                            newChipKeyCodes={[32]}
                            className={classNames(classes.field, classes.w100)}
                            InputLabelProps={{ shrink: true }}
                          /> */}
                            <FormControl
                              className={classNames(
                                classes.formControl,
                                classes.w100
                              )}
                            >
                              <InputLabel shrink>Productor(es)</InputLabel>

                              <Select
                                multiple
                                value={this.state.producerList}
                                name={"producerList"}
                                id={"producerList"}
                                className={classNames(
                                  classes.field,
                                  classes.w100
                                )}
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange}
                                renderValue={(selected) => (
                                  <div className={classes.chips}>
                                    {this.state.producerList.map(
                                      (value, idx) => {
                                        let auxValue = this.state.catProducers.find(
                                          (p) => parseInt(p.value) == value
                                        );
                                        return (
                                          <Chip
                                            key={`chip_prod_${idx}`}
                                            label={
                                              auxValue ? auxValue.data : "-"
                                            }
                                            className={classes.chip}
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              >
                                {this.state.catProducers.map(
                                  (producer, idx) => (
                                    <MenuItem
                                      key={`producer_${idx}`}
                                      value={producer.value}
                                    >
                                      {" "}
                                      {producer.data}{" "}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                            <TextValidator
                              label="Año de Producción"
                              placeholder="Ej. 2019"
                              id="anio_produccion"
                              name="anio_produccion"
                              key={4}
                              value={this.state.anio_produccion}
                              className={classes.w100}
                              onChange={this.handleChange}
                              validators={[
                                "required",
                                "isSpaceEmpty",
                                "isPositive",
                                "minNumber:1000",
                              ]}
                              errorMessages={[
                                "Campo requerido",
                                "Campo requerido",
                                "Año inválido",
                                "Año inválido",
                              ]}
                              InputProps={{
                                inputProps: { maxLength: 4 },
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                            {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              label="Año de Producción"
                              placeholder="Ej. 2017"
                              name="anio_produccion"
                              views={["year"]}
                              format="YYYY"
                              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                              clearable={true}
                              value={this.state.anio_produccion}
                              disableFuture={true}
                              onChange={this.handleDateChangeI}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                              className={classes.w100}
                              InputLabelProps={{ shrink: true }}
                            />
                          </MuiPickersUtilsProvider> */}
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                            {/* JCMY 05/06/2019 */}
                            <TextValidator
                              id="proEpisodiosAire"
                              label="Episodios al aire"
                              placeholder="Ej. 250"
                              name="ep_alAire"
                              value={this.state.ep_alAire}
                              className={classNames(
                                classes.textField,
                                classes.w100
                              )}
                              InputLabelProps={{ shrink: true }}
                              onChange={this.handleChange}
                              validators={["isNumber", "isPositive"]}
                              errorMessages={[
                                "(Solo se permiten números)",
                                "(Valor no permitido)",
                              ]}
                            />
                          </Grid>
                          <Grid item md={4} sm={12} xs={12}>
                            <FormControl
                              className={classNames(
                                classes.formControl,
                                classes.w100
                              )}
                            >
                              <InputLabel shrink htmlFor="proDuracion">
                                Duración
                              </InputLabel>
                              <Select
                                value={this.state.aired_duration}
                                onChange={this.handleChangeSelect}
                                displayEmpty
                                name="aired_duration"
                                id="proDuracion"
                                className={classes.selectEmpty}
                                InputLabelProps={{ shrink: true }}
                              >
                                {this.state.catDuration.map((dur, idx) => (
                                  <MenuItem value={dur.value} key={`dur${idx}`}>
                                    {dur.data}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ) : null}

                  {/* GEO. 26/03/2019 Cambios en tipos de inputs y acomodo */}
                  {//EGS - 10/05/2019
                  // JCMY 31/05/2019
                  (this.state.proProducida === 1 &&
                    this.state.tipo_obra == "primigenia") ||
                  this.state.tipo_obra == "adaptacion" ? (
                    <ExpansionPanel
                      expanded={expanded === "panel5"}
                      onChange={this.handleChangeExpand("panel5")}
                    >
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.headingExpand}>
                          Otras Versiones
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid container spacing={16}>
                          <Grid item md={12} sm={12} xs={12}>
                            {/* <ChipInput
                            label="Otras versiones*"
                            placeholder="Ej. amor, drama, cdmx"
                            value={this.state.versionList}
                            onAdd={(chip) => this.handleAddChip(chip, 'versionList')}
                            onDelete={(chip, index) => this.handleDeleteChip(chip, index, 'versionList')}
                            newChipKeyCodes={[32]}
                            dataSourceConfig={{ text: "fist", vale: "one" }}
                            className={classNames(classes.field, classes.w100)}
                            InputLabelProps={{ shrink: true }}
                          /> */}
                            <FormControl
                              className={classNames(
                                classes.formControl,
                                classes.w100
                              )}
                            >
                              <InputLabel shrink>Otras versiones</InputLabel>

                              <Select
                                multiple
                                value={this.state.versionList}
                                name={"versionList"}
                                id={"versonList"}
                                className={classNames(
                                  classes.field,
                                  classes.w100
                                )}
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange}
                                renderValue={(selected) => (
                                  <div className={classes.chips}>
                                    {selected.map((value, idx) => {
                                      let auxValue = this.state.works.find(
                                        (p) => p.titulo === value
                                      );
                                      return (
                                        <Chip
                                          key={`chip_prod_${idx}`}
                                          label={
                                            auxValue != undefined
                                              ? auxValue.titulo
                                              : value == "Otras"
                                              ? value
                                              : "-"
                                          }
                                          className={classes.chip}
                                        />
                                      );
                                    })}
                                  </div>
                                )}
                              >
                                {// EEGS 06/05/2019
                                this.state.works.map((work, idx) =>
                                  parseInt(work.status) === 4 ? (
                                    <MenuItem
                                      key={`work${idx}`}
                                      value={work.titulo}
                                    >
                                      {" "}
                                      {work.titulo}{" "}
                                    </MenuItem>
                                  ) : null
                                )}
                                <MenuItem key="work-1" value="Otras">
                                  {" "}
                                  Otras{" "}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {this.state.versionList.find(
                            (e) => e === "Otras"
                          ) && (
                            <Grid item md={12} sm={12} xs={12}>
                              <TextValidator
                                id="otras_versiones"
                                label="Títulos de otras versiones"
                                name="otras_versiones"
                                value={this.state.otras_versiones}
                                className={classNames(
                                  classes.textField,
                                  classes.w100
                                )}
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange}
                                // validators={['required']}
                                // errorMessages={['(Campo requerido)']}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  ) : null}
                </div>
              </section>
            </div>
          );
        case 2:
          return (
            <div>
              <section className={classes.bodyForm}>
                <div>
                  <ExpansionPanel
                    expanded={expanded === "panel21"}
                    onChange={this.handleChangeExpand("panel21")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>
                        Logline
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        <Grid item md={12} sm={12} xs={12}>
                          {/* <TextField
                            id="proLogline"
                            name="logline"
                            multiline
                            rows="7"
                            placeholder="Escribe aquí"
                            className={classes.field}
                            onChange={this.handleChange}
                            //margin="normal"
                            // value={this.state.proLogline ? this.state.proLogline : dataInit.description}
                            value={this.state.logline}
                            InputProps={{
                              inputProps: { maxLength: 1000 }
                            }}
                          /> */}
                          <div>
                            <Editor
                              editorState={this.state.loglineEditorState}
                              editorClassName="TextEditor"
                              toolbarClassName="ToolbarEditor"
                              onEditorStateChange={(state) =>
                                this.onEditorStateChange("logline", state)
                              }
                              readOnly={!this.props.canEditWorkSynopsisData}
                              handlePastedText={(
                                text,
                                html,
                                editorState,
                                onChange
                              ) =>
                                this.handlePastedText(
                                  text,
                                  html,
                                  editorState,
                                  onChange,
                                  "logline"
                                )
                              }
                              handleBeforeInput={() =>
                                this.handleBeforeInput("logline")
                              }
                            />
                          </div>
                          <div className={classes.noteSpace}>
                            <Typography>
                              <small>Máximo 1,000 caracteres</small>
                            </Typography>
                            <Typography>
                              <small>
                                <strong>{this.state.loglineLength}</strong> de
                                1,000
                              </small>
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    expanded={expanded === "panel22"}
                    onChange={this.handleChangeExpand("panel22")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>
                        Sinopsis corta
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        <Grid item md={12} sm={12} xs={12}>
                          {/* <TextField
                            id="proSinopsisCorta"
                            name="sinopsis_corta"
                            multiline
                            rows="7"
                            placeholder="Escribe una sinopsis corta"
                            className={classes.field}
                            onChange={this.handleChange}
                            //margin="normal"
                            // value={this.state.proSinopsisCorta ? this.state.proSinopsisCorta : dataInit.description}
                            value={this.state.sinopsis_corta}
                            InputProps={{
                              inputProps: { maxLength: 15000 }
                            }}
                          /> */}
                          <Editor
                            editorState={this.state.sinopsis_cortaEditorState}
                            editorClassName="TextEditor"
                            toolbarClassName="ToolbarEditor"
                            onEditorStateChange={(state) =>
                              this.onEditorStateChange("sinopsis_corta", state)
                            }
                            handlePastedText={(
                              text,
                              html,
                              editorState,
                              onChange
                            ) =>
                              this.handlePastedText(
                                text,
                                html,
                                editorState,
                                onChange,
                                "sinopsis_corta"
                              )
                            }
                            readOnly={!this.props.canEditWorkSynopsisData}
                            handleBeforeInput={() =>
                              this.handleBeforeInput("sinopsis_corta")
                            }
                          />
                          <div className={classes.noteSpace}>
                            <Typography>
                              <small>Máximo 15,000 caracteres</small>
                            </Typography>
                            <Typography>
                              <small>
                                <strong>
                                  {this.state.sinopsis_cortaLength}
                                </strong>{" "}
                                de 15,000
                              </small>
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel
                    expanded={expanded === "panel23"}
                    onChange={this.handleChangeExpand("panel23")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>
                        Sinopsis Larga
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        <Grid item md={12} sm={12} xs={12}>
                          {/* <TextField
                            id="proSinopsisLarga"
                            name="sinopsis_larga"
                            multiline
                            rows="7"
                            placeholder="Escribe una sinopsis larga"
                            className={classes.field}
                            onChange={this.handleChange}
                            value={this.state.sinopsis_larga}
                          /> */}
                          <Editor
                            editorState={this.state.sinopsis_largaEditorState}
                            editorClassName="TextEditor"
                            toolbarClassName="ToolbarEditor"
                            onEditorStateChange={(state) =>
                              this.onEditorStateChange("sinopsis_larga", state)
                            }
                            readOnly={!this.props.canEditWorkSynopsisData}
                            // handlePastedText={this.handlePastedText}
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </section>
            </div>
          );
        case 3:
          return (
            <div>
              <section className={classes.bodyForm}>
                <div>
                  <ExpansionPanel
                    expanded={expanded === "panel31"}
                    onChange={this.handleChangeExpand("panel31")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>
                        Personajes
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {/* GEO. 27/03/2019 Cambio en cards de personajes para drag and drop */}
                      {/* CH. 17/04/2019 Se agrega drag and drop de personajes */}
                      <Grid container spacing={24}>
                        <Grid item xs={12}>
                          <Grid
                            item
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="flex-start"
                            // className={classes.pb3}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.button}
                              disabled={!this.props.canEditWorkCharactersData}
                              onClick={() =>
                                this.handledOpenFormCharacters(undefined)
                              }
                            >
                              <AddIcon
                                className={classNames(
                                  classes.leftIcon,
                                  classes.iconSmall
                                )}
                              />{" "}
                              Nuevo Personaje
                            </Button>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="stretch"
                            spacing={24}
                          >
                            <Grid item md={12} sm={12} xs={12}>
                              <ReactDragList
                                dataSource={this.state.new_characters}
                                rowKey="id"
                                row={(record, index) => (
                                  <CharacterPaper
                                    character={record}
                                    canEdit={
                                      !this.props.canEditWorkCharactersData
                                    }
                                    onEdit={this.handledOpenFormCharacters}
                                    onDelete={this.handleDeleteCharacters}
                                    key={`character_${index}`}
                                  />
                                )}
                                // handles={false}
                                // onUpdate={this.handleUpdateCharacters}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                    {/*Add new character form*/}
                    {/*AHDA 06/05/2019: changed closeForm*/}
                    <AddCatalogue
                      validate={1}
                      openForm={this.state.openFormCharacters}
                      closeForm={() =>
                        this.handledCloseForm("openFormCharacters")
                      }
                      classes={classes}
                      submit={this.handleSubmitCharacters}
                      // JCMY 05/06/2019
                      clearForm={() => this.handleClear("personaje")}
                      title={
                        this.state.selected
                          ? "Actualizar Personaje"
                          : "Nuevo Personaje"
                      }
                    >
                      <Grid container spacing={24}>
                        <Grid item xs={12} sm={12} md={8}>
                          <FormControl
                            className={classNames(classes.field, classes.w100)}
                            error={this.state.errornew_characterName}
                          >
                            <TextField
                              error={this.state.errornew_characterName}
                              label="Nombre completo*"
                              placeholder="Ej. Federico García"
                              id="new_characterName"
                              name="new_characterName"
                              value={this.state.new_characterName}
                              type="text"
                              autoComplete="off"
                              className={classes.field}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              InputLabelProps={{ shrink: true }}
                            />
                            {this.state.errornew_characterName ? (
                              <FormHelperText id="weight-helper-text">
                                Campo requerido
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <FormControl
                            className={classNames(classes.field, classes.w100)}
                          >
                            <TextField
                              label="Edad"
                              placeholder="Ej. 45 años"
                              id="new_characterAge"
                              name="new_characterAge"
                              value={this.state.new_characterAge}
                              autoComplete="off"
                              type="text"
                              className={classes.field}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              inputProps={{ maxLength: 50 }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={24}>
                        <Grid item xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                            error={this.state.errornew_characterPsychology}
                          >
                            <TextField
                              error={this.state.errornew_characterPsychology}
                              label="Psicología del personaje"
                              placeholder="Ej. This is the story of an ambitious secretary, Nora, who falls in love for her wealthy boss, who is married to a respectable woman, with whom he has a child. "
                              id="new_characterPsychology"
                              name="new_characterPsychology"
                              multiline
                              rows="5"
                              className={classes.field}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              value={this.state.new_characterPsychology}
                              inputProps={{ maxLength: 1000 }}
                              InputLabelProps={{ shrink: true }}
                            />
                            {this.state.errornew_characterPsychology ? (
                              <FormHelperText id="weight-helper-text">
                                Campo requerido
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </AddCatalogue>
                  </ExpansionPanel>
                  <ExpansionPanel
                    expanded={expanded === "panel32"}
                    onChange={this.handleChangeExpand("panel32")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>
                        Diagrama de relaciones
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        {/*AHDA 02/05/2019: Changed props, TODO: Add validation for existing file*/}
                        {this.state.relationDiagramFile ? (
                          <Grid item xs={12}>
                            <List dense={false}>
                              <ListItem>
                                <ListItemText
                                  primary={
                                    this.state.relationDiagramFile.ruta
                                      ? getFileTitle(
                                          this.state.relationDiagramFile.ruta
                                        )
                                      : "Diagrama de Relaciones (Archivo corrupto)"
                                  }
                                  secondary={null}
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() =>
                                      this.handleDeleteSingleMaterial(
                                        "relationDiagramFile"
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            </List>
                          </Grid>
                        ) : (
                          <div className={classes.cajaDropZone}>
                            <MaterialDropZone
                              onDrop={(files) =>
                                this.onDropFile(files, "filesDiagram", "PDF")
                              }
                              onRemove={(file, fileIndex) =>
                                this.handleRemove(
                                  file,
                                  fileIndex,
                                  "filesDiagram"
                                )
                              }
                              files={filesDiagram}
                              showPreviews
                              acceptedFiles={["application/pdf"]}
                              // JCMY 03/07/2019 incidencia 1029
                              maxSize={54760833024}
                              filesLimit={1}
                              text="Arrastra tu archivo o da click aquí"
                            />
                          </div>
                        )}
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </section>
            </div>
          );
          {
            /* GEO. 27/03/2019 Cambio en estilos de inputs */
          }
        case 4:
          return (
            <div>
              <section className={classes.bodyForm}>
                <Grid container spacing={16}>
                  <Grid item md={6} sm={6} xs={12}>
                    <FormControl
                      className={classNames(classes.formControl, classes.w100)}
                    >
                      <InputLabel shrink htmlFor="proHorario2">
                        Horario
                      </InputLabel>
                      <Select
                        value={this.state.evHorario}
                        name="evHorario"
                        onChange={this.handleChangeSelect}
                        displayEmpty
                        id="evHorario"
                        className={classes.selectEmpty}
                        InputLabelProps={{ shrink: true }}
                      >
                        {schedule.map((sch, idx) => (
                          <MenuItem value={sch} key={`schedule_${idx}`}>
                            {sch}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item md={6} sm={6} xs={12}>
                    <FormControl
                      className={classNames(classes.formControl, classes.w100)}
                    >
                      <InputLabel shrink htmlFor="proNivelRiesgo">
                        Nivel de riesgo
                      </InputLabel>
                      <SelectValidator
                        value={this.state.evRiskLevel}
                        name="evRiskLevel"
                        onChange={this.handleChangeSelect}
                        displayEmpty
                        id="evRiskLevel"
                        className={classes.selectEmpty}
                        InputLabelProps={{ shrink: true }}
                        // validators={['required']}
                        // errorMessages={['(Campo requerido)']}
                      >
                        {this.state.catRiskLevel.map((rLevel, idx) => (
                          <MenuItem value={rLevel.id} key={`rLevel${idx}`}>
                            {rLevel.descripcion}
                          </MenuItem>
                        ))}
                      </SelectValidator>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} sm={12} xs={12}>
                    <FormControl
                      className={classNames(classes.formControl, classes.w100)}
                    >
                      <InputLabel shrink htmlFor="evPaises">
                        Paises
                      </InputLabel>
                      <Select
                        onChange={this.handleEvCountryChange}
                        displayEmpty
                        multiple
                        name="evPaises"
                        value={this.state.evPaises}
                        id="evPaises"
                        className={classes.selectEmpty}
                        InputLabelProps={{ shrink: true }}
                        // validators={['hasCountriesReachLimit']}
                        // errorMessages={['Solo se permiten 10 evaluaciones de país']}
                      >
                        {this.state.countries.map((country, idx) => (
                          <MenuItem value={country.value} key={`country${idx}`}>
                            {country.data}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* 
                  <Grid item md={4} sm={6} xs={12}>
                    <TextValidator
                      id="evQualification"
                      value={this.state.evQualification}
                      name="evQualification"
                      label="Calificación*"
                      onChange={this.handleChange}
                      placeholder='Ej. 2.75'
                      className={classNames(classes.textField, classes.w100)}
                      InputLabelProps={{ shrink: true }}
                      validators={['required', 'isFloat', 'isPositive', 'minNumber:0', 'maxNumber:100']}
                      errorMessages={['(Campo requerido)', '(Solo se permiten números)', '(Valor no permitido)', '(Rango permitido: 0-100)', '(Rango permitido: 0-100)']}
                    />
                  </Grid> 
                  */}
                  {countryEvaluations.map((evaluation, index) => (
                    <Grid item md={12} sm={12} xs={12}>
                      <ExpansionPanel
                        defaultExpanded
                        expanded={
                          this.state.countryEvExpanded === evaluation.countryId
                        }
                        onChange={() =>
                          this.handleEvCountryChangeExpand(evaluation.countryId)
                        }
                      >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography className={classes.headingExpand}>
                            {evaluation.countryName}
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <Grid container spacing={16}>
                            <Grid item md={6} sm={6} xs={12}>
                              <TextValidator
                                id="countryEvTransmissionPerformance"
                                value={evaluation.transmissionPerformance}
                                name="transmissionPerformance"
                                label="Desempeño de transmisión"
                                onChange={this.handleCountryEvaluationChange(
                                  index
                                )}
                                placeholder="Ej. 4.5"
                                className={classNames(
                                  classes.textField,
                                  classes.w100
                                )}
                                InputLabelProps={{ shrink: true }}
                                validators={[
                                  "minFloat:0",
                                  "maxFloat:100",
                                  "isFloat",
                                  "isPositive",
                                ]}
                                errorMessages={[
                                  "(Solo se permiten números)",
                                  "(Valor no permitido)",
                                ]}
                              />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12}>
                              <TextValidator
                                id="countryEvNetworkPerformance"
                                value={evaluation.networkPerformance}
                                name="networkPerformance"
                                label="Desempeño de Redes"
                                onChange={this.handleCountryEvaluationChange(
                                  index
                                )}
                                placeholder="Ej. 4.5"
                                className={classNames(
                                  classes.textField,
                                  classes.w100
                                )}
                                InputLabelProps={{ shrink: true }}
                                validators={[
                                  "minFloat:0",
                                  "maxFloat:100",
                                  "isFloat",
                                  "isPositive",
                                ]}
                                errorMessages={[
                                  "(Solo se permiten números)",
                                  "(Valor no permitido)",
                                ]}
                              />
                            </Grid>

                            <Grid item md={6} sm={6} xs={12}>
                              <FormControl
                                className={classNames(
                                  classes.formControl,
                                  classes.w100
                                )}
                              >
                                <InputLabel shrink htmlFor="proHorario2">
                                  Horario
                                </InputLabel>
                                <Select
                                  value={evaluation.schedule}
                                  name="schedule"
                                  onChange={this.handleCountryEvaluationChange(
                                    index
                                  )}
                                  displayEmpty
                                  id="evCountrySchedule"
                                  className={classes.selectEmpty}
                                  InputLabelProps={{ shrink: true }}
                                >
                                  {schedule.map((sch, idx) => (
                                    <MenuItem
                                      value={sch}
                                      key={`schedule_${idx}`}
                                    >
                                      {sch}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item md={6} sm={6} xs={12}>
                              {/* JCMY 08/07/2019 incidencia */}
                              <TextValidator
                                label="Share"
                                placeholder="Ej. 17.50%"
                                id="evCountryShare"
                                value={evaluation.share}
                                name="share"
                                type="text"
                                autoComplete="off"
                                className={classNames(
                                  classes.field,
                                  classes.w100
                                )}
                                onChange={this.handleCountryEvaluationChange(
                                  index
                                )}
                                InputLabelProps={{ shrink: true }}
                                validators={[
                                  "minFloat:0",
                                  "maxFloat:100",
                                  "isFloat",
                                ]}
                                errorMessages={[
                                  "(Numero invalido)",
                                  "(Numero invalido)",
                                  "(Solo se permiten numeros)",
                                ]}
                                inputProps={{
                                  maxLength: 6,
                                }}
                              />

                              {/* <TextField
                              label="Share"
                              placeholder="Ej. 17.50%"
                              id="evShare"
                              value={this.state.evShare}
                              name="evShare"
                              type="text"
                              autoComplete="off"
                              className={classNames(classes.textField, classes.w100)}
                              onChange={this.handleChange}
                              InputLabelProps={{ shrink: true }} /> */}
                            </Grid>
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                  ))}
                  {/* {
                    countryEvaluations.length < 1 && (
                      <div>
                        <Typography 
                          className={classes.headingExpand}
                          align='center'
                          >No Evaluaciones por país</Typography>
                      </div>
                    )
                  } */}
                  <Grid item md={12} sm={12} xs={12}>
                    {/* JCMY 02/07/2019 incidencia  710*/}
                    <TextField
                      value={this.state.evComments}
                      name="evComments"
                      label="Comentarios"
                      id="evComments"
                      multiline
                      rows="7"
                      placeholder=""
                      className={classes.field}
                      onChange={this.handleChange}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        maxLength: 2000,
                      }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      value={this.state.epComments}
                      name="epComments"
                      label="Comentarios a mostrar: Empresa-Proveedor"
                      id="epComments"
                      multiline
                      rows="4"
                      placeholder=""
                      className={classes.field}
                      onChange={this.handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <InputLabel htmlFor="">Dictamen de evaluación</InputLabel>
                    <div>
                      {/*AHDA 03/05/2019: Changed props, added validation for existing file*/}
                      {this.state.evaluationFile ? (
                        <Grid item xs={12}>
                          <List dense={false} className={classes.customList}>
                            {this.state.evaluationFile.map((item, key) => {
                              return (
                                <ListItem key={key}>
                                  <ListItemText
                                    className={classes.ellipsis}
                                    primary={getFileTitle(item.ruta)}
                                    secondary={item.material_nombre}
                                  />
                                  <ListItemSecondaryAction>
                                    {item &&
                                      canDeleteEvalFilesWorkReg &&
                                      ["Registrada", "Preevaluación"].includes(
                                        this.state.statusWorkFlow
                                      ) && (
                                        <IconButton
                                          aria-label="Delete"
                                          onClick={() =>
                                            this.handleDeleteJudgement(
                                              item,
                                              key
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}

                                    <IconButton
                                      aria-label="Ver"
                                      onClick={() =>
                                        this.handleViewSingleMaterial(item)
                                      }
                                    >
                                      <Visibility />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Grid>
                      ) : null}
                      <div className={classes.cajaDropZone}>
                        <MaterialDropZone
                          onDrop={(files) =>
                            this.onDropFile(files, "filesEvaluation")
                          }
                          onRemove={(file, fileIndex) =>
                            this.handleRemove(
                              file,
                              fileIndex,
                              "filesEvaluation"
                            )
                          }
                          files={filesEvaluation}
                          showPreviews
                          acceptedFiles={[
                            "application/pdf",
                            "application/msword",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          ]}
                          // JCMY 03/07/2019 incidencia 1029
                          maxSize={54760833024}
                          filesLimit={1}
                          text="Arrastra tu archivo o da click aquí"
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </section>
            </div>
          );
        case 5:
          return (
            <div>
              <section className={classes.bodyform}>
                <div className={classes.root}>
                  <ExpansionPanel
                    expanded={expanded === "panel2"}
                    onChange={this.handleChangeExpand("panel2")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography className={classes.headingExpand}>
                        Otros Materiales
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div className={classes.root}>
                        <Paper className={classes.rootTable}>
                          {/*AHDA 03/05/2019: Changed props showVisibility, handleViewItem, TODO: deleteItem*/}
                          {/*AHDA 24/05/2019: Added showSearch*/}
                          <Header
                            title="Otros Materiales"
                            tooltip="Agregar Material"
                            textBtn="Nuevo"
                            openForm={() =>
                              this.handleOpenFormMaterial(undefined)
                            }
                            canAdd={canAddWork}
                            showSearch={false}
                          />

                          <TableCatalogue
                            headers={this.state.headersMaterial}
                            data={this.state.materials}
                            openForm={this.handleOpenFormMaterial}
                            deleteItem={this.handleDeleteMaterial}
                            workId={0}
                            canEdit={true}
                            showDelete={this.props.canEditWorkMaterialData}
                            canDelete={true}
                            showVisibility={true}
                            handleViewItem={this.handleViewItem}
                          />
                        </Paper>
                        {/* ************************** */}
                        {/*AHDA 05/05/2019: changed props, submit*/}
                        <AddCatalogue
                          validate={1}
                          openForm={this.state.openFormMaterial}
                          closeForm={() =>
                            this.handledCloseForm("openFormMaterial")
                          }
                          classes={classes}
                          submit={this.handleSubmitMaterial}
                          clearForm={() => this.handleClear("material")}
                          title={this.state.titleModalMaterial}
                        >
                          {/* GEO. 27/03/2019 Cambio en estilos de inputs */}
                          <div>
                            <Grid container spacing={16}>
                              <Grid item md={12} sm={12} xs={12}>
                                <FormControl
                                  className={classNames(
                                    classes.formControl,
                                    classes.w100
                                  )}
                                >
                                  <TextField
                                    id="proNameMaterial"
                                    name="proNameMaterial"
                                    value={this.state.proNameMaterial}
                                    label={"Título"}
                                    className={classNames(classes.w100)}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleChange}
                                    type="text"
                                    autoComplete="off"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item md={12} sm={12} xs={12}>
                                <FormControl
                                  className={classNames(
                                    classes.formControl,
                                    classes.w100
                                  )}
                                >
                                  <InputLabel shrink htmlFor="proTipo">
                                    Tipo*
                                  </InputLabel>
                                  <Select
                                    value={this.state.proTypeMaterial}
                                    onChange={this.handleMaterialChange}
                                    displayEmpty
                                    name="proTypeMaterial"
                                    id="proTypeMaterial"
                                    required
                                    className={classes.selectEmpty}
                                    InputLabelProps={{ shrink: true }}
                                  >
                                    {this.state.materialTypes.map(
                                      (material, idx) => (
                                        <MenuItem
                                          value={material.value}
                                          key={`material_${idx}`}
                                        >
                                          {material.data}
                                        </MenuItem>
                                      )
                                    )}
                                    <MenuItem value={"0"} key={`material_0`}>
                                      Nuevo
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {this.state.isNewMaterialTypeSelected && (
                                <Grid item md={12} sm={12} xs={12}>
                                  <FormControl
                                    className={classNames(
                                      classes.formControl,
                                      classes.w100
                                    )}
                                  >
                                    <TextField
                                      id="proNewNameMaterialType"
                                      name="proNewNameMaterialType"
                                      value={this.state.newMaterialTypeValue}
                                      label={
                                        "Nombre del nuevo tipo de material"
                                      }
                                      className={classNames(classes.w100)}
                                      InputLabelProps={{ shrink: true }}
                                      onChange={this.handleNewMaterialChange}
                                      type="text"
                                      autoComplete="off"
                                    />
                                  </FormControl>
                                </Grid>
                              )}
                              <Grid item md={12} sm={12} xs={12}>
                                <FormControl
                                  className={classNames(
                                    classes.formControl,
                                    classes.w100
                                  )}
                                >
                                  <InputLabel
                                    shrink
                                    htmlFor="proLanguageMaterial"
                                  >
                                    Idioma*
                                  </InputLabel>
                                  <Select
                                    value={this.state.proLanguageMaterial}
                                    onChange={this.handleChange}
                                    displayEmpty
                                    name="proLanguageMaterial"
                                    id="proLanguageMaterial"
                                    className={classes.selectEmpty}
                                    InputLabelProps={{ shrink: true }}
                                  >
                                    {this.state.catLanguage.map(
                                      (language, idx) => (
                                        <MenuItem
                                          value={language.value}
                                          key={`language_${idx}`}
                                        >
                                          {language.data}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item md={12} sm={12} xs={12}>
                                <FormControl
                                  className={classNames(
                                    classes.formControl,
                                    classes.w100
                                  )}
                                >
                                  {/* <InputLabel shrink htmlFor="proChapterMaterial">Número de Capitulo</InputLabel> */}
                                  {/* <TextField
                                    id="proChapterMaterial"
                                    name="proChapterMaterial"
                                    value={this.state.proChapterMaterial}
                                    label={'Número de Capitulo'}
                                    className={classNames(classes.w100)}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleChange}
                                    type="text"
                                    autoComplete="off"
                                  /> */}
                                  <TextValidator
                                    id="proChapterMaterial"
                                    name="proChapterMaterial"
                                    label={"Número de Capitulo"}
                                    value={this.state.proChapterMaterial}
                                    className={classes.w100}
                                    onChange={this.handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                      inputProps: { maxLength: 250 },
                                    }}
                                    validators={["isChapterNumber"]}
                                    errorMessages={[
                                      "No se permiten capturar letras: Ejemplo (1,2.5)",
                                    ]}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item md={12} sm={12} xs={12}>
                                <MaterialDropZone
                                  // cramirez
                                  onDrop={this.onDrop}
                                  onRemove={(file, fileIndex) =>
                                    this.handleRemove(
                                      file,
                                      fileIndex,
                                      "filesMaterial"
                                    )
                                  }
                                  files={filesMaterial}
                                  showPreviews
                                  // JCMY 03/07/2019 incidencia 1029
                                  maxSize={54760833024}
                                  filesLimit={1}
                                  acceptedFiles={this.state.acceptedFiles}
                                  text="Arrastra tu archivo o da click aquí"
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </AddCatalogue>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </section>
            </div>
          );
        case 6:
          return (
            <div>
              <section className={classes.bodyForm}>
                <div className={classes.root}>
                  <ExpansionPanel
                    expanded={expanded === "panel41"}
                    onChange={this.handleChangeExpand("panel41")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        className={classNames(
                          classes.heading,
                          classes.headingExpand
                        )}
                      >
                        Información Contractual
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="company">
                              Empresa / Proveedor*
                            </InputLabel>
                            {/*AHDA 06/05/2019: Added disabled, mapping and value*/}
                            <Select
                              id="contCompanyProviderCatalog"
                              name="companyProviderCatalog"
                              value={this.state.companyProviderCatalog}
                              onChange={this.handleChange}
                              displayEmpty
                              className={classes.selectEmpty}
                              disabled={true}
                            >
                              {this.state.companysProviders.map(
                                (companyProvider, idx) => (
                                  <MenuItem
                                    value={companyProvider.value}
                                    key={`contCompanyProvider${idx}`}
                                  >
                                    {companyProvider.data}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          {/*AHDA 06/05/2019: Added mapping and value*/}
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="contType">
                              Tipo de contrato*
                            </InputLabel>
                            <SelectValidator
                              id="contType"
                              name="contType"
                              value={this.state.contType}
                              onChange={this.handleChange}
                              displayEmpty
                              className={classes.selectEmpty}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                            >
                              {this.state.catContractType.map(
                                (contract, idx) => (
                                  <MenuItem
                                    value={contract.value}
                                    key={`contract_${idx}`}
                                  >
                                    {contract.data}
                                  </MenuItem>
                                )
                              )}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        {/*AHDA 06/05/2019: Added select for validity*/}
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="contValidity">
                              Vigencia del contrato*
                            </InputLabel>
                            <SelectValidator
                              id="contValidity"
                              name="contValidity"
                              value={this.state.contValidity}
                              onChange={this.handleChange}
                              displayEmpty
                              className={classes.selectEmpty}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                            >
                              {this.state.catValidity.map((validity, idx) => (
                                <MenuItem
                                  value={validity.value}
                                  key={`validity_${idx}`}
                                >
                                  {validity.data}
                                </MenuItem>
                              ))}
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          {/*AHDA 06/05/2019: Added mapping and value*/}
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              label="Fecha firma de contrato*"
                              placeholder="DD/MM/AAAA"
                              format="DD/MM/YYYY"
                              mask={[
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              clearable={true}
                              disableFuture={true}
                              value={this.state.contSignDate}
                              onChange={this.handleChangeDateSign}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                              className={classes.w100}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          {/*AHDA 06/05/2019: Added mapping and value*/}
                          <TextValidator
                            label="Precio por capítulo*"
                            placeholder="Ej. $520.60"
                            id="contChapterPrice"
                            className={classNames(
                              classes.textField,
                              classes.w100
                            )}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            value={this.state.contChapterPrice}
                            name="contChapterPrice"
                            onChange={this.handleChange}
                            validators={["required"]}
                            errorMessages={["(Campo requerido)"]}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          {/*AHDA 06/05/2019: Added mapping and value*/}
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            <InputLabel shrink htmlFor="contCurrency">
                              Moneda*
                            </InputLabel>
                            <SelectValidator
                              id="contCurrency"
                              name="contCurrency"
                              value={this.state.contCurrency}
                              onChange={this.handleChange}
                              displayEmpty
                              className={classes.selectEmpty}
                              validators={["required"]}
                              errorMessages={["(Campo requerido)"]}
                            >
                              <MenuItem value="MXN">MXN</MenuItem>
                              <MenuItem value="EUR">EUR</MenuItem>
                              <MenuItem value="USD">USD</MenuItem>
                            </SelectValidator>
                          </FormControl>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          {/*AHDA 06/05/2019: Added mapping and value*/}
                          <TextValidator
                            label="Número de capítulos*"
                            placeholder="Ej. 150"
                            id="contPurchasedChapters"
                            className={classNames(
                              classes.textField,
                              classes.w100
                            )}
                            InputLabelProps={{ shrink: true }}
                            value={this.state.contPurchasedChapters}
                            name="contPurchasedChapters"
                            onChange={this.handleChange}
                            // JCMY 03/06/2019
                            validators={[
                              "required",
                              "isNumber",
                              "isPositive",
                              "isFloat",
                            ]}
                            errorMessages={[
                              "(Campo requerido)",
                              "(Solo se permiten números)",
                              "(Valor no permitido)",
                              "(No es un valor entero)",
                            ]}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          {/*AHDA 06/05/2019: Added mapping and value*/}
                          <TextValidator
                            label="Monto total del contrato*"
                            placeholder="Ej. $520.60"
                            id="contTotalAmount"
                            className={classNames(
                              classes.textField,
                              classes.w100
                            )}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            value={this.state.contTotalAmount}
                            disabled
                            name="contTotalAmount"
                            // onChange={this.handleChange}
                            validators={["required"]}
                            errorMessages={["(Campo requerido)"]}
                          />
                        </Grid>
                        {/*AHDA 06/05/2019: Deleted duplicated field*/}
                        <Grid item md={3} sm={4} xs={12}>
                          {/*AHDA 06/05/2019: Added mapping and value*/}
                          <TextValidator
                            label="Número de pagos*"
                            placeholder="Ej. 35"
                            id="contPaymentsNumber"
                            className={classNames(
                              classes.textField,
                              classes.w100
                            )}
                            InputLabelProps={{ shrink: true }}
                            value={this.state.contPaymentsNumber}
                            name="contPaymentsNumber"
                            onChange={this.handleChange}
                            defaultValue={0}
                            // JCMY 03/06/2019
                            validators={["required", "isPositive", "isNumber"]}
                            errorMessages={[
                              "(Campo requerido)",
                              "(No es un valor permitido)",
                              "(Número no valido)",
                            ]}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          {/*AHDA 06/05/2019: Added mapping and value*/}
                          {/* JCMY 03/06/2019 */}
                          <TextValidator
                            label="Importe del pago*"
                            placeholder="Ej. $520.60"
                            id="contPaymentAmount"
                            className={classNames(
                              classes.textField,
                              classes.w100
                            )}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            value={this.state.contPaymentAmount}
                            name="contPaymentAmount"
                            defaultValue={0}
                            onChange={this.handleChange}
                            validators={["required", "isPositive", "isFloat"]}
                            errorMessages={[
                              "(Campo requerido)",
                              "(No es un valor permitido)",
                              "(Solo se permiten números)",
                            ]}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          {/*AHDA 06/05/2019: Added mapping and value*/}
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              label="Fecha del pago*"
                              placeholder="DD/MM/AAAA"
                              format="DD/MM/YYYY"
                              mask={[
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              clearable={true}
                              disableFuture={true}
                              value={this.state.contStartDate}
                              onChange={this.handleChangeDateStartC}
                              disableOpenOnEnter
                              animateYearScrolling={false}
                              className={classes.w100}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3} sm={4} xs={12}>
                          <FormControl
                            className={classNames(
                              classes.formControl,
                              classes.w100
                            )}
                          >
                            {/*AHDA 06/05/2019: Added mapping and value*/}
                            <InputLabel shrink htmlFor="contPaymentFrequency">
                              Periodicidad del pago*
                            </InputLabel>
                            <Select
                              id="contPaymentFrequency"
                              name="contPaymentFrequency"
                              value={this.state.contPaymentFrequency}
                              onChange={this.handleChange}
                              displayEmpty
                              className={classes.selectEmpty}
                            >
                              {/*TODO: cambiar a constante*/}
                              <MenuItem value="semanal">Semanal</MenuItem>
                              <MenuItem value="quincenal">Quincenal</MenuItem>
                              <MenuItem value="mensual">Mensual</MenuItem>
                              <MenuItem value="bimestral">Bimestral</MenuItem>
                              <MenuItem value="anual">Anual</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  {/* 
                    CH 26/03/2019
                    Cambio de tabla con collapsable
                    */}
                  <ExpansionPanel
                    expanded={expanded === "panel42"}
                    onChange={this.handleChangeExpand("panel42")}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        className={classNames(
                          classes.heading,
                          classes.headingExpand
                        )}
                      >
                        Checklist de documentación
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={16}>
                        {/*AHDA 05/05/2019 : Commented unused functions*/}
                        {/*<Grid item md={12} sm={12} xs={12}>
                            <Grid item container direction="row" justify="flex-end" alignItems="flex-start" className={classes.pb3}>
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.button}
                                onClick={() => this.handledOpenForm(undefined)}>
                                <AddIcon className={classNames(classes.leftIcon, classes.iconSmall)} /> Agregar Documentos
                              </Button>
                              </Grid>
                            {FORM Agregar Documentación}
                            <AddCatalogue
                              openForm={this.state.openForm}
                              closeForm={this.handledCloseForm}
                              classes={classes}
                              submit={this.handleSubmit}
                              clearForm={this.handleClear}
                              title="Nuevo Documento"
                            >
                              { GEO. 27/03/2019 Cambio en estilos de inputs }
                              <Grid container spacing={24}>
                                <Grid item xs={12}>
                                  <FormControl className={classNames(classes.formControl, classes.w100)}>
                                    <InputLabel shrink htmlFor="proDocumento">Documento</InputLabel>
                                    <Select
                                      value={this.state.proDocumento}
                                      onChange={this.handleChangeSelect}
                                      displayEmpty
                                      name="proDocumento"
                                      id="proDocumento"
                                      required
                                      className={classes.selectEmpty}
                                    >
                                      <MenuItem value={10}>Acta de nacimiento</MenuItem>
                                      <MenuItem value={20}>Identificación oficial con fotografía</MenuItem>
                                      <MenuItem value={30}>CURP</MenuItem>
                                      <MenuItem value={40}>Comprobante de domicilio</MenuItem>
                                      <MenuItem value={50}>Constancia de inscripción RFC</MenuItem>
                                      <MenuItem value={60}>Registro de los derechos de la obra</MenuItem>
                                      <MenuItem value={70}>Comprobante de cuenta bancaria</MenuItem>
                                      <MenuItem value={80}>Recibo de honorarios y/o factura modelo</MenuItem>
                                      <MenuItem value={90}>Constancia de residencia fiscal vigente</MenuItem>
                                      <MenuItem value={100}>Identificación oficial con fotografía de los apoderados legales</MenuItem>
                                      <MenuItem value={110}>Acta constitutiva</MenuItem>
                                      <MenuItem value={120}>Factura Modelo</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                      label="Fecha de entrega"
                                      placeholder="DD/MM/AAAA"
                                      format="DD/MM/YYYY"
                                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                      clearable={true}
                                      disableFuture={true}
                                      // value={this.state.fechaFirma}
                                      onChange={this.handleDateChangeI}
                                      disableOpenOnEnter
                                      animateYearScrolling={false}
                                      className={classes.w100}
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                  <MaterialDropZone
                                    files={files}
                                    showPreviews
                                    maxSize={5000000}
                                    filesLimit={1}
                                    text="Arrastra tus archivos o da click aquí"
                                  />
                                </Grid>
                              </Grid>
                            </AddCatalogue>
                          </Grid>*/}
                        <Grid item md={12} sm={12} xs={12}>
                          {/*AHDA 07/05/2019: Mapped documents*/}
                          {this.state.catDocumentList.length > 0
                            ? this.state.catDocumentList.map(
                                (document, idx) => (
                                  <ExpansionPanel
                                    key={`documentExpandable_${idx}`}
                                  >
                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandMoreIcon />}
                                    >
                                      <Typography className={classes.heading}>
                                        {document.data}
                                      </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                      <Grid container spacing={16}>
                                        <Grid item md={12} sm={12} xs={12}>
                                          <TableDocumentacion
                                            data={getDocumentContent(
                                              document.value
                                            )}
                                            handleView={this.handleViewDocument}
                                            showDelete={false}
                                          />
                                        </Grid>
                                      </Grid>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                )
                              )
                            : null}
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </section>
            </div>
          );
          break;
        case 7:
          const { filesDataEvaluationProcess } = this.state;
          const auxFilesData = [...filesDataEvaluationProcess];
          auxFilesData.sort((fileA, fileB) => {
            let date1 = new Date(fileA.delivery_date);
            let date2 = new Date(fileB.delivery_date);

            return date2.getTime() - date1.getTime();
          });
          return (
            <Grid container spacing={16}>
              <Grid item md={12} sm={12} xs={12}>
                <div className={classes.ajusteTabla}>
                  <Table
                    className={classNames(classes.table, classes.stripped)}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell key={`header_1`}>
                          Revisión de Dictamen de Evaluación
                        </TableCell>
                        <TableCell key={`header_2`}>
                          Fecha de publicación
                        </TableCell>
                        <TableCell key={`header_3`}>
                          Nombre de usuario
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {auxFilesData.map((file, idx) => {
                        const { ruta, delivery_date, nombre, apellidos } = file;
                        const routeUntilSlash = ruta.slice(
                          ruta.lastIndexOf("/")
                        );
                        const nameFile = routeUntilSlash.slice(
                          routeUntilSlash.indexOf("_") + 1
                        );
                        const date = new Date(delivery_date);
                        const dateFormatted = `${date.getDate()} ${getSpanishMonthName(
                          date.getMonth() + 1
                        )} ${date.getFullYear()}`;

                        return (
                          <TableRow key={`row_${idx}`}>
                            <TableCell>{nameFile}</TableCell>
                            <TableCell>{dateFormatted}</TableCell>
                            <TableCell>{`${nombre} ${apellidos}`}</TableCell>
                            <TableCell>
                              <Tooltip title="Ver">
                                <IconButton
                                  aria-label="Ver"
                                  onClick={() => this.handleViewItem(file)}
                                >
                                  <Visibility />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <InputLabel htmlFor="">Dictamen de evaluación</InputLabel>
                <div className={classes.cajaDropZone}>
                  <MaterialDropZone
                    onDrop={(files) =>
                      this.onDropFile(files, "filesEvaluationProcess")
                    }
                    onRemove={(file, fileIndex) =>
                      this.handleRemove(
                        file,
                        fileIndex,
                        "filesEvaluationProcess"
                      )
                    }
                    files={filesEvaluationProcess}
                    showPreviews
                    acceptedFiles={[
                      "application/pdf",
                      "application/msword",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    ]}
                    // JCMY 03/07/2019 incidencia 1029
                    maxSize={54760833024}
                    filesLimit={1}
                    text="Arrastra tu archivo o da click aquí"
                  />
                </div>
              </Grid>
            </Grid>
          );

        default:
          return "Unknown step";
      }
    };

    return (
      <div className={classes.detailContainer}>
        <PapperBlock
          title="Actualización de Obra Literaria"
          desc=""
          className={classes.centerProgress}
        >
          {/* JCMY 13/05/2019 */}
          {this.state.loading ? (
            <LoaderFull process={this.state.uploadProgress} />
          ) : (
            ""
          )}
          <Grid container className={classes.root} spacing={0}>
            <Grid item md={12} sm={12} xs={12}>
              <Stepper
                nonLinear
                activeStep={
                  this.props.canEditWorkUserData
                    ? activeStep
                    : activeStep > 0
                    ? activeStep - 1
                    : activeStep
                }
                alternativeLabel={altLabel}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton
                      className={classes.stepItem}
                      // onClick={this.handleStep(index)}
                      completed={this.state.completed[index]}
                    >
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              {/* <Divider /> */}
              <div>
                {this.allStepsCompleted() ? (
                  <div>
                    <div className={classes.finishText}>
                      <Check color="primary" />
                      <p>Gracias por registrar su obra.</p>
                      <small>
                        Le informamos que a partir de este momento inicia el
                        proceso de evaluación de su obra sin tener un tiempo
                        definido para obtener una respuesta. Al momento de
                        recibir el dictamen nos pondremos en contacto con usted
                        a la brevedad posible.
                      </small>
                    </div>

                    <div className={classes.rightAction}>
                      <Button onClick={this.handleReset}>
                        Registrar Nueva Obra
                      </Button>
                      <Button onClick={onClose}>Cerrar</Button>
                    </div>
                  </div>
                ) : (
                  <ValidatorForm ref="form" onSubmit={this.handleComplete}>
                    <div>
                      <div className={classes.contentStep}>
                        {getStepContent(activeStep)}
                      </div>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={this.handleBack}
                          className={classes.button}
                        >
                          Atrás
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleNext}
                          className={classes.button}
                        >
                          {" "}
                          Siguiente{" "}
                        </Button>

                        {/* JCMY 09/07/2019 incidecnia 1058*/}
                        {/* {activeStep !== steps.length && (this.state.completed[this.state.activeStep] ?
                           (<Typography variant="caption" className={classes.completed}>Paso {activeStep + 1} completado</Typography>) 
                           : ( */}

                        {// JCMY 22/04/2019
                        // JCMY 29/04/2019
                        // isanchez 06.05.2019 add canEditWork
                        // JCMY 01/07/2019 insidencia 812
                        ![
                          "Comprada",
                          "Compra directa",
                        ].includes(this.state.statusWorkFlow) &&
                          (![
                            "En proceso de compra",
                            "En condiciones de negociación",
                            "Solicitud de elaboración de contrato",
                            "En firmas",
                            "En proceso de pago",
                          ].includes(this.state.statusWorkFlow) ||
                            this.state.activeStep !== 7) &&
                          canEditWork && (
                            <Button
                              type="submit"
                              onClick={this.validador}
                              variant="contained"
                              color="primary"
                            >
                              {/* JCMY 09/07/0219 incidencia  1059*/}
                              {this.completedSteps() === this.totalSteps() - 1
                                ? "Cerrar"
                                : "Guardar"}
                            </Button>
                          )}

                        {/* ))} */}

                        <div className={classes.rightActionInline}>
                          {/* isanchez 06.05.2019 add permission canUpgradeWork */}
                          {/* JCMY 03/06/2019 */}
                          {/* && canUpgradeWork */}

                          {[
                            "Registrada",
                            "Revisar más material",
                            "Con potencial para otros formatos",
                          ].includes(this.state.statusWorkFlow) &&
                            canUpgradeWork && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleOpenAvanzarObra}
                                className={classes.button}
                              >
                                Avanzar Obra
                              </Button>
                            )}

                          <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.openAvanzarObra}
                            onClose={this.handleCloseAvanzarObra}
                          >
                            {/* CH 26/06/2019 Se pone Loader por encima del Modal */}
                            <React.Fragment>
                              <div
                                style={getModalStyle()}
                                className={classes.paperModal}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justify="flex-start"
                                  alignItems="center"
                                >
                                  <Grid item xs={12}>
                                    <div
                                      className={classNames(
                                        classes.bgTitle,
                                        classes.flexHead
                                      )}
                                    >
                                      <Typography
                                        variant="h6"
                                        className={classes.colorTitle}
                                        id="modal-title"
                                      >
                                        Avanzar Obra{" "}
                                      </Typography>
                                      <IconButton
                                        color="inherit"
                                        onClick={this.handleCloseAvanzarObra}
                                        aria-label="Close"
                                      >
                                        {/* GEO. 27/03/2019 Cambio de color a boton cerrar en modal Avanzar Obra */}
                                        <CloseIcon
                                          className={classes.colorButton}
                                        />
                                      </IconButton>
                                    </div>

                                    <div className={classes.bgContenido}>
                                      <section className={classes.bodyForm}>
                                        {/* GEO. 27/03/2019 Cambio en estilos de inputs de modal Avanzar Obra */}
                                        <Grid container spacing={16}>
                                          <Grid item md={6} sm={12} xs={12}>
                                            <FormControl
                                              className={classNames(
                                                classes.formControl,
                                                classes.w100
                                              )}
                                              error={
                                                this.state.errorstateWorkflow
                                              }
                                            >
                                              <InputLabel
                                                shrink
                                                htmlFor="stateWorkflow"
                                              >
                                                Avanzar a:*
                                              </InputLabel>
                                              <Select
                                                value={this.state.stateWorkflow}
                                                onChange={
                                                  this
                                                    .handleChangeSelectWorkflow
                                                }
                                                displayEmpty
                                                name="stateWorkflow"
                                                id="stateWorkflow"
                                                className={classes.selectEmpty}
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                onBlur={this.handleBlur}
                                              >
                                                {/* JCMY 17/04/2019 */}
                                                {this.state.statesWorkFlow.map(
                                                  (state, idx) => (
                                                    <MenuItem
                                                      value={state.value}
                                                      key={`state${idx}`}
                                                    >
                                                      {state.data}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                              {this.state.errorstateWorkflow ? (
                                                <FormHelperText id="weight-helper-text">
                                                  (Campo requerido)
                                                </FormHelperText>
                                              ) : null}
                                            </FormControl>
                                          </Grid>

                                          {/* JCMY 30/04/2019 */}
                                          {this.state.stateWorkflow !== 2 &&
                                            ![
                                              "En condiciones de negociación",
                                              "Solicitud de elaboración de contrato",
                                              "Comprar",
                                              "En firmas",
                                              "En proceso de pago",
                                              "Comprada",
                                              "Compra directa",
                                            ].includes(
                                              this.state.stateWorkflowString
                                            ) && (
                                              <React.Fragment>
                                                {this.state.stateWorkflow !==
                                                  1 &&
                                                  this.state.stateWorkflow !==
                                                    3 &&
                                                  this.state
                                                    .stateWorkflowString ===
                                                    "No viable" && ( //||
                                                  //this.state.stateWorkflowString === "Con potencial" ||
                                                  //this.state.stateWorkflowString === "Con potencial para otros formatos"
                                                    <React.Fragment>
                                                      <Grid
                                                        item
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                      >
                                                        <FormControl
                                                          className={classNames(
                                                            classes.formControl,
                                                            classes.w100
                                                          )}
                                                          error={
                                                            this.state
                                                              .errornotViable
                                                          }
                                                        >
                                                          <InputLabel
                                                            shrink
                                                            htmlFor="notViable"
                                                          >
                                                            Causa de no
                                                            viabilidad:
                                                          </InputLabel>
                                                          <Select
                                                            value={
                                                              this.state
                                                                .notViable
                                                            }
                                                            onChange={
                                                              this
                                                                .handleChangeSelect
                                                            }
                                                            displayEmpty
                                                            name="notViable"
                                                            id="notViable"
                                                            className={
                                                              classes.selectEmpty
                                                            }
                                                            InputLabelProps={{
                                                              shrink: true,
                                                            }}
                                                            onBlur={
                                                              this.handleBlur
                                                            }
                                                          >
                                                            {/* JCMY 16/04/2019 */}
                                                            {this.state.rejectReasons.map(
                                                              (reject, idx) => (
                                                                <MenuItem
                                                                  value={
                                                                    reject.value
                                                                  }
                                                                  key={`reject${idx}`}
                                                                >
                                                                  {reject.data}
                                                                </MenuItem>
                                                              )
                                                            )}
                                                          </Select>
                                                          {this.state
                                                            .errornotViable ? (
                                                            <FormHelperText id="weight-helper-text">
                                                              (Campo requerido)
                                                            </FormHelperText>
                                                          ) : null}
                                                        </FormControl>
                                                      </Grid>
                                                    </React.Fragment>
                                                  )}

                                                {this.state.stateWorkflow !==
                                                  0 && (
                                                  <React.Fragment>
                                                    {this.state
                                                      .stateWorkflowString !==
                                                      "Preevaluación" && (
                                                      <Grid
                                                        item
                                                        md={
                                                          this.state
                                                            .stateWorkflowString !==
                                                          "No viable"
                                                            ? 6
                                                            : 6
                                                        }
                                                        sm={12}
                                                        xs={12}
                                                      >
                                                        <FormControl
                                                          className={classNames(
                                                            classes.formControl,
                                                            classes.w100
                                                          )}
                                                          error={
                                                            this.state
                                                              .errorresponsibleUser
                                                          }
                                                        >
                                                          <InputLabel
                                                            shrink
                                                            htmlFor="responsibleUser"
                                                          >
                                                            {this.state
                                                              .stateWorkflowString ===
                                                              "Asignada" &&
                                                              "Asignar director de análisis de contenido:*"}

                                                            {(this.state
                                                              .stateWorkflowString ===
                                                              "Registrada" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "Más material" ||
                                                              //this.state.stateWorkflowString === "Revisar más material" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "Solicitar ajustes" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "Viable" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "Con potencial" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "Con potencial para otros formatos" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "No viable") &&
                                                              "Asignar jefe de contenido literario*"}

                                                            {this.state
                                                              .stateWorkflowString ===
                                                              "En proceso de compra" &&
                                                              "Asignar director de control y administración literario:*"}
                                                          </InputLabel>
                                                          <Select
                                                            value={
                                                              this.state
                                                                .responsibleUser
                                                            }
                                                            onChange={
                                                              this
                                                                .handleChangeSelect
                                                            }
                                                            displayEmpty
                                                            name="responsibleUser"
                                                            id="responsibleUser"
                                                            className={
                                                              classes.selectEmpty
                                                            }
                                                            InputLabelProps={{
                                                              shrink: true,
                                                            }}
                                                            onBlur={
                                                              this.handleBlur
                                                            }
                                                          >
                                                            {(this.state
                                                              .stateWorkflowString ===
                                                              "Registrada" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "Más material" ||
                                                              //this.state.stateWorkflowString === "Revisar más material" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "Solicitar ajustes" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "Viable" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "No viable" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "Con potencial" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "Con potencial para otros formatos") &&
                                                              this.state.usersAnalysisContent.map(
                                                                (user, idx) => (
                                                                  <MenuItem
                                                                    value={
                                                                      user.value
                                                                    }
                                                                    key={`user${idx}`}
                                                                  >
                                                                    {user.data}
                                                                  </MenuItem>
                                                                )
                                                              )}

                                                            {this.state
                                                              .stateWorkflowString ===
                                                              "Asignada" &&
                                                              this.state.usersAnalysisDirectors.map(
                                                                (user, idx) => (
                                                                  <MenuItem
                                                                    value={
                                                                      user.value
                                                                    }
                                                                    key={`user${idx}`}
                                                                  >
                                                                    {user.data}
                                                                  </MenuItem>
                                                                )
                                                              )}

                                                            {(this.state
                                                              .stateWorkflowString ===
                                                              "En proceso de compra" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "En condiciones de negociación" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "Solicitud de elaboración de contrato" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "En firmas" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "En proceso de pago" ||
                                                              this.state
                                                                .stateWorkflowString ===
                                                                "No viable") &&
                                                              this.state.usersAdministrator.map(
                                                                (user, idx) => (
                                                                  <MenuItem
                                                                    value={
                                                                      user.value
                                                                    }
                                                                    key={`user${idx}`}
                                                                  >
                                                                    {user.data}
                                                                  </MenuItem>
                                                                )
                                                              )}
                                                          </Select>
                                                          {this.state
                                                            .errorresponsibleUser ? (
                                                            <FormHelperText id="weight-helper-text">
                                                              (Campo requerido)
                                                            </FormHelperText>
                                                          ) : null}
                                                        </FormControl>
                                                      </Grid>
                                                    )}
                                                    {this.state
                                                      .stateWorkflowString ===
                                                      "Preevaluación" && (
                                                      <Grid
                                                        item
                                                        md={
                                                          this.state
                                                            .stateWorkflowString !==
                                                          "No viable"
                                                            ? 6
                                                            : 6
                                                        }
                                                        sm={12}
                                                        xs={12}
                                                      >
                                                        <FormControl
                                                          className={classNames(
                                                            classes.formControl,
                                                            classes.w100
                                                          )}
                                                          error={
                                                            this.state
                                                              .errorresponsibleUser
                                                          }
                                                        >
                                                          <InputLabel
                                                            shrink
                                                            htmlFor="evaluadores"
                                                          >
                                                            Asignar
                                                            especialistas
                                                            evaluadores*{" "}
                                                          </InputLabel>
                                                          <Select
                                                            value={
                                                              this.state
                                                                .evaluadorUserMultiple
                                                            }
                                                            onChange={
                                                              this
                                                                .handleMultipleEvaluadorChange
                                                            }
                                                            multiple
                                                            name="evaluadores"
                                                            id="evaluadores"
                                                            className={
                                                              classes.selectEmpty
                                                            }
                                                            InputLabelProps={{
                                                              shrink: true,
                                                            }}
                                                            onBlur={
                                                              this.handleBlur
                                                            }
                                                          >
                                                            {this.state.usersSpecialists.map(
                                                              (user, idx) => (
                                                                <MenuItem
                                                                  value={
                                                                    user.value
                                                                  }
                                                                  key={`user${idx}`}
                                                                >
                                                                  {user.data}
                                                                </MenuItem>
                                                              )
                                                            )}
                                                          </Select>
                                                          {this.state
                                                            .errorevaluadorresponsibleUser ? (
                                                            <FormHelperText id="weight-helper-text">
                                                              (Campo requerido)
                                                            </FormHelperText>
                                                          ) : null}
                                                        </FormControl>
                                                      </Grid>
                                                    )}
                                                    {this.state
                                                      .stateWorkflow !== 3 &&
                                                      this.state
                                                        .stateWorkflowString !==
                                                        "En proceso de compra" && (
                                                        <React.Fragment>
                                                          <Grid
                                                            item
                                                            md={
                                                              this.state
                                                                .stateWorkflowString !==
                                                              "No viable"
                                                                ? 6
                                                                : 4
                                                            }
                                                            sm={12}
                                                            xs={12}
                                                          >
                                                            <FormControl
                                                              className={classNames(
                                                                classes.formControl,
                                                                classes.w100
                                                              )}
                                                              error={
                                                                this.state
                                                                  .errordeadLine
                                                              }
                                                            >
                                                              <MuiPickersUtilsProvider
                                                                utils={
                                                                  MomentUtils
                                                                }
                                                              >
                                                                <DatePicker
                                                                  label="Fecha límite*"
                                                                  placeholder="DD/MM/AAAA"
                                                                  format="DD/MM/YYYY"
                                                                  mask={[
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                  ]}
                                                                  clearable={
                                                                    true
                                                                  }
                                                                  value={
                                                                    this.state
                                                                      .deadLine
                                                                  }
                                                                  onChange={
                                                                    this
                                                                      .handleDateChangeDl
                                                                  }
                                                                  disableOpenOnEnter
                                                                  name="deadLine"
                                                                  id="deadLine"
                                                                  animateYearScrolling={
                                                                    false
                                                                  }
                                                                  className={
                                                                    classes.field
                                                                  }
                                                                  minDate={
                                                                    new Date()
                                                                  }
                                                                />
                                                              </MuiPickersUtilsProvider>
                                                              {this.state
                                                                .errordeadLine ? (
                                                                <FormHelperText id="weight-helper-text">
                                                                  (Campo
                                                                  requerido)
                                                                </FormHelperText>
                                                              ) : null}
                                                            </FormControl>
                                                          </Grid>
                                                        </React.Fragment>
                                                      )}
                                                  </React.Fragment>
                                                )}
                                              </React.Fragment>
                                            )}

                                          <Grid item md={12} sm={12} xs={12}>
                                            <FormControl
                                              className={classNames(
                                                classes.formControl,
                                                classes.w100
                                              )}
                                              error={this.state.errorcomments}
                                            >
                                              <TextField
                                                label="Comentarios:"
                                                id="workflowComments"
                                                name="workflowComments"
                                                multiline
                                                required
                                                rows="7"
                                                placeholder="Escribe aquí"
                                                className={classes.field}
                                                onChange={
                                                  this.handleChangeSelect
                                                }
                                                value={
                                                  this.state.workflowComments
                                                }
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                onBlur={this.handleBlur}
                                              />
                                              {this.state.errorcomments ? (
                                                <FormHelperText id="weight-helper-text">
                                                  (Campo requerido)
                                                </FormHelperText>
                                              ) : null}
                                            </FormControl>
                                          </Grid>
                                        </Grid>
                                      </section>
                                      <DialogActions>
                                        <Button
                                          onClick={this.handleCloseAvanzarObra}
                                          color="primary"
                                        >
                                          Cerrar
                                        </Button>
                                        <Button
                                          onClick={this.handleSubmitChangeStep}
                                          color="primary"
                                        >
                                          Guardar
                                        </Button>
                                      </DialogActions>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div>
                                {this.state.loading ? <LoaderFull /> : ""}
                              </div>
                            </React.Fragment>
                          </Modal>

                          {/* isanchez 06.05.2019 add permision for reevaluate work */}
                          {this.state.statusWorkFlow == "No viable" ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handleOpenReevaluar}
                              className={classes.button}
                            >
                              Reevaluar
                            </Button>
                          ) : (
                            ""
                          )}

                          <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.openReevaluar}
                            onClose={this.handleCloseReevaluar}
                          >
                            <React.Fragment>
                              <div
                                style={getModalStyle()}
                                className={classes.paperModal}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justify="flex-start"
                                  alignItems="center"
                                >
                                  <Grid item xs={12}>
                                    <div
                                      className={classNames(
                                        classes.bgTitle,
                                        classes.flexHead
                                      )}
                                    >
                                      <Typography
                                        variant="h6"
                                        className={classes.colorTitle}
                                        id="modal-title"
                                      >
                                        Reevaluar
                                      </Typography>
                                      <IconButton
                                        color="inherit"
                                        onClick={this.handleCloseReevaluar}
                                        aria-label="Close"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </div>
                                    <div className={classes.bgContenido}>
                                      <section className={classes.bodyForm}>
                                        <Grid container spacing={16}>
                                          <Grid item md={12} sm={12} xs={12}>
                                            {/* JCMY 25/04/2019 */}
                                            <FormControl
                                              className={classNames(
                                                classes.formControl,
                                                classes.w100
                                              )}
                                              error={
                                                this.state
                                                  .errorcommentsReappraise
                                              }
                                            >
                                              {/* <InputLabel htmlFor="commentsReappraise">Comentarios:*</InputLabel> */}
                                              <TextField
                                                label="Comentarios:"
                                                id="commentsReappraise"
                                                name="commentsReappraise"
                                                multiline
                                                required
                                                rows="7"
                                                placeholder="Escribe aquí"
                                                className={classes.field}
                                                onChange={this.handleChange}
                                                value={
                                                  this.state.commentsReappraise
                                                }
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                onBlur={this.handleBlur}
                                              />
                                              {this.state
                                                .errorcommentsReappraise ? (
                                                <FormHelperText id="weight-helper-text">
                                                  (Campo requerido)
                                                </FormHelperText>
                                              ) : null}
                                            </FormControl>
                                          </Grid>
                                        </Grid>
                                      </section>
                                      <DialogActions>
                                        <Button
                                          onClick={this.handleCloseReevaluar}
                                          color="primary"
                                        >
                                          Cerrar
                                        </Button>
                                        <Button
                                          onClick={this.handleSubmitReappraise}
                                          color="primary"
                                        >
                                          Guardar
                                        </Button>
                                      </DialogActions>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div>
                                {this.state.loading ? <LoaderFull /> : ""}
                              </div>
                            </React.Fragment>
                          </Modal>

                          {/* JCMY 25/04/2019 */}
                          {/* isanchez 06.05.2019 add permission extendTerm  */}
                          {this.state.stageWorkFlow === 2 &&
                            canExtendTermWork &&
                            !["No viable", "Más material"].includes(
                              this.state.statusWorkFlow
                            ) && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleOpenAmpliar}
                                className={classes.button}
                              >
                                {/* JCMY 08/07/2019 incidencia 1061*/}
                                Ampliar plazo
                              </Button>
                            )}

                          <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.openAmpliar}
                            onClose={this.handleCloseExtendTerm}
                          >
                            <React.Fragment>
                              <div
                                style={getModalStyle()}
                                className={classes.paperModal}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justify="flex-start"
                                  alignItems="center"
                                >
                                  <Grid item xs={12}>
                                    <div
                                      className={classNames(
                                        classes.bgTitle,
                                        classes.flexHead
                                      )}
                                    >
                                      <Typography
                                        variant="h6"
                                        className={classes.colorTitle}
                                        id="modal-title"
                                      >
                                        Ampliar plazo de la obra
                                      </Typography>
                                      <IconButton
                                        color="inherit"
                                        onClick={this.handleCloseExtendTerm}
                                        aria-label="Close"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </div>
                                    <div className={classes.bgContenido}>
                                      <section className={classes.bodyForm}>
                                        <Grid container spacing={16}>
                                          <Grid item md={6} sm={12} xs={12}>
                                            <FormControl
                                              className={classNames(
                                                classes.formControl,
                                                classes.w100
                                              )}
                                              error={
                                                this.state
                                                  .errordeadLineExtendTerm
                                              }
                                            >
                                              <MuiPickersUtilsProvider
                                                utils={MomentUtils}
                                              >
                                                {/* JCMY 22/04/2019 */}
                                                <DatePicker
                                                  label="Fecha limite*"
                                                  placeholder="DD/MM/AAAA"
                                                  format="DD/MM/YYYY"
                                                  mask={[
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    "/",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                  ]}
                                                  clearable={true}
                                                  value={
                                                    this.state
                                                      .deadLineExtendTerm
                                                  }
                                                  onChange={
                                                    this.handleDateChangeDlet
                                                  }
                                                  disableOpenOnEnter
                                                  animateYearScrolling={false}
                                                  className={classes.field}
                                                  minDate={new Date()}
                                                  name="deadLineExtendTerm"
                                                  id="deadLineExtendTerm"
                                                />
                                              </MuiPickersUtilsProvider>
                                              {this.state
                                                .errordeadLineExtendTerm ? (
                                                <FormHelperText id="weight-helper-text">
                                                  (Campo requerido)
                                                </FormHelperText>
                                              ) : null}
                                            </FormControl>
                                          </Grid>
                                          <Grid item md={12} sm={12} xs={12}>
                                            {/* JCMY 25/04/2019  */}
                                            <FormControl
                                              className={classNames(
                                                classes.formControl,
                                                classes.w100
                                              )}
                                              error={
                                                this.state
                                                  .errorcommentsExtendTerm
                                              }
                                            >
                                              {/* <InputLabel htmlFor="commentsExtendTerm" shrink >Comentariosxxx:</InputLabel> */}
                                              <TextField
                                                label="Comentarios:"
                                                id="commentsExtendTerm"
                                                name="commentsExtendTerm"
                                                multiline
                                                rows="7"
                                                placeholder="Escribe aquí"
                                                className={classes.field}
                                                onChange={this.handleChange}
                                                value={
                                                  this.state.commentsExtendTerm
                                                }
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                onBlur={this.handleBlur}
                                              />
                                              {this.state
                                                .errorcommentsExtendTerm ? (
                                                <FormHelperText id="weight-helper-text">
                                                  (Campo requerido)
                                                </FormHelperText>
                                              ) : null}
                                            </FormControl>
                                          </Grid>
                                        </Grid>
                                      </section>
                                      <DialogActions>
                                        <Button
                                          onClick={this.handleCloseExtendTerm}
                                          color="primary"
                                        >
                                          Cerrar
                                        </Button>
                                        <Button
                                          onClick={this.handleSubmitExtendTerm}
                                          color="primary"
                                        >
                                          Guardar
                                        </Button>
                                      </DialogActions>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                              <div>
                                {this.state.loading ? <LoaderFull /> : ""}
                              </div>
                            </React.Fragment>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                )}
              </div>
            </Grid>
          </Grid>

          {/* JCMY 24/04/2019 */}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={this.state.openAlert}
            autoHideDuration={6000}
            onClose={this.handleCloseAlert}
          >
            <CustomNotification
              onClose={this.handleCloseAlert}
              variant={this.state.variant}
              message={this.state.message}
            />
          </Snackbar>

          {/* JCMY 04/06/2019 */}
          <SweetAlert
            show={this.state.show}
            html
            title="Obras con nombre igual"
            text={renderToStaticMarkup(
              <TableRepeatedWorks data={this.state.dataRepeatedWorks} />
            )}
            onConfirm={() => this.setState({ show: false })}
          />

          {/*AHDA 05/05/2019: added MediaModal*/}
          {this.state.modalFiles.length > 0 ? (
            <MediaModal
              openModal={this.state.openMediaModal}
              handleCloseModal={this.handleCloseMediaModla}
              media={this.state.modalFiles}
            />
          ) : null}
        </PapperBlock>
      </div>
    );
  }
}

RegistroObra.propTypes = {
  classes: PropTypes.object.isRequired,
  workId: PropTypes.number,
  canAddWork: PropTypes.bool,
  canEditWork: PropTypes.bool,
  canUpgradeWork: PropTypes.bool,
  getRegisteredWorks: PropTypes.func,
  //isanchez 06.05.2019 add permision
  canReevaluateWork: PropTypes.bool,
  canExtendTermWork: PropTypes.bool,
  onClose: PropTypes.func,

  canEditWorkUserData: PropTypes.bool,
  canEditWorkGeneralData: PropTypes.bool,
  canEditWorkSynopsisData: PropTypes.bool,
  canEditWorkCharactersData: PropTypes.bool,
  canEditWorkDiagramData: PropTypes.bool,
  canEditWorkMaterialData: PropTypes.bool,
  canDeleteEvalFilesWorkReg: PropTypes.bool,
  canSeeEvaluationProcess: PropTypes.bool,
};

RegistroObra.defaultProps = {
  canAddWork: true,
  canEditWork: true,
  canUpgradeWork: true,
  canReevaluateWork: true,
  canExtendTermWork: true,

  canEditWorkUserData: true,
  canEditWorkGeneralData: true,
  canEditWorkSynopsisData: true,
  canEditWorkCharactersData: true,
  canEditWorkDiagramData: true,
  canEditWorkMaterialData: true,
  canDeleteEvalFilesWorkReg: true,
  canSeeEvaluationProcess: true,
};

export default withStyles(styles)(RegistroObra);
