import React from "react";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";

import classNames from 'classnames';
import Modal from '@material-ui/core/Modal';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Input, InputLabel } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './../../routes/Pages/Reportes/reportes-jss'

//services 
import { apiPOST, returnCurrentUserId, fetchCall } from '../../services/service_base'

import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../components/Notification/CustomNotification';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
// JCMY 14/05/2019
import LoaderFull from '../../components/Loader/LoaderFull';

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}

class CustomToolbarSelect extends React.Component {
  state = {
    open: false,
    // JCMY 25/04/2019
    users: [],


    // Fields
    user: 0,
    status: "",
    date: null,
    comment: "",
    listWorkId: [],

    // valdiate
    erroruser: false,
    errordate: false,
    errorcomment: false,

    // Alerts
    openAlert: false,
    variant: "",
    message: "",

    loading: false,

    usersAdministrator: [],
    usersAnalysisContent: [],
    usersAnalysisDirectors: [],
  }

  handleOpen = () => {

    const selectedRows = this.props.selectedRows
    const data = this.props.displayData
    let pass = true, status = "", sameStatus = true;

    // JCMY 29/04/2019
    for (const y in selectedRows.data) {
      const obj = data.find(obj => obj.dataIndex === selectedRows.data[y].dataIndex);
      if (status == "" || obj.data[10] == status) {
        status = obj.data[10];
        sameStatus = true;
      } else {
        sameStatus = false
      }
      if (["No viable", "Compra directa", "Comprada"]
        .includes(obj.data[10])) {
        pass = false
      }
    }

    if (!sameStatus) {
      this.setState({ openAlert: true, variant: "warning", message: "Los estatus de las obras seleccionadas deben ser iguales" });
    } else if (pass) {
      console.log(status)
      this.setState({ open: true, selectedStatus: status });
    } else {
      this.setState({ openAlert: true, variant: "warning", message: "Las obras seleccionadas no se pueden reasignar, favor de validar su estatus" });
    }
  };
  handleClose = () => {
    this.setState({ open: false, user: 0, date: null, comment: "", status: "" });
  };


  // JCMY 25/09/2019
  componentDidMount() {
    // get users especialista evaluado
    // JCMY 03/07/2019 insidencia 1020
    // apiPOST('/catalogos/getCatalogo', { idCatalogo: 44 }).then(
    //   response => {
    //     if (response.status === 200) {

    //       this.setState({ users: response.data })
    //     }
    //   }).catch(
    //     error => {
    //       console.log(error);
    //     });

    this.handleCatalogue('usersAdministrator', 38);
    this.handleCatalogue('usersAnalysisContent', 46);
    this.handleCatalogue('usersAnalysisDirectors', 45);

    this.setState({ loading: false })
  }

  handleCatalogue = (catalogue, idCatalogue) => {
    if (this.state[catalogue].length <= 0) {
      //AHDA 17/05/2019: added validation for refresh 
      apiPOST('/catalogos/getCatalogo', { idCatalogo: idCatalogue }, true).then(
        response => {
          if (response.status === 200) {
            this.setState({ [catalogue]: response.data });
          }
        }
      )
    }
  }
  handleDateChangeI = (date) => {

    this.setState({ date: date });
    const error = `errordate`;
    if (!date || date === "") {
      this.setState({ [error]: true });
    } else {
      this.setState({ [error]: false });
    }


  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  handleSubmitReasign = () => {


    if (!this.state.erroruser && !this.state.errordate && !this.state.errorcomment) {

      if (!this.state.user && !this.state.date && !this.state.comment) {
        this.setState({ erroruser: true, errordate: true, errorcomment: true });
        return false;
      } else {


        var d = this.state.date === null ? 0 : new Date(this.state.date);

        const selectedRows = this.props.selectedRows
        const data = this.props.displayData

        let listIds = []

        for (const y in selectedRows.data) {
          const obj = data.find(obj => obj.dataIndex === selectedRows.data[y].index);
          listIds.push(obj.data[0])
        }

        const request = {
          listWorkId: listIds,
          approved: this.state.status,
          dueDate: d === 0 ? "0" : d.getFullYear() + "-" + ((d.getMonth() + 1) > 9 ? '' : '0') + (d.getMonth() + 1) + "-" + ((d.getDate()) <= 9 ? '0' : '') + d.getDate(),
          description: this.state.comment,
          assigneeId: this.state.user
        }

        // LLamada reasignar
        this.setState({ loading: true });
        fetchCall("POST", "/reassignWork", request).then(
          response => {
            if (response.status === 200) {
              if (this.state.status !== 5) {
                const promises = [];
                for (const y in listIds) {
                  promises.push(apiPOST('/works/updatestage', {
                    idObra: listIds[y], idWorkflow: 0,
                    idStatus: (this.state.status == 1 ? 2 : 5)
                  }))
                }
                Promise.all(promises)
                  .then(
                    response => {
                      if (response.status !== 200) {
                        // this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al actualizar la etapa" });
                      }
                    }).catch(error => {
                      console.log(error);
                      this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado al actualizar la etapa" });
                    })
                  .finally(() => this.props.getRegisteredWorks())
              } else {
                this.props.getRegisteredWorks();
              }
              this.setState({ openAmpliar: false, user: 0, date: null, comment: "", openAlert: true, variant: "success", message: "Obras reasignadas", open: false, loading: false });
            } else {
              this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error al reasignar obras", loading: false });
            }
          }).catch(error => {
            console.log(error);
            this.setState({ openAlert: true, variant: "error", message: "Ocurrió un error inesperado", loading: false });
          });


      }
    }
  };

  handleBlur = (event) => {
    const error = `error${event.target.name}`;
    if (!event.target.value || event.target.value === "") {
      this.setState({ [error]: true });
    } else {
      this.setState({ [error]: false });
    }
  }


  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }


  render() {
    const { classes, textBtn, reasignar, getRegisteredWorks } = this.props;

    return (
      // {workId != 0 ? (
      <React.Fragment>
        {/* JCMY 14/05/2019 */}
        {this.state.loading ? <LoaderFull /> : ''}
        {reasignar ?
          <div className={classes.cajaBtn}>
            <Button className={classes.Button} variant="contained" color="primary" onClick={this.handleOpen}>
              {textBtn}
            </Button>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.open}
              onClose={this.handleClose}
            >
              <React.Fragment>
                <div style={getModalStyle()} className={classes.paperModal}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <div className={classNames(classes.bgTitle, classes.flexHead)}>
                        <Typography variant="h6" className={classes.colorTitle} id="modal-title">
                          Reasignar Obra
                        </Typography>
                        <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <div className={classes.bgContenido}>
                        <section className={classes.bodyForm}>
                          <Grid container spacing={16}>
                            <Grid item md={6} sm={12} xs={12} className={classes.pb3}>
                              <FormControl className={classNames(classes.field, classes.w100)} error={this.state.errorstatus}>
                                <InputLabel htmlFor="status">Estatus*</InputLabel>
                                <Select
                                  value={this.state.status}
                                  onChange={this.handleChange}
                                  displayEmpty
                                  name="status"
                                  id="status"
                                  className={classes.field}
                                // onBlur={this.handleBlur}
                                >
                                  {["Registrada"].includes(this.state.selectedStatus) && <MenuItem value={"1"} key={`${1}`}>
                                    Asignada
                                  </MenuItem>}
                                  {(this.state.selectedStatus=="Preevaluación") && <MenuItem value={2} key={`${2}`}>
                                    Con potencial
                                  </MenuItem>}
                                  {(this.state.selectedStatus=="Preevaluación") && <MenuItem value={3} key={`${3}`}>
                                    Con potencial para  otros formatos
                                  </MenuItem>}
                                  {["Registrada", "Asignada", "Preevaluación"].includes(this.state.selectedStatus) && <MenuItem value={0} key={`${0}`}>
                                    No viable
                                  </MenuItem>}
                                  <MenuItem value={5} key={`${5}`}>
                                    Compra directa
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          
                            <Grid item md={6} sm={12} xs={12} className={classes.pb3}>
                              <FormControl className={classNames(classes.field, classes.w100)}>
                                <TextField
                                  label="Rol"
                                  name="rol"
                                  id="rol"
                                  className={classes.field}
                                  disabled
                                  value={this.state.status == 1 ?
                                    "Coordinador de análisis literario"
                                    :
                                    (this.state.status == 5 ?
                                      "Director de control y administración literario"
                                      : "Jefe de análisis de contenido"
                                    )
                                  }
                                />
                              </FormControl>
                            </Grid>
                          
                            <Grid item md={6} sm={12} xs={12} className={classes.pb3}>
                              <FormControl className={classNames(classes.field, classes.w100)} error={this.state.erroruser}>
                                <InputLabel htmlFor="user">
                                  {
                                    this.state.status == 1 ? "Asignar coordinador de análisis literario"
                                      : this.state.status == 5 ? "Asignar director de control y administración literario"
                                        : "Asignar jefe de análisis de contenido"
                                  }
                                </InputLabel>
                                <Select
                                  value={this.state.user}
                                  onChange={this.handleChange}
                                  displayEmpty
                                  name="user"
                                  id="user"
                                  className={classes.field}
                                  onBlur={this.handleBlur}
                                >
                                  {this.state.status == 1
                                    && (
                                      this.state.usersAnalysisDirectors.map((user, idx) => (
                                        <MenuItem value={user.value} key={`user${idx}`}>
                                          {user.data}
                                        </MenuItem>))
                                    )}
                                  {this.state.status == 5
                                    && (
                                      this.state.usersAdministrator.map((user, idx) => (
                                        <MenuItem value={user.value} key={`user${idx}`}>
                                          {user.data}
                                        </MenuItem>))
                                    )}
                                  {(this.state.status == 0 || this.state.status == 2||this.state.status == 3)
                                    && (
                                      this.state.usersAnalysisContent.map((user, idx) => (
                                        <MenuItem value={user.value} key={`user${idx}`}>
                                          {user.data}
                                        </MenuItem>))
                                    )}
                                </Select>
                                {this.state.erroruser ? <FormHelperText id="weight-helper-text">Campo requerido</FormHelperText> : null}
                              </FormControl>
                            </Grid>
                          
                            <Grid item md={6} sm={12} xs={12}>
                              <FormControl className={classNames(classes.field, classes.w100)} error={this.state.errordate}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <DatePicker
                                    label="Fecha de entrega"
                                    placeholder="DD/MM/AAAA"
                                    format="DD/MM/YYYY"
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                    clearable={true}
                                    value={this.state.date}
                                    onChange={this.handleDateChangeI}
                                    disableOpenOnEnter
                                    animateYearScrolling={false}
                                    className={classes.field}
                                    minDate={new Date()}
                                    name="date"
                                    id="date"
                                  />
                                </MuiPickersUtilsProvider>
                                {this.state.errordate ? <FormHelperText id="weight-helper-text">Campo requerido</FormHelperText> : null}
                              </FormControl>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                              <FormControl className={classNames(classes.field, classes.w100)} error={this.state.errorcomment}>
                                {/* CH 25/06/2019 se acomoda label de multiline*/}
                                <TextField
                                  label="Comentarios:"
                                  id="comment"
                                  name="comment"
                                  multiline
                                  required
                                  rows="7"
                                  placeholder="Escribe aquí"
                                  className={classes.field}
                                  onChange={this.handleChange}
                                  value={this.state.comment}
                                  InputLabelProps={{ shrink: true }}
                                  onBlur={this.handleBlur}
                                />
                                {this.state.errorcomment ? <FormHelperText id="weight-helper-text">Campo requerido</FormHelperText> : null}
                              </FormControl>
                            </Grid>

                          </Grid>
                        </section>
                        <DialogActions>
                          <Button onClick={this.handleClose} color="primary">
                            Cerrar
                          </Button>
                          <Button onClick={this.handleSubmitReasign} color="primary">
                            Guardar
                          </Button>
                        </DialogActions>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  {this.state.loading ? <LoaderFull /> : ''}
                </div>
              </React.Fragment>
            </Modal>
          </div> :
          <div className={classes.cajaBtn}>
            {/* isanchez  06.05.2019 disable this section */}

            {/* <Button className={classes.Button} variant="contained" color="primary">
                    {textBtn}
                  </Button>  */}

          </div>
        }
        {/* JCMY 25/04/2019 */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={this.state.openAlert}
          autoHideDuration={6000}
          onClose={this.handleCloseAlert}>
          <CustomNotification
            onClose={this.handleCloseAlert}
            variant={this.state.variant}
            message={this.state.message} />
        </Snackbar>
      </React.Fragment>

    );
  }
}

CustomToolbarSelect.propTypes = {
  reasignar: PropTypes.bool,
  getRegisteredWorks: PropTypes.func
};

CustomToolbarSelect.defaultProps = {
  reasignar: false,
}

export default withStyles(styles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);