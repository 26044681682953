const styles = theme => ({
    reportePais: {
        '& tr th, tr td': {
            // paddingLeft: 0,
            // paddingRight: 5
        },
        '& tr td:nth-child(3)': {
            color: theme.palette.primary.main
        }
    }
  });
  
  export default styles;