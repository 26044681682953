import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
//iconos
//import Settings from '@material-ui/icons/Settings';
import Dashboard from '@material-ui/icons/Dashboard';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InsertChart from '@material-ui/icons/InsertChart';
import List from '@material-ui/icons/List';
import Settings from '@material-ui/icons/Settings';
import Notifications from '@material-ui/icons/Notifications';
//import Restore from '@material-ui/icons/Restore'; 
//import ViewColumn from '@material-ui/icons/ViewColumn';
//import AddProfile from './AddProfile';
import Modal from '@material-ui/core/Modal';
//import Icon from '@material-ui/core/Icon';
//Taps
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { apiPOST } from '../../../../services/service_base';

//import { fetchCall } from '../../../../services/service_base';

function getModalStyle() {
    return {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  }

const VerticalTabs = withStyles(theme => ({
    flexContainer: {
      flexDirection: "column",
      maxHeight: 350,
      overflow: 'auto',
      '& button':{
        textAlign: 'left',
        minHeight: 50,
        maxWidth: 230,
        '& span':{
          flexDirection: 'row',
          justifyContent: 'flex-start',
        },
      },
    },
    indicator: {
      display: "none"
    }
  }))(Tabs);
  
    //GEO 10/04/2019  
    //Se arreglan estilos de tabs seleccionados.
  const MyTab = withStyles(theme => ({
    selected: {
      color: theme.palette.secondary.main,
      borderLeft: `2px solid ${theme.palette.secondary.main}`,
      padding: '0 5px',
      background: '#ededed',
    }
  }))(Tab);



let PERMISSION_ASIGNACIONES = `{"name":"Ver mis obras","attr":"chkViewWork"},
                                {"name":"Agregar obra","attr":"chkAddWork"},
                                {"name":"Editar obra","attr":"chkEditWork"},
                                {"name": "Visualizar Datos del usuario", "attr": "chkWorkUserData"},
                                {"name": "Editar Datos generales de la obra", "attr": "chkEditWorkGeneralData"},
                                {"name": "Editar Sinopsis de la obra", "attr": "chkEditWorkSynopsisData"},
                                {"name": "Editar Personajes de la obra", "attr": "chkEditWorkCharactersData"},
                                {"name": "Editar Diagrama de relaciones de la obra", "attr": "chkEditWorkDiagramData"},
                                {"name": "Editar Material de la obra", "attr": "chkEditWorkMaterialData"},
                                {"name":"Avanzar Obra","attr":"chkUpgradeWork"},
                                {"name": "Subir dictamen", "attr": "chkAddEvalFilesWorkAsig"},
                                {"name": "Eliminar archivos de dictamen", "attr": "chkDeleteEvalFilesWorkAsig"}`;
                                
const PERMISSION_PROFILE =`{"name":"Ver","attr":"chkViewProfile"}`;
const PERMISSION_REPORTS =`{"name":"Area evaluadora","attr":"chkEvalArea"},
                            {"name":"Bitácora de movimientos","attr":"chkHistMovement"},
                            {"name":"Bitácora de obras","attr":"chkHistWork"},
                            {"name":"Compra de obras","attr":"chkBuyWork"},
                            {"name":"Obras por escritor","attr":"chkWorkAutor"},
                            {"name":"Estatus contractual","attr":"chkStatusContr"},
                            {"name":"Estatus documentación","attr":"chkDocStatus"},
                            {"name":"Flujos de pago","attr":"chkWorkFlowPayment"},
                            {"name":"Obras literarias","attr":"chkLitWork"}`;

const PERMISSION_CATALOG =`{"name":"Ver","attr":"chkViewCatalog"},{"name":"Editar","attr":"chkEditCatalog"},{"name":"Crear","attr":"chkNewCatalog"},{"name":"Eliminar","attr":"chkDeleteCatalog"}`;
const PERMISSION_USERADMIN =`{"name":"Ver","attr":"chkViewUser"},{"name":"Editar","attr":"chkEditUser"},{"name":"Bloquear","attr":"chkBlockUser"},{"name":"Desbloquear","attr":"chkUnlockUser"},{"name":"Eliminar","attr":"chkDeleteUser"}`;
const PERMISSION_PROFILEADMIN = `{"name":"Ver","attr":"chkViewProfileAdmin"},{"name":"Editar","attr":"chkEditProfileAdmin"},{"name":"Crear","attr":"chkCreateProfileAdmin"},{"name":"Eliminar","attr":"chkDeleteProfileAdmin"},{"name":"Ver Privilegios","attr":"chkShowPrivileges"},{"name":"Editar Privilegios","attr":"chkEditPrivileges"}`;
const PERMISSION_NOTIFICATION = `{"name":"Vista Admin","attr":"chkViewNotification"},{"name":"Editar","attr":"chkEditNotification"},{"name":"Vista Usuario","attr":"chkViewUserNoti"}`;

//isanchez 5.05.2019
const PERMISSION_REGISTERWORK = `{"name": "Ver todas las obras", "attr": "chkViewAllWork"},
                                {"name": "Editar Obra", "attr": "chkRegisterEditWork"},
                                {"name": "Visualizar Datos del usuario", "attr": "chkRegisterWorkUserData"},
                                {"name": "Editar Datos generales de la obra", "attr": "chkRegisterEditWorkGeneralData"},
                                {"name": "Editar Sinopsis de la obra", "attr": "chkRegisterEditWorkSynopsisData"},
                                {"name": "Editar Personajes de la obra", "attr": "chkRegisterEditWorkCharactersData"},
                                {"name": "Editar Diagrama de relaciones de la obra", "attr": "chkRegisterEditWorkDiagramData"},
                                {"name": "Editar Material de la obra", "attr": "chkRegisterEditWorkMaterialData"},
                                {"name": "Avanzar Obra", "attr": "chkRegisterUpgradeWork"},
                                {"name": "Ampliar plazo de la Obra", "attr": "chkExtendTermWork"},
                                {"name": "Reasignar", "attr": "chkReasignWork"},
                                {"name": "Reevaluar", "attr": "chkReEvaulateWork"},
                                {"name": "Eliminar archivos de dictamen", "attr": "chkDeleteEvalFilesWorkReg"}`;

const CONFIG_ASIGNACIONES = `{
  "chkViewWork":false,
  "chkAddWork":false,
  "chkEditWork":false,
  "chkWorkUserData": false,
  "chkEditWorkGeneralData": false,
  "chkEditWorkSynopsisData": false,
  "chkEditWorkCharactersData": false,
  "chkEditWorkDiagramData": false,
  "chkEditWorkMaterialData": false,
  "chkUpgradeWork": false,
  "chkDeleteEvalFilesWorkAsig":false,
  "chkAddEvalFilesWorkAsig":false,
  "chkEvaluationProcess":false
}`;
const CONFIG_PROFILE =`{"chkViewProfile":false}`;
const CONFIG_REPORTS =`{"chkEvalArea":false,"chkHistMovement":false,"chkHistWork":false,"chkBuyWork":false,"chkWorkAutor":false,"chkStatusContr":false,"chkDocStatus":false,"chkWorkFlowPayment":false,"chkLitWork":false}`;
const CONFIG_CATALOG =`{"chkViewCatalog":false,"chkEditCatalog":false,"chkNewCatalog":false,"chkDeleteCatalog":false}`;
const CONFIG_USERADMIN =`{"chkViewUser":false,"chkEditUser":false,"chkBlockUser":false,"chkUnlockUser":false,"chkDeleteUser":false}`;
const CONFIG_PROFILEADMIN =`{"chkViewProfileAdmin":false,"chkEditProfileAdmin":false,"chkCreateProfileAdmin":false,"chkDeleteProfileAdmin":false,"chkShowPrivileges":false,"chkEditPrivileges":false}`;
const CONFIG_NOTIFICATION =`{"chkViewNotification":false,"chkEditNotification":false, "chkViewUserNoti": false}`;
//isanchez 5.05.2019
const CONFIG_REGISTERWORK = `{
  "chkViewAllWork": false,
  "chkRegisterEditWork": false,
  "chkRegisterWorkUserData": false,
  "chkRegisterEditWorkGeneralData": false,
  "chkRegisterEditWorkSynopsisData": false,
  "chkRegisterEditWorkCharactersData": false,
  "chkRegisterEditWorkDiagramData": false,
  "chkRegisterEditWorkMaterialData": false,
  "chkRegisterUpgradeWork": false,
  "chkExtendTermWork": false,
  "chkReasignWork": false,
  "chkReEvaulateWork": false,
  "chkDeleteEvalFilesWorkReg":false,
  "chkRegisterEvaluationProcess": false
}`;

/* isanchez 03.04.2019 inicio */
const PERMISSION_PARAMETERS = `{"name":"Ver","attr":"chkViewParameter"},{"name":"Editar","attr":"chkEditParameter"}`;
const CONFIG_PARAMETERS = `{"chkViewParameter": false, "chkEditParameter":false}`;
/* isanchez 03.04.2019 fin */

  function TabContainer(props) {
    return (
      <Typography component="div" style={{ paddingLeft: 8 * 2 }}>
        {props.children}
      </Typography>
    );
  }
  // fin Taps

class ModalPermissions extends React.Component {

  constructor(props) {
    super(props);
   this.handleShowPermissions = this.handleShowPermissions.bind(this);
   this.handleChangeChecked = this.handleChangeChecked.bind(this);
  }

  state = {
    activeIndex: 0, //AHDA 31/05/2019
    checkedAll: false,
    chkAddWork: false,
    chkWorkUserData:false,
    chkEditWorkGeneralData:false,
    chkEditWorkSynopsisData:false,
    chkEditWorkCharactersData:false,
    chkEditWorkDiagramData:false,
    chkEditWorkMaterialData:false,
    chkApplyForMaterial: false,
    chkAssignProject: false,
    chkBlockUser: false,
    chkBuyWork: false,
    chkCompAcervoLiterario: false,
    chkCreateProfileAdmin: false,
    chkDeleteCatalog: false,
    chkDeleteProfileAdmin: false,
    chkDeleteUser: false,
    chkDocStatus: false,
    chkEditCatalog: false,
    chkEditNotification: false,
    chkEditPrivileges: false,
    chkEditProfileAdmin: false,
    chkEditUser: false,
    chkEditWork: false,
    chkEditWorkAdmin: false,
    chkEditar: false,
    chkEliminar: false,
    chkEndWork: false,
    chkEvalArea: false,
    chkEvaluaWorkAdmin: false,
    chkFavorite: false,
    chkHistMovement: false,
    chkHistWork: false,
    chkLitWork: false,
    chkMaterial: false,
    chkNewCatalog: false,
    chkNewWorkAdmin: false,
    chkProductionGeneralView: false,
    chkProspect: false,
    chkPublicar: false,
    chkSalesGeneralView: false,
    chkShareMaterial: false,
    chkShowMovementHistory: false,
    chkShowPrivileges: false,
    chkStatusContr: false,
    chkUnlockUser: false,
    chkUnlockWork: false,
    chkUpgradeWork: false,
    chkVer: false,
    chkViewCatalog: false,
    chkViewFavorite: false,
    chkViewNotification: false,
    chkViewProfile: false,
    chkViewProfileAdmin: false,
    chkViewProject: false,
    chkViewProspect: false,
    chkViewUser: false,
    chkViewWork: false,
    chkViewWorkAdmin: false,
    chkViewWorkBlock: false,
    chkWorkAutor: false,
    chkWorkFlowPayment: false,
    chkWorkMoreView:false,
    chkViewParameter:false,
    chkEditParameter:false,
    optionsMenu:[],
    menuSelected:0,
    newConfiguration:[],
    profileId :0,
    onlyView : false,
    isl:false,
    showConfiguration:"",
    menuAcervo:[],
    arrayPermissions:[],
    //isanchez 05.05.2019
    chkViewAllWork: false,
    chkRegisterEditWork: false,
    chkRegisterUpgradeWork: false,
    chkRegisterWorkUserData: false,
    chkRegisterEditWorkGeneralData: false,
    chkRegisterEditWorkSynopsisData: false,
    chkRegisterViewWorkCharactersData: false,
    chkRegisterEditWorkCharactersData: false,
    chkRegisterEditWorkDiagramData: false,
    chkRegisterEditWorkMaterialData: false,
    chkExtendTermWork: false,
    chkReasignWork: false,
    chkReEvaulateWork: false,
    chkViewUserNoti : false,
    chkDeleteEvalFilesWorkReg:false,
    chkDeleteEvalFilesWorkAsig:false,
    chkAddEvalFilesWorkAsig:false,
    chkRegisterEvaluationProcess: false,
    chkEvaluationProcess: false,
  }
 
  componentWillReceiveProps(newProps){
    if(newProps.openModalPermission){
      apiPOST("/roles/getmenuaction",{
        rolId : newProps.profileSelected.rol_id
      }).then(response => {

        this.setState({
          arrayPermissions:[],
          menuAcervo:response.data,
          optionsMenu : newProps.optionsMenu,
          profileId:newProps.profileSelected.rol_id,
          onlyView:newProps.onlyView
        });

        //AHDA 31/05/2019: show first menu when open
        if(newProps.optionsMenu.length > 0)
          this.handleShowPermissions(newProps.optionsMenu[0].menu_id); 

      }).catch(err => console.log("error menu action", err));
      
    }else{
      this._handleClearCloseModal();
    }
  }

  _handleClearCloseModal = () =>{
    this.setState({
      menuSelected: 0,
      showConfiguration:"",
      arrayPermissions:[],
      activeIndex: 0 //AHDA 31/05/2019
    })
  }

  handleChange = (_, activeIndex) => {
    this.setState({ activeIndex })
  };

  handleChangeChecked = name => event => {
    
    if(!this.state.onlyView) {
          
      //AHDA 15/04/2019: changed notation
      let newConfiguration = [];
      let grantId = 0;
      this.state.arrayPermissions.forEach(element => {
        if(element.menu_id !== this.state.menuSelected){
          grantId = element.grant_id;
          newConfiguration.push(element);
        }
      });
      
      let config={};

      let newMenu ={
        grant_id:grantId,
        menu_id : this.state.menuSelected,
        grant_configuration:""
      }
      
      if(name === "checkedAll"){
      
        switch (this.state.menuSelected) {
          case 11:              
            config = {
              chkViewWork: event.target.checked,
              chkAddWork: event.target.checked,
              chkEditWork: event.target.checked,
              chkWorkUserData:event.target.checked,
              chkEditWorkGeneralData:event.target.checked,
              chkEditWorkSynopsisData:event.target.checked,
              chkEditWorkCharactersData:event.target.checked,
              chkEditWorkDiagramData:event.target.checked,
              chkEditWorkMaterialData:event.target.checked,
              chkUpgradeWork: event.target.checked,
              chkDeleteEvalFilesWorkAsig:event.target.checked,
              chkAddEvalFilesWorkAsig:event.target.checked,
              chkEvaluationProcess: event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 12:        
            config = {
              chkViewProfile: event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 13:    
            config = {
              chkEvalArea: event.target.checked,
              chkHistMovement: event.target.checked,
              chkHistWork: event.target.checked,
              chkBuyWork: event.target.checked,
              chkWorkAutor: event.target.checked,
              chkStatusContr: event.target.checked,
              chkDocStatus: event.target.checked,
              chkWorkFlowPayment: event.target.checked,
              chkLitWork: event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 14:     
            config = {
              chkViewUser: event.target.checked,
              chkEditUser: event.target.checked,
              chkBlockUser: event.target.checked,
              chkUnlockUser:  event.target.checked,
              chkDeleteUser: event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 15:     
            config = {
              chkViewProfileAdmin: event.target.checked,
              chkEditProfileAdmin: event.target.checked,
              chkCreateProfileAdmin: event.target.checked,
              chkDeleteProfileAdmin: event.target.checked,
              chkShowPrivileges: event.target.checked,
              chkEditPrivileges: event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 16:     
            config = {
              chkViewNotification: event.target.checked,
              chkEditNotification: event.target.checked,
              chkViewUserNoti : event.target.checked,
              [name]: event.target.checked
            }
            break;
            case 17:   
            config = {
              chkViewCatalog: event.target.checked,
              chkEditCatalog: event.target.checked,
              chkNewCatalog: event.target.checked,
              chkDeleteCatalog: event.target.checked,
              [name]: event.target.checked
            }
            break; 
            //isanchez 05.05.2019
            case 21:
            config = {
              chkViewAllWork: event.target.checked,
              chkRegisterEditWork: event.target.checked,
              chkRegisterUpgradeWork: event.target.checked,
              chkExtendTermWork: event.target.checked,
              chkReasignWork: event.target.checked,
              chkReEvaulateWork: event.target.checked,
              chkRegisterWorkUserData: event.target.checked,
              chkRegisterEditWorkGeneralData: event.target.checked,
              chkRegisterEditWorkSynopsisData: event.target.checked,
              chkRegisterEditWorkCharactersData: event.target.checked,
              chkRegisterEditWorkDiagramData: event.target.checked,
              chkRegisterEditWorkMaterialData: event.target.checked,
              chkDeleteEvalFilesWorkReg: event.target.checked,
              chkRegisterEvaluationProcess: event.target.checked,
              [name]: event.target.checked
            }
            break;
            break;    
            /*isanchez 03.04.2019 inicio*/
            case 19:         
            config = {
              chkViewParameter: event.target.checked,
              chkEditParameter: event.target.checked,
              [name]: event.target.checked
            }
            break;
            /*isanchez 03.04.2019 fin*/        
            default: 
            break;            
          }

        this.setState(config);
        newMenu.grant_configuration = JSON.stringify(config);       
        newConfiguration.push(newMenu); 
        this.setState({arrayPermissions: newConfiguration});

      }else{      
        this.setState({ [name]: event.target.checked }, () =>{
          switch (this.state.menuSelected) {
            case 11:     
              config = {
                chkViewWork: this.state.chkViewWork,
                chkAddWork: this.state.chkAddWork,
                chkEditWork: this.state.chkEditWork,
                chkWorkUserData:this.state.chkWorkUserData,
                chkEditWorkGeneralData:this.state.chkEditWorkGeneralData,
                chkEditWorkSynopsisData:this.state.chkEditWorkSynopsisData,
                chkEditWorkCharactersData:this.state.chkEditWorkCharactersData,
                chkEditWorkDiagramData:this.state.chkEditWorkDiagramData,
                chkEditWorkMaterialData:this.state.chkEditWorkMaterialData,
                chkUpgradeWork: this.state.chkUpgradeWork,
                chkDeleteEvalFilesWorkAsig:this.state.chkDeleteEvalFilesWorkAsig,
                chkAddEvalFilesWorkAsig:this.state.chkAddEvalFilesWorkAsig,
                chkEvaluationProcess: this.state.chkEvaluationProcess
              }
              break;
              case 12:        
              config = {
                chkViewProfile: this.state.chkViewProfile,
              }
              break;
              case 13:
              config = {
                chkEvalArea: this.state.chkEvalArea,
                chkHistMovement: this.state.chkHistMovement,
                chkHistWork: this.state.chkHistWork,
                chkBuyWork: this.state.chkBuyWork,
                chkWorkAutor: this.state.chkWorkAutor,
                chkStatusContr: this.state.chkStatusContr,
                chkDocStatus: this.state.chkDocStatus,
                chkWorkFlowPayment: this.state.chkWorkFlowPayment,
                chkLitWork: this.state.chkLitWork
              }
              break;
              case 14:     
              config = {
                chkViewUser: this.state.chkViewUser,
                chkEditUser: this.state.chkEditUser,
                chkBlockUser: this.state.chkBlockUser,
                chkUnlockUser: this.state.chkUnlockUser,
                chkDeleteUser: this.state.chkDeleteUser
              }
              break;
              case 15:             
              config = {
                chkViewProfileAdmin: this.state.chkViewProfileAdmin,
                chkEditProfileAdmin: this.state.chkEditProfileAdmin,
                chkCreateProfileAdmin: this.state.chkCreateProfileAdmin,
                chkDeleteProfileAdmin: this.state.chkDeleteProfileAdmin,
                chkShowPrivileges: this.state.chkShowPrivileges,
                chkEditPrivileges: this.state.chkEditPrivileges
              }
              break;
              case 16:     
              config = {
                chkViewNotification: this.state.chkViewNotification,
                chkEditNotification: this.state.chkEditNotification,
                chkViewUserNoti : this.state.chkViewUserNoti
              }
              break;
              case 17:   
              config = {
                chkViewCatalog: this.state.chkViewCatalog,
                chkEditCatalog: this.state.chkEditCatalog,
                chkNewCatalog: this.state.chkNewCatalog,
                chkDeleteCatalog: this.state.chkDeleteCatalog
              }
              break;  
              //isanchez 05.05.2019
              case 21:
              config = {
                chkViewAllWork: this.state.chkViewAllWork,
                chkRegisterEditWork: this.state.chkRegisterEditWork,
                chkRegisterUpgradeWork: this.state.chkRegisterUpgradeWork,
                chkExtendTermWork: this.state.chkExtendTermWork,
                chkReasignWork: this.state.chkReasignWork,
                chkReEvaulateWork: this.state.chkReEvaulateWork,
                chkRegisterWorkUserData: this.state.chkRegisterWorkUserData,
                chkRegisterEditWorkGeneralData: this.state.chkRegisterEditWorkGeneralData,
                chkRegisterEditWorkSynopsisData: this.state.chkRegisterEditWorkSynopsisData,
                chkRegisterEditWorkCharactersData: this.state.chkRegisterEditWorkCharactersData,
                chkRegisterEditWorkDiagramData: this.state.chkRegisterEditWorkDiagramData,
                chkRegisterEditWorkMaterialData: this.state.chkRegisterEditWorkMaterialData,
                chkDeleteEvalFilesWorkReg:this.state.chkDeleteEvalFilesWorkReg,
                chkRegisterEvaluationProcess: this.state.chkRegisterEvaluationProcess
              }
              break;
              break;
              /*isanchez 03.04.2019 inicio*/
              case 19:         
              config = {
                chkViewParameter: this.state.chkViewParameter,
                chkEditParameter: this.state.chkEditParameter
              }
              break;
              /*isanchez 03.04.2019 fin*/              
              default:
              break;             
          }
          
          newMenu.grant_configuration = JSON.stringify(config);       
          newConfiguration.push(newMenu); 
          this.setState({arrayPermissions: newConfiguration});
        });
           
      }  
        
    }   
  };

  handleShowPermissions = (menu_id) =>{  
    this.setState({
      menuSelected: menu_id,
      showConfiguration : this._getPermission(menu_id) 
    }, () => {
      this.movePermissions(menu_id, this._getConfigMenu(menu_id));
    });
  }

  movePermissions= (menu_id,jsonConfiguration) =>{ 
    let data = this.state.menuAcervo.filter(x => x.menu_id == menu_id)[0];
    this.setState({ menuSelected : menu_id , checkedAll:false, chkEditar:false, chkPublicar:false, chkVer:false, chkEliminar:false});

    if(data != undefined){

      let modify = this.state.arrayPermissions.filter(x => x.menu_id === data.menu_id)[0];

      if(modify === undefined){
        this.setState(JSON.parse(data.grant_configuration));
      }else{
        this.setState(JSON.parse(modify.grant_configuration));
      }

    }else{
      let menu = this.state.arrayPermissions.filter(m => m.menu_id == menu_id)[0];
       
      if(menu !== undefined){
        this.setState(JSON.parse(menu.grant_configuration));        
      }else{
        
        let newMenu = {
          grant_configuration:jsonConfiguration,
          menu_id:menu_id,
          grant_id:0
        }

        this.setState(JSON.parse(newMenu.grant_configuration));
        let auxiliar = this.state.arrayPermissions;
        auxiliar.push(newMenu);
        this.setState({arrayPermissions :  auxiliar});

      }
    }
  }

  _getPermission = (menu) => {
    let ASSIGNED, REGISTER;
    if (this.props.profileSelected.rol_id === 19 || this.props.profileSelected.rol_id === 21) {
      ASSIGNED = PERMISSION_ASIGNACIONES.concat(',{"name": "Ver Proceso de Evaluación", "attr":"chkEvaluationProcess"}');
      REGISTER = PERMISSION_REGISTERWORK.concat(', {"name": "Ver Proceso de Evaluación", "attr":"chkRegisterEvaluationProcess"}');
    } else {
      ASSIGNED = PERMISSION_ASIGNACIONES;
      REGISTER = PERMISSION_REGISTERWORK;
    }
    //AHDA 15/04/2019: deleted break an added default case
    switch (menu) {
      case 11: return ASSIGNED;
      case 12: return PERMISSION_PROFILE;
      case 13: return PERMISSION_REPORTS; 
      case 14: return PERMISSION_USERADMIN;
      case 15: return PERMISSION_PROFILEADMIN;
      case 16: return PERMISSION_NOTIFICATION;
      case 17: return PERMISSION_CATALOG;
      case 19: return PERMISSION_PARAMETERS;
      //isanchez 05.05.2019
      case 21: return REGISTER;
      default: 
      break; 
    }
  }

  _getConfigMenu = (menu) => {
    //AHDA 15/04/2019: deleted break an added default case
    switch (menu) {
      case 11: return CONFIG_ASIGNACIONES;
      case 12: return CONFIG_PROFILE;
      case 13: return CONFIG_REPORTS; 
      case 14: return CONFIG_USERADMIN;
      case 15: return CONFIG_PROFILEADMIN; 
      case 16: return CONFIG_NOTIFICATION;
      case 17: return CONFIG_CATALOG; 
      case 19: return CONFIG_PARAMETERS; break;
      //isanchez 05.05.2019
      case 21: return CONFIG_REGISTERWORK;
      default: 
      break; 
      
    }
  }
  
  render() {    
    const {
        profileSelected,
        handleCloseModal,
        openModalPermission,
        classes,
        saveChanges
    } = this.props; 

    //GEO 10/04/2019  
    //Se agrega esta linea para distinguir entre tabs seleccionadas y no seleccionadas.
    const { activeIndex } = this.state;
    
    if(profileSelected==null){
        return false;
    }
    
    return (
      <div>
       <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openModalPermission}
          onClose={handleCloseModal}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <div >
              <Typography className={classes.paperHeader}>
                Privilegios / {profileSelected.name}
              </Typography>
            </div>
            <div className={classes.paperBody}>
              <div
                style={{
                  display: "flex"
                }}
              > 
              {/* CH 21/06/2019 Se cambia overflow de tabs */}
                <VerticalTabs className={classes.overflowVisible} value={activeIndex} onChange={this.handleChange}>
                  {
                    this.state.optionsMenu.map((item, index) =>{
                      return(
                        <MyTab 
                          key={item.menu_id}
                          onClick={() => this.handleShowPermissions(item.menu_id)} 
                          label={item.menu_name}
                          indicatorColor="blue"
                          icon={
                            index === 0 ? <Dashboard /> :
                            index === 1 ? <InsertChart /> :
                            index === 2 ? <Dashboard /> :
                            index === 3 ? <Settings /> :
                            index === 4 ? <AccountCircle /> :
                            index === 5 ? <Settings /> :
                            index === 6 ? <Settings /> :
                            index === 7 ? <Settings /> :
                            //AHDA 28/05/2019 index === 8 ? <Notifications /> :
                            index === 8 ? <List /> :
                            ''
                          }/>
                      );
                    })
                  }

                </VerticalTabs>

                {/* {activeIndex === 0 && */}
                <TabContainer>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.checkedAll}
                          onChange={this.handleChangeChecked('checkedAll')}
                          value="checkedAll"
                          color="primary"
                        />
                      }
                      label="Seleccionar Todo"
                    />
                  </div>
                  <div>
                    <FormGroup>
                        {
                          this.state.showConfiguration === "" ? '' :
                          JSON.parse(`[${this.state.showConfiguration}]`).map((item, index)=>{
                            return (
                              <FormControlLabel
                                key={index.toString()}
                                control={
                                  <Switch
                                    checked={this.state[`${item.attr}`]}
                                    onChange={this.handleChangeChecked(item.attr)}
                                    value={item.attr}
                                    color="primary"
                                  /> 
                                }
                                label={item.name}
                              />
                            )
                          })
                        }
                    </FormGroup>
                  </div>
                </TabContainer>
              </div>
            </div>
            <div className={classes.paperFooter}>
              <Button onClick={()=>{
                handleCloseModal(false);
                this._handleClearCloseModal();
              }} variant="contained" color="primary" type="submit">
                Cerrar
              </Button>
              <Button variant="contained" color="primary" type="submit" disabled={this.state.onlyView} onClick={()=> saveChanges(this.state.arrayPermissions)} >
                Guardar
              </Button>
            </div>
          </div>
        </Modal> 

      </div>
    );
  }
}

ModalPermissions.propTypes = {
  classes: PropTypes.object,
  openModalPermission:PropTypes.bool,
  profileSelected: PropTypes.object,
  handleCloseModal: PropTypes.func,
  optionsMenu:PropTypes.array,
  onlyView : PropTypes.bool,
  saveChanges: PropTypes.func,
};

export default ModalPermissions;
