import { API, Auth } from 'aws-amplify';

export const apiPOST = (path, body, avoidRefresh) => {

    //AHDA 17/05/2019: Added validation for refreshing activity
    if (!avoidRefresh) {
        //console.log(`Entro desde ${path}, ${new Date().getTime()}`);     
        localStorage.setItem("last_activity", new Date().getTime()); //AHDA 16/05/2019
    }
    return new Promise(
        function (resolve, reject) {
            Auth.currentSession().then((response) => {
                let myInit = {
                    body: body,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': response.idToken.jwtToken
                    }
                }
                API.post(process.env.REACT_APP_aws_apigateway_name, path, myInit).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            }
            )
        })

}


export const apiPOSTNotAuth = (path, body, avoidRefresh) => {

    //AHDA 17/05/2019: Added validation for refreshing activity
    if (!avoidRefresh) {
        //console.log(`Entro desde ${path}, ${new Date().getTime()}`);     
        localStorage.setItem("last_activity", new Date().getTime()); //AHDA 16/05/2019
    }
    return new Promise(
        function (resolve, reject) {
            let myInit = {
                body: body,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            API.post(process.env.REACT_APP_aws_apigateway_name, path, myInit).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        })

}


export const apiGET = (path, params, avoidRefresh) => {

    //AHDA 17/05/2019: Added validation for refreshing activity
    if (!avoidRefresh) {
        //console.log(`Entro desde ${path}, ${new Date().getTime()}`);     
        localStorage.setItem("last_activity", new Date().getTime()); //AHDA 16/05/2019
    }

    let myInit = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        queryStringParameters: params
    }

    return new Promise(
        function (resolve, reject) {
            API.get(process.env.REACT_APP_aws_apigateway_name, path, myInit).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        }
    )
}

export const fetchCall = (method, api, data, avoidRefresh) => {

    //AHDA 17/05/2019: Added validation for refreshing activity
    if (!avoidRefresh) {
        //console.log(`Entro desde ${path}, ${new Date().getTime()}`);     
        localStorage.setItem("last_activity", new Date().getTime()); //AHDA 16/05/2019
    }

    return new Promise(
        function (resolve, reject) {

            var myInit = {
                method: method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                mode: 'cors',
                cache: 'default',
                body: JSON.stringify(data)
            };

            var myRequest = new Request(`${window.location.origin}/workflow` + api, myInit);

            fetch(myRequest)
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson);
                }).catch((error) => {
                    reject(error);
                });
        }
    );
}

export const fetchLocal = (file, avoidRefresh) => {

    //AHDA 17/05/2019: Added validation for refreshing activity
    if (!avoidRefresh) {
        //console.log(`Entro desde ${path}, ${new Date().getTime()}`);     
        localStorage.setItem("last_activity", new Date().getTime()); //AHDA 16/05/2019
    }

    return new Promise(
        function (resolve, reject) {
            fetch(file)
                .then((response) => {
                    response.json()
                })
                .then((responseJson) => {
                    resolve(responseJson);
                }).catch((error) => {
                    console.log(error);
                    reject(error);
                })
        }
    )
}

export const returnCurrentUserId = () => {
    let user = localStorage.getItem("current_user");
    if (user !== null) {
        user = JSON.parse(user);
        return user.rdsUser.id_usuario;
    } else {
        return 0;
    }
}

export const returnCurrentUser = () => {
    let user = localStorage.getItem("current_user");
    if (user !== null) {
        user = JSON.parse(user);
        return user.rdsUser;
    } else {
        return undefined;
    }
}

export const updateCurrentUser = (newUser) => {
    let user = localStorage.getItem("current_user");
    if (user !== null) {
        user = JSON.parse(user);
        user.rdsUser.nombre = newUser.nombre;
        user.rdsUser.apellidos = newUser.apellidos;
        user.rdsUser.estatus = newUser.estatus;
        user.rdsUser.id_rol = newUser.id_rol;
        user.rdsUser.key = newUser.avatar;
        user.rdsUser.asigned_profile = newUser.asigned_profile;
        localStorage.setItem("current_user", JSON.stringify(user));
        return true;
    } else {
        return false;
    }
}

// JCMY 07/05/2019
export const apiElasticSearch = (path, body) => {

    let myInit = {
        body: body,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    return new Promise(
        function (resolve, reject) {
            API.post(process.env.REACT_APP_ELASTICNAME, path, myInit).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        }
    )
}