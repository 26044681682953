import pink from '@material-ui/core/colors/pink';
import lightGreen from '@material-ui/core/colors/lightGreen';
import dark from '@material-ui/core/colors/blueGrey';

const styles = theme => ({
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`
  },
  dividerCard: {
    // margin: '0 auto',
    height: 1,
    width: 'calc (100% - 48px)',
    backgroundColor: '#dbdbdb',
    margin: '0 24px',
    [theme.breakpoints.down('sm')]: {
      width: 'calc (100% - 32px)',
    },
  },
  card: {
    minWidth: 275,
  },
  liked: {
    color: pink[500]
  },
  shared: {
    color: lightGreen[500]
  },
  num: {
    fontSize: 14,
    marginLeft: 5
  },
  rightIcon: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    marginRight: theme.spacing.unit
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardPlayer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 150,
    height: 150,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  cardSocmed: {
    minWidth: 275,
  },
  cardNotify: {
    minWidth: 200,
    '& div:first-child': {
      paddingTop: 5,
      paddingBottom: 5
    },
    '& div:nth-child(3)': {
      paddingTop: 10,
      paddingBottom: 10
    },
    '& div:first-child div:nth-child(2)': {
      width: '70%',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
      },
      '& span:first-child': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: 'auto',
        minWidth: 50,
      }
    }
  },
  cardProduct: {
    position: 'relative'
  },
  mediaProduct: {
    height: 0,
    paddingTop: '60.25%', // 16:9
  },
  rightAction: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    marginRight: '0px !important',
  },
  floatingButtonWrap: {
    position: 'relative',
    paddingTop: 50
  },
  buttonAdd: {
    position: 'absolute',
    right: 20,
    top: -20,
  },
  buttonAddList: {
    display: 'none',
    marginLeft: 10
  },
  title: {
    fontSize: 20,
    height: 30,
  },
  ratting: {
    margin: '10px 0',
    '& button': {
      width: 24,
      height: 24
    }
  },
  status: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 10,
    '& > *': {
      margin: 5
    }
  },
  desc: {
    height: 45,
    overflow: 'hidden'
  },
  chipDiscount: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.dark,
  },
  chipSold: {
    background: dark[500],
    color: theme.palette.getContrastText(dark[500]),
  },
  contentProfle: {
    flex: '1 0 auto',
    textAlign: 'center'
  },
  mediaProfile: {
    height: 0,
    paddingTop: '36.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  avatarBig: {
    width: 80,
    height: 80,
    margin: '-56px auto 10px',
    background: theme.palette.secondary.dark
  },
  name: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonProfile: {
    margin: 20
  },
  bottomLink: {
    width: '100%',
  },
  price: {
    paddingBottom: 20
  },
  contentProfile: {
    textAlign: 'center'
  },
  verified: {
    fontSize: 16,
    color: theme.palette.primary.main
  },
  cardList: {
    display: 'flex',
    justifyContent: 'space-between',
    '& $buttonAddList': {
      display: 'inline-block'
    },
    '& $floatingButtonWrap': {
      flex: 1,
    },
    '& $buttonAdd': {
      display: 'none'
    },
    '& $status': {
      right: 'auto',
      left: 0,
    },
    '& $mediaProduct': {
      width: 300,
      paddingTop: '21.25%'
    },
    '& $price': {
      flexDirection: 'column',
      justifyContent: 'center',
      '& button': {
        marginTop: 20
      }
    }
  },
  cardModal: {
    width: '60%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& button': {
      marginRight: '-14px',
      marginTop: 15,
      //position: 'absolute',
      //right: 10,
      //top: 10
    }
  },
  modalSlider: {
    padding: '0 40px'
  },
  alertDialog: {
    "& .confirm": {
      color: 'white',
      backgroundColor: `${theme.palette.primary.main} !important`,
    }
  }
});

export default styles;
