import React from 'react';
import MUIDataTable from 'mui-datatables';
import PapperBlock from '../../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './../reportes-jss'
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';



//Filtros
import Button from '@material-ui/core/Button';
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { LanguageConsumer, LanguageCxt } from './../../../../language/LanguageContext';
import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';
import { renderInput, getSuggestions, renderSuggestion } from './../autocomplete'
import { apiPOST, returnCurrentUserId } from '../../../../services/service_base';
import LoaderFull from '../../../../components/Loader/LoaderFull';
import { textLabelsEs } from '../../../../utils/tableTexts';
import Grid from '@material-ui/core/Grid';

//Iconos
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';

class CurriculumAutor extends React.Component {
  state = {
    //Filters
    author: "",
    fechaInicio: null,
    fechaFin: null,
    //Table
    columns: [],
    data: [],
    authors: [],
    isLoading: true,
    results: 0,
    openError: false,
    errorMessage: "errMsg"
  }

  //DATES
  handleDateChange = date => {
    this.setState({ fechaInicio: date });
  };

  handleDateChangeFin = date => {
    this.setState({ fechaFin: date });
  };

  //Inputs
  handleStateChange = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ author: changes.selectedItem })
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ author: changes.inputValue })
    }
  }

  //AHDA 19/02/2019
  handleClearFilters = () => {
    this.setState({
      author: "",
      fechaInicio: null,
      fechaFin: null,
      isLoading: true
    })

    const request = {
      start_date: "0",
      end_date: "0",
      author: "0"
    }
    this.handleFetch(request);
  }

  //Notificaitons
  handleCloseError = () => {
    this.setState({ openError: false });
  }

  //Download csv
  handleDownload = (data) => {

    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'obras',
      screen: 'reporteCurriculumAutor'
    }
    apiPOST('/audit/add', body);

    //AHDA 02/04/2019
    //Correccion bug, desfase de Excel
    const dataCsv = [["Título de la Obra", "Autor / Nickname", "Tipo de Obra", "Tipo de Contrato", "Vigencia", "Fecha de Compra", "Capítulos", "Precio por capítulo", "Moneda"]]

    for (const x in data) {
      const object = []
      object.push(data[x]);
      dataCsv.push(object);
    }

    const download = document.createElement("a");
    document.body.appendChild(download); //AHDA 23/05/2019
    const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: 'application/vnd.ms-excel' });
    download.href = window.URL.createObjectURL(blob);
    download.download = "Obrasescritor.csv";
    download.click();

  }

  // JCMY 24/01/19
  //Llamada de busqueda
  searchs = () => {

    var fi = this.state.fechaInicio === null ? 0 : new Date(this.state.fechaInicio);
    var ff = this.state.fechaFin === null ? 0 : new Date(this.state.fechaFin)

    // JCMY 27/03/19
    const request = {
      start_date: fi === 0 ? 0 : fi.getDate() + "/" + (fi.getMonth() + 1) + "/" + fi.getFullYear(),
      end_date: ff === 0 ? 0 : ff.getDate() + "/" + (ff.getMonth() + 1) + "/" + ff.getFullYear(),
      author: this.state.author === "" ? "0" : this.state.author === "TODOS" ? "0" : (this.state.author).replace(/([\ \t]+(?=[\ \t])|^\s+|\s+$)/g, '')
    }

    // JCMY 08/02/19
    const ds = new Date(fi);
    const df = new Date(ff);

    if (ds.getTime() > df.getTime()) {
      this.setState({ openError: true, isLoading: false, errorMessage: "dateError" });
    } else {
      this.setState({ isLoading: true })
      this.handleFetch(request);
    }
  }

  componentDidMount() {

    const { lang } = this.context;
    this.setState({
      columns: [
        lang.reports.title, lang.reports.author, lang.reports.playType, lang.reports.contractType,
        lang.reports.validity, lang.reports.purchaseDate, lang.reports.chapterNumber, lang.reports.chapterPrice, lang.reports.currency
      ]
    });

    //Catalogo autores
    //AHDA 17/05/2019: added validation for refresh 
    apiPOST('/catalogos/getCatalogo', { idCatalogo: 18 }, true).then(response => {
      if (response.status === 200) {
        this.setState({ authors: response.data })
      }
    });

    //Carga inicial
    const request = {
      start_date: "0",
      end_date: "0",
      author: "0"
    }
    this.handleFetch(request);
  }

  //AHDA 19/02/2019
  handleFetch = (request) => {
    apiPOST('/reportes/authorCurriculum', request).then(response => {
      if (response.status === 200) {
        const result = [];
        for (const x in response.data) {
          var obj = [];

          
          //AHDA 10/05/2019
          //Added validations
          const item = response.data[x]; 
          obj.push(item.title ? item.title : "-");
          // JCMY 03/07/2019 incidencias 1028
          obj.push(!item.autor ? "-" : item.autor.replace(/,/g, '/') ? item.autor.replace(/,/g, '/'): "-");
          obj.push(!item.play_type ? "-" : item.play_type === "adaptacion" ? "Adaptación" : "Primigenia");
          obj.push(item.contract_type ? item.contract_type : "-");
          obj.push(item.validity ? item.validity : "-");
          obj.push(item.purchase_date ? item.purchase_date : "-");
          obj.push(item.total_chapters ? item.total_chapters : "-");
          obj.push(!item.price_perChapter ? "-" : item.price_perChapter.replace(/,/g, '') ? item.price_perChapter.replace(/,/g, ''): "$0");
          obj.push(item.currency ? item.currency : "-");
          result.push(obj);
        }
        this.setState({ data: result, isLoading: false, results: response.data.length })
      } else {
        this.setState({ openError: true, isLoading: false, errorMessage: "errMsg" });
      }
    }).catch(error => {
      this.setState({ openError: true, isLoading: false, errorMessage: "errMsg" });
    });
  }

  render() {
    const { classes } = this.props;
    const { columns, data } = this.state;
    const options = {
      filterType: 'multiselect',
      responsive: 'scroll',
      download: false,
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs,
      customToolbar: () => {
        return <LanguageConsumer>
          {
            ({ lang }) =>
              <Tooltip title={lang.reports.print} placement="bottom">
                {/* JCMY 26/03/2019 */}
              {/* Cambiaron la variable y con eso dejo de imprimir el reporte */}
                <IconButton aria-label="Imprimir" onClick={() => this.handleDownload(this.state.data)}>
                  <CloudDownload />
                </IconButton>
              </Tooltip>
          }
        </LanguageConsumer>
      }
    };
    return (
      <div className="marginTopLogo">
        <LanguageConsumer>
          {
            ({ lang }) =>
              <PapperBlock title={lang.reports.reports} whiteBg desc="">
                {this.state.isLoading ? <LoaderFull /> : ''}
                {/* Inicio Filtros */}
                <form className={classes.container}>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    <Grid item xs={6} sm={4} md={4}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          keyboard={false}
                          helperText={lang.reports.startDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={this.state.fechaInicio}
                          onChange={this.handleDateChange}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          keyboard={false}
                          helperText={lang.reports.endDate}
                          className={classes.formControl}
                          format="DD/MM/YYYY"
                          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                          value={this.state.fechaFin}
                          onChange={this.handleDateChangeFin}
                          disableOpenOnEnter
                          animateYearScrolling={false}
                          disableFuture={true}
                          clearable={true}
                          minDate={this.state.fechaInicio}
                          disabled={this.state.fechaInicio == null ? true : false}
                          cancelLabel={lang.common.cancel}
                          clearLabel={lang.common.clear}
                          placeholder="DD/MM/AAAA"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <div className={classes.root}>
                        <Downshift selectedItem={this.state.author} onStateChange={this.handleStateChange}>
                          {({
                            getInputProps,
                            getItemProps,
                            isOpen,
                            inputValue,
                            selectedItem,
                            highlightedIndex
                          }) => (
                              <div className={classes.formControl}>
                                {renderInput({
                                  fullWidth: true,
                                  classes,
                                  helperText: lang.reports.authors,
                                  InputProps: getInputProps({
                                    id: 'author',
                                    name: 'author',
                                  }),
                                })}
                                {isOpen ? (
                                  <Paper className={classes.paper} square>
                                    {getSuggestions(inputValue, this.state.authors).map((suggestion, index) =>
                                      renderSuggestion({
                                        suggestion,
                                        index,
                                        itemProps: getItemProps({ item: suggestion.label }),
                                        highlightedIndex,
                                        selectedItem,
                                      }),
                                    )}
                                  </Paper>
                                ) : null}
                              </div>
                            )}
                        </Downshift>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={24}
                    direction="row"
                    justify="flex-start"
                    alignItems="center">
                    {/* CH 05/04/2019
                    Se centran botones en versión móvil */}
                    <Grid item xs={12} sm={12} md={12} className={classes.centerOnlySmall}>
                      <Button onClick={this.searchs} variant="contained" color="primary" className={classes.button}>
                        {lang.common.search}
                      </Button>
                      <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                        Limpiar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                {/* Fin Filtros */}
                <div className={classes.bitacoraMov}>
                  <MUIDataTable
                    title={`${lang.reports.repWorksPerAuthor} (${this.state.results} ${lang.reports.results})`}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </div>

                {/* JCMY 08/02/19 */}
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.openError}
                  autoHideDuration={6000}
                  onClose={this.handleCloseError}>
                  <CustomNotification
                    onClose={this.handleCloseError}
                    variant="error"
                    message={lang.common[this.state.errorMessage]} />
                </Snackbar>
              </PapperBlock>
          }
        </LanguageConsumer>
      </div>
    );
  }
}

CurriculumAutor.contextType = LanguageCxt;

export default withStyles(styles)(CurriculumAutor);
