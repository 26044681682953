module.exports = {
    palette: {
        primary: {
            main: '#ff5807',
        },
        secondary: {
            main: 'rgba(254, 85, 3, 0.8)',
        },
        warning: {
            main: '#fcca15',
        },
    },
    overrides: {
        MuiAppBar: {
            root: {
                background: 'linear-gradient(93.01deg, #F62E1F 0.18%, #FF5702 49.51%, #F68E5C 98.85%)',
            },
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
            root: {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
        },
        MUIDataTableSearch: {
            main: {
                '@media (max-width: 600px)': {
                    justifyContent: 'center',
                },
            },
        },
        MUIDataTableHeadCell: {
            root: {
                minWidth: '180px',
            },
        },
        MUIDataTableToolbar: {
            filterPaper: {
                maxWidth: '100%',
            },
        },
        MUIDataTableSelectCell: {
            root: {
                backgroundColor: 'white',
            },
        },
        MUIDataTableFilter: {
            root: {
                '@media (max-width: 470px)': {
                    width: 285,
                },
                '& ul': {
                    //  width: '480px',
                    // backgroundColor: 'red',
                    '@media (max-width: 470px)': {
                        flexDirection: 'column',
                        width: 260,
                        '& li': {
                            width: '100% !important',
                        },
                    },
                },
            },
            selectFormControl: {
                marginRight: '24px',
                marginBottom: '12px',
                // '& label':{
                //   fontSize: "14px"
                // },
                // '& div':{
                //   fontSize: "12px"
                // }
            },
        },
        MuiList: {
            root: {
                maxHeight: '480px',
                maxWidth: '420px'
            }
        },
        MuiMenuItem: {
            root: {
                whiteSpace: 'inherit',
                minWidth: '240px',
            }
        }
    },
};
