import React, { Component } from 'react';
import { Router } from "react-router-dom";
import createHistory from 'history/createBrowserHistory';
import { renderRoutes } from 'react-router-config';
import routes from "./app/routes/index"; 
import {LanguageProvider} from './app/language/LanguageContext'; 
import langstr from './app/language/lang'; 
import Amplify, { Auth } from 'aws-amplify';
import {awscognito, awsapi, awsstorage, OAuth} from './config/cognito'; 

//Styles
import './app/styles/normalize.scss';
import './app/styles/layout/_base.scss';

const history = createHistory();


Amplify.configure({
  Auth: awscognito, 
  API: awsapi, 
  Storage: awsstorage
});

Auth.configure({ oauth: OAuth });

class App extends Component {
  render() {
    return (
      <LanguageProvider value={{lang: langstr}}>
        <Router history={history}>
          {renderRoutes(routes)}
        </Router>
      </LanguageProvider>
    );
  }
}

export default App;
