import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import styles from './calendar-jss';
import './styleCalendar.css';
import DetailEvent from './DetailEvent'; 
import Toolbar from 'react-big-calendar/lib/Toolbar';

import {LanguageConsumer} from '../../language/LanguageContext'; 

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

function Event(event) {
  return (
    <span className="eventBlock">{event.title}</span>
  );
}

class CustomToolBar extends Toolbar {
  
  navigate = action => {
    this.props.onNavigate(action)
  }

  render(){
    return(
      <LanguageConsumer>
        {
          ({lang}) => 
          <div className="rbc-toolbar">
            <div className="rbc-btn-group">
              <button type="button" onClick={() => this.navigate('PREV')}>{lang.calendar.prev}</button>
              <button type="button" onClick={() => this.navigate('TODAY')}>{lang.calendar.today}</button>
              <button type="button" onClick={() => this.navigate('NEXT')}>{lang.calendar.next}</button>
            </div>
            <div className="rbc-toolbar-label">{this.props.label}</div>
            <div className="rbc-btn-group">
              <button type="button" onClick={this.view.bind(null, 'month')}>{lang.calendar.month}</button>
              <button type="button" onClick={this.view.bind(null, 'agenda')}>{lang.calendar.agenda}</button>
            </div>
          </div>
        }
      </LanguageConsumer>
    )
  }
}

class EventCalendar extends React.Component {
  
  constructor(props){
    super(props); 
    this.state = {
      anchorEl: false, 
      anchorPos: { top: 0, left: 0 }, 
      selectedEvent: null
    }
  }

  handleEventClick = (selectedEvent) => {
    setTimeout(() => {
      const target = document.getElementsByClassName('rbc-selected')[0];
      const targetBounding = target.getBoundingClientRect();
    
      this.setState({
        anchorEl: true, 
        selectedEvent: selectedEvent, 
        anchorPos: { top: targetBounding.top, left: targetBounding.left }})
    }, 200);
  }

  handleEventClose = () => {
    this.setState({anchorEl: false})
  }

  eventStyleGetter = event => {
    //const backgroundColor = '#' + event.hexColor;
    const backgroundColor = '#ffced3';
    const style = {
      backgroundColor,
    };
    return {
      style
    };
  }

  render() {
    const {
      classes,
      events
    } = this.props;
    return (
      <Paper className={classes.root}>
        <LanguageConsumer>
          {
            ({lang}) => 
            <BigCalendar
              className={classes.calendarWrap}
              events={events}
              defaultView={BigCalendar.Views.MONTH}
              step={60}
              showMultiDayTimes
              popup
              scrollToTime={new Date(1970, 1, 1, 6)}
              onSelectEvent={(selectedEvent) => this.handleEventClick(selectedEvent)}
              eventPropGetter={(this.eventStyleGetter)}
              components={{
                event: Event,
                toolbar : CustomToolBar
              }}
              views={{ month: true, agenda: true }}
              messages={
                {
                  date: lang.calendar.date, 
                  time: lang.calendar.time, 
                  event: lang.calendar.event
                }
              }
            />
          }
        </LanguageConsumer>
        <DetailEvent 
        anchorEl={this.state.anchorEl} 
        anchorPos={this.state.anchorPos}
        event={this.state.selectedEvent}
        close={this.handleEventClose}
        monthNames={this.props.monthNames}/>
      </Paper>
    );
  }
}

EventCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  handleEventClick: PropTypes.func,
};

EventCalendar.defaultProps = {
  handleEventClick: () => {}
}

export default withStyles(styles)(EventCalendar);
