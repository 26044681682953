const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      overflowX: 'auto',
    },
    table: {
      minWidth: 300,
    },
    tableHead: {
        background: '#f5f5f5',
    },
    stripped: {
      '& tbody tr:nth-child(even)': {
        background: '#f5f5f5'
    }
    },
    button: {
        margin: theme.spacing.unit,
      },
    inputUpload: {
      display: 'none',
    },
  });



  export default styles;