import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Add, CloudDownload, Share, Visibility } from "@material-ui/icons";
import classNames from "classnames";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Button, ButtonAction, SearchDropdownDialog } from "../../../atoms";
import MaterialDropZone from "../../../components/Forms/MaterialDropZone";
import { getFile, getFileDownload } from "../../../services/storage";
import { getSpanishMonthName } from "../../../utils/formatDateHelper";
import { textLabelsEs } from "../../../utils/tableTexts";
import styles from "./registroObra-jss";

const StepProcesoEvaluacion = ({
  fileSizes,
  classes,
  data,
  onDropFile,
  filesEvaluationProcess,
  handleViewItem,
  usersSpecialists,
  handleSendFieldSharedEmail,
  onRemove,
  fileTotal,
  sizeTotal
}) => {
  const [uploadFiles, setUploadFiles] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [dataFilterd, setDataFilterd] = useState(data);
  const [yearDates, setYearDates] = useState([]);
  const [nameEvaluators, setNameEvaluators] = useState([]);
  const [yearSelected, setYearSelected] = useState();
  const [nameEvaluatorSelected, setNameEvaluatorSelected] = useState("");
  const [alertFile, setAlertFile] = useState("");
  const [nameFile, setNameFile] = useState("");
  const [formatFile, setFormatFile] = useState("");
  const [materialSelecteds, setMaterialSelecteds] = useState([]);
  // let materialSelecteds = [];

  const filterYears = () => {
    let dates = data.map((item) =>
      parseInt(moment(item.delivery_date).format("YYYY"))
    );
    let uniques = [];
    dates.map((item) => (!uniques.includes(item) ? uniques.push(item) : null));
    setYearDates(uniques);
  };
  const filterEvaluators = () => {
    let evaluators = data.map((item) => `${item.nombre} ${item.apellidos}`);
    let uniques = [];
    evaluators.map((item) =>
      !uniques.includes(item) ? uniques.push(item) : null
    );
    setNameEvaluators(uniques);
  };
  useEffect(() => {
    filterYears();
    filterEvaluators();
  }, [data]);

  const handleDownload = async (file, name) => {
    await getFileDownload(file.ruta, "public")
      .then((response) => {
        const url = URL.createObjectURL(new Blob([response.Body]));
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      name: "filename",
      label: "Nombre del archivo",
    },
    {
      name: "date",
      label: "Fecha de publicación",
    },
    {
      name: "specialist",
      label: "Especialista evaluador",
    },
    {
      name: "actions",
      label: "Acciones",
      options: {
        customHeadRender: () => "",
        customBodyRender: (value, tableMeta) => {
          // console.log(value, tableMeta);
          return (
            <>
              <ButtonAction
                color="none"
                className={classes.pevTableAction}
                // onClick={() => handleDownloadItem(value)}
                onClick={() => handleDownload(value, tableMeta.rowData[0])}
              >
                <CloudDownload />
              </ButtonAction>
              <ButtonAction
                color="none"
                className={classes.pevTableAction}
                onClick={() => handleViewItem(value)}
              >
                <Visibility />
              </ButtonAction>
            </>
          );
        },
        filter: false,
      },
    },
  ];

  const changeSelecteds = (data) => {
    //required for change state out table flow
    if (JSON.stringify(data) !== JSON.stringify(materialSelecteds))
      setMaterialSelecteds(data);
  };

  const options = {
    filterType: "dropdown",
    responsive: "scroll",
    fixedHeader: true,
    download: false,
    print: false,
    rowsPerPage: 10,
    page: 0,
    selectableRows: true,
    textLabels: textLabelsEs,
    isRowSelectable: (dataIndex, selectedRows) => {
      changeSelecteds(selectedRows.data);
      return true;
    },
    rowsSelected: materialSelecteds.map((item) => item.index),
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return <></>;
    },
    customSort: (data, colIndex, order) => {
      if (order == "asc") {
        return data.sort((a, b) => {
          return (
            (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) *
            (order === "desc" ? 1 : -1)
          );
        });
      } else {
        return data.sort((a, b) => {
          return (
            (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) *
            (order === "asc" ? 1 : -1)
          );
        });
      }
    },
  };

  const cleanAdvancedFilters = () => {
    setFormatFile("");
    setNameFile("");
    setYearSelected();
    setNameEvaluatorSelected("");
    setDataFilterd(data);
  };
  const filterAdvanced = () => {
    let dataFilted = data;
    if (yearSelected) {
      dataFilted = dataFilted.filter(
        (item) =>
          parseInt(moment(item.delivery_date).format("YYYY")) === yearSelected
      );
    }
    if (nameEvaluatorSelected !== "") {
      dataFilted = dataFilted.filter((item) => {
        if (`${item.nombre} ${item.apellidos}`.includes(nameEvaluatorSelected))
          return item;
      });
    }
    if (nameFile !== "") {
      dataFilted = dataFilted.filter((item) => {
        const routeUntilSlash = item.ruta.slice(item.ruta.lastIndexOf("/"));
        const nameFileExtract = routeUntilSlash.slice(
          routeUntilSlash.indexOf("_") + 1
        );
        if (nameFileExtract.includes(nameFile)) return item;
      });
    }
    if (formatFile !== "") {
      dataFilted = dataFilted.filter((item) => {
        const routeUntilSlash = item.ruta.slice(item.ruta.lastIndexOf("/"));
        const formatFileExtract = routeUntilSlash.slice(
          routeUntilSlash.indexOf(".") + 1
        );
        if (formatFileExtract.includes(formatFile)) return item;
      });
    }
    setDataFilterd(dataFilted);
  };

  const shareFilestoSelected = async (value) => {

    let filestoShare = [];
    if (materialSelecteds.length === 0) {
      // all share
      //filestoShare = data;
    } else {
      materialSelecteds.map((item) => {
        filestoShare.push(data[item.index]);
      });
    }

    // console.log(filestoShare);
    // get files selecteds
    // let routesNoFirmed = filestoShare
    //   .map((item) => item.ruta)
    //   .map((item) => getFile(item, "public"));
    // await Promise.all(routesNoFirmed).then(async (responses) => {
    // let routesSigneds = responses.map((route) => encodeURIComponent(route));
    await handleSendFieldSharedEmail(
      "process",
      filestoShare.map(
        (item) => window.location.origin + "/file?doc=" + item.ruta
      ),
      parseInt(value.value)
    );
    // });
    // if(checks)
    //end files selecteds
    setShareDialogOpen(false);
  };
  return (
    <>
      <Grid container spacing={16}>
        <Grid item md={3} sm={3} xs={12}>
          <FormControl
            className={classNames(classes.formControl, classes.w100)}
          >
            <InputLabel shrink>Año de publicación de dictamen</InputLabel>
            <Select
              value={yearSelected}
              onChange={(e) => setYearSelected(e.target.value)}
              displayEmpty
              className={classes.selectEmpty}
              InputLabelProps={{ shrink: true }}
            >
              {yearDates.map((item, i) => (
                <MenuItem value={item} key={i}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Año de publicación de dictamen</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <FormControl
            className={classNames(classes.formControl, classes.w100)}
          >
            <InputLabel shrink>Nombre del evaluador</InputLabel>
            <Select
              value={nameEvaluatorSelected}
              onChange={(e) => setNameEvaluatorSelected(e.target.value)}
              displayEmpty
              className={classes.selectEmpty}
              InputLabelProps={{ shrink: true }}
            >
              {nameEvaluators.map((item, i) => (
                <MenuItem value={item} key={i + 1}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Especialista evaluador</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <FormControl
            className={classNames(classes.formControl, classes.w100)}
          >
            <InputLabel shrink>Nombre del archivo</InputLabel>
            <Input
              value={nameFile}
              onChange={(e) => setNameFile(e.target.value)}
              displayEmpty
              className={classes.selectEmpty}
              InputLabelProps={{ shrink: true }}
            />
            <FormHelperText>Nombre del archivo</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <FormControl
            className={classNames(classes.formControl, classes.w100)}
          >
            <InputLabel shrink>Formato del archivo</InputLabel>
            <Input
              value={formatFile}
              onChange={(e) => setFormatFile(e.target.value)}
              displayEmpty
              className={classes.selectEmpty}
              InputLabelProps={{ shrink: true }}
            />
            <FormHelperText>Formato del archivo</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={16}
        className={classes.actionButtons}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={12}>
          <Button onClick={() => filterAdvanced()}>Buscar</Button>
          <Button
            className={classes.buttonSpacings}
            onClick={() => cleanAdvancedFilters()}
          >
            Limpiar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={16}>
        <Grid
          item
          md={8}
          sm={8}
          xs={12}
          className={classNames(classes.flexRow, classes.flexAlignCenter)}
        >
          <Typography
            variant="body2"
            component={"span"}
            className={classes.pevRevisionTitle}
          >
            Revisión de Dictamen de Evaluación
          </Typography>

          <ButtonAction
            color="none"
            className={classes.pevTableAction}
            onClick={() => setShareDialogOpen(true)}
          >
            <Share />
          </ButtonAction>

          <SearchDropdownDialog
            label="Nombre"
            open={shareDialogOpen}
            options={usersSpecialists.map((item, key) => {
              return { key, ...item, label: item.data };
            })}
            onClose={() => setShareDialogOpen(false)}
            onSelect={shareFilestoSelected}
          />
        </Grid>
        <Grid item md={4} sm={4} xs={12} className={classes.pevCargarArchivo}>
          <Button onClick={() => setUploadFiles(!uploadFiles)}>
            <Add /> Cargar Archivo
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={16} className={classes.pevDataTable}>
        {!uploadFiles && (
          <Grid item md={12} sm={12} xs={12}>
            <MUIDataTable
              data={
                dataFilterd.map((file) => {
                  const { ruta, delivery_date, nombre, apellidos } = file;

                  const routeUntilSlash = ruta.slice(ruta.lastIndexOf("/"));
                  const nameFile = routeUntilSlash.slice(
                    routeUntilSlash.indexOf("_") + 1
                  );
                  const date = new Date(delivery_date);
                  const dateFormatted = `${date.getDate()} ${getSpanishMonthName(
                    date.getMonth() + 1
                  )} ${date.getFullYear()}`;

                  return {
                    filename: nameFile,
                    date: dateFormatted,
                    specialist: `${nombre} ${apellidos}`,
                    actions: file,
                  };
                })
                // [
                // {
                //   filename: 1,
                //   date: moment().toString(),
                //   specialist: 1,
                // }, ]
              }
              columns={columns}
              options={options}
            />
          </Grid>
        )}
        {uploadFiles && (
          <Grid item md={12} sm={12} xs={12}>
            <FormControl error fullWidth>
              <MaterialDropZone
                onDrop={(files) => {
                  let byte = 1000000000;
                  if (alertFile !== "") setAlertFile("");

                  if ((filesEvaluationProcess.length + files.length) > fileTotal) {
                    setAlertFile("moreFiles");
                    return;
                  }
                  let total = 0;
                  files = files.map((file) => {
                    const formatFileExtract = file.name.split(".")[file.name.split(".").length - 1];
                    const fileSize = fileSizes.find(
                      (item) => item.type === formatFileExtract
                    );
                    if (!fileSize) {
                      setAlertFile("noFormat");
                      return;
                    }
                    total = total + file.size;
                    if (
                      fileSize &&
                      file.size > parseInt(fileSize.size) * byte
                    ) {
                      setAlertFile("size");
                      return;
                    }

                    return file;
                  });
                  filesEvaluationProcess.forEach(({size})=>total = total + size)
                  if(total > sizeTotal * byte){
                    setAlertFile("totalsize");
                    return false;
                  }
                  files = files.filter((item) => item);
                  if (files.length > 0)
                    onDropFile(files, "filesEvaluationProcess");
                }}
                onRemove={onRemove}
                files={filesEvaluationProcess}
                showPreviews
                acceptedFiles={["application/pdf"]}
                // maxSize={54760833024}
                filesLimit={1}
                text="Arrastra tu archivo o da click aquí"
              />
              {alertFile === "size" && (
                <FormHelperText>
                  El tamaño del archivo excede el límite permitido
                </FormHelperText>
              )}
              {alertFile === "totalsize" && (
                <FormHelperText>
                  El tamaño de los archivos excede el límite total permitido por carga
                </FormHelperText>
              )}
              {alertFile === "noFormat" && (
                <FormHelperText>
                  El tipo de archivo no es permitido
                </FormHelperText>
              )}
              {alertFile === "moreFiles" && (
                <FormHelperText>
                  Excediste el total de archivos permitidos
                </FormHelperText>
              )}
              {/* <FormHelperText>
                Excediste el total de archivos permitidos
              </FormHelperText> */}
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  );
};

StepProcesoEvaluacion.propTypes = {
  update: PropTypes.func.isRequired,
};

export default withStyles(styles)(StepProcesoEvaluacion);
