import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import { apiPOST, returnCurrentUserId } from '../../services/service_base'

//Styles
import styles from './nuevaObra-jss';

//Stepper
import NuevaObra from './NuevaObra';
import MisAsignaciones from '../../routes/Pages/ObrasRegistradas/MisAsignaciones';


function Transition(props) {
  return <Slide direction="up" {...props} />;
}


class NewWorkDialog extends React.Component {
  constructor(props) {
    super(props);

    this.handledGetRegisteredWorks = this.handledGetRegisteredWorks.bind(this)

    this.state = {
      data: []
    }
  }

  handledGetRegisteredWorks() {
    apiPOST('/works/getregisteredworks').then(
      response => {
        if (response.status === 200) {
          const result = [];
          for (const x in response.data) {
            var obj = [];
            obj.push(response.data[x].id_obras);
            obj.push(response.data[x].empresa);
            obj.push(response.data[x].titulo);
            obj.push(response.data[x].autores);
            obj.push(response.data[x].pais);
            obj.push(response.data[x].fechaRegistro);
            obj.push(response.data[x].usuarioRegistro);
            obj.push(response.data[x].genero);
            obj.push(response.data[x].fuente);
            obj.push(response.data[x].etapa);
            obj.push(response.data[x].estatus);
            obj.push(response.data[x].usuarioAsignado);
            result.push(obj);
          }
          this.setState({ data: result });
        }
        else {
        }


      }
    ).catch(
      err => {
        console.log(err);
      }
    );
  }


  componentDidMount() {
    // Obtengo obras    
    //this.handledGetRegisteredWorks();
    let config = localStorage.getItem("user_permissions") ? JSON.parse(localStorage.getItem("user_permissions")).find(x => x.menu_id === 11) : undefined;
    let configPermission = "";
    if (config !== undefined) {
      configPermission = JSON.parse(config.grant_configuration);
      this.setState({
        chkDeleteEvalFilesWorkAsig: configPermission.chkDeleteEvalFilesWorkAsig ? configPermission.chkDeleteEvalFilesWorkAsig : false,
        chkAddEvalFilesWorkAsig: configPermission.chkAddEvalFilesWorkAsig ? configPermission.chkAddEvalFilesWorkAsig : false,
        // Interla Registered Word Actions Permissions

        chkWorkUserData: configPermission.chkWorkUserData ? configPermission.chkWorkUserData : false,
        chkEditWorkGeneralData: configPermission.chkEditWorkGeneralData ? configPermission.chkEditWorkGeneralData : false,
        chkEditWorkSynopsisData: configPermission.chkEditWorkSynopsisData ? configPermission.chkEditWorkSynopsisData : false,
        //chkViewWorkCharactersData: configPermission.chkViewWorkCharactersData ? configPermission.chkViewWorkCharactersData : false,
        chkEditWorkCharactersData: configPermission.chkEditWorkCharactersData ? configPermission.chkEditWorkCharactersData : false,
        chkEditWorkDiagramData: configPermission.chkEditWorkDiagramData ? configPermission.chkEditWorkDiagramData : false,
        chkEditWorkMaterialData: configPermission.chkEditWorkMaterialData ? configPermission.chkEditWorkMaterialData : false,
        chkEvaluationProcess: configPermission.chkEvaluationProcess ? configPermission.chkEvaluationProcess : false
      }, () => {
        // Obtengo obras    
        this.handledGetRegisteredWorks();
      });
    }
  }


  render() {
    /*isanchez 26,03,19

      se agregaron las propiedades 
      canAddWork,
      canEditWork,
      canUpgradeWork

    */
    //  JCMY 03/05/2019
    //AHDA 29/05/2019: Added screen, currentRol, selected, isAssignation
    const {
      classes,
      openStep,
      handleCloseStep,
      workId,
      canAddWork,
      canEditWork,
      canUpgradeWork,
      rol,
      getRegisteredWorks,
      screen,
      currentRol,
      selected,
      isAssignation
    } = this.props;
    
    return (
      <Dialog fullScreen open={openStep} onClose={handleCloseStep} TransitionComponent={Transition}>


        <AppBar className={classes.appBar}>
          <Toolbar>
            {/*AHDA 28/05/2019: Changed render, deleted validation*/}
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {workId == 0 ? "Nueva Obra" : selected ? selected : "Edición de obra"}
            </Typography>
            <IconButton color="inherit" onClick={handleCloseStep} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {/*AHDA 20/05/2019: Added screen, currentRol*/}
        {
          rol === 12 ?
            <NuevaObra
              open={openStep}
              onClose={handleCloseStep}
              workId={workId}
              canAddWork={canAddWork}
              canEditWork={canEditWork}
              canUpgradeWork={canUpgradeWork}
              screen={screen}
              currentRol={rol}
              canEditWorkSynopsisData={this.state.chkEditWorkSynopsisData}
            />
            :
            workId === 0 || isAssignation ?
              // JCMY 13/09/2019
              <NuevaObra
                open={openStep}
                onClose={handleCloseStep}
                workId={workId}
                canAddWork={canAddWork}
                canEditWork={canEditWork}
                canUpgradeWork={canUpgradeWork}
                screen={screen}
                currentRol={rol}
                canEditWorkSynopsisData={this.state.chkEditWorkSynopsisData}
              />
              :
              // JCMY 02/07/2019 incidencia 747
              <MisAsignaciones
                onClose={handleCloseStep}
                workId={workId}
                getRegisteredWorks={getRegisteredWorks}
                canAddWork={canAddWork}
                canEditWork={canEditWork}
                canEditWorkUserData={this.state.chkWorkUserData}
                canEditWorkGeneralData={this.state.chkEditWorkGeneralData}
                canEditWorkSynopsisData={this.state.chkEditWorkSynopsisData}
                canEditWorkCharactersData={this.state.chkEditWorkCharactersData}
                canEditWorkDiagramData={this.state.chkEditWorkDiagramData}
                canEditWorkMaterialData={this.state.chkEditWorkMaterialData}
                canDeleteEvalFilesWorkAsig={this.state.chkDeleteEvalFilesWorkAsig}
                canAddEvalFilesWorkAsig={this.state.chkAddEvalFilesWorkAsig}
                canUpgradeWork={canUpgradeWork}
                canSeeEvaluationProcess={this.state.chkEvaluationProcess}
                screen={screen}
              />
        }

      </Dialog>
    )
  }
}

//AHDA 29/05/2019
//Added screen, isAssignation
NewWorkDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  openStep: PropTypes.bool.isRequired,
  handleCloseStep: PropTypes.func.isRequired,
  workId: PropTypes.number,
  canAddWork: PropTypes.bool,
  canEditWork: PropTypes.bool,
  canUpgradeWork: PropTypes.bool,
  rol: PropTypes.number,
  getRegisteredWorks: PropTypes.func,
  screen: PropTypes.string,
  selected: PropTypes.string,
  isAssignation: PropTypes.bool
}

//AHDA 29/05/2019
//Added isAssignation
NewWorkDialog.defaultProps = {
  handleCloseStep: () => { },
  selected: "",
  isAssignation: false
}

export default withStyles(styles)(NewWorkDialog);