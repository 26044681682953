import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Close from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { Player, BigPlayButton } from 'video-react';
import { S3Image } from 'aws-amplify-react';
import Modal from '@material-ui/core/Modal';


//styles 
import styles from './../CardPaper/cardStyle-jss'; 
import './../CardPaper/style.css'
import 'video-react/dist/video-react.css'


class MediaModal extends React.Component{
    
  state = {
    media: []
  }

  handleBeforeChange = (oldIndex, newIndex) => {
    if(this.props.media[oldIndex].mediaType === "video"){
      this.refs[`video${oldIndex}`].pause();
    }
  }

  render(){

    const { handleCloseModal, classes, media, openModal } = this.props; 

    return(
        <Modal
        aria-labelledby="media-modal"
        aria-describedby=""
        open={openModal}
        onClose={handleCloseModal}
        className="modalArrows dark"
        >
            <div className={classes.cardModal}>
                <Button  className={classNames(classes.button, classes.rightAction )} variant="fab" mini aria-label="Delete" color="secondary" onClick={handleCloseModal}>
                <Close />
                </Button >
                {
                    media.map((material, index) => (
                        <div className={classes.modalSlider} key={`material${index}`}>
                        {/* GEO 11/04/2019
                        Se agregan estilos para que las imágenes tengan un alto máximo definido y se vean completas en el slider. */}
                        
                        {
                            
                            material.type === 'video' ? 
                                <Player
                                playsInline={false}
                                preload="metadata"
                                src={`${material.url}#t=0.5`}
                                ref={`video${index}`}>
                                <BigPlayButton position="center" />
                                </Player> : 
                                <S3Image theme={{ photoImg: { width: '964px', maxHeight: '600px', objectFit: 'contain' } }} imgKey={material.url} />
                        }
                        </div>
                    ))
                }
            </div>
        </Modal> 
    )
  }
}

MediaModal.propTypes = {
  classes: PropTypes.object,
  handleCloseModal : PropTypes.func.isRequired, 
  media: PropTypes.array.isRequired
}

MediaModal.defaultProps = {
  openModal : false, 
  media: []
}

export default withStyles(styles)(MediaModal); 