const registeredStep = [
  { "value": "1", "data": "Asignada" },
  { "value": "2", "data": "Más material" },
  { "value": "3", "data": "Solicitar ajustes" }
];
const moreMaterialStep = [
  { "value": "1", "data": "Asignada" }
];
// const checkMoreMaterialStep = [
//   // { "value": "0", "data": "No viable" }, 
//   // { "value": "1", "data": "En evaluación" }, 
//   { "value": "2", "data": "Registrada" }, 
//   // { "value": "3", "data": "En proceso de compra" },
//   // { "value": "4", "data": "Asignada" },  
// ];
const requestAdjustmentsStep = [
  { "value": "1", "data": "Asignada" },
  { "value": "5", "data": "Compra directa" }
];
const assignedStep = [
  { "value": "0", "data": "No viable" }, 
  { "value": "1", "data": "Preevaluación" }, 
  { "value": "2", "data": "Más material" }, 
  { "value": "3", "data": "Solicitar ajustes" }, 
  { "value": "8", "data": "Asignar evaluadores" }
  // { "value": "5", "data": "Compra directa" }
];
const inEvaluationStep = [
  { "value": "0", "data": "No viable" }, 
  { "value": "2", "data": "Con potencial" },
  { "value": "3", "data": "Con potencial para otros formatos" },
  { "value": "4", "data": "Más material" },
  { "value": "6", "data": "Solicitar ajustes" }
];
const viableStep =[
  { "value": "1", "data": "En proceso de compra" }
]
const inProcessPurchaseStep = [ 
  { "value": "1", "data": "Comprar" },
  { "value": "5", "data": "Compra directa" },
  { "value": "7", "data": "Compra cancelada"}
];
const buyStep = [ 
  { "value": "1", "data": "En condiciones de negociación" },
  { "value": "5", "data": "Compra directa" },
  { "value": "7", "data": "Compra cancelada"}
];
const negotiationConditionsStep = [
  { "value": "1", "data": "Solicitud de elaboración de contrato" },
  { "value": "7", "data": "Compra cancelada"}
];
const contractApplicationStep = [
  { "value": "1", "data": "En firmas" },
  { "value": "7", "data": "Compra cancelada"}
];
const inSignaturesStep = [ 
  { "value": "1", "data": "En proceso de pago" },
  { "value": "7", "data": "Compra cancelada"}
];
const inProcessPaymentStep = [ 
  { "value": "2", "data": "Comprada" },
  { "value": "7", "data": "Compra cancelada"}
];
const notViableStep = [
  { "value": "1", "data": "Registrada" }
];

const withPotencialStep = [
  { "value": "0", "data": "No viable" },
  { "value": "1", "data": "Viable" }
];

const withPotencialOtherFormatStep = [
  { "value": "0", "data": "No viable" }
];

const setEvaluatorSpecialists = [
  { "value": "8", "data": "Asignar evaluadores" }
];

const directPurchaseStep = [];
const purchasedStep = [];
const purchaseCanceledStep = [];

const WorkflowSteps = {
  registeredStep,
  moreMaterialStep,
  requestAdjustmentsStep,
  assignedStep,
  inEvaluationStep,
  viableStep,
  inProcessPurchaseStep,
  buyStep,
  negotiationConditionsStep,
  contractApplicationStep,
  inSignaturesStep,
  inProcessPaymentStep,
  withPotencialStep,
  withPotencialOtherFormatStep,
  setEvaluatorSpecialists,

  // FINAL STEPS
  directPurchaseStep,
  purchasedStep,
  notViableStep,
  purchaseCanceledStep
}
export default WorkflowSteps;

//ETAPAS
// "1"	"Registrada"
// "2"	"En evaluación"
// "3"	"Evaluada"
// "4"	"Compra"
// "5"	"Rechazada"
// "6"	"Comprada"
