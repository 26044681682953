import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import PapperBlock from './../PapperBlock/PapperBlock';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Loader from '../Loader/Loader';
import { LanguageConsumer } from './../../language/LanguageContext';
import TextField from '@material-ui/core/TextField';

//Styles
import styles from './user-jss';
//Services 
import { forgotPassword, completeForgotPassword } from '../../services/cognito_service';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../Notification/CustomNotification';

// validation functions
/*const required = value => (value == null ? 'Required' : undefined);
const email = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email'
    : undefined
);*/

class ResetForm extends React.Component {

  state = {
    //Password visibility
    showPassword: false,
    showPasswordC: false,
    //Password
    email: '',
    verificationCode: '',
    newPassword: '',
    confirmPassword: '',
    //Stepper
    step: 'email',
    desc: 'verificationCodeMsg',
    //Notification
    open: false,
    //Errors
    errEmail: false,
    errMatch: false,
    errPattern: false,
    errCode: false, 
    errType: "error", //AHDA 23/05/2019 
    errMsg: "errMsg", //AHDA 23/05/2019
  }

  //Password
  handleClickShowPassword = (control) => {
    switch (control) {
      case 'new':
        this.setState({ showPassword: !this.state.showPassword });
        break;
      case 'confirm':
        this.setState({ showPasswordC: !this.state.showPasswordC });
        break;
      default:
        break;
    }
  };

  //Go to login 
  handleClick = () => {
    window.location.href = '/'
  }

  //Handle submit: send code and change password 
  handleSubmit = (event) => {
    event.preventDefault();
    const user = {
      username: this.state.email,
      verficationCode: this.state.verificationCode,
      newPassword: this.state.newPassword
    }

    switch (this.state.step) {
      case 'email':
        if (!this.state.errEmail) {
          this.setState({ step: 'loading' });
          forgotPassword(user).then(
            response => {
              //TODO: show success, send to login 
              if (response.code === 200) {
                this.setState({ step: 'code', desc: 'enterVCMsg' });
              }
            }
          ).catch(
            err => {
              console.log(err);
              if (err.status === 405) {
                this.setState({ errEmail: true, step: 'email' });
              }
              else {
                //AHDA 23/05/2019: added errType and errMsg
                this.setState({ open: true, step: 'email', errType: "error", errMsg: "errMsg" });
              }
            }
          )
        }
        break;
      case 'code':
        if (!this.state.errMatch && !this.state.errPattern) {
          completeForgotPassword(user).then(
            response => {
              if (response.code === 200) {
                this.setState({ step: 'message', desc: '' });
              }
            }
          ).catch(
            err => {
              if (err.status === 505) {
                this.setState({ errCode: true, step: 'code' });
              }
              //AHDA 23/05/2019: validation for password history
              else if(err.status == 400){
                this.setState({ open: true, step: 'code', errType: "error", errMsg: "pwdHistoryErr" });
              }
              else {
                //AHDA 23/05/2019: added errType and errMsg
                this.setState({ open: true, step: 'code', errType: "error", errMsg: "errMsg" });
              }
            }
          )
        }
        break;
      default:
        break;
    }
  }

  //For inputs
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  //For notifications and errors 
  handleClose = () => {
    this.setState({ open: false });
  }

  handleBlur = (event) => {
    switch (event.target.name) {
      case 'email':
        this.setState({ errEmail: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value) })
        break;
      case 'confirmPassword':
        this.setState({ errMatch: this.state.newPassword === event.target.value ? false : true });
        break;
        //AHDA 14/05/2019
      case 'newPassword':
        this.setState({ errPattern: !/^(?=.*[\d])(?=.*[ÑA-Z])(?=.*[ña-z])(?=.*[@$!%*?.;,:-_{}[/#&'¡¿()+><|])[\wñÑ@$!%*?.;,:-_{}[/#&'¡¿()+><|]{12,}$/i.test(event.target.value) })
        break;
      default:
        break;
    }
  }

  render() {
    const {
      classes
    } = this.props;
    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className={classes.formWrap}>
              <PapperBlock whiteBg title={lang.login.resetPassword} desc={lang.login[`${this.state.desc}`]}>
                <form onSubmit={this.handleSubmit}>
                  <div>
                    {{
                      'email':
                        <React.Fragment>
                          <FormControl className={classes.formControl} required={true} error={this.state.errEmail}>
                            {/* GEO 01/04/2019 Se cambia estilo de input para reestablecer contraseña */}
                            <TextField
                              id="email"
                              name="email"
                              label={lang.common.email}
                              className={classNames(classes.textField, classes.w100)}
                              value={this.state.email}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              InputLabelProps={{ shrink: true }}
                            />
                            {this.state.errEmail ? <FormHelperText id="weight-helper-text">{lang.login.invalidEmail}</FormHelperText> : null}
                          </FormControl>
                          <div className={classes.btnArea}>
                            <Button variant="contained" color="primary" type="submit">
                              {lang.login.sendCode}
                              <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} />
                            </Button>
                          </div>
                        </React.Fragment>,
                      'code':
                        <React.Fragment>
                          <FormControl className={classes.formControl} required={true} error={this.state.errCode}>
                            <InputLabel htmlFor="verificationCode">{lang.login.verificationCode}</InputLabel>
                            <Input
                              id="verificationCode"
                              name="verificationCode"
                              value={this.state.verificationCode}
                              onChange={this.handleChange}
                            />
                            {this.state.errCode ? <FormHelperText id="weight-helper-text">{lang.login.invalidCode}</FormHelperText> : null}
                          </FormControl>
                          <FormControl className={classes.formControl} required={true} error={this.state.errPattern}>
                            <InputLabel htmlFor="newPassword">{lang.login.newPwd}</InputLabel>
                            <Input
                              id="newPassword"
                              name="newPassword"
                              type={this.state.showPassword ? 'text' : 'password'}
                              value={this.state.newPassword}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={() => this.handleClickShowPassword('new')}
                                  >
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              inputProps={
                                { maxLength: 60 }
                              }
                            />
                            {this.state.errPattern ? <FormHelperText id="weight-helper-text">{lang.login.pwdPolicy}</FormHelperText> : null}
                          </FormControl>
                          <FormControl required={true} className={classes.formControl} error={this.state.errMatch}>
                            <InputLabel htmlFor="confirmPassword">{lang.login.confirmPassword}</InputLabel>
                            <Input
                              id="confirmPassword"
                              name="confirmPassword"
                              type={this.state.showPasswordC ? 'text' : 'password'}
                              value={this.state.confirmPassword}
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={() => this.handleClickShowPassword('confirm')}
                                  >
                                    {this.state.showPasswordC ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              inputProps={
                                { maxLength: 60 }
                              }
                            />
                            {this.state.errMatch ? <FormHelperText id="weight-helper-text">{lang.login.passNoMatch}</FormHelperText> : null}
                          </FormControl>
                          <div className={classes.btnArea}>
                            <Button variant="contained" color="primary" type="submit">
                              {lang.common.confirm}
                              <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)} />
                            </Button>
                          </div>
                        </React.Fragment>,
                      'message':
                        <React.Fragment>
                          <Typography variant="subheading" gutterBottom >
                            <IconButton className={classes.button} color="primary" >
                              <Check />
                            </IconButton>
                            {lang.login.successfullChange}
                          </Typography>
                          <Button
                            size="small"
                            color="secondary"
                            className={classNames(classes.rightIcon, classes.button)} onClick={this.handleClick}>
                            {lang.login.login}
                          </Button>
                        </React.Fragment>,
                      'loading':
                        <div>
                          <Loader text="" />
                        </div>
                    }[this.state.step]}
                  </div>
                </form>
              </PapperBlock>

              {/*Error Notifications*/}
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.open}
                autoHideDuration={6000}
                onClose={this.handleClose}>
                {/*AHDA 23/05/2019: Changed props to state*/}
                <CustomNotification
                  onClose={this.handleClose}
                  variant={this.state.errType}
                  message={lang.common[this.state.errMsg]} />
              </Snackbar>
            </div>
        }
      </LanguageConsumer>

    );
  }
}

ResetForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ResetForm);
