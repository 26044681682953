import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import ArrowForward from '@material-ui/icons/ArrowForward';
import styles from './user-jss';
import PapperBlock from './../PapperBlock/PapperBlock';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../Notification/CustomNotification'; 
import Loader from '../Loader/Loader';

//Services
import {CompleteSignUp, ResendCode} from './../../services/cognito_service';  
//Language
import { LanguageConsumer } from '../../language/LanguageContext';

class ConfirmUserForm extends React.Component{
    
    state = {
        email: '', 
        code: '', 
        //Errors
        errEmail: false, 
        errCode: false,
        errInvalidEmail: false,
        notifMessage: 'errMsg',
        notifVariant: 'error',
        //Notifications
        open: false, 
        //Loading: 
        step: 'code'
    };

    //Inputs
    handleChange = (event) => {
        this.setState({[event.target.name] : event.target.value}); 
    }

    handleBlur = (event) => {
        switch(event.target.name){
          case 'email' : 
          this.setState({errEmail: !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)})
          break;  
          default:
          break; 
        }
    }

    //Submit
    handleSubmit = (event) => {
        event.preventDefault();

        const user = {
            username: this.state.email, 
            code: this.state.code,
            //TO RDS
            usuario: this.state.email, 
            verificado: 1
        }

        //TODO: validate errors then call
        if(!this.state.errEmail){
            this.setState({step: 'loading'}); 

            CompleteSignUp(user).then(
                response => {
                    this.setState({step: 'message'})
                }
            ).catch(
                err =>{
                    if(err.status === 400){
                        this.setState({open: true, step: 'code', notifMessage: 'invalidEmailCode', notifVariant: 'error'})
                    }
                    else if(err.status === 404){
                        this.setState({errCode: true, step: 'code'})
                    }
                    else{
                        this.setState({open: true, step: 'code', notifMessage: 'errMsg', notifVariant: 'error'})
                    }
                }
            )
        }
    }

    //Resend code 
    handleClick = () => {
        const user = {
            username: this.state.email
        }

        //TODO: validate errors then call
        if(!this.state.errEmail && this.state.email !== ""){
            ResendCode(user).then(
                response => {
                    //todo: manage response
                    this.setState({open: true, step: 'code', notifMessage: 'codeSent', notifVariant: 'success'})
                }
            ).catch(
                err =>{
                    if(err.status === 400){
                        this.setState({open: true, step: 'code', notifMessage: 'invalidEmailCode', notifVariant: 'error'})
                    }
                    else{
                        this.setState({open: true, step: 'code', notifMessage: 'errMsg', notifVariant: 'error'})
                    }
                }
            )
        }
    }

    handleClickLogin = () => {
        window.location.href = "/"
    }

    //Notifications
    handleClose = () => {
        this.setState({open: false}); 
    }

    render(){
        const {
            classes
        } = this.props;
        return(
            <LanguageConsumer>
            {
            ({lang}) => 
            <div className={classes.formWrap} >
                <PapperBlock whiteBg title={lang.login.confirmAccount} desc="" >
                {{
                    'code': 
                        <form onSubmit={this.handleSubmit}>
                            <div>
                            <FormControl className={classes.formControl} error={this.state.errEmail || this.state.errInvalidEmail}>
                                <InputLabel htmlFor="email">{lang.common.email}</InputLabel>
                                <Input
                                    id="email"
                                    name="email"
                                    required
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    onBlur={this.handleBlur}
                                    inputProps={
                                        {maxLength: 50}
                                    }
                                />
                                {this.state.errEmail ? <FormHelperText id="weight-helper-text">{lang.common.emailValidation}</FormHelperText> : null }
                            </FormControl>
                            </div>
                            <div>
                            <FormControl className={classes.formControl} error={this.state.errCode}>
                                <InputLabel htmlFor="code">{lang.login.verificationCode}</InputLabel>
                                <Input
                                    id="code"
                                    name="code"
                                    required
                                    autoComplete="off"
                                    value={this.state.code}
                                    onChange={this.handleChange}
                                />
                                {this.state.errCode ? <FormHelperText id="weight-helper-text">{lang.login.invalidCode}</FormHelperText> : null }
                            </FormControl>
                            </div>

                            <div className={classNames(classes.btnArea)}>
                                <Button variant="contained" color="primary" type="submit">
                                    {lang.common.continue}
                                    <ArrowForward className={classNames(classes.rightIcon, classes.iconSmall)}/>
                                </Button>
                            </div>
                            <div className={classes.footer}>
                                <Typography variant="subtitle2" gutterBottom >
                                    {lang.login.verificationCodeProblems}
                                </Typography>
                                <Button size="small" color="secondary" type="button" onClick={this.handleClick}>
                                    {lang.common.resend}
                                </Button>
                            </div>
                        </form>, 
                    'loading': 
                    <Loader text=""/>, 
                    'message': 
                    <React.Fragment>
                        <Typography variant="subheading" gutterBottom >
                          <IconButton className={classes.button} color="primary" >
                          <Check />
                          </IconButton>
                          {lang.login.confirmedAccount}
                        </Typography>
                        <Button 
                        size="small" 
                        color="secondary" 
                        className={classNames(classes.rightIcon, classes.button)} onClick={this.handleClickLogin}>
                          {lang.login.login}
                        </Button>
                    </React.Fragment> 
                }[this.state.step]}
                {/*for error notifications*/}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                    <CustomNotification 
                    onClose={this.handleClose}
                    variant={this.state.notifVariant}
                    message={lang.login[this.state.notifMessage]}/>
                </Snackbar>
                </PapperBlock>
            </div>
            }
        </LanguageConsumer>
        )
    }
}

ConfirmUserForm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ConfirmUserForm);