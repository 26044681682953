import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
//import Menu from '@material-ui/core/Menu';
//import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import Today from '@material-ui/icons/Today';
import Close from '@material-ui/icons/Close';
import styles from './calendar-jss';

import {LanguageConsumer} from '../../language/LanguageContext'; 

class DetailEvent extends React.Component {
  constructor(props){
    super(props); 
    this.state = {
      anchorElOpt: null,
      monthNames: this.props.monthNames
    }
  }

  render() {
    

    const {
      classes,
      anchorEl,
      event,
      close,
      anchorPos
    } = this.props;
    return (
      <Popover
        open={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={anchorPos}
        className={classes.eventDetail}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <IconButton aria-label="Close" className={classes.moreOpt} onClick={close}>
          <Close />
        </IconButton>
        {event !== null &&
          <Fragment>
            <Typography variant="headline" style={{ background: `#ffced3` }} className={classes.eventName}>
              <Today /> {`${event.provider} (${new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(event.amount)})`}
            </Typography>
            <LanguageConsumer>
              {
                ({lang}) => 
               
                <div className={classes.time}>
                  <Typography>{event.work}</Typography>
                  <Divider className={classes.divider} />
                  <Typography>{lang.reports.paymentDate}: {event.payment_date}</Typography>
                </div>
              }
            </LanguageConsumer>
          </Fragment>
        }
      </Popover>
    );
  }
}

DetailEvent.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.bool.isRequired,
  anchorPos: PropTypes.object.isRequired,
  event: PropTypes.object,
  close: PropTypes.func.isRequired
};

DetailEvent.defaultProps = {
  event: null,
};

export default withStyles(styles)(DetailEvent);
