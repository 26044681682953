// OAuthButton.js
import { withOAuth } from 'aws-amplify-react';
import React from 'react';
import { awscognito, OAuth } from '../../../config/cognito'
import * as uuid from 'uuid/v1';
import { Button, withStyles } from '@material-ui/core';
import styles from './user-jss';

class OAuthButton extends React.Component {

  componentWillMount() {
    console.log(uuid());
  }

  handleActiveDirectory() {

    window.location.href = 'https://' + OAuth.domain 
      + '/oauth2/authorize?identity_provider='+ OAuth.identityProvider 
      + '&redirect_uri=' + process.env.REACT_APP_host
      + '&response_type=TOKEN&client_id=' + awscognito.userPoolWebClientId 
      + '&state=' + uuid() 
      + '&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile';
  }

  render() {
    const {classes} = this.props;

    return (
      <div className="blockCenter">
        <Button variant="contained" size="small" color="secondary" type="submit"
          onClick={this.handleActiveDirectory}
          className={classes.btnActiveDirectory}
          >
          Iniciar con directorio activo
        </Button>
      </div>
    )
  }
}

export default withOAuth(withStyles(styles)(OAuthButton));