import TextField from '@material-ui/core/TextField';
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

export function renderInput(inputProps) {
    const {
      helperText,
      inputValue,
      handleChange, 
      InputProps,
      classes,
      ref,
      ...other
    } = inputProps;
  
    return (
      <TextField 
      helperText={helperText}
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
          },
          ...InputProps,
        }}
        {...other}
      />
    );
  }

  export function renderSuggestion({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem
  }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;
  
    return (
      <MenuItem
        {...itemProps}
        key={suggestion.label}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {suggestion.label}
      </MenuItem>
    );
  }


  //importante llena
  export function getSuggestions(inputValue,array) {
    let count = 0;
  
    return array.filter(suggestion => {
      //AHDA 09/05/2019
      //Validation when empty
      const sugg = suggestion.label ?  suggestion.label.toLowerCase().indexOf(inputValue.toLowerCase()) : ""
      const keep =
        (!inputValue || sugg !== -1) &&
        count < 5;
  
      if (keep) {
        count += 1;
      }
  
      return keep;
    });
  }

