import LocalizedStrings from "react-localization";

let langstr = new LocalizedStrings({
  en: {
    login: {
      login: "Login",
      logginIn: "Logging in...",
      incorrectUser: "Incorrect username or password",
      remember: "Remember",
      cantLogin: "Can't login?",
      forgotPassword: "FORGOT PASSWORD",
      changingPassword: "Changing password",
      currentPwd: "Current Password",
      newPwd: "New Password",
      confirmPassword: "Confirm Password",
      passNoMatch: "Passwords don't match",
      changeRequired: "Change temporary password",
      pwdPolicy:
        "Password must contain numbers, special characters, uppercase and lowercase letters; and 12 digits minimum",
      //Traducir
      resetPassword: "Restablecer contraseña",
      sendCode: "Mandar código",
      verificationCode: "Código de verificación",
      invalidCode: "Código inválido, por favor verifique",
      successfullChange: "Tu contraseña se ha cambiado satisfactoriamente",
      verificationCodeMsg: "Enviaremos un código a tu correo electrónico",
      enterVCMsg: "Ingrese el código de verificación y su nueva contraseña",
      invalidEmail:
        "El correo electrónico no es válido. Favor de intentar nuevamente",
      errMsg: "Algo salió mal, intenta de nuevo más tarde",
      invalidEmailCode:
        "La cuenta con el correo electrónico no existe o ya fue confirmada",
      codeSent:
        "Se ha enviado un nuevo código de verificación al correo electrónico",
      expiredPswdMsg:
        "Tu contraseña ha caducado, es necesario que la actualices",
      changePswdRequiredMsg:
        "Por seguridad, debes cambiar la contraseña que se envió a tu correo electrónico",
      confirmAccount: "Confirmar cuenta",
      verificationCodeProblems: "¿Problemas con el código de verificación?",
      confirmedAccount: "Tu cuenta ha sido confirmada.",
      emailNoMatch: "Los correos no coinciden",
      confirmedRegister:
        "Tu cuenta fue registrada exitosamente. Se ha mandado un código de verificación a tu dirección de correo electrónico.",
      register: "Registrarme",
      accountNotVerified:
        "Falta la confirmación de su cuenta, favor de validar su correo.",
    },
    common: {
      errMsg: "Something went wrong, try again later",
      blockedUser: "Has excedido el número de intentos",
      deletedUser: "Se te ha bloqueado el acceso al portal",
      existingUser:
        "Ya existe en el portal un usuario con este correo, favor de validar",
      email: "Email",
      password: "Password",
      continue: "Continue",
      youAreHere: "You are here",
      emailValidation: "Invalid email",
      confirm: "Confirm",
      profile: "Profile",
      logOut: "Log Out",
      search: "Buscar",
      resend: "Reenviar",
      name: "Nombre",
      lastName: "Apellidos",
      confirmEmail: "Confirmar correo electrónico",
      cancel: "Cancelar",
      accountNotVerified:
        "Falta la confirmación de su cuenta, favor de validar su correo.",
      clear: "Limpiar",
      dateError: "La fecha final no puede ser menor a la fecha inicial o vacía", //JCMY 08/07/2019 incidencia 1041
      pwdHistoryErr:
        "La contraseña capturada debe ser distinta a las últimas 6 registradas", //AHDA 23/05/2019
    },
    notifications: {
      emptyNotifications: "Bandeja de notificaciones vacía",
    },
    menu: {
      //Menu options, linked to key in /utils/menu
      assignments: "Asignaciones",
      profile: "Mi Perfil",
      obras_registradas: "Obras Registradas",
      reportes: "Reportes",
      reporte_areaEvaluadora: "Área evaluadora",
      reporte_compraObras: "Compra de obras",
      reporte_estatusContractual: "Estatus contractual",
      reporte_estatusDocumentacion: "Estatus documentación",
      reporte_obras: "Obras literarias",
      bitacora_movimientos: "Bitácora de movimientos",
      bitacora_obras: "Bitácora de obras",
      fechas_trabajo: "Fechas de trabajo",
      fechas_vencimiento: "Fechas de vencimiento",
      fechas_flujos: "Flujos de pago",
      reporte_curriculumAutor: "Obras por escritor",
      settings: "Configuración",
      users: "Users",
      privilegios: "Perfiles y Privilegios",
      notifications: "Notificaciones",
      configureNotifications: "Configuración de notificaciones",
      parametros: "Parámetros",
      catalogos: "Catálogos",
      pais_de_origen: "País de Origen",
      fuente: "Fuente",
      //AHDA 02/04/2019
      //Correccion de ortografia
      tematica: "Temática",
      audiencia: "Audiencia",
      ambiente: "Ambiente",
      productores: "Productores",
      idioma: "Idioma",
      generos: "Géneros",
      formato: "Formato",
      motivacion: "Motivación",
      vigencia: "Vigencia",
      autores_adaptadores: "Autores/Adaptadores",
      duracion_libretos: "Duración de los libretos",
      subgenero: "Subgénero",
      ventana: "Ventana",
      temporalidad: "Temporalidad",
      tipo_contrato: "Tipo de contrato",
      empresa_proveedores: "Empresas/Proveedores",
      nivel_riesgo: "Nivel de riesgo",
      causas_rechazo: "Causas de rechazo",
      area_evaluadora: "Área Evaluadora",
    },
    reports: {
      myAssigments: "Mis Asignaciones",
      reports: "Reportes",
      // JCMY 08/07/2019 incidencia 1043
      repEvaluatingArea: "Área evaluadora",
      repPlayPurchase: "Compra de obras",
      repContractualState: "Estatus contractual",
      repDocumentationState: "Estatus documentación",
      repLiteraryPlays: "Obras literarias",
      fileNumber: "Folio",
      compProv: "Empresa/Proveedor",
      title: "Título de la Obra",
      authors: "Autor(es)/Nickname",
      country: "País",
      step: "Etapa",
      source: "Fuente",
      registerDate: "Fecha de registro",
      epComments: "Comentarios de evaluación",
      genre: "Género",
      status: "Estatus",
      playType: "Tipo Obra",
      evDateEnter: "Ingreso evaluación",
      compDateEnter: "Ingreso material complementario",
      company: "Empresa",
      evaluatingArea: "Área evaluadora",
      all: "Todos",
      playEstatus: "Estatus de la Obra",
      materialType: "Tipo de material",
      primal: "Primigenia",
      adaptation: "Adaptación",
      contractType: "Tipo de Contrato",
      startDate: "Fecha Inicio",
      endDate: "Fecha Final",
      provider: "Proveedor",
      complete: "Completa",
      incomplete: "Incompleta",
      docStatus: "Estatus Documentación",
      year: "Año",
      purchaseStart: "Inicio de compra",
      docsDelivery: "Entrega de docs",
      daysPassed: "Días transcurridos",
      documentation: "Documentación",
      missingDoc: "Documentación Faltante",
      contractElaboration: "Elaboración de contrato",
      contractSignin: "Firma de contrato",
      signers: "Firmantes",
      chapterNumber: "Num. Capítulos",
      chapterPrice: "Precio por capítulo",
      playTotal: "Total de la obra",
      entryDate: "Fecha de ingreso",
      mcDate: "Fecha de MC",
      timePassed: "Tiempo transcurrido",
      results: "resultados",
      print: "Descargar",
      date: "Fecha",
      user: "Usuario",
      action: "Acción",
      description: "Descripción",
      repMovementLog: "Bitácora de movimientos",
      author: "Autor/Nickname",
      validity: "Vigencia",
      purchaseDate: "Fecha de compra",
      currency: "Moneda",
      repWorksPerAuthor: "Obras por escritor",
      paymentAmount: "Importe de pago",
      contactTotal: "Total del contrato",
      paymentDate: "Fecha de pago",
      tableView: "Vista tabla",
      calendarView: "Vista calendario",
      repPaymentFlows: "Fecha flujos de pago",
      nonViability: "Causa de no viabilidad", //AHDA 27/05/2019
      tipo_obra: "Tipo de Obra",
      empresa_proveedor: "Empresa/Proveedor",
      employee: "Número de empleado",
    },
    actionTypesCatalogue: {
      //THIS COME FROM DB; DO NOT CHANGE
      login: "Inicio de sesión",
      searchWork: "Búsqueda de obra",
      exportCSV: "Exportar reporte",
      markAsFavorite: "Marcar como favorito",
      markAsProspect: "Marcar como prospecto",
      asignProject: "Asignar proyecto",
      unlockProject: "Desbloquear obra",
      finishProject: "Finalizar proyecto",
      edit: "Edición",
      register: "Registro",
      delete: "Eliminación",
      advance: "Avanzar",
      extendTerm: "Ampliar plazo",
      requestMaterial: "Solicitar material",
      shareMaterial: "Compartir material",
      userUpdateStatus: "Actualización de usuario",
      workUpdateStatus: "Actualización de obra",
      workConsulting: "Consulta de obra", //AHDA 10/05/2019
      userPsswUpdate: "Cambio de contraseña",
      userBlocked: "Bloqueo de usuario",
      userUnBlocked: "Desbloqueo de usuario",
      profileUpdated: "Modificación de perfiles",
      permissionUpdated: "Modificación de permisos",
    },
    screens: {
      home: "Obras",
      login: "Inicio de sesión",
      notDefined: "-",
      // JCMY 08/07/2019 incidencia 1043
      reporteAreaEvaluadora: "Área Evaluadora",
      reporteBitacoraMovimientos: "Reporte Bitácora Movimientos",
      reporteCompraObras: "Compra de obras",
      reporteCurriculumAutor: "Reporte Currículum autor",
      reporteEstatusContractual: "Estatus contractual",
      reporteEstatusDocumentacion: "Estatus documentación",
      reporteFechasFlujosPago: "Reporte Flujos de pago",
      reporteObrasLiterarias: "Reporte Obras literarias",
      reporteObras: "Reporte Obras literarias",
      myAsignations: "Mis Asignaciones", //AHDA 10/05/2019
      configurationUsers: "Configuración de Usuarios", //AHDA 10/05/2019
      profile: "Perfil", //AHDA 10/05/2019
      reporteBitacoraObras: "Reporte Bitácora de Obras", //AHDA 30/05/2019
    },
    calendar: {
      next: "Siguiente",
      prev: "Anterior",
      today: "Hoy",
      month: "Mes",
      agenda: "Agenda",
      january: "Enero",
      february: "Febrero",
      march: "Marzo",
      april: "Abril",
      may: "Mayo",
      june: "Junio",
      july: "Julio",
      august: "Agosto",
      september: "Septiembre",
      october: "Octubre",
      november: "Noviembre",
      december: "Diciembre",
      date: "Fecha",
      time: "Hora",
      event: "Pago",
    },
  },
  es: {
    login: {
      login: "Iniciar sesión",
      logginIn: "Iniciando sesión...",
      incorrectUser: "Usuario o contraseña incorrecto",
      remember: "Recordar",
      cantLogin: "¿No puedes entrar? ",
      forgotPassword: "OLVIDÉ MI CONTRASEÑA",
      changingPassword: "Cambiando contraseña...",
      currentPwd: "Contraseña Temporal",
      newPwd: "Nueva Contraseña",
      confirmPassword: "Confirmar Contraseña",
      passNoMatch: "Las contraseñas no coinciden",
      changeRequired: "Cambiar contraseña temporal",
      pwdPolicy:
        "La contraseña debe tener números, caracteres especiales, letras mayúsculas y minúsculas; y longitud mínima de 12 caracteres",
      resetPassword: "Restablecer contraseña",
      sendCode: "Mandar código",
      verificationCode: "Código de verificación",
      invalidCode: "Código inválido, por favor verifique",
      successfullChange: "Tu contraseña se ha cambiado satisfactoriamente",
      verificationCodeMsg: "Enviaremos un código a tu correo electrónico",
      enterVCMsg: "Ingrese el código de verificación y su nueva contraseña",
      invalidEmail:
        "El correo electrónico no es válido. Favor de intentar nuevamente",
      errMsg: "Algo salió mal, intenta de nuevo más tarde",
      invalidEmailCode:
        "La cuenta con el correo electrónico no existe o ya fue confirmada",
      codeSent:
        "Se ha enviado un nuevo código de verificación a su correo electrónico",
      expiredPswdMsg:
        "Tu contraseña ha caducado, es necesario que la actualices",
      changePswdRequiredMsg:
        "Por seguridad, debes cambiar la contraseña que se envió a tu correo electrónico",
      confirmAccount: "Confirmar cuenta",
      verificationCodeProblems: "¿Problemas con el código de verificación?",
      confirmedAccount: "Tu cuenta ha sido confirmada.",
      emailNoMatch: "Los correos no coinciden",
      confirmedRegister:
        "Tu cuenta fue registrada exitosamente. Se ha mandado un código de verificación a tu dirección de correo electrónico.",
      register: "Registrarme",
      accountNotVerified:
        "Falta la confirmación de su cuenta, favor de validar su correo.",
    },
    common: {
      errMsg: "Algo salió mal, intenta de nuevo más tarde",
      blockedUser: "Has excedido el número de intentos",
      deletedUser: "Se te ha bloqueado el acceso al portal",
      existingUser:
        "Ya existe en el portal un usuario con este correo, favor de validar",
      email: "Correo electrónico",
      password: "Contraseña",
      continue: "Continuar",
      youAreHere: "Estás aquí:",
      emailValidation: "Correo electrónico inválido",
      confirm: "Confirmar",
      profile: "Perfil",
      logOut: "Cerrar Sesión",
      search: "Buscar",
      resend: "Reenviar",
      name: "Nombre",
      lastName: "Apellidos",
      confirmEmail: "Confirmar correo electrónico",
      cancel: "Cancelar",
      accountNotVerified:
        "Falta la confirmación de su cuenta, favor de validar su correo.",
      clear: "Limpiar",
      dateError: "La fecha final no puede ser menor a la fecha inicial o vacía", // JCMY 08/07/2019 incidencia 1041
      pwdHistoryErr:
        "La contraseña capturada debe ser distinta a las últimas 6 registradas", //AHDA 23/05/2019
    },
    notifications: {
      emptyNotifications: "Bandeja de notificaciones vacía",
    },
    menu: {
      //Menu options, linked to key in /utils/menu
      assignments: "Asignaciones",
      profile: "Mi Perfil",
      obras_registradas: "Obras Registradas",
      reportes: "Reportes",
      reporte_areaEvaluadora: "Área evaluadora",
      reporte_compraObras: "Compra de obras",
      reporte_estatusContractual: "Estatus contractual",
      reporte_estatusDocumentacion: "Estatus documentación",
      reporte_obras: "Obras literarias",
      bitacora_movimientos: "Bitácora de movimientos",
      bitacora_obras: "Bitácora de obras",
      fechas_trabajo: "Fechas de trabajo",
      fechas_vencimiento: "Fechas de vencimiento",
      fechas_flujos: "Flujos de pago",
      reporte_curriculumAutor: "Obras por escritor",
      settings: "Configuración",
      privilegios: "Perfiles y Privilegios",
      notifications: "Notificaciones",
      configureNotifications: "Configuración de notificaciones",
      parametros: "Parámetros",
      users: "Usuarios",
      catalogos: "Catálogos",
      pais_de_origen: "País de Origen",
      fuente: "Fuente",
      //AHDA 02/04/2019
      //Correccion de ortografia
      tematica: "Temática",
      audiencia: "Audiencia",
      ambiente: "Ambiente",
      productores: "Productores",
      idioma: "Idioma",
      generos: "Géneros",
      formato: "Formato",
      motivacion: "Motivación",
      vigencia: "Vigencia",
      autores_adaptadores: "Autores/Adaptadores",
      duracion_libretos: "Duración de los libretos",
      subgenero: "Subgénero",
      ventana: "Ventana",
      temporalidad: "Temporalidad",
      tipo_contrato: "Tipo de contrato",
      empresa_proveedores: "Empresas/Proveedores",
      nivel_riesgo: "Nivel de riesgo",
      causas_rechazo: "Causas de rechazo",
      area_evaluadora: "Área Evaluadora",
    },
    reports: {
      myAssigments: "Mis Asignaciones",
      reports: "Reportes",
      // JCMY 08/07/2019 incidencia 1043
      repEvaluatingArea: "Área evaluadora",
      repPlayPurchase: "Compra de obras",
      repContractualState: "Estatus contractual",
      repDocumentationState: "Estatus documentación",
      repLiteraryPlays: "Obras literarias",
      fileNumber: "Folio",
      compProv: "Empresa/Proveedor",
      title: "Título de la Obra",
      authors: "Autor(es)/Nickname",
      country: "País",
      step: "Etapa",
      source: "Fuente",
      registerDate: "Fecha de registro",
      epComments: "Comentarios de evaluación",
      genre: "Género",
      status: "Estatus",
      playType: "Tipo Obra",
      evDateEnter: "Ingreso evaluación",
      compDateEnter: "Ingreso material complementario",
      company: "Empresa",
      evaluatingArea: "Área evaluadora",
      all: "Todos",
      playEstatus: "Estatus de la Obra",
      materialType: "Tipo de material",
      primal: "Primigenia",
      adaptation: "Adaptación",
      contractType: "Tipo de Contrato",
      startDate: "Fecha Inicio",
      endDate: "Fecha Final",
      provider: "Proveedor",
      complete: "Completa",
      incomplete: "Incompleta",
      docStatus: "Estatus Documentación",
      year: "Año",
      purchaseStart: "Inicio de compra",
      docsDelivery: "Entrega de docs",
      daysPassed: "Días transcurridos",
      documentation: "Documentación",
      missingDoc: "Documentación Faltante",
      contractElaboration: "Elaboración de contrato",
      contractSignin: "Firma de contrato",
      signers: "Firmantes",
      chapterNumber: "Num. Capítulos",
      chapterPrice: "Precio por capítulo",
      playTotal: "Total de la obra",
      entryDate: "Fecha de ingreso",
      mcDate: "Fecha de MC",
      timePassed: "Tiempo transcurrido",
      results: "resultados",
      print: "Descargar",
      date: "Fecha",
      user: "Usuario",
      action: "Acción",
      description: "Descripción",
      repMovementLog: "Bitácora de movimientos",
      author: "Autor/Nickname",
      validity: "Vigencia",
      purchaseDate: "Fecha de compra",
      currency: "Moneda",
      repWorksPerAuthor: "Obras por escritor",
      paymentAmount: "Importe de pago",
      contactTotal: "Total del contrato",
      paymentDate: "Fecha de pago",
      tableView: "Vista tabla",
      calendarView: "Vista calendario",
      repPaymentFlows: "Fecha flujos de pago",
      nonViability: "Causa de no viabilidad", //AHDA 27/05/2019
      tipo_obra: "Tipo de Obra",
      empresa_proveedor: "Empresa/Proveedor",
      employee: "Número de empleado",
    },
    actionTypesCatalogue: {
      //THIS COME FROM DB; DO NOT CHANGE
      login: "Inicio de sesión",
      searchWork: "Búsqueda de obra",
      exportCSV: "Exportar reporte",
      markAsFavorite: "Marcar como favorito",
      markAsProspect: "Marcar como prospecto",
      asignProject: "Asignar proyecto",
      unlockProject: "Desbloquear obra",
      finishProject: "Finalizar proyecto",
      edit: "Edición",
      register: "Registro",
      delete: "Eliminación",
      advance: "Avanzar",
      extendTerm: "Ampliar plazo",
      requestMaterial: "Solicitar material",
      shareMaterial: "Compartir material",
      userUpdateStatus: "Actualización de usuario",
      workUpdateStatus: "Actualización de obra",
      workConsulting: "Consulta de obra", //AHDA 10/05/2019
      registeredWorks: "Obras Registradas", //AHDA 10/05/2019
      userPsswUpdate: "Cambio de contraseña",
      userBlocked: "Bloqueo de usuario",
      userUnBlocked: "Desbloqueo de usuario",
      profileUpdated: "Modificación de perfiles",
      permissionUpdated: "Modificación de permisos",
    },
    screens: {
      home: "Obras",
      login: "Inicio de sesión",
      notDefined: "-",
      reporteAreaEvaluadora: "Reporte Area Evaluadora",
      reporteBitacoraMovimientos: "Reporte Bitácora Movimientos",
      reporteCompraObras: "Reporte Compra de obras",
      reporteCurriculumAutor: "Reporte Currículum autor",
      reporteEstatusContractual: "Estatus contractual",
      reporteEstatusDocumentacion: "Estatus documentación",
      reporteFechasFlujosPago: "Reporte Flujos de pago",
      reporteObrasLiterarias: "Reporte Obras literarias",
      reporteObras: "Reporte Obras literarias",
      myAsignations: "Mis Asignaciones", //AHDA 10/05/2019
      registeredWorks: "Obras Registradas", //AHDA 10/05/2019
      configurationUsers: "Configuración de Usuarios", //AHDA 10/05/2019
      profile: "Perfil", //AHDA 10/05/2019
      reporteBitacoraObras: "Reporte Bitácora de Obras", //AHDA 30/05/2019
    },
    calendar: {
      next: "Siguiente",
      prev: "Anterior",
      today: "Hoy",
      month: "Mes",
      agenda: "Agenda",
      january: "Enero",
      february: "Febrero",
      march: "Marzo",
      april: "Abril",
      may: "Mayo",
      june: "Junio",
      july: "Julio",
      august: "Agosto",
      september: "Septiembre",
      october: "Octubre",
      november: "Noviembre",
      december: "Diciembre",
      date: "Fecha",
      time: "Hora",
      event: "Pago",
    },
  },
});

langstr.setLanguage("es");

export default langstr;
