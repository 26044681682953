const styles = theme => ({
  root: {
    // width: '100%',
    // overflowX: 'auto',
    marginTop: 0,
  },
  field: {
    width: '100%',
    marginBottom: 20,
    '& svg': {
      color: theme.palette.grey[400],
      fontSize: 18,
    }
  },
  table: {
    marginTop: 0,
    '& thead tr th': {
      color: '#9F9F9F'
    },
    '& tr td': {
      padding: '4px 24px 4px 24px'
    }
  },
  stripped: {
    '& tbody tr:nth-child(even)': {
      background: '#f5f7f9',
    }
  },
  actionsBtn: {
    minWidth: 110,
  },
  toolbar: {
    background: `linear-gradient(93.01deg, #F62E1F 0.18%, #FF5702 49.51%, #F68E5C 98.85%)`,
    marginTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      color: 'white'
    }
    // position: 'absolute',
    // width: '100%'
  },
  title: {
    flex: '0 0 auto',
    color: '#FFFFFF',
    '& h2': {
      color: theme.palette.common.white
    },
    "@media (max-width: 425px)": {
      textOverflow: 'ellipsis',
      width: '150px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      wordWrap: 'normal',
    },
    "@media (max-width: 320px)": {
      textOverflow: 'ellipsis',
      width: '100px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      wordWrap: 'normal',
    },
  },
  spacer: {
    flex: '1 1 100%',
  },
  ajusteTabla: {
    overflowX: 'auto',
    width: '100%',
  },
  widthCell: {
    maxWidth: '200px',
    minWidth: '50px',
  },
  button: {
    color: theme.palette.primary.main,
    background: 'white',
    marginLeft: theme.spacing.unit,
    transition: 'all 0.2s ease-in-out',
    '& svg': {
      color: theme.palette.primary.main,
      position: 'relative',
      top: -2,
    },
    '&:hover, &:focus, &:active': {
      color: theme.palette.primary.main,
      background: 'white',
      opacity: 0.8
    }
  },
  cursorDefault: {
    cursor: 'default !important'
  },
  searchWhite: {
    '& > div': {
      color: 'white',
      '&:hover': {
        '&:before': {
          borderColor: 'rgba(255,255,255,0.42) !important'
        }
      },
      '&:before': {
        borderColor: 'rgba(255,255,255,0.42)'
      },
      '&:after': {
        borderColor: 'white'
      }
    }
  },
  alertDialog: {
    "& .confirm": {
      color: 'white',
      backgroundColor: `${theme.palette.primary.main} !important`,
    }
  }
});

export default styles;