export const awscognito = {
    region: process.env.REACT_APP_aws_cognito_region,
    identityPoolId: process.env.REACT_APP_aws_cognito_identity_pool_id,
    userPoolId: process.env.REACT_APP_aws_user_pools_id,
    userPoolWebClientId: process.env.REACT_APP_aws_user_pools_web_client_id,
    mandatorySignIn: true
}

export const awsapi = {
    endpoints: [
        {
            name: process.env.REACT_APP_aws_apigateway_name,
            endpoint: process.env.REACT_APP_aws_apigateway_endpoint,
            region: process.env.REACT_APP_aws_apigateway_region
        },
        // elastisearch config
        {
            name: process.env.REACT_APP_ELASTICNAME,
            endpoint: process.env.REACT_APP_ELASTICBEHAVIOR,
            region: process.env.REACT_APP_ELASTICREGION
        }
    ]
};

export const awsstorage = {
    AWSS3: {
        bucket: process.env.REACT_APP_aws_user_files_s3_bucket,
        region: process.env.REACT_APP_aws_user_files_s3_bucket_region
    }
}

export const OAuth = {
    domain: process.env.REACT_APP_aws_cognito_domain,
    identityProvider: process.env.REACT_APP_cognito_identity_provider,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_host,
    redirectSignOut: process.env.REACT_APP_host,
    responseType: 'token' // puede ser code o token
};