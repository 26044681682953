import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
//import { withStyles } from '@material-ui/core/styles';

//Pagination
import Pagination from '../../../components/Pagination/Pagination';

// notificaciones
import NotifyCard from '../../../components/CardPaper/NotifyCard'; 

class PaginationNotification extends React.Component {
    constructor(props){
        super(props); 
        
        this.state = {
            page: 1, 
            contentsPerPage: this.props.contentsPerPage, 
            content: this.props.content
        }
    }

    //Pagination
    onPageChange = (page) => {
        this.setState({ page: page });
    }

    onPrev = () => {
        if (this.state.page > 1) {
            this.setState({ page: this.state.page -= 1 });
        } else {
            this.setState({ page: 1 });
        }
    }
    
    onNext = (totalPages) => {
        if (this.state.page < totalPages) {
            this.setState({ page: this.state.page += 1 });
        } else {
            this.setState({ page: totalPages });
        }
    }
    
    onGoFirst = () => {
        this.setState({ page: 1 });
    }

    onGoLast = (totalPages) => {
        this.setState({ page: totalPages });
    }
    
    render(){
        const { page,  contentsPerPage } = this.state;
        const { type, content, handleDeleteItem, icon } = this.props; 

        const indexOfLastWork = page * contentsPerPage;
        const indexOfFirstWork = indexOfLastWork - contentsPerPage;
        const currentContent = content.slice(indexOfFirstWork, indexOfLastWork);

        const pageNumbers = [];
            for (let i = 1; i <= Math.ceil(content.length / contentsPerPage); i ++) {
            pageNumbers.push(i);
        };
        
        const getMessage = (type, notification) => {
            switch(type){
                case "newWorkRegister":
                    return `${notification.productorObras?"La empresa '"+notification.productorObras+"'":(notification.empresa?"La empresa '"+notification.empresa+"'":'Se')} ha registrado una nueva obra '${notification.titulo_original}' en el portal para su evaluación`;
                case "workEvaluationAssign":
                    return `Se ha asignado la obra '${notification.titulo_original}' para su evaluación`;
                case "additionalMaterialRequest":
                    return `Se requiere de material adicional para continuar con la evaluación de la obra '${notification.titulo_original}.'`;
                case "additionalMaterialReception":
                    return `Se recibió material complementario de la obra '${notification.titulo_original}.'`;
                case "dueTime":
                    return `El tiempo para emitir el dictamen de la obra '${notification.titulo_original}' está a punto de finalizar, faltan 5 días.'`;
                case "evaluationAssessment":
                    return `Se ha finalizado la evaluación de la obra '${notification.titulo_original}'.`;
                case "purchaseAuthorization":
                    return `Se autorizó la compra de la obra '${notification.titulo_original}'.`;
                //AHDA 08/05/2019
                //Added legalDocumentsExpiration
                case "legalDocumentsExpiration": 
                    return `La obra '${notification.titulo_original}' tiene documentos que están por vencer`;  
                //AHDA 08/05/2019
                //Added registerWorkTime
                case "registerWorkTime": 
                    return `Ha expirado el tiempo para avanzar la obra '${notification.titulo_original}'`; 
                case "evaluatorSpecialistFile": 
                    return `El especialista evaluador ha cargado su dictamen`; 
                default: 
                return '';
            }
        }; 

        const getName = (type) => {
            switch(type){
                case "newWorkRegister":
                    return `Nueva Obra`;
                case "workEvaluationAssign":
                    return `Obra asignada`;
                case "additionalMaterialRequest":
                    return `Solicitud de material`;
                case "additionalMaterialReception":
                    return `Recepción de material`;
                case "dueTime":
                    return `Tiempo restante`;
                case "evaluationAssessment":
                    return `Fin de evaluación`;
                case "purchaseAuthorization":
                    return `Autorización de compra`;
                //AHDA 08/05/2019
                //Added legalDocumentsExpiration
                case "legalDocumentsExpiration": 
                    return "Documentos por vencer"; 
                //AHDA 08/05/2019
                //Added legalDocumentsExpiration
                case "registerWorkTime": 
                    return "Expiración de tiempo"; 
                case "evaluatorSpecialistFile": 
                    return "Especialista evaluador"; 
                default: 
                return '';
            }
        }; 

        /*getIcon = (type) => {
            switch(type){
                case "newWorkRegister":
                    return <LibraryAdd />;
                case "workEvaluationAssign":
                    return <LocalLibrary />;
                case "additionalMaterialRequest":
                    return <NoteAdd />;
                case "additionalMaterialReception":
                    return <PlaylistAddCheck />;
                case "dueTime":
                    return <Alarm />;
                case "evaluationAssessment":
                    return <Check />;
                case "purchaseAuthorization":
                    return <AttachMoney />;
                default: 
                return '';
            }
        };*/

        return(
            <div>
                <Grid container spacing={16}>
                    {
                        currentContent.map((notification, index) => (
                            <Grid item xs={12} sm={6} md={4} key={`notification_${type}_${index}`}>
                                <NotifyCard 
                                icon={icon} 
                                deleteItem={handleDeleteItem} 
                                notification={notification} 
                                content={getMessage(type, notification)} name={getName(type)} />
                            </Grid> 
                        ))
                    }
                </Grid>

               <Pagination
                    curpage={page}
                    totpages={pageNumbers.length}
                    boundaryPagesRange={1}
                    onChange={(page) => this.onPageChange(page)}
                    siblingPagesRange={1}
                    hideEllipsis={false}
                    onPrev={this.onPrev}
                    onNext={() => this.onNext(pageNumbers.length)}
                    onGoFirst={this.onGoFirst}
                    onGoLast={() => this.onGoLast(pageNumbers.length)}
                />
                
            </div>
           
        )
    }
}

PaginationNotification.propTypes = {
    contentsPerPage: PropTypes.number, 
    type: PropTypes.string, 
    content: PropTypes.array, 
    handleDeleteItem: PropTypes.func.isRequired, 
    icon: PropTypes.any
}

PaginationNotification.defaultProps = {
    content: [], 
    paginate: true, 
    list: false, 
    contentsPerPage: 9
}
export default PaginationNotification;