import React from 'react';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import PapperBlock from '../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import LoaderFull from '../../../components/Loader/LoaderFull';
//import styles from './asignaciones-jss'
import styles from './../Reportes/reportes-jss'
import { LanguageConsumer, LanguageCxt } from './../../../language/LanguageContext';

//Auxiliars 

import { textLabelsEs } from '../../../utils/tableTexts';
//Notifications  
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../components/Notification/CustomNotification';

//services 
import { apiPOST, returnCurrentUserId } from '../../../services/service_base'


import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';

// Modal
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import RegistroObra from './RegistroObra';
import CustomToolbarSelect from "../../../components/Tables/CustomToolbarSelect";

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
let selectablerows = [];
class AdvFilter extends React.Component {

  constructor(props) {
    super(props);

    this.handledGetRegisteredWorks = this.handledGetRegisteredWorks.bind(this)

    this.state = {
      columns: ['ID', 'Empresa/Proveedor',
        {
          name: 'Título de la obra',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                // JCMY 18/06/2019 Incidencia televisa AC-913
                <a className="cursorPointer" onClick={() => this.handleClickOpen(tableMeta.rowData[0])} >{value}</a>
              );
            }
          }
        }, 'Autor(es)/Nickname', 'País', 'Fecha de registro', 'Usuario registro', 'Género', 'Fuente', 'Etapa', 'Estatus', 'Usuario Asignado'//,'Título inglés','Título otro idioma'
      ],
      data: [],
      workId: 0,


      open: false,
      openStep: false,
      loading: false,
      rol: "0",
      user: "0",
      //Filters
      folio: "",
      empresa: 0,
      titulo: "",
      autor: "",
      pais: 0,
      estatus: 0,
      genre: 0,
      //Catalogues 
      empresas: [],
      works: [],
      authors: [],
      countries: [],
      genres: [],
      listStatus: [],
      dataCsV: [],
      //isanchez 06.05.2019
      chkExtendTermWork: false,
      chkReEvaulateWork: false,
      chkReasignWork: false,
      chkRegisterEditWork: false,
      chkRegisterUpgradeWork: false,
      chkViewAllWork: false,
      chkDeleteEvalFilesWorkReg: false,
      chkRegisterWorkUserData: false,
      chkRegisterEditWorkGeneralData: false,
      chkRegisterEditWorkSynopsisData: false,
      chkRegisterEditWorkCharactersData: false,
      chkRegisterEditWorkDiagramData: false,
      chkRegisterEditWorkMaterialData: false,
      chkRegisterEvaluationProcess: false
    }
  }

  //Modal
  handleClickOpen = (workId) => {

    //AHDA 10/05/2019
    //Added audit call 
    const auditBody = {
      id_usuario: returnCurrentUserId(),
      user_action: 18,
      work_id: workId,
      portal: 'obras',
      screen: 'registeredWorks'
    }
    apiPOST('/audit/add', auditBody).then().catch(err => console.log("Error in audit: ", err));

    let title = this.state.data.find(p => p[0] == workId)[2];
    this.setState({ openStep: true, workId: workId, workTitle: title });
  };

  handleCloseStep = () => {
    this.setState({ openStep: false });
    apiPOST('/works/getregisteredworks').then(
      response => {
        if (response.status === 200) {

          const result = [];
          for (const x in response.data) {
            var obj = [];
            obj.push(response.data[x].id_obras);
            obj.push(response.data[x].empresa ? response.data[x].empresa : response.data[x].companyProvider ? response.data[x].companyProvider : '-');
            obj.push(response.data[x].titulo);
            // obj.push(response.data[x].autores);
            obj.push(response.data[x].autores ? response.data[x].authores ? `${response.data[x].authores}/${response.data[x].autores}` : response.data[x].autores : response.data[x].authores ? response.data[x].authores : '-');

            // obj.push(response.data[x].authores);
            obj.push(response.data[x].pais);
            obj.push(response.data[x].fechaRegistro);
            obj.push(response.data[x].usuarioRegistro);
            obj.push(response.data[x].genero);
            obj.push(response.data[x].fuente);
            obj.push(response.data[x].etapa);
            obj.push(response.data[x].estatus);
            obj.push(response.data[x].usuarioAsignado);
            // obj.push(response.data[x].tituloEN?(response.data[x].tituloEN.trim()==""?'-':response.data[x].tituloEN):'-');
            // obj.push(response.data[x].tituloOtro?(response.data[x].tituloOtro.trim()==""?'-':response.data[x].tituloOtro):'-');
            result.push(obj);
          }
          this.setState({ data: result });
        }
      }
    ).catch(
      err => {
        console.log(err);
      }
    );
  }

  //Notifications 
  handleClose = () => {
    this.setState({ open: false });
  }


  handledGetRegisteredWorks() {
    this.setState({ loading: true });
    apiPOST('/works/getregisteredworks').then(
      response => {
        if (response.status === 200) {

          const result = [];
          for (const x in response.data) {
            var obj = [];
            obj.push(response.data[x].id_obras);
            obj.push(response.data[x].empresa ? response.data[x].empresa : response.data[x].companyProvider ? response.data[x].companyProvider : '-');
            obj.push(response.data[x].titulo);
            obj.push(response.data[x].autores ? response.data[x].authores ? `${response.data[x].authores}/${response.data[x].autores}` : response.data[x].autores : response.data[x].authores ? response.data[x].authores : '-');
            obj.push(response.data[x].pais);
            obj.push(response.data[x].fechaRegistro);
            obj.push(response.data[x].usuarioRegistro);
            obj.push(response.data[x].genero);
            obj.push(response.data[x].fuente);
            obj.push(response.data[x].etapa);
            obj.push(response.data[x].estatus);
            obj.push(response.data[x].usuarioAsignado);
            obj.push(response.data[x].tituloEN ? (response.data[x].tituloEN.trim() == "" ? '-' : response.data[x].tituloEN) : '-');
            obj.push(response.data[x].tituloOtro ? (response.data[x].tituloOtro.trim() == "" ? '-' : response.data[x].tituloOtro) : '-');
            result.push(obj);
          }
          this.setState({ data: result, loading: false });
        }
        else {
          this.setState({ open: true, loading: false });
        }
      }
    ).catch(
      err => {
        console.log(err);
        this.setState({ open: true, loading: false });
      }
    );
    this.setState({ loading: false })
  }


  componentDidMount() {
    //isanchez 06.05.2019 load permissions
    let config = JSON.parse(localStorage.getItem("user_permissions")).filter(x => x.menu_id === 21)[0];
    let configPermission = "";
    if (config !== undefined) {
      configPermission = JSON.parse(config.grant_configuration);
      this.setState({
        chkExtendTermWork: configPermission.chkExtendTermWork ? configPermission.chkExtendTermWork : false,
        chkReEvaulateWork: configPermission.chkReEvaulateWork ? configPermission.chkReEvaulateWork : false,
        chkReasignWork: configPermission.chkReasignWork ? configPermission.chkReasignWork : false,
        chkRegisterEditWork: configPermission.chkRegisterEditWork ? configPermission.chkRegisterEditWork : false,
        chkRegisterUpgradeWork: configPermission.chkRegisterUpgradeWork ? configPermission.chkRegisterUpgradeWork : false,
        chkViewAllWork: configPermission.chkViewAllWork ? configPermission.chkViewAllWork : false,
        chkDeleteEvalFilesWorkReg: configPermission.chkDeleteEvalFilesWorkReg ? configPermission.chkDeleteEvalFilesWorkReg : false,
        // Interla Registered Word Actions Permissions

        chkRegisterWorkUserData: configPermission.chkRegisterWorkUserData ? configPermission.chkRegisterWorkUserData : false,
        chkRegisterEditWorkGeneralData: configPermission.chkRegisterEditWorkGeneralData ? configPermission.chkRegisterEditWorkGeneralData : false,
        chkRegisterEditWorkSynopsisData: configPermission.chkRegisterEditWorkSynopsisData ? configPermission.chkRegisterEditWorkSynopsisData : false,
        //chkRegisterViewWorkCharactersData: configPermission.chkRegisterViewWorkCharactersData ? configPermission.chkRegisterViewWorkCharactersData : false,
        chkRegisterEditWorkCharactersData: configPermission.chkRegisterEditWorkCharactersData ? configPermission.chkRegisterEditWorkCharactersData : false,
        chkRegisterEditWorkDiagramData: configPermission.chkRegisterEditWorkDiagramData ? configPermission.chkRegisterEditWorkDiagramData : false,
        chkRegisterEditWorkMaterialData: configPermission.chkRegisterEditWorkMaterialData ? configPermission.chkRegisterEditWorkMaterialData : false,
        chkRegisterEvaluationProcess: configPermission.chkRegisterEvaluationProcess ? configPermission.chkRegisterEvaluationProcess : false
      }, () => {
        // Obtengo obras    
        this.handledGetRegisteredWorks();
      });
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { columns, data } = this.state;
    const options = {
      filterType: 'dropdown',
      responsive: 'scroll',
      download: false,
      print: false,
      rowsPerPage: 100,
      page: 0,
      // CH 23/04/2019 Se personaliza el boton al seleccionar datos de tabla
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {

        // const selected = selectedRows.data.map(e=>e.index);
        // let status ="";
        // let tempavailable = []
        // displayData.forEach(e=>{
        //   if(selected.includes(e.dataIndex)){
        //     if(status=="" || e.data[10]==status){
        //       status=e.data[10];
        //     }
        //   }
        //   if(e.data[10]==status){
        //     tempavailable.push(e.dataIndex)
        //   }
        // })
        // selectablerows=tempavailable
        return <CustomToolbarSelect getRegisteredWorks={() => this.handledGetRegisteredWorks()} selectedRows={selectedRows} displayData={displayData} textBtn="Reasignar Obra" reasignar={this.state.chkReasignWork} setSelectedRows={setSelectedRows} />
      },
      customSort: (data, colIndex, order) => {
        if (order == 'asc') {
          return data.sort((a, b) => {
            return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1);
          });
        } else {
          return data.sort((a, b) => {
            return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'asc' ? 1 : -1);
          });

        }

      },
      selectableRows: this.state.chkReasignWork,
      // onRowsSelect:(currentRowsSelected, allRowsSelected)=>{
      //   if(allRowsSelected.length==0){
      //     selectablerows=[];
      //   }
      // },
      // isRowSelectable:(index)=>{
      //   return selectablerows.length==0?true:(selectablerows.includes(index)?true:false)
      // },
      textLabels: textLabelsEs
      // JCMY 05/06/2019
    };
    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className="marginTopLogo">
              <PapperBlock title="Obras Registradas" desc="">
                {this.state.loading ? <LoaderFull /> : ''}
                {/* Inicio Filtros */}
                {
                  this.state.rol !== "External Users" ?
                    <form>
                      {/* GEO 27/03/2019 Se agrega div para alinear a la derecha los botones y dar margen hacia abajo. */}
                      <div className={classes.alignButtons}>
                        {/* <Button variant="contained" onClick={this.handleClickOpen} color="primary" className={classes.button}>
                          Nueva Obra
                      </Button> */}
                        {/* Modal Steper */}
                        <Dialog
                          fullScreen
                          open={this.state.openStep}
                          onClose={this.handleCloseStep}
                          TransitionComponent={Transition}
                        >
                          <AppBar className={classes.appBar}>
                            <Toolbar>
                              <Typography variant="h6" color="inherit" className={classes.flex}>
                                {this.state.workTitle}
                              </Typography>
                              <IconButton color="inherit" onClick={this.handleCloseStep} aria-label="Close">
                                <CloseIcon />
                              </IconButton>
                            </Toolbar>
                          </AppBar>

                          {/* AHDA 10/05/2019: Added screen */}
                          {/* isanchez 06.05.2019 add permissions to RegistroObra */}
                          <RegistroObra
                            workId={this.state.workId}
                            getRegisteredWorks={() => this.handledGetRegisteredWorks()}
                            canAddWork={this.state.chkRegisterEditWork}//
                            canEditWork={this.state.chkRegisterEditWork}
                            canEditWorkUserData={this.state.chkRegisterWorkUserData}
                            canEditWorkGeneralData={this.state.chkRegisterEditWorkGeneralData}
                            canEditWorkSynopsisData={this.state.chkRegisterEditWorkSynopsisData}
                            canEditWorkCharactersData={this.state.chkRegisterEditWorkCharactersData}
                            canEditWorkDiagramData={this.state.chkRegisterEditWorkDiagramData}
                            canEditWorkMaterialData={this.state.chkRegisterEditWorkMaterialData}
                            canUpgradeWork={this.state.chkRegisterUpgradeWork}
                            canReevaluateWork={this.state.chkReEvaulateWork}
                            canExtendTermWork={this.state.chkExtendTermWork}
                            canDeleteEvalFilesWorkReg={this.state.chkDeleteEvalFilesWorkReg}
                            canSeeEvaluationProcess={this.state.chkRegisterEvaluationProcess}
                            screen={"registeredWorks"}
                            onClose={this.handleCloseStep}
                          />

                        </Dialog>

                      </div>

                    </form> :
                    null
                }
                {/* Fin Filtros */}
                <div className={classes.asignaciones}>
                  <MUIDataTable
                    title=""
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </div>

                {/*for error notifications*/}
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={this.handleClose}>
                  <CustomNotification
                    onClose={this.handleClose}
                    variant="error"
                    message={lang.common.errMsg} />
                </Snackbar>
              </PapperBlock>
            </div>
        }
      </LanguageConsumer>

    );
  }
}

AdvFilter.contextType = LanguageCxt;

AdvFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  reasignar: PropTypes.bool,
};

export default withStyles(styles)(AdvFilter);
