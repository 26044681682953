import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import { Input, InputLabel } from '@material-ui/core';
//import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';

//import InputAdornment from '@material-ui/core/InputAdornment';
//import PermContactCalendar from '@material-ui/icons/PermContactCalendar';

import styles from './profile-jss';
import { Alphanumeric } from '../../../../validators/Regex';

//AHDA 03/04/2019
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

class AddProfileForm extends React.Component {


  handleChangeChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  saveRef = ref => {
    this.ref = ref;
    return this.ref;
  };

  state = {
    nameRol: '',
    description: '',
    isUpdating:false
  }

  resetValues = () => {
    this.setState({
      nameRol: '',
      description: ''
    })
  }

  handleChange = event => {
    if(!Alphanumeric(event.target.value)){
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  componentWillReceiveProps(newProps) {

    if (newProps.dataInit != null) {
      this.setState({
        nameRol: newProps.dataInit.name,
        description: newProps.dataInit.descripcion,
        isUpdating:true
      });
    } 
    
    if(!newProps.isOpen){
      this._clearData();
      this.setState({
        isUpdating:false
      })
    }
  }

  _clearData = () => {
    this.setState({
      nameRol: '',
      description: ''
    });
  }

  //Life cycle
  componentDidMount (){
    //AHDA 03/04/2019
    //Empty strings validation
    ValidatorForm.addValidationRule('isSpaceEmpty', (value) => {
      if (/^\s+$/i.test(value)) {
          return false;
      }
      return true;
    });
  }

  render() {
    const {
      classes,
      pristine,
      submitting,
      handleSubmit
    } = this.props;

    return (
      <div>
        {/*AHDA 03/04/2019
        Change of components*/}
        <ValidatorForm className={classes.container} onSubmit={(event) => {
          handleSubmit(event, this.state);
        }}>
          <section className={classes.bodyForm}>
            <Grid
              container spacing={0}
            >
              <Grid item xs={12}>
                <TextValidator
                  label="Nombre"
                  placeholder="Escribe un nombre de perfil"
                  id="nameRol"
                  name="nameRol"
                  value={this.state.nameRol}
                  className={classNames(classes.textField, classes.w100)}
                  onChange={this.handleChange}
                  validators={['required', 'isSpaceEmpty']}
                  errorMessages={['Campo requerido', 'Campo requerido']}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  label="Descripción"
                  placeholder="Escriba una breve descripción"
                  id="description"
                  name="description"
                  multiline
                  margin="normal"
                  value={this.state.description}
                  className={classNames(classes.textField, classes.w100)}
                  onChange={this.handleChange}
                  validators={['required', 'isSpaceEmpty']}
                  errorMessages={['Campo requerido', 'Campo requerido']}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
          </section>
          <div className={classes.buttonArea}>
            <Button variant="contained" color="secondary" className={classes.btnBlue} type="submit" disabled={submitting}>
              Guardar
            </Button>
            <Button
              type="button"
              disabled={pristine || submitting}
              onClick={() => { this._clearData() }}
            >
              Limpiar
            </Button>
          </div>
        </ValidatorForm>
      </div>
    );
  }
}

AddProfileForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  onDrop: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  imgAvatar: PropTypes.string,
  dataInit: PropTypes.object
};


export default withStyles(styles)(AddProfileForm);
