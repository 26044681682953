import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import brand from '../../../utils/brand';
import ConfirmUserForm from '../../../components/Forms/ConfirmUserForm';
import styles from './../../../components/Forms/user-jss';
import logo_televisa from '../../../../static/images/logo-televisa.svg'
import './Login.css'

//Services 
import { checkHasSession } from './../../../services/cognito_service'; 

class ConfirmUser extends React.Component {

  constructor(props){
    super(props); 
    checkHasSession(); 
  }

  /*UNSAFE_componentWillMount(){
    checkHasSession(); 
  }*/

  render() {
    const title = brand.name + ' - Confirmar Registro';
    const description = brand.desc;
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet>
        <div className={classes.container}>
          <Grid container spacing={24} alignItems="center" direction="row" justify="center">
            <Grid item container justify="center" spacing={0} className={classes.loginWrap}>
              <Hidden smDown>
                <Grid item md={6} className={classes.welcomeWrap}>
                  {/* Welcome Login */}
                  <div className={classes.welcome}>
                    {/* <div className={classes.welcomeContent}>
                      <div className={classes.brand}> */}
                        <img src={logo_televisa} alt={brand.name} className="centrarLogo" />
                      {/* </div>
                    </div> */}
                    {/* <ArrowForward className={classes.decoBottom} /> */}
                  </div>
                </Grid>
              </Hidden>
              <Grid item md={6} sm={8} xs={11}>
                <ConfirmUserForm/>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

ConfirmUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConfirmUser);