import fondo from '../../../static/images/material_bg.png';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    cover: {
        '& $name, & $subheading': {
            color: theme.palette.common.white,
        },
        marginTop: 90,
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        height: 300,
        backgroundColor: theme.palette.primary.dark,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        borderRadius: 2,
        backgroundSize: 'cover',
        textAlign: 'center',
        boxShadow: theme.shadows[7],
    },
    content: {
        background: `url(${fondo}), linear-gradient(93.01deg, #F62E1F 0.18%, #FF5702 49.51%, #F68E5C 98.85%)`,
        height: '100%',
        width: '100%',
        padding: `70px ${theme.spacing.unit * 3}px 30px`,
    },
    name: {},
    subheading: {},
    avatar: {
        width: 120,
        height: 120,
        border: '3px solid rgba(255, 255, 255, .5)',
        objectFit: 'cover',
    },
    opt: {
        position: 'absolute',
        top: 10,
        right: 10,
        '& button': {
            color: theme.palette.common.white,
        },
    },
    verified: {
        margin: theme.spacing.unit,
        top: 10,
        position: 'relative',
    },
    button: {
        marginTop: theme.spacing.unit,
    },
    unsetFlex: {
        display: 'unset',
    },
});

export default styles;
