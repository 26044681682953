const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    width: '100%',
  },
  tableHead: {
    background: '#f5f5f5',
  },
  stripped: {
    '& tbody tr:nth-child(even)': {
      background: '#f5f5f5',
    },
  },
  inputMini: {
    maxWidth: '60px !important',
  },
  actionCell: {
    maxWidth: 250,
  },
  alignTop: {
    paddingBottom: theme.spacing.unit * 1.5,
    paddingTop: theme.spacing.unit,
    verticalAlign: 'top',
  },
  button: {
    margin: theme.spacing.unit,
  },
  cajaAviso: {
    maxWidth: 300,
    maxHeight: 180,
    overflow: 'auto',
    paddingRight: 10,
  },
  titleContenido: {
    color: '#4a4949',
    fontSize: 12,
    fontWeight: 'bold',
  },
  textContenido: {
    color: '#7d7d7d',
    fontSize: 12,
    textAlign: 'justify',
  },
  ajusteTabla: {
    overflowX: 'auto',
    width: '100%',
  },
  valueCell: {
    maxWidth: '70%',
  },
  sizeCell: {
    minWidth: 180,
  },
  styleCell: {
    verticalAlign: 'top',
    paddingBottom: theme.spacing.unit,
    paddingTop: theme.spacing.unit,
  },
  actionButton: {
    marginLeft: theme.spacing.unit,
  },
  flexRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  tableRowNoBorderBottom: {
    borderBottom: 'unset',
    '& td': {
      borderBottom: 'unset',
    },
  },
  inputSelect: {
    minWidth: 150,
    maxWidth: '80%',
    width: '100%'
  },
  deleteButton: {
    marginLeft: theme.spacing.unit,
    '& svg': {
      height: '0.85em',
      width: '0.85em',
    },
  },
  actionCellPadding: {
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    width: 100, 
  },
  arrayRowItem: {
    minHeight: theme.spacing.unit * 5,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      width: '100%',
    },
  },
  buttonAdd: {
    marginLeft: theme.spacing.unit,
    flexShrink: 0,
    position: 'relative',
  },
  buttonAction: {
    margin: `0 ${theme.spacing.unit}px 0`,
    flexShrink: 0,
  },
  buttonActionDelete: {
    '& svg': {
      height: '0.85em',
      width: '0.85em',
    },
  },
});

export default styles;
