import React from 'react';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PapperBlock from '../../../components/PapperBlock/PapperBlock';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import LoaderFull from '../../../components/Loader/LoaderFull';
//import styles from './asignaciones-jss'
import styles from './../Reportes/reportes-jss'
import Paper from '@material-ui/core/Paper';
import { LanguageConsumer, LanguageCxt } from './../../../language/LanguageContext';

//Auxiliars 
//import { convertToStringArray } from '../../../utils/helpers'
import { textLabelsEs } from '../../../utils/tableTexts';
import { getRedirectUrl } from '../../../helpers/general'; //AHDA 25/04/2019: Imported auxiliar method 

//Notifications  
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../components/Notification/CustomNotification';

//services 
import { apiPOST, returnCurrentUserId } from '../../../services/service_base'

//Filtros
import { renderInput, getSuggestions, renderSuggestion } from './../Reportes/autocomplete'
import Downshift from 'downshift';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
//import CloudDownload from '@material-ui/icons/CloudDownload';
//import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

// Modal
import NewWorkDialog from '../../../components/Works/NewWorkDialog';
import SweetAlert from 'sweetalert-react';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

//Keys to convert object to array 
//const keysInternal = ['id_obras', 'empresa', 'tituloObra', 'autor', 'pais', 'fechaRegistro', 'genero', 'estatus'];
//const keysExternal = ['id_obras', 'tituloObra', 'autor', 'tipoObra', 'fechaRegistro', 'estatus'];

class AdvFilter extends React.Component {

  constructor(props) {
    super(props);

    // JCMY 02/05/2019
    this.handledGetRegisteredWorks = this.handledGetRegisteredWorks.bind(this)

    this.state = {
      columns: [],
      data: [],
      open: false,
      block: false,
      openStep: false,
      loading: false,
      rol: "0",
      user: "0",
      selected: "", //AHDA 28/05/2019
      //Filters
      idObra: "",
      empresa: 0,
      titulo: "",
      autor: "",
      pais: 0,
      estatus: 0,
      genre: 0,
      //Catalogues 
      empresas: [],
      works: [],
      authors: [],
      countries: [],
      genres: [],
      listStatus: [],
      dataCsV: [],
      workId: 0,
      chkAddWork: false,
      chkEditWork: false,
      chkUpgradeWork: false,
      chkViewWork: false,
      selectDetail: null
    }
  }

  //Modal
  //AHDA 28/05/2019: Added value
  handleClickOpen = (workId, value, index) => {
    if (localStorage.getItem('fieldsStep0') != null && workId == 0) {
      this.setState({
        show: true,
      });
    } else {

      if (workId > 0) {
        //AHDA 10/05/2019
        //Added audit call 
        const auditBody = {
          id_usuario: returnCurrentUserId(),
          user_action: 18,
          work_id: workId,
          portal: 'obras',
          screen: 'myAsignations'
        }
        apiPOST('/audit/add', auditBody).then().catch(err => console.log("Error in audit: ", err));
      }

      //AHDA 29/05/2019: Added isAssignation}
      const auxItem = this.state.data[index];
      const assigned =
        !auxItem
          ? false
          : auxItem[auxItem.length - 1] == returnCurrentUserId()
            ? true : false; //Last position is user id

      //AHDA 28/05/2019: Added selected
      this.setState({ openStep: true, workId: workId, show: false, selected: value, isAssignation: assigned });
    }

  };

  handleConfirmSwal = () => {
    localStorage.removeItem('fieldsStep0');
    this.setState({ openStep: true, show: false });
  }

  handleCloseStep = () => {
    if (localStorage.getItem('fieldsStep2')) {
      this.setState({ block: true, loading: false });
    } else {
      this.handleSearch()
      this.setState({ openStep: false });
    }
  }

  //Notifications 
  handleClose = () => {
    this.setState({ open: false });
  }

  componentDidMount() {

    this.setState({ loading: true });

    let configPermission = "";

    //AHDA 23/04/2019
    //Changed user and added rdsUser
    let user = JSON.parse(localStorage.getItem('current_user'));
    const rdsUser = user ? user.rdsUser : undefined;
    let body = {};

    /*isanchez fix permissions home 26.03.19*/
    apiPOST("/roles/getmenuaction", {
      rolId: rdsUser.id_rol
    }).then(response => {

      let menuPermissions = response.data;
      let config = menuPermissions.filter(x => x.menu_id === 11)[0];
      const auxConfig = menuPermissions.filter(c => c.grant_configuration.indexOf("true") > -1); //AHDA 28/05/2019
      let redirect = false; //AHDA 28/05/2019

      //AHDA 28/05/2019
      if (config !== undefined) {
        configPermission = JSON.parse(config.grant_configuration);
        redirect = configPermission.chkViewWork ? false : true;
      } else {
        redirect = true;
      }

      //AHDA 28/05/2019
      if (redirect) {
        if (auxConfig.length > 0) {
          let redirect = getRedirectUrl(auxConfig);
          if (redirect) {
            this.props.history.push(redirect);
          }
        }
      }

      //Table columns 
      const { lang } = this.context;

      const columnsInternal = [
        "ID",
        lang.reports['compProv'],
        {
          name: lang.reports['title'],
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              //AHDA 28/05/2019: added value to onClick, added rowIndex
              return (
                //GEO 10/04/2019
                //Se agrega clase para que tenga cursor de manita enlace de tabla.
                <a className="cursorPointer" onClick={() => this.handleClickOpen(tableMeta.rowData[0], value, tableMeta.rowIndex)} >{value ? value : "-"}</a>
              );
            }
          }
        },
        lang.reports['authors'],
        lang.reports['country'],
        lang.reports['source'],
        lang.reports['registerDate'],
        lang.reports['genre'],
        lang.reports['status'],
        "Usuario Asignado",
        {
          name: "id_user",
          label: "id_user",
          options: {
            filter: false,
            display: false,
            viewColumns: false
          }
        } //AHDA 29/05/2019
        // ,{
        //   name: "tituloEN",
        //   label: "Título inglés",
        //   options: {
        //     filter: true,
        //     display: true,
        //     viewColumns: true
        //   }
        // }
        // ,{
        //   name: "tituloOther",
        //   label: "Otros idiomas",
        //   options: {
        //     filter: true,
        //     display: true,
        //     viewColumns: true
        //   }
        // }
      ];

      const columnsExternal = [
        "ID",
        {
          name: lang.reports['title'],
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              //AHDA 28/05/2019: added value to onClick
              return (
                <a className="cursorPointer" onClick={() => this.handleClickOpen(tableMeta.rowData[0], value)} >{value ? value : "-"}</a>
              );
            }
          }
        },
        lang.reports['authors'],
        lang.reports['playType'],
        lang.reports['registerDate'],
        lang.reports['status'],
        {
          name: lang.reports['epComments'],
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                (value && value != "" && value.length > 15) ?
                  <div className="columnComments">
                    <div className='textEllipsis' >
                      <span >{value === "" ? "-" : value}</span>
                    </div>
                    <IconButton className="cursorPointer" aria-label="Details" onClick={() => this.handleOpenDetail(tableMeta.rowData)}>
                      <MoreHorizIcon />
                      {/* <SearchIcon /> */}
                    </IconButton>
                    {/* <a className="cursorPointer" onClick={() => this.handleClickOpen(tableMeta.rowData)} >
                          <span>{value === "" ? "-" : value}</span>
                          &nbsp;
                          <strong>...</strong>
                      </a> */}
                  </div>
                  : value
              );
            }
          }
        }
      ];

      //Get current user
      const user = JSON.parse(localStorage.getItem('current_user'))

      //AHDA 23/04/2019
      //Changed validation
      this.setState({
        columns: rdsUser.id_rol === undefined ? columnsExternal : rdsUser.id_rol === 12 ? columnsExternal : columnsInternal,
        chkViewWork: configPermission.chkViewWork,
        chkAddWork: configPermission.chkAddWork,
        chkEditWork: configPermission.chkEditWork,
        chkUpgradeWork: configPermission.chkUpgradeWork
      });
    });

    if (rdsUser.id_rol !== undefined) {

      //Fetch catalogues (only for televisa users): 
      if (rdsUser.asigned_profile !== 12) {
        //Companies
        //AHDA 17/05/2019: added validation for refresh 
        apiPOST('/catalogos/getCatalogo', { idCatalogo: 7 }, true).then(
          response => {
            if (response.status === 200) {
              this.setState({ empresas: response.data })
            }
          }
        )

        //AHDA 10/05/2019
        //Changed catalogue number
        //Works
        //AHDA 17/05/2019: added validation for refresh 
        apiPOST('/catalogos/getCatalogo', { idCatalogo: 40 }, true).then(
          response => {
            if (response.status === 200) {
              this.setState({ works: response.data })
            }
          }
        )

        //Authors
        //AHDA 17/05/2019: added validation for refresh 
        apiPOST('/catalogos/getCatalogo', { idCatalogo: 18 }, true).then(
          response => {
            if (response.status === 200) {
              this.setState({ authors: response.data })
            }
          }
        )

        //Countries
        //AHDA 17/05/2019: added validation for refresh 
        apiPOST('/catalogos/getCatalogo', { idCatalogo: 3 }, true).then(
          response => {
            if (response.status === 200) {
              this.setState({ countries: response.data })
            }
          }
        )

        //Genres
        //AHDA 17/05/2019: added validation for refresh 
        apiPOST('/catalogos/getCatalogo', { idCatalogo: 4 }, true).then(
          response => {
            if (response.status === 200) {
              this.setState({ genres: response.data })
            }
          }
        )

        //Status
        //AHDA 17/05/2019: added validation for refresh 
        apiPOST('/catalogos/getCatalogo', { idCatalogo: 2 }, true).then(
          response => {
            if (response.status === 200) {
              this.setState({ listStatus: response.data })
            }
          }
        )
      }

      //AHDA 23/04/2019
      //Commented method 
      //Get initial request (some roles see every work)
      /*switch (user["cognito:groups"][0]) {
        case 'Administrador_del_Sistema':
        case 'Directivo_Control_de_Operaciones':
        case 'Directivo_Desarrollo_de_Contenido':
        case 'Jefe_Nuevos_Contenidos':
        case 'Supervisor_Evaluador':
          //AHDA 03/04/2019
          //Change in services, idObra with -1
          body = {
            usuarioCognito: 0,
            rol: 0,
            idObra: -1,
            empresaObra: 0,
            tituloObra: "0",
            autorObra: "0",
            paisObra: 0,
            generoObra: 0,
            estatusObra: 0
          }
          break;
        default:
          //AHDA 03/04/2019
          //Change in services, idObra with -1
          body = {
            usuarioCognito: user["cognito:username"],
            rol: user.rdsUser.asigned_profile,
            idObra: -1,
            empresaObra: 0,
            tituloObra: "0",
            autorObra: "0",
            paisObra: 0,
            generoObra: 0,
            estatusObra: 0
          }
          this.setState({ rol: body.rol, user: body.usuarioCognito });
          break;
      }*/

      //AHDA 23/04/2019
      //Added body

      let idRolRelacionado = 0;
      let usuarioCognito = user.rdsUser.id_usuario;
      switch (rdsUser.id_rol) {
        // Especialista Evaluador  
        case 21: idRolRelacionado = 19; break;
        // Director de Análisis
        // case 19: idRolRelacionado = 20; break;
        // Jefe de Análisis de Contenido
        case 20: idRolRelacionado = 20; break;
        // empresa proveedor
        case 12: idRolRelacionado = 12; break;
        default: break;
      }
      body = {
        usuarioCognito,
        idRolRelacionado,
        rol: rdsUser.id_rol,
        idObra: -1,
        empresaObra: 0,
        tituloObra: "0",
        autorObra: "0",
        paisObra: 0,
        generoObra: 0,
        estatusObra: 0
      }
    }
    else {
      body = {
        usuarioCognito: user.rdsUser.id_usuario,
        rol: 0,
        idObra: -1,
        empresaObra: 0,
        tituloObra: "0",
        autorObra: "0",
        paisObra: 0,
        generoObra: 0,
        estatusObra: 0
      }
    }

    //AHDA 23/04/2019
    //Changed state to the end 
    this.setState({ rol: body.rol, user: body.usuarioCognito });
    //TODO: obtener datos de tabla 
    this.handleFetch(body);
  }

  handleSearch = () => {
    this.setState({ loading: true })
    //AHDA 03/04/2019
    //Change in services, idObra with -1
    let idRolRelacionado = 0;
    let usuarioCognito = this.state.user;
    switch (this.state.rol) {
      // Especialista Evaluador  
      case 21: idRolRelacionado = 19; break;
      // Director de Análisis
      // case 19: idRolRelacionado = 20; break;
      // Jefe de Análisis de Contenido
      case 20: idRolRelacionado = 20; break;
      // empresa proveedor
      case 12: idRolRelacionado = 12; break;
      default: break;
    }

    let body = {
      usuarioCognito,
      rol: this.state.rol,
      idRolRelacionado,
      idObra: this.state.idObra === "" ? -1 : this.state.idObra,
      empresaObra: this.state.empresa,
      tituloObra: this.state.titulo === "" ? 0 : this.state.titulo === "TODOS" ? 0 : this.state.titulo,
      autorObra: this.state.autor === "" ? 0 : this.state.autor === "TODOS" ? 0 : this.state.autor,
      paisObra: this.state.pais,
      generoObra: this.state.genre,
      estatusObra: this.state.estatus
    }

    this.handleFetch(body);
  }

  handleFetch = (body) => {
    apiPOST('/reportes/misAsignaciones', body).then(
      response => {
        if (response.status === 200) {
          const user = JSON.parse(localStorage.getItem('current_user'))
          const result = [];
          for (const x in response.data) {
            var obj = [];


            if (user.rdsUser.id_rol === 12) {
              obj.push(response.data[x].id_obras ? response.data[x].id_obras : "-");
              obj.push(response.data[x].tituloObra ? response.data[x].tituloObra : "-");
              // JCMY 12/07/2019
              // isanchez 31.07.2019 validate null
              let cAutor = response.data[x].catalogue_author ? `${response.data[x].catalogue_author}, ` : '';
              obj.push(response.data[x].autor ? `${cAutor}${response.data[x].autor}` : `${cAutor}`);
              // JCMY 08/07/2019 incidencia 1056
              obj.push(response.data[x].tipoObra === 'primigenia' ? 'Primigenia' : response.data[x].tipoObra === 'adaptacion' ? 'Adaptación' : "-");
              obj.push(response.data[x].fechaRegistro ? response.data[x].fechaRegistro : "-");
              console.log(response.data[x].id_obras, response.data[x].estatus)
              // JCMY 07/05/2019
              switch (response.data[x].estatus) {
                case "Registrada":
                case "Más material":
                case "Solicitar ajustes":
                  obj.push("REGISTRADA");
                  break;
                case "Asignada":
                case "Preevaluación":
                  obj.push("EN EVALUACIÓN");
                  break;
                case "Viable":
                case "En proceso de compra":
                case "Comprar":
                case "En condiciones de negociación":
                case "Con potencial":
                  obj.push("EVALUADA");
                  break;
                case "Solicitud de elaboración de contrato":
                case "En firmas":
                case "En proceso de pago":
                  obj.push("COMPRA");
                  break;
                case "Comprada":
                case "Compra directa":
                  obj.push("COMPRADA");
                  break;
                case "No viable":
                case "Con potencial para otros formatos":
                  obj.push("RECHAZADA");
                  break;
                // JCMY 22/07/2019 incidencia 1072
                case null:
                  obj.push("-");
                  break;
              }
              obj.push(response.data[x].comentariosEP ? response.data[x].comentariosEP : "-");
              // obj.push(response.data[x].tituloEN?(response.data[x].tituloEN.trim()==""?'-':response.data[x].tituloEN):'-');
              // obj.push(response.data[x].tituloOtro?(response.data[x].tituloOtro.trim()==""?'-':response.data[x].tituloOtro):'-');
              // } else if(user.rdsUser.id_rol === 18){
              //   if(["En proceso de compra","Comprar","En condiciones de negociación","Compra directa",
              //   "Solicitud de elaboración de contrato","En firmas", "En proceso de pago", "Comprada"].includes(response.data[x].estatus)
              //   ){
              //     obj.push(response.data[x].id_obras ? response.data[x].id_obras : "-");
              //     obj.push(response.data[x].catalogue_provider ? response.data[x].catalogue_provider : response.data[x].empresa ? response.data[x].empresa :"-");
              //     obj.push(response.data[x].tituloObra ? response.data[x].tituloObra : "-");
              //     obj.push(response.data[x].catalogue_author ? response.data[x].autor ? `${response.data[x].autor} ${response.data[x].catalogue_author}` : response.data[x].catalogue_author : response.data[x].autor ? `${response.data[x].autor}` : "-");
              //     obj.push(response.data[x].pais ? response.data[x].pais : "-");
              //     obj.push(response.data[x].fuente ? response.data[x].fuente : "-");
              //     obj.push(response.data[x].fechaRegistro ? response.data[x].fechaRegistro : "-");
              //     obj.push(response.data[x].genero ? response.data[x].genero : "-");
              //     obj.push(response.data[x].estatus != null ? response.data[x].estatus : "-"); //JCMY 06/06/2019
              //     obj.push(response.data[x].id_usuario); //AHDA 29/05/2019
              //     obj.push(response.data[x].tituloEN?(response.data[x].tituloEN.trim()==""?'-':response.data[x].tituloEN):'-');
              //     obj.push(response.data[x].tituloOtro?(response.data[x].tituloOtro.trim()==""?'-':response.data[x].tituloOtro):'-');
              //   }
            } else {
              //AHDA 30/05/2019: Added validations, changed author and provivder
              obj.push(response.data[x].id_obras ? response.data[x].id_obras : "-");
              obj.push(response.data[x].catalogue_provider ? response.data[x].catalogue_provider : response.data[x].empresa ? response.data[x].empresa : "-");
              obj.push(response.data[x].tituloObra ? response.data[x].tituloObra : "-");
              obj.push(response.data[x].catalogue_author ? response.data[x].autor ? `${response.data[x].autor} ${response.data[x].catalogue_author}` : response.data[x].catalogue_author : response.data[x].autor ? `${response.data[x].autor}` : "-");
              obj.push(response.data[x].pais ? response.data[x].pais : "-");
              obj.push(response.data[x].fuente ? response.data[x].fuente : "-");
              obj.push(response.data[x].fechaRegistro ? response.data[x].fechaRegistro : "-");
              obj.push(response.data[x].genero ? response.data[x].genero : "-");
              obj.push(response.data[x].estatus != null ? response.data[x].estatus : "-"); //JCMY 06/06/2019
              obj.push(response.data[x].usuarioAsignado ? (response.data[x].usuarioAsignado.trim() == "" ? '-' : response.data[x].usuarioAsignado) : '-');
              obj.push(response.data[x].id_usuario); //AHDA 29/05/2019
              obj.push(response.data[x].tituloEN ? (response.data[x].tituloEN.trim() == "" ? '-' : response.data[x].tituloEN) : '-');
              obj.push(response.data[x].tituloOtro ? (response.data[x].tituloOtro.trim() == "" ? '-' : response.data[x].tituloOtro) : '-');
            }

            result.push(obj);
          }
          this.setState(
            {
              data: result,
              loading: false,
              dataCsV: result
            });
        }
        else {
          this.setState({ open: true, loading: false });
        }
      }
    ).catch(
      err => {
        console.log(err);
        this.setState({ open: true, loading: false });
      }
    );
  }

  //Inputs
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //*AHDA 02/04/2019
  //Input validation, only numbers 
  handleChangeID = event => {
    if (/^[0-9]*$/i.test(event.target.value) || event.target.value === "") {
      this.setState({ idObra: event.target.value });
    }
  }

  handleStateChangeT = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ titulo: changes.selectedItem })
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ titulo: changes.inputValue })
    }
  }

  handleStateChangeA = changes => {
    if (changes.hasOwnProperty('selectedItem')) {
      this.setState({ autor: changes.selectedItem })
    } else if (changes.hasOwnProperty('inputValue')) {
      this.setState({ autor: changes.inputValue })
    }
  }

  //AHDA 19/02/2019
  handleClearFilters = () => {
    this.setState({
      idObra: "",
      empresa: 0,
      titulo: "",
      autor: "",
      pais: 0,
      estatus: 0,
      genre: 0,
      loading: true
    })

    //AHDA 03/04/2019
    //Change in services, idObra with -1
    let body = {
      usuarioCognito: this.state.user,
      rol: this.state.rol,
      idObra: -1,
      empresaObra: 0,
      tituloObra: "0",
      autorObra: 0,
      paisObra: 0,
      generoObra: 0,
      estatusObra: 0
    }

    this.handleFetch(body);
  }

  //Download csv
  handleDownload = (data) => {

    const body = {
      id_usuario: returnCurrentUserId(),
      user_action: 3,
      work_id: 0,
      portal: 'obras',
      screen: 'reporteObrasLiterarias'
    }
    apiPOST('/audit/add', body);

    //AHDA 02/04/2019
    //Correccion bug, desfase de Excel 
    const dataCsv = [["ID", "Empresa/Proveedor", "Título de la Obra", "Autor(es)/Nickname", "País", "Fecha de registro", "Género", "Estatus"]]

    for (const x in data) {
      const object = data[x]
      dataCsv.push(object);
    }

    const download = document.createElement("a");
    document.body.appendChild(download); //AHDA 23/05/2019
    const blob = new Blob(["\ufeff", dataCsv.join('\n')], { type: 'application/vnd.ms-excel' });

    download.href = window.URL.createObjectURL(blob);
    download.download = "MisAsignaciones.csv";
    download.click();
  }


  // JCMY 02/05/2018
  handledGetRegisteredWorks() {

    this.handleSearch();


  }

  handleOpenDetail = (data) => {
    this.setState({
      selectDetail: {
        date: data[4] || "",
        title: data[1] || "",
        comment: data[6] || "",
      }
    })
  }

  handleCloseDetail = () => {
    this.setState({ selectDetail: null });
  }

  render() {
    const { classes } = this.props;
    const { columns, data, selectDetail } = this.state;
    const options = {
      filterType: 'dropdown',
      responsive: 'scroll',
      fixedHeader: true,
      download: false,
      print: false,
      rowsPerPage: 10,
      page: 0,
      selectableRows: false,
      textLabels: textLabelsEs,
      isRowSelectable: () => {

      },
      customSort: (data, colIndex, order) => {
        if (order == 'asc') {
          return data.sort((a, b) => {
            return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1);
          });
        } else {
          return data.sort((a, b) => {
            return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'asc' ? 1 : -1);
          });

        }

      }

      // JCMY 01/04/19 Se comenta ya que no es necesario la descarga
      // customToolbar: () => {
      //   return <LanguageConsumer>
      //     {
      //       ({ lang }) =>
      //         <Tooltip title={lang.reports.print} placement="bottom">
      //           <IconButton aria-label="Imprimir" onClick={() => this.handleDownload(this.state.dataCsV)}>
      //             <CloudDownload />
      //           </IconButton>
      //         </Tooltip>
      //     }
      //   </LanguageConsumer>
      // }
    };
    return (
      <LanguageConsumer>
        {
          ({ lang }) =>
            <div className="marginTopLogo">

              <SweetAlert
                show={this.state.show}
                customClass={classes.alertDialog}
                title="¿Estás seguro?"
                text="Hay una obra en proceso de registro, ¿Desea continuar?"
                cancelButtonText="Continuar Obra"
                confirmButtonText="Nueva Obra"
                showCancelButton
                onConfirm={() => {
                  this.handleConfirmSwal();
                }}
                onCancel={() => {
                  this.setState({ openStep: true, show: false });
                }}
              />
              <SweetAlert
                show={this.state.block}
                customClass={classes.alertDialog}
                title="Terminar registro"
                text="Debe finalizar la obra para guardar sus cambios"
                onConfirm={() => {
                  this.setState({ block: false })
                }}
              />
              <PapperBlock title={lang.reports.myAssigments} desc="">
                {this.state.loading ? <LoaderFull /> : ''}
                {/* Inicio Filtros */}
                {/*AHDA 23/04/2019: Changed to rol 12*/}
                {
                  this.state.rol !== 12 ?
                    <form className={classes.container}>
                      <Grid
                        container
                        spacing={24}
                        direction="row"
                        justify="flex-start"
                        alignItems="center">
                        <Grid item xs={6} sm={3} md={3}>
                          {/*AHDA 02/04/2019
                        Input validation (inputProps)*/}
                          <FormControl className={classes.formControl} aria-describedby="name-helper-text">
                            <Input
                              id="name-helper"
                              value={this.state.idObra}
                              onChange={this.handleChangeID}
                              placeholder="ID"
                              name="idObra"
                              inputProps={
                                { maxLength: 10 }
                              } />
                            <FormHelperText>ID</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3} md={3}>
                          <FormControl className={classes.formControl}>
                            <Select value={this.state.empresa} onChange={this.handleChange} name="empresa" displayEmpty className={classes.selectEmpty}>
                              <MenuItem value={0}>{lang.reports.all}</MenuItem>
                              {
                                this.state.empresas.map((empresa, idx) => (
                                  <MenuItem value={empresa.value} key={`empresa${idx}`}>
                                    {empresa.data}
                                  </MenuItem>))
                              }
                            </Select>
                            <FormHelperText>{lang.reports.compProv}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3} md={3}>
                          <div className={classes.root}>
                            <Downshift selectedItem={this.state.titulo} onStateChange={this.handleStateChangeT}>
                              {({
                                getInputProps,
                                getItemProps,
                                isOpen,
                                inputValue,
                                selectedItem,
                                highlightedIndex
                              }) => (
                                <div className={classes.formControl}>
                                  {renderInput({
                                    fullWidth: true,
                                    classes,
                                    // helperText: lang.reports.company,
                                    helperText: lang.reports.title,
                                    InputProps: getInputProps({
                                      // placeholder: lang.reports.company,
                                      placeholder: lang.reports.title,
                                      id: 'titulo',
                                      name: 'titulo',
                                    }),
                                  })}
                                  {isOpen ? (
                                    <Paper className={classes.paper} square>
                                      {getSuggestions(inputValue, this.state.works).map((suggestion, index) =>
                                        renderSuggestion({
                                          suggestion,
                                          index,
                                          itemProps: getItemProps({ item: suggestion.label }),
                                          highlightedIndex,
                                          selectedItem,
                                        }),
                                      )}
                                    </Paper>
                                  ) : null}
                                </div>
                              )}
                            </Downshift>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3} md={3}>
                          <div className={classes.root}>
                            <Downshift selectedItem={this.state.autor} onStateChange={this.handleStateChangeA}>
                              {({
                                getInputProps,
                                getItemProps,
                                isOpen,
                                inputValue,
                                selectedItem,
                                highlightedIndex
                              }) => (
                                <div className={classes.formControl}>
                                  {renderInput({
                                    fullWidth: true,
                                    classes,
                                    // helperText: lang.reports.company,
                                    helperText: lang.reports.authors,
                                    InputProps: getInputProps({
                                      // placeholder: lang.reports.company,
                                      placeholder: lang.reports.authors,
                                      id: 'autor',
                                      name: 'autor',
                                    }),
                                  })}
                                  {isOpen ? (
                                    <Paper className={classes.paper} square>
                                      {getSuggestions(inputValue, this.state.authors).map((suggestion, index) =>
                                        renderSuggestion({
                                          suggestion,
                                          index,
                                          itemProps: getItemProps({ item: suggestion.label }),
                                          highlightedIndex,
                                          selectedItem,
                                        }),
                                      )}
                                    </Paper>
                                  ) : null}
                                </div>
                              )}
                            </Downshift>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3} md={3}>
                          <FormControl className={classes.formControl}>
                            <Select value={this.state.genre} onChange={this.handleChange} name="genre" displayEmpty className={classes.selectEmpty}>
                              <MenuItem value={0}>{lang.reports.all}</MenuItem>
                              {
                                this.state.genres.map((genre, idx) => (
                                  <MenuItem value={genre.value} key={`genre${idx}`}>
                                    {genre.data}
                                  </MenuItem>))
                              }
                            </Select>
                            <FormHelperText>{lang.reports.genre}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3} md={3}>
                          <FormControl className={classes.formControl}>
                            <Select value={this.state.estatus} onChange={this.handleChange} name="estatus" displayEmpty className={classes.selectEmpty}>
                              {/* JCMY 06/06/2019 */}
                              <MenuItem value={0}>{lang.reports.all}</MenuItem>
                              {
                                this.state.listStatus.map((status, idx) => (
                                  <MenuItem value={status.data} key={`status${idx}`}>
                                    {status.data}
                                  </MenuItem>))
                              }
                            </Select>
                            <FormHelperText className={classes.nowrap}>{lang.reports.playEstatus}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={24}
                        direction="row"
                        justify="flex-start"
                        alignItems="center">
                        {/* CH 05/04/2019
                        Se centran botones en versión móvil */}
                        <Grid item xs={12} sm={12} md={12} className={classes.centerOnlySmall}>
                          <Button onClick={this.handleSearch} variant="contained" color="primary" className={classes.button}>
                            {lang.common.search}
                          </Button>
                          <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClearFilters}>
                            Limpiar
                          </Button>
                        </Grid>
                      </Grid>
                    </form> :
                    null
                }


                <Grid
                  container
                  spacing={24}
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
                  <Grid item xs={12} sm={12} md={12} className={classNames(classes.alignRight, classes.centerOnlySmall)}>
                    <Button disabled={!this.state.chkAddWork} variant="contained" onClick={() => this.handleClickOpen(0)} color="primary" className={classNames(classes.button, classes.btnFullOnlySmall)}><Add className={classes.leftIcon} />Nueva Obra</Button>
                  </Grid>
                </Grid>



                {/* Modal Steper */}
                {/* JCMY 03/05/2019 */}
                {/*AHDA 28/05/2019: Added selected*/}
                <NewWorkDialog
                  getRegisteredWorks={() => this.handledGetRegisteredWorks()}
                  rol={this.state.rol}
                  openStep={this.state.openStep}
                  handleCloseStep={this.handleCloseStep}
                  workId={this.state.workId}
                  canAddWork={this.state.chkEditWork}
                  canEditWork={this.state.chkEditWork}
                  canUpgradeWork={this.state.chkUpgradeWork}
                  screen={"myAsignations"}
                  selected={this.state.selected}
                  isAssignation={this.state.isAssignation}
                />

                {/* Fin Filtros */}
                <div className={classes.asignaciones}>
                  <MUIDataTable
                    title={`${lang.reports.myAssigments} (${this.state.data.length} ${lang.reports.results})`}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </div>

                {/*for error notifications*/}
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}

                  open={this.state.open}
                  autoHideDuration={6000}
                  onClose={this.handleClose}>
                  <CustomNotification
                    onClose={this.handleClose}
                    variant="error"
                    message={lang.common.errMsg} />
                </Snackbar>
                <Dialog
                  open={selectDetail != null}
                  onClose={this.handleCloseDetail}
                >
                  <AppBar className={classes.appBar}>
                    <Toolbar>
                      <Typography variant="h6" color="inherit" className={classes.flex}>
                        Detalle de comentarios
                      </Typography>
                      <IconButton color="inherit" onClick={this.handleCloseDetail} aria-label="Close">
                        <CloseIcon />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <div className={classes.detailContainer}>
                    <div className={classes.detailRowContainer}>
                      <strong>{'Título de la obra: '}</strong>
                      {!!selectDetail && selectDetail.title && <span>{selectDetail.title}</span>}
                    </div>
                    <div className={classes.detailRowContainer}>
                      <strong>{'Fecha: '}</strong>
                      {!!selectDetail && selectDetail.date && <span>{selectDetail.date}</span>}
                    </div>
                    <div className={classes.detailRowContainer}>
                      <strong>{'Comentarios: '}</strong>
                      {!!selectDetail && selectDetail.comment && <span>{selectDetail.comment}</span>}
                    </div>
                  </div>
                </Dialog>
              </PapperBlock>
            </div>
        }
      </LanguageConsumer>

    );
  }
}

AdvFilter.contextType = LanguageCxt;

AdvFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AdvFilter));