const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
  },
  table: {
    marginTop: 0,
    '& thead tr th': {
      color: '#9F9F9F'
    },
    '& tr td': {
      padding: '4px 24px 4px 24px'
    }
  },
  stripped: {
    '& tbody tr:nth-child(even)': {
      background: '#f5f7f9',
    }
  },
  actionsBtn: {
    minWidth: 110,
  },
  toolbar: {
    background: `linear-gradient(93.01deg, #F62E1F 0.18%, #FF5702 49.51%, #F68E5C 98.85%)`,
    marginTop: 0,
  },
  title: {
    flex: '0 0 auto',
    color: '#FFFFFF',
    '& h2': {
      color: theme.palette.common.white
    }
  },
  spacer: {
    flex: '1 1 100%',
  },
  w100: {
    width: '100%',
  },
  listaRoles: {
    textTransform: 'capitalize',
  },
  mbTable: {
    marginBottom: '2rem',
  }
});

export default styles;