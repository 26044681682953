import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { S3Image } from 'aws-amplify-react';
//import BottomNavigation from '@material-ui/core/BottomNavigation';
//import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SearchIcon from '@material-ui/icons/Search';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
//import Star from '@material-ui/icons/Star';
import styles from './contact-jss';
import Loader from '../Loader/Loader'; 

class ContactList extends React.Component {
  state = {
    filter: '',
  };

  //Input
  handleChange = (event) => {
    this.setState({ filter: event.target.value });
    this.props.search(event); 
  };

  //Clear filter 
  handleClear = () =>{
    this.setState({filter: ''}); 
    this.props.clearFilter(); 
  }

  render() {
    const {
      classes,
      dataContact,
      itemSelected,
      showDetail,
      clippedRight, 
      isLoading
    } = this.props;
    
    const getItem = dataArray => dataArray.map(data => {
    
      //AHDA 26/03/2019
      //Cambio de validacion 
      if (data.id_usuario === -1) {
        return false;
      }
      return (
        <ListItem
          button
          key={`user_${data.id_usuario}`}
          className={data.id_usuario === itemSelected ? classes.selected : ''}
          onClick={() => showDetail(data)}
        >
          <Avatar alt={data.name} src={!data.avatar ? "https://s3.amazonaws.com/portalacervo/resources/img/default-avatar.png" : ""} className={classNames(classes.avatar, classes.unsetFlex)}>
            {
              data.avatar ? <S3Image theme={{ photoImg: { width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' } }} imgKey={data.avatar} /> : ""
            }
          </Avatar>
          <ListItemText primary={`${data.nombre}`} secondary={data.portal_profile ? data.portal_profile : "-"} />
        </ListItem>
      );
    });

    return (
      <Fragment>
        <Drawer
          variant="permanent"
          anchor="left"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div>
            <div className={classNames(classes.toolbar, clippedRight && classes.clippedRight)}>
              <div className={classes.flex}>
                <div className={classes.searchWrapper}>
                  <div className={classes.search}>
                    <SearchIcon />
                  </div>
                  <input className={classes.input} value={this.state.filter} onChange={this.handleChange} placeholder="Buscar usuario" disabled={isLoading}/>
                </div>
              </div>
            </div>
            <Divider />
            <List>
              {
                isLoading ? 
                <Loader text="" /> :
                getItem(dataContact)}
            </List>
          </div>
        </Drawer>
        <div className={classes.bottomIcon} onClick={this.handleClear}>
          <PermContactCalendar className={classes.iconBlock}/>
          <small>Todos</small>
        </div>
      </Fragment>
    );
  }
}

ContactList.propTypes = {
  classes: PropTypes.object ,
  dataContact: PropTypes.array,
  keyword: PropTypes.string ,
  itemSelected: PropTypes.number,
  showDetail: PropTypes.func,
  search: PropTypes.func,
  clippedRight: PropTypes.bool,
  isLoading: PropTypes.bool, 
  clearFilter: PropTypes.func
};

ContactList.defaultProps = {
  clippedRight: false, 
  isLoading: false, 
  clearFilter: () => {}
};

export default withStyles(styles)(ContactList);