import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import styles from './../styles-jss'
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import Loader from '../../../../components/Loader/Loader';

//Table
import AddCatalogue from '../../../../components/Catalogue/AddCatalogue';
import Header from '../../../../components/Catalogue/Header';
import TableCatalogue from '../../../../components/Catalogue/TableCatalogue';

//AHDA 15/04/2019: commented unused dependencies 
//Form
import { InputLabel } from '@material-ui/core';
//import InputAdornment from '@material-ui/core/InputAdornment';
//import Sort from '@material-ui/icons/Sort';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
//import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';

//Services
import { apiPOST } from '../../../../services/service_base';
import { getObjectById, getfilteredDataAllKeys } from '../../../../helpers/general';

//Notifications
import Snackbar from '@material-ui/core/Snackbar';
import CustomNotification from '../../../../components/Notification/CustomNotification';

class Catalogo extends React.Component {
    state = {
        //Open form
        openForm: false,
        //Table
        headers: ["ID", "Empresa/Proveedor", "Tipo de persona", "País origen", "Dirección", "Teléfono",
            "Estatus", "Opciones"],
        rawData: [],
        data: [],
        //Selected catalogue item
        selected: null,
        proTipoPersona: "fisica",
        proPaisOrigin: undefined,
        proNombre: "",
        proNombreRepresentante: "",
        proCorreoDelRepresentante: "",
        proNombreContactoVenta: "",
        proCorreoContactoVenta: "",
        proNombreContactoLegal: "",
        proCorreoContactoLegal: "",
        proNombreContactoAdministrativo: "",
        proCorreoContactoAdministrativo: "",
        proDireccion: "",
        proTelefono: "",
        proPaginaWeb: "",
        proRfc: "",
        proNumeroPasaporte: "",
        proPrimerNombre: "",
        proSegundoNombre: "",
        proApellidoPaterno: "",
        proApellidoMaterno: "",
        //Error 
        openError: false,
        errType: "error", //AHDA 31/05/2019
        errMsg: "Ocurrió un error, intenta de nuevo más tarde", //AHDA 31/05/2019
        isLoading: false,
        //Catalogues
        countries: [],
        chkDeleteCatalog: false,
        chkEditCatalog: false,
        chkNewCatalog: false
    };

    //Open and close form
    handledOpenForm = (item) => {
        //Clear Form 
        this.handleClear();

        if (item !== undefined) {
            let auxItem = this.state.rawData.find(it => it.id === item.id);
            this.setState({
                openForm: true,
                proPaisOrigin: auxItem.id_pais_origen.toString(),
                proTipoPersona: auxItem.tipo_persona,
                proNombre: auxItem.nombre_proveedor,
                proNombreRepresentante: auxItem.nombre_representante,
                proCorreoDelRepresentante: auxItem.correo_del_representante,
                proNombreContactoVenta: auxItem.nombre_contacto_de_venta,
                proCorreoContactoVenta: auxItem.correo_contacto_venta,
                proNombreContactoLegal: auxItem.nombre_contacto_legal,
                proCorreoContactoLegal: auxItem.coreo_contacto_legal,
                proNombreContactoAdministrativo: auxItem.nombre_contacto_administrativo,
                proCorreoContactoAdministrativo: auxItem.correo_contacto_administrativo,
                proDireccion: auxItem.direccion,
                proTelefono: auxItem.telefono,
                proPaginaWeb: auxItem.pagina_web,
                proRfc: auxItem.rfc,
                proNumeroPasaporte: auxItem.numero_pasaporte,
                proPrimerNombre: auxItem.primerNombre,
                proSegundoNombre: auxItem.segundoNombre,
                proApellidoPaterno: auxItem.apellidoPaterno,
                proApellidoMaterno: auxItem.apellidoMaterno,
                selected: item
            });
        }
        else {
            this.setState({
                openForm: true,
            });
        }
    }

    handledCloseForm = () => {
        this.setState({ openForm: false });
    }

    //Handle Form
    handleChange = (event) => {
        //AHDA 02/04/2019
        //Validation for single quote
        if (event.target.value.indexOf('\'') <= -1) {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    //*AHDA 02/04/2019
    //Input validation, only numbers 
    handleChangeNumber = event => {
        if (/^[0-9]*$/i.test(event.target.value) || event.target.value === "") {
            // JCMY 06/06/2019
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    handleClear = () => {
        this.setState({
            //AHDA 03/04/2019
            //Validation for empty country, change to undefined
            proPaisOrigin: undefined,
            selected: null,
            proNombre: "",
            proNombreRepresentante: "",
            proCorreoDelRepresentante: "",
            proNombreContactoVenta: "",
            proCorreoContactoVenta: "",
            proNombreContactoLegal: "",
            proCorreoContactoLegal: "",
            proNombreContactoAdministrativo: "",
            proCorreoContactoAdministrativo: "",
            proDireccion: "",
            proTelefono: "",
            proPaginaWeb: "",
            proRfc: "",
            proNumeroPasaporte: "",
            proPrimerNombre: "",
            proSegundoNombre: "",
            proApellidoPaterno: "",
            proApellidoMaterno: ""
        });
    }

    //Notifications
    handleClose = () => {
        this.setState({ openError: false });
    }

    //Catalogue CRUD

    //Catalogue CRUD
    handleBodyRequest = (item, status) => {
        let auxItem = getObjectById(this.state.rawData, item);
        if (auxItem) {
            return {
                idCatalog: 18,
                id_record: auxItem.id,
                "new_provider_name": auxItem.tipo_persona === "fisica" ? `${auxItem.primerNombre} ${auxItem.segundoNombre} ${auxItem.apellidoPaterno} ${auxItem.apellidoMaterno}` : auxItem.nombre_proveedor,
                "new_physical_moral": auxItem.tipo_persona,
                "new_representative_name": auxItem.nombre_representante,
                "new_representantive_mail": auxItem.correo_del_representante,
                "new_sales_contact_name": auxItem.nombre_contacto_de_venta,
                "new_sales_contact_mail": auxItem.correo_contacto_venta,
                "new_legal_contact_name": auxItem.nombre_contacto_legal,
                "new_legal_contact_mail": auxItem.correo_contacto_legal,
                "new_administrative_contact_name": auxItem.nombre_contacto_administrativo,
                "new_administrative_contact_mail": auxItem.correo_contacto_administrativo,
                "new_country": auxItem.id_pais_origen,
                "new_provider_address": auxItem.direccion,
                "new_telephone": auxItem.telefono,
                "new_web_page": auxItem.pagina_web,
                "new_rfc": auxItem.rfc,
                "new_passport_number": auxItem.numero_pasaporte,
                "new_first_name": auxItem.primerNombre,
                "new_second_name": auxItem.segundoNombre,
                "new_last_name": auxItem.apellidoPaterno,
                "new_mothers_last_name": auxItem.apellidoMaterno,
                "new_status": status
            }
        } else {
            return {
                idCatalog: 18,
                id_record: item.id,
                "new_provider_name": "",
                "new_physical_moral": "",
                "new_representative_name": "",
                "new_representantive_mail": "",
                "new_sales_contact_name": "",
                "new_sales_contact_mail": "",
                "new_legal_contact_name": "",
                "new_legal_contact_mail": "",
                "new_administrative_contact_name": "",
                "new_administrative_contact_mail": "",
                "new_country": "",
                "new_provider_address": "",
                "new_telephone": "",
                "new_web_page": "",
                "new_rfc": "",
                "new_passport_number": "",
                "new_first_name": "",
                "new_second_name": "",
                "new_last_name": "",
                "new_mothers_last_name": "",
                "new_status": status
            }
        }
    }

    handleDeleteItem = (item) => {
        const body = this.handleBodyRequest(item, 2);

        apiPOST('/catalogue/update', body).then(
            response => {
                if (response.status === 200) {
                    let auxIndex = this.state.data.findIndex(itm => itm.id === item.id),
                        auxRawIndex = this.state.data.findIndex(itm => itm.id === item.id);

                    let auxData = [...this.state.data], auxRawData = [...this.state.rawData];

                    auxData.splice(auxIndex, 1);
                    auxRawData.splice(auxRawIndex, 1);

                    this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                }
                else {
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            }
        ).catch(
            err => {
                //AHDA 31/05/2019: added errType, errMsg
                this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
            }
        )
    }

    handleUpdateStatus = (data, item) => {

        const body = this.handleBodyRequest(item, item.estatus === 1 ? 0 : 1);

        apiPOST('/catalogue/update', body).then(
            response => {
                if (response.status === 200) {
                    let auxData = [...this.state.data], auxRawData = [...this.state.rawData];
                    let auxIndex = this.state.data.findIndex(itm => itm.id === item.id), auxRawIndex = this.state.rawData.findIndex(itm => itm.id === item.id);

                    auxData[auxIndex].estatus = response.data[0].estatus;
                    auxRawData[auxRawIndex].estatus = response.data[0].estatus;

                    this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                }
                else {
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            }
        ).catch(
            err => {
                //AHDA 31/05/2019: added errType, errMsg
                this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
            }
        )
    }

    handleSubmit = (event) => {
        event.preventDefault();

        let body = {
            "idCatalog": 18,
            "id_record": this.state.selected ? this.state.selected.id : 0,
            "new_provider_name": this.state.proTipoPersona === "fisica" ? `${this.state.proPrimerNombre} ${this.state.proSegundoNombre} ${this.state.proApellidoPaterno} ${this.state.proApellidoMaterno}` : this.state.proNombre,
            "new_physical_moral": this.state.proTipoPersona,
            "new_representative_name": this.state.proNombreRepresentante,
            "new_representantive_mail": this.state.proCorreoDelRepresentante,
            "new_sales_contact_name": this.state.proNombreContactoVenta,
            "new_sales_contact_mail": this.state.proCorreoContactoVenta,
            "new_legal_contact_name": this.state.proNombreContactoLegal,
            "new_legal_contact_mail": this.state.proCorreoContactoLegal,
            "new_administrative_contact_name": this.state.proNombreContactoAdministrativo,
            "new_administrative_contact_mail": this.state.proCorreoContactoAdministrativo,
            "new_country": this.state.proPaisOrigin,
            "new_provider_address": this.state.proDireccion,
            "new_telephone": this.state.proTelefono,
            "new_web_page": this.state.proPaginaWeb,
            "new_rfc": this.state.proRfc,
            "new_passport_number": this.state.proNumeroPasaporte,
            "new_first_name": this.state.proPrimerNombre,
            "new_second_name": this.state.proSegundoNombre,
            "new_last_name": this.state.proApellidoPaterno,
            "new_mothers_last_name": this.state.proApellidoMaterno,
            "new_status": this.state.selected ? this.state.selected.estatus : true
        }

        //Call to lambda -- when success it should do this 
        let auxData = [...this.state.data];
        let auxRawData = [...this.state.rawData];

        if (this.state.selected) {
            this.setState({ isLoading: true });
            apiPOST('/catalogue/update', body).then(
                response => {
                    if (response.status === 200) {
                         
                        let auxItem = response.data[0];
                        console.log(auxItem);
                        let auxIndex = this.state.data.findIndex(itm => itm.id === this.state.selected.id);

                        auxRawData[auxIndex] = auxItem;
                        auxItem = {
                            "id": auxItem.id,
                            // JCMY 18/06/2019
                            "nombre_proveedor": (auxItem.nombre_proveedor).replace(/null/g, " "),
                            //AHDA 15/04/2019
                            //Cambio en mapeo de datos
                            "tipo_persona": auxItem.tipo_persona === "moral" ? "Moral" : "Física",
                            "pais_origen": auxItem.pais_origen,
                            "direccion": auxItem.direccion,
                            "telefono": auxItem.telefono,
                            "estatus": auxItem.estatus
                        }
                        auxData[auxIndex] = auxItem;

                        this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                    }
                    else {
                        //console.log(response)
                        //AHDA 31/05/2019: added errType, errMsg
                        this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                    }
                }
            ).catch(
                err => {
                    //.log(err); 
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            )
        } else {
            //AHDA 03/06/2019: Existing data validation
            const auxFilter = auxRawData.filter(i => i.tipo_persona === body.new_physical_moral);
            let existsItem = undefined;

            if (auxFilter.length > 0) {
                existsItem = auxFilter.find(i => {
                    if (body.new_physical_moral === "fisica") {
                        const auxItemSN = i.segundoNombre ? i.segundoNombre.toUpperCase() : "",
                            auxStateSN = this.state.proSegundoNombre ? this.state.proSegundoNombre.toUpperCase() : "",

                            auxItemAM = i.apellidoMaterno ? i.apellidoMaterno.toUpperCase() : "",
                            auxStateAM = this.state.proApellidoMaterno ? this.state.proApellidoMaterno.toUpperCase() : "";

                        if (
                            i.primerNombre.toUpperCase() === this.state.proPrimerNombre.toUpperCase() &&
                            auxItemSN === auxStateSN &&
                            i.apellidoPaterno.toUpperCase() === this.state.proApellidoPaterno.toUpperCase() &&
                            auxItemAM === auxStateAM &&
                            i.tipo_persona === "fisica"
                        ) {
                            return i;
                        }
                    } else {
                        if (
                            i.tipo_persona === "moral" &&
                            i.nombre_proveedor.toUpperCase() === this.state.proNombre.toUpperCase()
                        ) {
                            return i;
                        }
                    }
                });
            }

            if (existsItem) {
                this.setState({ openError: true, errType: "warning", errMsg: "Ya existe una opción con este nombre, favor de validar" });
                return false;
            }

            this.setState({ isLoading: true });

            //for dummy only, service returns inserted item with id 
            apiPOST('/catalogue/add', body).then(
                response => {
                    if (response.status === 200) {
                        let auxItem = response.data[0];
                        auxRawData.push(auxItem);

                        console.log(auxItem);

                        auxItem = {
                            "id": auxItem.id,
                            "nombre_proveedor": auxItem.nombre_proveedor,
                            //AHDA 15/04/2019
                            //Cambio en mapeo de datos
                            "tipo_persona": auxItem.tipo_persona === "moral" ? "Moral" : "Física",
                            "pais_origen": auxItem.pais_origen,
                            "direccion": auxItem.direccion,
                            "telefono": auxItem.telefono,
                            "estatus": auxItem.estatus
                        }
                        auxData.push(auxItem);

                        this.setState({ data: auxData, rawData: auxRawData, selected: undefined, openForm: false, isLoading: false });
                    } else {
                        //AHDA 31/05/2019: added errType, errMsg
                        this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                    }
                }
            ).catch(
                err => {
                    //AHDA 31/05/2019: added errType, errMsg
                    this.setState({ openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", selected: undefined, openForm: false, isLoading: false });
                }
            )
        }
    }

    //AHDA 24/05/2019
    //------------------------------- Search
    handleSearch = (search) => {
        let key = search.toUpperCase();
        //Get all
        if (key === "") {
            this.setState({ data: this.getTableData([...this.state.rawData]) });
            return false;
        }

        let auxData = this.getTableData([...this.state.rawData]);

        auxData = getfilteredDataAllKeys(auxData, key);
        this.setState({ data: auxData });
    }
    //------------------------------- End Search

    //AHDA 24/05/2019
    //------------------------------- Helpers
    getTableData = (data) => {
        let auxData = [];
        if (data.length > 0) {
            for (const x in data) {
                let auxCompany = data[x];
                auxCompany = {
                    "id": auxCompany.id,
                    // JCMY 18/06/2019
                    "nombre_proveedor": auxCompany.nombre_proveedor ? (auxCompany.nombre_proveedor).replace(/null/g, " ") : '',
                    "tipo_persona": auxCompany.tipo_persona === "fisica" ? "Física" : "Moral",
                    "pais_origen": auxCompany.pais_origen ? auxCompany.pais_origen : '',
                    "direccion": auxCompany.direccion ? auxCompany.direccion : '',
                    "telefono": auxCompany.telefono ? auxCompany.telefono : '',
                    "estatus": auxCompany.estatus
                }
                auxData.push(auxCompany)
            }
        }

        return auxData;
    }
    //------------------------------- End Helpers

    componentDidMount() {
        // JCMY 05/06/2019
        //Validation for phone
        ValidatorForm.addValidationRule('isPhone', (value) => {
            if ((value.length >= 8 && value.length <= 20) || value.length === 0) {
                // if (value.length === 8 || value.length === 13  || value.length === 0) {
                return true;
            }
            return false;
        });


        let config = JSON.parse(localStorage.getItem("user_permissions")).filter(x => x.menu_id === 17)[0];
        let configPermission = JSON.parse(config.grant_configuration);

        this.setState({
            chkDeleteCatalog: configPermission.chkDeleteCatalog,
            chkEditCatalog: configPermission.chkEditCatalog,
            chkNewCatalog: configPermission.chkNewCatalog
        });

        //Get countries catalogue
        apiPOST('/catalogos/getCatalogo', { idCatalogo: 3 }).then(
            response => {
                if (response.status === 200) {
                    console.log(response);
                    this.setState({ countries: response.data })
                }
            }
        )

        //Get Data
        apiPOST('/catalogue/getComplete', { idCatalog: 18 }).then(
            response => {
                if (response.status === 200) {
                    //AHDA 24/05/2019: Adjustments for search
                    this.setState({ isLoading: false, rawData: response.data, data: this.getTableData(response.data) });
                } else {
                    this.setState({ isLoading: false, openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", });
                }
            }
        ).catch(
            err => {
                this.setState({ isLoading: false, openError: true, errType: "error", errMsg: "Ocurrió un error, intenta de nuevo más tarde", });
            }
        )
    }

    // JCMY 04/06/2019
    cleanWhiteSpace = (event) => {
        const value = event.target.value
        this.setState({ [event.target.name]: value.trim() });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className="marginTopLogo">

                <Paper className={classes.root}>
                    {/*AHDA 24/05/2019: Added search*/}
                    <Header
                        title="Empresas/Proveedores"
                        tooltip="Nuevo Empresa/Proveedor"
                        textBtn="Nuevo"
                        openForm={() => this.handledOpenForm(undefined)}
                        workId={0}
                        canAdd={this.state.chkNewCatalog}
                        search={this.handleSearch}
                    />
                    {
                        this.state.isLoading ?
                            <Loader text={"Cargando"} /> :
                            <TableCatalogue
                                headers={this.state.headers}
                                data={this.state.data}
                                openForm={this.handledOpenForm}
                                deleteItem={this.handleDeleteItem}
                                updateStatus={this.handleUpdateStatus}
                                workId={0}
                                canEdit={this.state.chkEditCatalog}
                                canDelete={this.state.chkDeleteCatalog}
                            />
                    }
                </Paper>

                <AddCatalogue
                    openForm={this.state.openForm}
                    closeForm={this.handledCloseForm}
                    classes={classes}
                    submit={this.handleSubmit}
                    clearForm={this.handleClear}
                    title="Empresa/Proveedor"
                >
                    <div>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl className={classNames(classes.formControl, classes.w100)}>
                                    <InputLabel shrink htmlFor="">Tipo de persona*</InputLabel>
                                    <Select
                                        label="Tipo de persona"
                                        placeholder="Elige una opción"
                                        value={this.state.proTipoPersona}
                                        onChange={this.handleChange}
                                        name="proTipoPersona"
                                        displayEmpty
                                        className={classes.selectEmpty}
                                        inputProps={{ name: 'proTipoPersona' }}
                                        InputLabelProps={{ shrink: true }} >
                                        >
                                        <MenuItem value={'fisica'}>Física</MenuItem>
                                        <MenuItem value={'moral'}>Moral</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                    {
                        this.state.proTipoPersona === 'fisica' ?
                            <div>
                                {/*Persona Fisica*/}
                                <div>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextValidator
                                                label="Primer Nombre*"
                                                placeholder="Ej. Miguel"
                                                id="proPrimerNombre"
                                                name="proPrimerNombre"
                                                value={this.state.proPrimerNombre}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                validators={['required']}
                                                errorMessages={['(Campo requerido)']}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 50 }
                                                    }
                                                }
                                                InputLabelProps={{ shrink: true }}
                                                // JCMY 04/06/2019
                                                onBlur={this.cleanWhiteSpace}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextValidator
                                                label="Segundo Nombre"
                                                placeholder="Ej. Alberto"
                                                id="proSegundoNombre"
                                                name="proSegundoNombre"
                                                value={this.state.proSegundoNombre}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 50 }
                                                    }
                                                }
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextValidator
                                                label="Apellido Paterno*"
                                                placeholder="Ej. Cervantes"
                                                id="proApellidoPaterno"
                                                name="proApellidoPaterno"
                                                value={this.state.proApellidoPaterno}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                validators={['required']}
                                                errorMessages={['(Campo requerido)']}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 50 }
                                                    }
                                                }
                                                InputLabelProps={{ shrink: true }}
                                                // JCMY 04/06/2019
                                                onBlur={this.cleanWhiteSpace}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextValidator
                                                label="Apellido Materno"
                                                placeholder="Ej. Dominguez"
                                                id="proApellidoMaterno"
                                                name="proApellidoMaterno"
                                                value={this.state.proApellidoMaterno}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 50 }
                                                    }
                                                }
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                {/*Pais*/}
                                <Grid container spacing={16}>
                                    <Grid item xs={12} sm={12} md={5}>
                                        <FormControl className={classNames(classes.formControl, classes.w100)}>
                                            {/*AHDA 02/04/2019
                                                Required field change*/}
                                            <SelectValidator
                                                label="País de origen*"
                                                value={this.state.proPaisOrigin}
                                                onChange={this.handleChange}
                                                name="proPaisOrigin"
                                                id="proPaisOrigin"
                                                validators={['required']}
                                                errorMessages={['Campo requerido']}
                                                className={classes.selectEmpty}
                                                InputLabelProps={{ shrink: true }}
                                                displayEmpty
                                            >
                                                {
                                                    this.state.countries.map((country, idx) => (
                                                        <MenuItem value={country.value} key={`countryF${idx}`}>
                                                            {country.data}
                                                        </MenuItem>))
                                                }
                                            </SelectValidator>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={7}>
                                        <TextValidator
                                            label="Télefono*"
                                            placeholder="Ej. 4778569695"
                                            id="proTelefono"
                                            name="proTelefono"
                                            value={this.state.proTelefono}
                                            className={classNames(classes.field, classes.w100)}
                                            onChange={this.handleChangeNumber}
                                            // JCMY 05/96(2019)
                                            validators={['required', 'isPhone']}
                                            errorMessages={['(Campo requerido)', 'El número debe ser de 8 o 20 dígitos']}
                                            InputProps={
                                                {
                                                    inputProps: { minLength: 8, maxLength: 20 }
                                                }
                                            }
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                </Grid>
                                <div>
                                    <Grid container spacing={16}>
                                        {/*AHDA 02/04/2019
                                        RFC Validation, only in Mexico*/}
                                        {
                                            this.state.proPaisOrigin === "1" ?
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <TextValidator
                                                        label="RFC"
                                                        placeholder="Ej. CUPU800825569"
                                                        id="proRfc"
                                                        name="proRfc"
                                                        value={this.state.proRfc}
                                                        className={classNames(classes.field, classes.w100)}
                                                        onChange={this.handleChange}
                                                        InputProps={
                                                            {
                                                                inputProps: { maxLength: 13 }
                                                            }
                                                        }
                                                        InputLabelProps={{ shrink: true }}
                                                        // JCMY 18/06/2019
                                                        validators={['matchRegexp:^([A-ZÑ\x26]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z0-9]{3}?$)']}
                                                        errorMessages={['(Formato de RFC incorrecto)']}
                                                    />
                                                </Grid> : null
                                        }
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextValidator
                                                label="Número de pasaporte"
                                                placeholder="Ej. 7AB000271"
                                                id="proNumeroPasaporte"
                                                name="proNumeroPasaporte"
                                                value={this.state.proNumeroPasaporte}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 13 }
                                                    }
                                                }
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextValidator
                                                label="Dirección"
                                                placeholder="Ej. Paseo del Moral 1345"
                                                id="proDireccion"
                                                name="proDireccion"
                                                value={this.state.proDireccion}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 200 }
                                                    }
                                                }
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextValidator
                                                label="Página web"
                                                placeholder="Ej. www.sitioweb.com"
                                                id="proPaginaWeb"
                                                name="proPaginaWeb"
                                                value={this.state.proPaginaWeb}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div> :
                            <div>
                                {/*Persona Moral*/}
                                <div>
                                    <Grid
                                        container
                                        spacing={16}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            {/*AHDA 02/04/2019 
                                        Max length validation*/}
                                            <TextValidator
                                                label="Nombre de la empresa*"
                                                placeholder="Ej. Televisa"
                                                id="proNombre"
                                                name="proNombre"
                                                value={this.state.proNombre}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                validators={['required']}
                                                errorMessages={['(Campo requerido)']}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 100 }
                                                    }
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5}>
                                            <FormControl className={classNames(classes.formControl, classes.w100)}>
                                                {/*AHDA 02/04/2019
                                                Required field change*/}
                                                <SelectValidator
                                                    label="País de origen*"
                                                    value={this.state.proPaisOrigin}
                                                    onChange={this.handleChange}
                                                    name="proPaisOrigin"
                                                    id="proPaisOrigin"
                                                    validators={['required']}
                                                    errorMessages={['Campo requerido']}
                                                    className={classes.selectEmpty}
                                                    InputLabelProps={{ shrink: true }}
                                                    displayEmpty
                                                >
                                                    {
                                                        this.state.countries.map((country, idx) => (
                                                            <MenuItem value={country.value} key={`countryM${idx}`}>
                                                                {country.data}
                                                            </MenuItem>))
                                                    }
                                                </SelectValidator>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={7}>
                                            <TextValidator
                                                label="Télefono*"
                                                placeholder="Ej. 4778569695"
                                                id="proTelefono"
                                                name="proTelefono"
                                                value={this.state.proTelefono}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChangeNumber}
                                                // JCMY 05/96(2019)
                                                validators={['required', 'isPhone']}
                                                errorMessages={['(Campo requerido)', 'El número debe ser de 8 o 20 dígitos']}
                                                InputLabelProps={{ shrink: true }}

                                                InputProps={
                                                    {
                                                        inputProps: { minLength: 8, maxLength: 20 }
                                                    }
                                                }
                                            />
                                        </Grid>
                                        {/*AHDA 02/04/2019
                                        RFC Validation, only in Mexico*/}
                                        {
                                            this.state.proPaisOrigin === "1" ?
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextValidator
                                                        label="RFC"
                                                        placeholder="Ej. CUP800825569"
                                                        id="proRfc"
                                                        name="proRfc"
                                                        value={this.state.proRfc}
                                                        className={classNames(classes.field, classes.w100)}
                                                        onChange={this.handleChange}
                                                        InputProps={
                                                            {
                                                                inputProps: { maxLength: 13 }
                                                            }
                                                        }
                                                        InputLabelProps={{ shrink: true }}
                                                        // JCMY 18/06/2019
                                                        validators={['matchRegexp:^([A-ZÑ\x26]{3}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z0-9]{3}?$)']}
                                                        errorMessages={['(Formato de RFC incorrecto)']}
                                                    />
                                                </Grid> : null
                                        }
                                        {/*AHDA 23/04/2019: Se eliminó campo duplicado*/}
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextValidator
                                                label="Página web"
                                                placeholder="Ej. www.sitioweb.com"
                                                id="proPaginaWeb"
                                                name="proPaginaWeb"
                                                value={this.state.proPaginaWeb}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextValidator
                                                label="Dirección"
                                                placeholder="Ej. Paseo del Moral 1245"
                                                id="proDireccion"
                                                name="proDireccion"
                                                value={this.state.proDireccion}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 200 }
                                                    }
                                                }
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextValidator
                                                label="Nombre del representante"
                                                placeholder="Ej. Francisco Robles"
                                                id="proNombreRepresentante"
                                                name="proNombreRepresentante"
                                                value={this.state.proNombreRepresentante}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 100 }
                                                    }
                                                }
                                                InputLabelProps={{ shrink: true }}
                                                // JCMY 03/07/2019 incidencia 988
                                                validators={['matchRegexp:^[A-Za-z ñ.áéíóúäëïöü\'-]*$']}
                                                errorMessages={['(Solo se permiten letras)']}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            {/*AHDA 02/04/2019
                                            Email length validation*/}
                                            <TextValidator
                                                label="Correo del representante"
                                                placeholder="Ej. ejemplo@ejemplo.com"
                                                id="proCorreoDelRepresentante"
                                                name="proCorreoDelRepresentante"
                                                value={this.state.proCorreoDelRepresentante}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                validators={['isEmail']}
                                                errorMessages={['Correo electronico invalido']}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 50 }
                                                    }
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>

                                            <TextValidator
                                                label="Contacto de ventas"
                                                placeholder="Ej. Julieta Quintanilla"
                                                id="proNombreContactoVenta"
                                                name="proNombreContactoVenta"
                                                value={this.state.proNombreContactoVenta}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                // JCMY 03/07/2019 incidencia 988
                                                validators={[ 'matchRegexp:^[A-Za-z ñ.áéíóúäëïöü\'-]*$']}
                                                errorMessages={[ '(Solo se permiten letras)']}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 100 }
                                                    }
                                                }

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            {/*AHDA 02/04/2019
                                            Email length validation*/}
                                            <TextValidator
                                                label="Correo contacto de ventas"
                                                placeholder="Ej. ejemplo@ejemplo.com"
                                                id="proCorreoContactoVenta"
                                                name="proCorreoContactoVenta"
                                                value={this.state.proCorreoContactoVenta}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                validators={['isEmail']}
                                                errorMessages={['Correo electrónico inválido']}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 50 }
                                                    }
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextValidator
                                                label="Nombre contacto legal"
                                                placeholder="Ej. Victoria Fonseca"
                                                id="proNombreContactoLegal"
                                                name="proNombreContactoLegal"
                                                value={this.state.proNombreContactoLegal}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                // JCMY 03/07/2019 incidencia 988
                                                validators={[ 'matchRegexp:^[A-Za-z ñ.áéíóúäëïöü\'-]*$']}
                                                errorMessages={[ '(Solo se permiten letras)']}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 100 }
                                                    }
                                                }


                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            {/*AHDA 02/04/2019
                                            Email length validation*/}
                                            <TextValidator
                                                label="Correo contacto legal"
                                                placeholder="Ej. ejemplo@ejemplo.com"
                                                id="proCorreoContactoLegal"
                                                name="proCorreoContactoLegal"
                                                value={this.state.proCorreoContactoLegal}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                validators={['isEmail']}
                                                errorMessages={['Correo electronico invalido']}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 50 }
                                                    }
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextValidator
                                                label="Contacto administrativo"
                                                placeholder="Ej. Adalbaro Romo Valdez"
                                                id="proNombreContactoAdministrativo"
                                                name="proNombreContactoAdministrativo"
                                                value={this.state.proNombreContactoAdministrativo}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                // JCMY 03/07/2019 incidencia 988
                                                validators={[ 'matchRegexp:^[A-Za-z ñ.áéíóúäëïöü\'-]*$']}
                                                errorMessages={[ '(Solo se permiten letras)']}
                                                InputLabelProps={{ shrink: true }}
                                                
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 100 }
                                                    }
                                                }
 
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            {/*AHDA 02/04/2019
                                            Email length validation*/}
                                            <TextValidator
                                                label="Correo contacto adtvo."
                                                placeholder="Ej. ejemplo@ejemplo.com"
                                                id="proCorreoContactoAdministrativo"
                                                name="proCorreoContactoAdministrativo"
                                                value={this.state.proCorreoContactoAdministrativo}
                                                className={classNames(classes.field, classes.w100)}
                                                onChange={this.handleChange}
                                                validators={['isEmail']}
                                                errorMessages={['Correo electronico invalido']}
                                                InputLabelProps={{ shrink: true }}
                                                InputProps={
                                                    {
                                                        inputProps: { maxLength: 50 }
                                                    }
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                    }

                </AddCatalogue>

                {/*For notifications*/}
                {/*AHDA 31/05/2019: changed variant and message*/}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.openError}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                    <CustomNotification
                        onClose={this.handleClose}
                        variant={this.state.errType}
                        message={this.state.errMsg} />
                </Snackbar>
            </div>
        )
    }
}

export default withStyles(styles)(Catalogo);