import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles-jss'
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
//AHDA 21/05/2019: Added search dependencies
//Search 
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import { AddCircleOutline } from '@material-ui/icons';

class Header extends React.Component {
    //AHDA 24/05/2019
    state = {
        isSearch: false,
        mSearch: ""
    };

    //AHDA 24/05/2019
    //--------------------------------- Search
    handleShowSearch = (show) => {
        this.setState({ isSearch: show });

        if (!show) {
            this.props.search("");
            this.setState({ mSearch: "" });
        }
    }

    //AHDA 24/05/2019
    handleChange = (event) => {
        this.setState({ mSearch: event.target.value });
        this.props.search(event.target.value);
    }
    //--------------------------------- End Search

    render() {

        //AHDA 24/05/2018: Added showSearch, isSearch
        const { title, classes, tooltip, openForm, textBtn, workId, canAdd, showSearch } = this.props;
        const { isSearch } = this.state;

        return (
            <Toolbar className={classes.toolbar}>
                <div className={classes.title}>
                    {/*AHDA 24/05/2019: Search textfield*/}
                    {!isSearch && title}
                    {
                        showSearch && isSearch &&
                        <div>
                            <FormControl>
                                <TextField
                                    label=""
                                    id="searchParam"
                                    name="searchParam"
                                    className={classes.searchWhite}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton aria-label="Search" onClick={() => this.handleShowSearch(false)}>
                                                <Close />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                    value={this.state.mSearch}
                                    onChange={this.handleChange}
                                />
                            </FormControl>
                        </div>
                    }
                </div>
                {/*<div className={classes.spacer} />*/}
                <div className={classes.actions}>

                    {/*AHDA 24/05/2019: Added button for search*/}
                    {
                        showSearch &&
                        <Tooltip title="Buscar">
                            <IconButton aria-label="Search" onClick={() => this.handleShowSearch(true)}>
                                <Search />
                            </IconButton>
                        </Tooltip>
                    }
                    {/* GEO 04/06/2019 Se cambia boton en resoluciones pequeñas. */}
                    {
                        canAdd ? 
                            <span>
                                <Hidden smUp>
                                    <Tooltip title={tooltip}>
                                        <Button variant="fab" mini disabled={!canAdd} onClick={openForm} color="secondary" className={classes.button}>
                                            <AddCircleOutline/>
                                        </Button>
                                    </Tooltip>
                                </Hidden>
                                <Hidden xsDown>
                                    <Button disabled={!canAdd} variant="contained" onClick={openForm} color="secondary" className={classes.button}>
                                        <AddCircleOutline className={classNames(classes.leftIcon, classes.iconSmall)} /> &nbsp; {textBtn}
                                    </Button>
                                </Hidden>
                            </span> :''
                                    
                    }
                </div>
            </Toolbar>
        )
    }
}


Header.propTypes = {
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    openForm: PropTypes.func,
    workId: PropTypes.number,
    canAdd: PropTypes.bool,
    showSearch: PropTypes.bool, //AHDA 24/05/2019 
    search: PropTypes.func //AHDA 24/05/2019
};

//AHDA 24/05/2019: Added defaultProps 
Header.defaultProps = {
    canAdd: true,
    showSearch: true,
    search: () => { }
};

export default withStyles(styles)(Header);