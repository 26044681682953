const styles = (theme) => {
    return {
        dialogPaper: {
            width: '95vw',
            maxWidth: 300,
            padding: theme.spacing.unit * 2,
        },
    };
};

export default styles;
